.Footer {
  width: 100%;
  overflow-x: hidden;
  background-image: url('../../assets/images/footer-gradient.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  background-position: bottom;
}
  .Footer .mobile-device {
    display: block;
  }

  .Footer .web-device {
    display: none;
  }

  .Footer .title-footer {
    color: #000;
    text-align: center;
    font-family: 'Lato Bold';
    font-size: 13px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 13px */
    text-transform: uppercase;
  }

  .Footer .the-paragraph {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 17.6px */

    .second-paragraph {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 11px;
      font-style: normal;
      font-weight: 800;
      line-height: 135%; /* 10.8px */
      text-transform: uppercase;
    }
  }

  .Footer .nav-link {
    color: #000;
    text-align: center;
    font-family: 'Lato Bold';
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 12px */

    a {
      color: #000;
    }
  }

  .Footer .text-1 {
    color: #1F282C;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-right: 30px;
    padding-left: 30px;
  }

  .Footer .text-2 {
    color: #1F282C;
    text-align: center;
    font-family: Lato;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 1rem;
  }

  .Footer .social-icons {
    margin-top: 2rem;
    margin-bottom: 2rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding-left: 25%;
    padding-right: 25%;
  }

  .Footer .facebook-img {
    width: 10px;
    height: auto;
  }

  .Footer .youtube-img {
    width: 30px;
    height: auto;
  }

  .Footer .others-img {
    width: 20px;
    height: auto;
  }

  .Footer .conoce-mas {
    color: #000;
    text-align: center;
    font-family: 'Lato Bold';
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 20px */
    text-transform: uppercase;
  }

  .Footer .conoce-mas-border {
    border-top: 2px solid rgba(0, 113, 147, 0.40);
  }

  .Footer .conoce-mas-title {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Rubik One";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;

    /* position: relative; */
    margin-top: -10vh;
  }

  .Footer .derechos-reservados-border {
    border-top: 1px solid rgba(0, 113, 147, 0.60);
  }

  .Footer .social-icons-container {
    width: 100%;
  }

  .Footer .footer-title-card-width {
    width: 95%;
  }
  
  @media (min-width: 768px) {
    .Footer .text-1 {
      color: #1F282C;
      text-align: center;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-right: 80px;
      padding-left: 80px;
    }
  
    .Footer .text-2 {
      color: #1F282C;
      text-align: center;
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 1rem;
    }
  
    .Footer .social-icons {
      margin-top: 2rem;
      margin-bottom: 2rem;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      padding-left: 15%;
      padding-right: 15%;
    }
  
    .Footer .facebook-img {
      width: 20px;
      height: auto;
    }
  
    .Footer .youtube-img {
      width: 50px;
      height: auto;
    }
  
    .Footer .others-img {
      width: 40px;
      height: auto;
    }

    .Footer .title-footer {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 24px */
    }

    .Footer .the-paragraph {
      color: #000;
      text-align: center;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 17.6px */
  
      .second-paragraph {
        color: #000;
        text-align: center;
        font-family: 'Lato Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }
    }

    .Footer .nav-link {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 10px */
  
      a {
        color: #000;
      }
    }

    .Footer .conoce-mas {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 38px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 20px */
      text-transform: uppercase;
    }
  
    .Footer .conoce-mas-border {
      border-top: 2px solid rgba(0, 113, 147, 0.40);
    }
  
    .Footer .conoce-mas-title {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: "Rubik One";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
  
      /* position: relative; */
      margin-top: -12vh;
    }
  
    .Footer .derechos-reservados-border {
      border-top: 1px solid rgba(0, 113, 147, 0.60);
    }
  
    .Footer .social-icons-container {
      width: 60%;
    }
  }

  /* web 1024 */
  @media (min-width: 1024px) {

    .Footer .mobile-device {
      display: none;
    }
  
    .Footer .web-device {
      display: block;
    }

    .Footer .text-1 {
      color: #1F282C;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    .Footer .text-2 {
      color: #1F282C;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin-top: 1rem;
    }

    .Footer .social-icons {
      margin-top: 5rem;
      margin-bottom: 5rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
    }

    .Footer .social-icon {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .Footer .facebook-img {
      width: 11px;
      height: auto;
    }

    .Footer .youtube-img {
      width: 30px;
      height: auto;
    }

    .Footer .others-img {
      width: 20px;
      height: auto;
    }

    .Footer .margin-footer-bottom {
      margin-top: 4rem;
    }

    .Footer .title-footer {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 20px */
      text-transform: uppercase;
    }

    .Footer .the-paragraph {
      color: #000;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 31.9px */
    }

    .Footer .the-paragraph .second-paragraph {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      line-height: 135%; /* 18.9px */
      text-transform: uppercase;
    }

    .Footer .nav-link {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 19px */
  
      a {
        color: #000;
      }
    }

    .Footer .footer-container {
      width: 85%;
    }

    .Footer .conoce-mas {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 26px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 32px */
      text-transform: uppercase;
    }
  
    .Footer .conoce-mas-border {
      border-top: 3px solid rgba(0, 113, 147, 0.40);
      width: 15%;
    }
  
    .Footer .conoce-mas-title {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: "Rubik One";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
  
      /* position: relative; */
      margin-top: -8vh;
    }
  }

  /* web 1280 */
  @media (min-width: 1280px) {
    .Footer .text-1 {
      color: #1F282C;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    .Footer .text-2 {
      color: #1F282C;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin-top: 1rem;
    }

    .Footer .social-icons {
      margin-top: 5rem;
      margin-bottom: 5rem;
    }

    .Footer .social-icon {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .Footer .facebook-img {
      width: 20px;
      height: auto;
    }

    .Footer .youtube-img {
      width: 50px;
      height: auto;
    }

    .Footer .others-img {
      width: 41px;
      height: auto;
    }

    .Footer .margin-footer-bottom {
      margin-top: 4rem;
    }

    .Footer .footer-container {
      width: 85%;
    }

    .Footer .conoce-mas {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 26px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 32px */
      text-transform: uppercase;
    }
  
    .Footer .conoce-mas-border {
      border-top: 3px solid rgba(0, 113, 147, 0.40);
      width: 15%;
    }
  
    .Footer .conoce-mas-title {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: "Rubik One";
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
  
      /* position: relative; */
      margin-top: -8vh;
    }
  }

  /* web 1366 */
  @media (min-width: 1366px) {
    .Footer .text-1 {
      color: #1F282C;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    .Footer .text-2 {
      color: #1F282C;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin-top: 1rem;
    }

    .Footer .social-icons {
      margin-top: 5rem;
      margin-bottom: 5rem;
    }

    .Footer .social-icon {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }

    .Footer .facebook-img {
      width: 18px;
      height: auto;
    }

    .Footer .youtube-img {
      width: 48px;
      height: auto;
    }

    .Footer .others-img {
      width: 36px;
      height: auto;
    }

    .Footer .margin-footer-bottom {
      margin-top: 4rem;
    }

    .Footer .footer-container {
      width: 85%;
    }

    .Footer .conoce-mas {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 26px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 32px */
      text-transform: uppercase;
    }
  
    .Footer .conoce-mas-border {
      border-top: 3px solid rgba(0, 113, 147, 0.40);
      width: 15%;
    }
  
    .Footer .conoce-mas-title {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: "Rubik One";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
  
      /* position: relative; */
      margin-top: -8vh;
    }
  }

  /* web 1440 */
  @media (min-width: 1440px) {
    .Footer .text-1 {
      color: #1F282C;
      font-family: Lato;
      font-size: 29px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    .Footer .text-2 {
      color: #1F282C;
      font-family: Lato;
      font-size: 29px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin-top: 1rem;
    }

    .Footer .social-icons {
      margin-top: 5rem;
      margin-bottom: 5rem;
    }

    .Footer .social-icon {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .Footer .facebook-img {
      width: 20px;
      height: auto;
    }

    .Footer .youtube-img {
      width: 50px;
      height: auto;
    }

    .Footer .others-img {
      width: 41px;
      height: auto;
    }

    .Footer .margin-footer-bottom {
      margin-top: 4rem;
    }

    .Footer .title-footer {
      color: #000;
      text-align: center;
      font-family: Lato;
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 30px */
    }

    .Footer .the-paragraph {
      color: #000;
      font-family: Lato;
      font-size: 29px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 31.9px */
    }

    .Footer .second-paragraph {
      color: #000;
      text-align: center;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 26.4px */
    }

    .Footer .footer-container {
      width: 85%;
    }

    .Footer .conoce-mas {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 26px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 32px */
      text-transform: uppercase;
    }
  
    .Footer .conoce-mas-border {
      border-top: 3px solid rgba(0, 113, 147, 0.40);
      width: 10%;
    }
  
    .Footer .conoce-mas-title {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: "Rubik One";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
  
      /* position: relative; */
      margin-top: -9vh;
    }
  }

  /* web 1920 */
  @media (min-width: 1920px) {
    .Footer .text-1 {
      color: #1F282C;
      font-family: Lato;
      font-size: 29px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    .Footer .text-2 {
      color: #1F282C;
      font-family: Lato;
      font-size: 29px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin-top: 1rem;
    }

    .Footer .social-icons {
      margin-top: 5rem;
      margin-bottom: 5rem;
    }

    .Footer .social-icon {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .Footer .facebook-img {
      width: 20px;
      height: auto;
    }

    .Footer .youtube-img {
      width: 50px;
      height: auto;
    }

    .Footer .others-img {
      width: 41px;
      height: auto;
    }

    .Footer .margin-footer-bottom {
      margin-top: 4rem;
    }

    .Footer .title-footer {
      color: #000;
      text-align: center;
      font-family: Lato;
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 30px */
    }

    .Footer .the-paragraph {
      color: #000;
      font-family: Lato;
      font-size: 29px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 31.9px */
    }

    .Footer .second-paragraph {
      color: #000;
      text-align: center;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 26.4px */
    }

    .Footer .footer-container {
      width: 85%;
    }

    .Footer .conoce-mas {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 32px */
      text-transform: uppercase;
    }
  
    .Footer .conoce-mas-border {
      border-top: 3px solid rgba(0, 113, 147, 0.40);
      width: 10%;
    }
  
    .Footer .conoce-mas-title {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: "Rubik One";
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
  
      /* position: relative; */
      margin-top: -9vh;
    }

    .Footer .the-paragraph .second-paragraph {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 135%; /* 18.9px */
      text-transform: uppercase;
    }

    .Footer .nav-link {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 19px */
  
      a {
        color: #000;
      }
    }
  }

  /* web 2560 */
  @media (min-width: 2560px) {
    .Footer .text-1 {
      color: #1F282C;
      font-family: Lato;
      font-size: 29px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    .Footer .text-2 {
      color: #1F282C;
      font-family: Lato;
      font-size: 29px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin-top: 1rem;
    }

    .Footer .social-icons {
      margin-top: 5rem;
      margin-bottom: 5rem;
    }

    .Footer .social-icon {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .Footer .facebook-img {
      width: 20px;
      height: auto;
    }

    .Footer .youtube-img {
      width: 50px;
      height: auto;
    }

    .Footer .others-img {
      width: 41px;
      height: auto;
    }

    .Footer .margin-footer-bottom {
      margin-top: 4rem;
    }

    .Footer .title-footer {
      color: #000;
      text-align: center;
      font-family: Lato;
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 30px */
    }

    .Footer .the-paragraph {
      color: #000;
      font-family: Lato;
      font-size: 29px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 31.9px */
    }

    .Footer .second-paragraph {
      color: #000;
      text-align: center;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 26.4px */
    }

    .Footer .footer-container {
      width: 85%;
    }

    .Footer .conoce-mas {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 32px */
      text-transform: uppercase;
    }
  
    .Footer .conoce-mas-border {
      border-top: 3px solid rgba(0, 113, 147, 0.40);
      width: 10%;
    }
  
    .Footer .conoce-mas-title {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: "Rubik One";
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
  
      /* position: relative; */
      margin-top: -7vh;
    }
  }
