.bg-amcl{
    background-color: #E3E559;
}
.bg-rjo{
    background-color:#F84E4E ;
}
.btn-visita{
    color: #FFF;
    text-align: center;
    font-family: Lato;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.4375rem; /* 183.333% */
}
.social-link{
    color: #505050;
    text-align: center;
    font-family: Lato;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.4375rem;
}

.custom-text {
    color: #505050;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 55px; /* 343.75% */
}

@media (max-width: 1440px) {
    .bg-amcl{
        background-color: #E3E559;
    }
    .bg-rjo{
        background-color:#F84E4E ;
    }
    .btn-visita{
        color: #FFF;
        text-align: center;
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem; /* 183.333% */
    }
    .social-link{
        color: #505050;
        text-align: center;
        font-family: Lato;
        font-size: 0.9rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem;
    }
    
    .custom-text {
        color: #505050;
        text-align: center;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 55px; /* 343.75% */
    }

}

@media (max-width: 1366px) {
    .bg-amcl{
        background-color: #E3E559;
    }
    .bg-rjo{
        background-color:#F84E4E ;
    }
    .btn-visita{
        color: #FFF;
        text-align: center;
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem; /* 183.333% */
    }
    .social-link{
        color: #505050;
        text-align: center;
        font-family: Lato;
        font-size: 0.9rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem;
    }
    
    .custom-text {
        color: #505050;
        text-align: center;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 55px; /* 343.75% */
    }
}

@media (max-width: 1024px) {
    .bg-amcl{
        background-color: #E3E559;
    }
    .bg-rjo{
        background-color:#F84E4E ;
    }
    .btn-visita{
        color: #FFF;
        text-align: center;
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem; /* 183.333% */
    }
    .social-link{
        color: #505050;
        text-align: center;
        font-family: Lato;
        font-size: 0.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem;
    }
    
    .custom-text {
        color: #505050;
        text-align: center;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 55px; /* 343.75% */
    }
}

@media screen and (max-width:900px){
    .btn-visita{
        font-size: 1.5rem;
        line-height: 2.3rem;
    }
    .social-link{
        color: #505050;
        text-align: center;
        font-family: Lato;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem;
    }
}
@media screen and (max-width:600px){
    .social-link{
        color: #505050;
        text-align: center;
        font-family: Lato;
        font-size: 0.9rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem;
    }
}