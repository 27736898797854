.mobile-device {
    display: block;
}

.web-device {
    display: none;
}

.camp-bg{
    background: #E9EDF0;
}
.camp-overlap{
    position: absolute;
    bottom: 0;
    margin-left: -20px;
    max-height: 80%;
    max-width: 50%;
}
.camp-play-overlay{
    position: absolute;
    left: 50%;
    top: 50%;
    cursor: pointer;
    z-index: 20;
    -webkit-transform:translate(-50%,-50%);
    transform:translate(-50%,-50%);
    width: 2rem;
}
.camp-conoce{
    color: #1F282C;
    font-family: Ruda;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-align: left;
}
.camp-camp{
    color: #FFF;
    font-family: "Rubik One";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.camp-textos{
    position: absolute;
    left: 40%;
    top: 20%;
    z-index: 20;
    -webkit-transform:translate(-50%,-50%);
    transform:translate(-50%,-50%);
}

@media (min-width: 768px) {
    .camp-bg{
        background: #E9EDF0;
    }
    .camp-overlap{
        position: absolute;
        bottom: 0;
        margin-left: -20px;
        max-height: 80%;
        max-width: 50%;
    }
    .camp-play-overlay{
        position: absolute;
        left: 50%;
        top: 50%;
        cursor: pointer;
        z-index: 20;
        -webkit-transform:translate(-50%,-50%);
        transform:translate(-50%,-50%);
        width: 3rem;
    }
    .camp-conoce{
        color: #1F282C;
        font-family: Ruda;
        font-size: 34px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-align: left;
    }
    .camp-camp{
        color: #FFF;
        font-family: "Rubik One";
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .camp-textos{
        position: absolute;
        left: 45%;
        top: 15%;
        z-index: 20;
        -webkit-transform:translate(-50%,-50%);
        transform:translate(-50%,-50%);
    }
}

@media (min-width: 1024px) {
    .mobile-device {
        display: none;
    }
    
    .web-device {
        display: block;
    }

    .camp-bg{
        background: #E9EDF0;
    }
    .camp-overlap{
        position: absolute;
        bottom: 0;
        margin-left: 0px;
        max-height: 80%;
        max-width: 50%;
    }
    .camp-play-overlay{
        position: absolute;
        left: 50%;
        top: 50%;
        cursor: pointer;
        z-index: 20;
        -webkit-transform:translate(-50%,-50%);
        transform:translate(-50%,-50%);
    }
    .camp-conoce{
        font-family: Ruda;
        font-size: 40px;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 0em;
        text-align: left;
    }
    .camp-camp{
        font-family: 'Rubik One';
        font-size: 30px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0em;
        text-align: left;    
        color: #fff;
    }
    .camp-textos{
        position: absolute;
        left: 45%;
        top: 25%;
        z-index: 20;
        transform: translate(-50%,-50%);
    }
}

@media (min-width: 1280px) {
    .camp-bg{
        background: #E9EDF0;
    }
    .camp-overlap{
        position: absolute;
        bottom: 0;
        margin-left: 0px;
        max-height: 80%;
        max-width: 50%;
    }
    .camp-play-overlay{
        position: absolute;
        left: 50%;
        top: 50%;
        cursor: pointer;
        z-index: 20;
        -webkit-transform:translate(-50%,-50%);
        transform:translate(-50%,-50%);
    }
    .camp-conoce{
        font-family: Ruda;
        font-size: 45px;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 0em;
        text-align: left;
    }
    .camp-camp{
        font-family: 'Rubik One';
        font-size: 35px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0em;
        text-align: left;    
        color: #fff;
    }
    .camp-textos{
        position: absolute;
        left: 45%;
        top: 25%;
        z-index: 20;
        transform: translate(-50%,-50%);
    }
}

@media (min-width: 1366px) {
    .camp-bg{
        background: #E9EDF0;
    }
    .camp-overlap{
        position: absolute;
        bottom: 0;
        margin-left: 0px;
        max-height: 80%;
        max-width: 50%;
    }
    .camp-play-overlay{
        position: absolute;
        left: 50%;
        top: 50%;
        cursor: pointer;
        z-index: 20;
        -webkit-transform:translate(-50%,-50%);
        transform:translate(-50%,-50%);
    }
    .camp-conoce{
        font-family: Ruda;
        font-size: 58px;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 0em;
        text-align: left;
    }
    .camp-camp{
        font-family: 'Rubik One';
        font-size: 38px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0em;
        text-align: left;    
        color: #fff;
    }
    .camp-textos{
        position: absolute;
        left: 45%;
        top: 25%;
        z-index: 20;
        transform: translate(-50%,-50%);
    }
}

@media (min-width: 1440px) {
    .camp-bg{
        background: #E9EDF0;
    }
    .camp-overlap{
        position: absolute;
        bottom: 0;
        margin-left: 0px;
        max-height: 80%;
        max-width: 50%;
    }
    .camp-play-overlay{
        position: absolute;
        left: 50%;
        top: 50%;
        cursor: pointer;
        z-index: 20;
        -webkit-transform:translate(-50%,-50%);
        transform:translate(-50%,-50%);
    }
    .camp-conoce{
        font-family: Ruda;
        font-size: 60px;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 0em;
        text-align: left;
    }
    .camp-camp{
        font-family: 'Rubik One';
        font-size: 45px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0em;
        text-align: left;    
        color: #fff;
    }
    .camp-textos{
        position: absolute;
        left: 45%;
        top: 25%;
        z-index: 20;
        transform: translate(-50%,-50%);
    }
}