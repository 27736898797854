.temprano .imp-par {
    color: #000;
    font-family: 'Ruda Bold';
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    text-align: left;
}

.temprano .imp-par .highlight {
    color: var(--et-color);
    font-family: "Rubik One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
}

.temprano .imp-par-2 {
    color: #1F282C;
    font-family: Lato;
    font-size: 25px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px;
    text-align: start;
}

.temprano .imp-par-2 .highlight {
    color: var(--et-color);
    font-family: "Rubik One";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
}

.temprano .bg-team {
    min-height: 75vh;
    background-image: url('../../../../assets/images/programas/tecnolochicas/WhatsApp Image 2023-08-29 at 18.24 3.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.temprano .rect-gr {
    background-image: url('../../../../assets/images/programas/tecnolochicas/Rectanglegr.png');
    background-repeat: no-repeat;
    padding: 1rem;
}
.et-custom-rec{
    object-fit: cover;
    max-height: 470px;
    object-position: 0 89%;
}

@media screen and (max-width:1440px) {
    .temprano .imp-par {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
        text-align: left;
    }
    
    .temprano .imp-par .highlight {
        color: var(--et-color);
        font-family: "Rubik One";
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
    }
    
    .temprano .imp-par-2 {
        color: #1F282C;
        font-family: Lato;
        font-size: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: 40px;
        text-align: start;
    }
    
    .temprano .imp-par-2 .highlight {
        color: var(--et-color);
        font-family: "Rubik One";
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px;
    }
}

@media screen and (max-width: 1366px) {
    .temprano .imp-par {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
        text-align: left;
    }
    
    .temprano .imp-par .highlight {
        color: var(--et-color);
        font-family: "Rubik One";
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px;
    }
    
    .temprano .imp-par-2 {
        color: #1F282C;
        font-family: Lato;
        font-size: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: 40px;
        text-align: start;
    }
    
    .temprano .imp-par-2 .highlight {
        color: var(--et-color);
        font-family: "Rubik One";
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px;
    }
    
    .temprano .bg-team {
        min-height: 75vh;
        background-image: url('../../../../assets/images/programas/tecnolochicas/WhatsApp Image 2023-08-29 at 18.24 3.png');
        background-size: cover;
        background-repeat: no-repeat;
    }
    
    .temprano .rect-gr {
        background-image: url('../../../../assets/images/programas/tecnolochicas/Rectanglegr.png');
        background-repeat: no-repeat;
        padding: 1rem;
    }
}

@media screen and (max-width: 1024px) {
    .temprano .imp-par {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
        text-align: left;
    }
    
    .temprano .imp-par .highlight {
        color: var(--et-color);
        font-family: "Rubik One";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
    }
    
    .temprano .imp-par-2 {
        color: #1F282C;
        font-family: Lato;
        font-size: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: 40px;
        text-align: start;
    }
    
    .temprano .imp-par-2 .highlight {
        color: var(--et-color);
        font-family: "Rubik One";
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px;
    }
    
    .temprano .bg-team {
        min-height: 75vh;
        background-image: url('../../../../assets/images/programas/tecnolochicas/WhatsApp Image 2023-08-29 at 18.24 3.png');
        background-size: cover;
        background-repeat: no-repeat;
    }
    
    .temprano .rect-gr {
        background-image: url('../../../../assets/images/programas/tecnolochicas/Rectanglegr.png');
        background-repeat: no-repeat;
        padding: 1rem;
    }
}

@media screen and (max-width:900px){
    .temprano .imp-par {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
        text-align: center;
    }
    
    .temprano .imp-par .highlight {
        color: var(--et-color);
        font-family: "Rubik One";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px;
    }
    
    .temprano .imp-par.w-75 {
        padding: 1.5rem 0;
        width: 90% !important;
    }
    .et-custom-rec{
        object-fit: unset;
        max-height: unset;
        object-position:unset;
    }
}

@media screen and (max-width:600px){
    .temprano .imp-par {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
        text-align: center;
    }
    
    .temprano .imp-par .highlight {
        color: var(--et-color);
        font-family: "Rubik One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
    }
    
    .temprano .imp-par.w-75 {
        padding: 1.5rem 0 0;
        width: 90% !important;
    }
    
}