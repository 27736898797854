.golxmex .imp-par{
    color: #000;
font-family: Ruda;
font-size: 32px;
font-style: normal;
font-weight: 800;
line-height: 58px;
text-align: start;

}
.golxmex .imp-par .highlight{
    color: #FF8434;
font-family: "Rubik One";
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: 58px; /* 93.548% */
text-align: start;

}

.golxmex .imp-par-2 .highlight{
    color: #FF8434;
    font-family: "Rubik One";
    font-size: 3rem;
}

.golxmex .imp-par-2 {
    color: #000;
    font-family: Ruda;
    font-weight: 800;
    font-size: 2.125rem;
}

.golxmex .bg-team{
    min-height: 63vh;
    background-image: url('../../../../assets/images/programas/golxmex/Penalti_por_Mexico_2_1.png');
    background-size: cover;
    background-repeat: no-repeat;
    
}
.golxmex .rect-gr{
    background-image: url('../../../../assets/images/programas/golxmex/Rectanglegrad.png');
    background-repeat: no-repeat;
    padding: 1rem  0 1rem 1rem;
    background-size: cover;
}

.golxmex .bg-golxmexfem {
    background-image: url('../../../../assets/images/programas/golxmex/golxmexfem.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 835px;
    background-position: center;
}
.golxmex .bg-golxmexfem .text-content {
        position: relative;
        padding-top: 600px;
    }


.golxmex .golxmexfem-description {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.25);
    font-family: Ruda;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 44px; /* 157.143% */
    width: 80%;
}
    .golxmex .golxmexfem-description b {
        color: #FFF;
        font-family: "Rubik One";
        font-size: 42px;
        font-style: normal;
        font-weight: 400;
        line-height: 44px;
    }

.golxmex .golxmexfem-description b {
    color: #FFF;
    font-family: "Rubik One";
    font-size: inherit;
    font-style: normal;
    font-weight: 400;
    line-height: inherit;
}
.golxmex .bg-paragraph-golxmexfem {
    background: linear-gradient(270deg, rgba(225, 204, 187, 0.00) 0%, rgba(234, 112, 33, 0.72) 100%);
}

@media screen and (max-width:1600px) {
    .golxmex .imp-par {
        font-size: 1.5rem;
        line-height: 150%;
        padding: 2rem 0;
    }
    .golxmex .imp-par .highlight{
        font-size: 1.5rem;
        line-height: 150%;    
    }
    .golxmex .imp-par.w-75{
        width: 80%!important;
        text-align: center;
        padding: 2rem 0;
    }
    .golxmex .bg-team{
        min-height: 60vh;
        background-image: url('../../../../assets/images/programas/golxmex/Penalti_por_Mexico_2_2.png');
        background-size: cover;
        background-repeat: no-repeat;
        
    }
    .golxmex .imp-par-2 .highlight{
        color: #FF8434;
        font-family: "Rubik One";
        font-size: 3rem;
    }
    .golxmex .imp-par-2 {
        color: #000;
        font-family: Ruda;
        font-weight: 800;
        font-size: 2.125rem;
    }

    .golxmex .rect-gr{
        background-image: url('../../../../assets/images/programas/golxmex/Rectanglegrad.png');
        background-repeat: no-repeat;
        padding: 0.5rem  0 0.5rem 1rem;
        background-size: cover;
    }

    .golxmex .bg-golxmexfem {
        background-image: url('../../../../assets/images/programas/golxmex/golxmexfem.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 835px;
        background-position: center;
    }
    .golxmex .bg-golxmexfem .text-content {
            position: relative;
            padding-top: 600px;
        }


    .golxmex .golxmexfem-description {
        color: #FFF;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.25);
        font-family: Ruda;
        font-size: 23px;
        font-style: normal;
        font-weight: 800;
        line-height: 44px; /* 157.143% */
        width: 85%;
    }
        .golxmex .golxmexfem-description b {
            color: #FFF;
            font-family: "Rubik One";
            font-size: 33px;
            font-style: normal;
            font-weight: 400;
            line-height: 44px;
        }
    
}

@media screen and (max-width:1366px) {
    .golxmex .imp-par {
        font-size: 1.5rem;
        line-height: 150%;
        padding: 2rem 0;
    }
    .golxmex .imp-par .highlight{
        font-size: 1.5rem;
        line-height: 150%;    
    }
    .golxmex .imp-par.w-75{
        width: 80%!important;
        text-align: center;
        padding: 2rem 0;
    }
    .golxmex .bg-team{
        min-height: 60vh;
        background-image: url('../../../../assets/images/programas/golxmex/Penalti_por_Mexico_2_2.png');
        background-size: cover;
        background-repeat: no-repeat;
        
    }

    .golxmex .rect-gr{
        background-image: url('../../../../assets/images/programas/golxmex/Rectanglegrad.png');
        background-repeat: no-repeat;
        padding: 0.5rem  0 0.5rem 1rem;
        background-size: cover;
    }

    .golxmex .bg-golxmexfem {
        background-image: url('../../../../assets/images/programas/golxmex/golxmexfem.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 835px;
        background-position: center;
    }
    .golxmex .bg-golxmexfem .text-content {
            position: relative;
            padding-top: 600px;
        }


    .golxmex .golxmexfem-description {
        color: #FFF;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.25);
        font-family: Ruda;
        font-size: 2p0x;
        font-style: normal;
        font-weight: 800;
        line-height: 35px; /* 157.143% */
        width: 80%;
    }
        .golxmex .golxmexfem-description b {
            color: #FFF;
            font-family: "Rubik One";
            font-size: 30px;
            font-style: normal;
            font-weight: 400;
            line-height: 35px;
        }
    

    .golxmex .imp-par-2 {
        color: #000;
        font-family: Ruda;
        font-weight: 800;
        font-size: 1.7rem;
    }

    .golxmex .imp-par-2 .highlight{
        color: #FF8434;
        font-family: "Rubik One";
        font-size: 2rem;
    }
}

@media screen and (max-width:1024px){
    .golxmex .imp-par {
        font-size: 1.5rem;
        line-height: 150%;
        padding: 2rem 0;
    }
    .golxmex .imp-par .highlight{
        font-size: 1.5rem;
        line-height: 150%;    
    }
    .golxmex .imp-par.w-75{
        width: 80%!important;
        text-align: center;
        padding: 2rem 0;
    }
    .golxmex .bg-team{
        min-height: 60vh;
        background-image: url('../../../../assets/images/programas/golxmex/Penalti_por_Mexico_2_2.png');
        background-size: cover;
        background-repeat: no-repeat;
        
    }

    .golxmex .rect-gr{
        background-image: url('../../../../assets/images/programas/golxmex/Rectanglegrad.png');
        background-repeat: no-repeat;
        padding: 0.5rem  0 0.5rem 1rem;
        background-size: cover;
    }

    .golxmex .bg-golxmexfem {
        background-image: url('../../../../assets/images/programas/golxmex/golxmexfem.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 835px;
        background-position: center;
    }
        .golxmex .bg-golxmexfem .text-content {
            position: relative;
            padding-top: 600px;
        }


    .golxmex .golxmexfem-description {
        color: #FFF;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.25);
        font-family: Ruda;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 34px; /* 157.143% */
        width: 80%;
}
        .golxmex .golxmexfem-description b {
            color: #FFF;
            font-family: "Rubik One";
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 34px;
        }
    
    .golxmex .imp-par-2 .highlight{
        color: #FF8434;
        font-family: "Rubik One";
        font-size: 2.5rem;
    }
    .golxmex .imp-par-2 {
        color: #000;
        font-family: Ruda;
        font-weight: 800;
        font-size: 1.785rem;
    }
}

@media screen and (max-width:900px){
    .golxmex .imp-par {
        font-size: 2rem;
        line-height: 150%;
        padding: 2rem 0;
    }
    .golxmex .imp-par .highlight{
        font-size: 2rem;
        line-height: 150%;    
    }
    .golxmex .imp-par.w-75{
        width: 80%!important;
        text-align: center;
        padding: 2rem 0;
    }
    .golxmex .bg-team{
        min-height: 60vh;
        background-image: url('../../../../assets/images/programas/golxmex/Penalti_por_Mexico_2_2.png');
        background-size: cover;
        background-repeat: no-repeat;
        
    }

    .golxmex .rect-gr{
        background-image: url('../../../../assets/images/programas/golxmex/Rectanglegrad.png');
        background-repeat: no-repeat;
        padding: 0.5rem  0 0.5rem 1rem;
        background-size: cover;
    }

    .golxmex .bg-golxmexfem {
        background-image: url('../../../../assets/images/programas/golxmex/golxmexfem.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 730px;
    }
        .golxmex .bg-golxmexfem .text-content {
            position: relative;
            padding-top: 500px;
        }


    .golxmex .golxmexfem-description {
        color: #FFF;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.25);
        font-family: Ruda;
        font-size: 19px;
        font-style: normal;
        font-weight: 800;
        line-height: 30px; /* 157.143% */
        width: 80%;
}
        .golxmex .golxmexfem-description b {
            color: #FFF;
            font-family: "Rubik One";
            font-size: 23px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
        }
    
    .golxmex .imp-par-2 .highlight{
        color: #FF8434;
        font-family: "Rubik One";
        font-size: 2.5rem;
        font-size: 1.785rem;


    }
    .golxmex .imp-par-2 {
        color: #000;
        font-family: "Ruda Bold";
        font-weight: 800;
        font-size: 1.785rem;
        padding:  1rem 0;

    }
}

@media screen and (max-width:600px){
    .golxmex .imp-par {
        font-size:19px;
        line-height: 130%;
        padding: 0;
    }
    .golxmex .imp-par .highlight{
        font-size:19px;
        line-height: 130%;
        text-align: center;   
    }
    .golxmex .imp-par.w-75{
        width: 90%!important;
        text-align: center;
        padding: 2rem 0;
    }
    .golxmex .bg-team{
        min-height: 60vh;
        background-image: url('../../../../assets/images/programas/golxmex/Penalti_por_Mexico_2_2.png');
        background-size: cover;
        background-repeat: no-repeat;
        
    }

    .golxmex .rect-gr{
        background-image: url('../../../../assets/images/programas/golxmex/Rectanglegrad.png');
        background-repeat: no-repeat;
        padding: 0.5rem  0 0.5rem 1rem;
        background-size: cover;
    }

    .golxmex .bg-golxmexfem {
        background-image: url('../../../../assets/images/programas/golxmex/golxmexfem.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 270px;
    }
        .golxmex .bg-golxmexfem .text-content {
            position: relative;
            padding-top: 125px;
        }


    .golxmex .bg-paragraph-golxmexfem {
        background: linear-gradient(270deg, rgba(225, 204, 187, 0.00) 0%, rgba(234, 112, 33, 0.72) 100%);
    }

    .golxmex .golxmexfem-description {
        color: #FFF;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.25);
        font-family: Ruda;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 17px; /* 157.143% */
        width: 90%;
}
    .golxmex .golxmexfem-description b {
        color: #FFF;
        font-family: "Rubik One";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
    }
    
    .golxmex .imp-par-2 .highlight{
        color: #FF8434;
        font-family: "Rubik One";
        font-size: 1.25rem;
        padding:  1.5rem 0;
    }
    .golxmex .imp-par-2 {
        color: #000;
        font-family: "Ruda Bold";
        font-weight: 800;
        font-size: 1.25rem;
        padding:  1.5rem 0;
    }
}