.cuantrix .bg-nar {
    background-color: #3F8EFF;
  }
  
  .cuantrix .com-bg-gris {
    background-color: #DDE5F1;
  }
  
  .cuantrix .obj-tit {
    text-align: start;
  }
  
  .cuantrix .obj-par {
    text-align: start;
    color: #FFF;
    font-family: Lato;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    b {
      color: #FFF;
      font-family: 'Lato Bold';
      font-size: 25px;
      font-style: normal;
      font-weight: bolder;
      line-height: normal;
    }
  }
  
  .cuantrix .com-dig {
    color: #3F8EFF;
    font-family: "Rubik One";
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 72px */
    text-transform: uppercase;
    text-align: start;

    
  }
  
  .cuantrix .com-dig-par {
    color: #000;
    font-family: 'Ruda Bold';
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: 45px;
    text-align: start;
  }
  
  .cuantrix .com-dig-par .highlight {
    color: #3F8EFF;
    font-family: "Rubik One";
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px; /* 132.353% */
  }
  
  .cuantrix .ctx-com-quote {
    color: #1F282C;
    font-family: Ruda;
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
  }
  
  .cuantrix .ctx-com-quote .bold {
    font-family: 'Ruda Bold';
    font-weight: bolder;
  }
  
  .cuantrix .com-h {
    min-height: 150px;
    padding: 10%;
  }

  .cuantrix .bg-com-dig-light {
    background-color: #F2F8FF;
  }

  .cuantrix .bullet {
    width: 10px;
    height: 10px;
    min-width: 10px;
    background: #A4C9FF;
    margin-top: 15px;
  }

  .cuantrix .comunidades-digitales-titulo {
    color: #3F8EFF;
  }

  .cuantrix .comunidades-digitales-items .highlight {
    color: var(--ctx-color);
  }

  @media screen and (max-width: 1440px) {
    .cuantrix .obj-tit {
      text-align: start;
      padding: 0rem;
      color: #FFF;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
    .cuantrix .obj-par {
      text-align: start;
      color: #FFF;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
  
      b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 20px;
        font-style: normal;
        font-weight: bolder;
        line-height: normal;
      }
    }
    .cuantrix .com-dig {
      color: #3F8EFF;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 72px */
      text-transform: uppercase;
      text-align: start;
  
      
    }
    
    .cuantrix .com-dig-par {
      color: #000;
      font-family: 'Ruda Bold';
      font-size: 26px;
      font-style: normal;
      font-weight: 800;
      line-height: 35px;
      text-align: start;
    }
    
    .cuantrix .com-dig-par .highlight {
      color: #3F8EFF;
      font-family: "Rubik One";
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 35px; /* 132.353% */
    }
    .cuantrix .ctx-com-quote {
      color: #1F282C;
      font-family: Ruda;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
    }
    .cuantrix .com-h {
      min-height: 150px;
      padding: 5%;
    }

    .cuantrix .bg-com-dig-light {
      background-color: #F2F8FF;
    }

    .cuantrix .img-objetivos {
      width: 85px;
    }
  }

  @media screen and (max-width: 1366px) {
    .cuantrix .obj-tit {
      text-align: start;
      padding: 0rem;
      color: #FFF;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
    .cuantrix .obj-par {
      text-align: start;
      color: #FFF;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
  
      b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 20px;
        font-style: normal;
        font-weight: bolder;
        line-height: normal;
      }
    }
    .cuantrix .com-dig {
      color: #3F8EFF;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 72px */
      text-transform: uppercase;
      text-align: start;
  
      
    }
    
    .cuantrix .com-dig-par {
      color: #000;
      font-family: 'Ruda Bold';
      font-size: 26px;
      font-style: normal;
      font-weight: 800;
      line-height: 35px;
      text-align: start;
    }
    
    .cuantrix .com-dig-par .highlight {
      color: #3F8EFF;
      font-family: "Rubik One";
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 35px; /* 132.353% */
    }
    .cuantrix .ctx-com-quote {
      color: #1F282C;
      font-family: Ruda;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
    }
    .cuantrix .com-h {
      min-height: 150px;
      padding: 5%;
    }

    .cuantrix .bg-com-dig-light {
      background-color: #F2F8FF;
    }

    .cuantrix .img-objetivos {
      width: 88px;
    }
  }

  @media screen and (max-width: 1024px) {
    .cuantrix .obj-tit {
      text-align: start;
      padding: 0rem;
      color: #FFF;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
    .cuantrix .obj-par {
      text-align: start;
      color: #FFF;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
  
      b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 14px;
        font-style: normal;
        font-weight: bolder;
        line-height: normal;
      }
    }
    .cuantrix .com-dig {
      color: #3F8EFF;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 72px */
      text-transform: uppercase;
      text-align: start;
  
      
    }
    
    .cuantrix .com-dig-par {
      color: #000;
      font-family: 'Ruda Bold';
      font-size: 21px;
      font-style: normal;
      font-weight: 800;
      line-height: 30px;
      text-align: start;
    }
    
    .cuantrix .com-dig-par .highlight {
      color: #3F8EFF;
      font-family: "Rubik One";
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 132.353% */
    }
    .cuantrix .ctx-com-quote {
      color: #1F282C;
      font-family: Ruda;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
    }
    .cuantrix .com-h {
      min-height: 150px;
      padding: 5%;
    }

    .cuantrix .bg-com-dig-light {
      background-color: #F2F8FF;
    }
  }

  @media screen and (max-width: 768px) {
    .cuantrix .obj-tit {
      text-align: center;
      font-family: Lato;
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      padding-top: 1rem;
    }
    .cuantrix .obj-par {
      text-align: center;
      color: #FFF;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
  
      b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 25px;
        font-style: normal;
        font-weight: bolder;
        line-height: normal;
      }
    }
    .cuantrix .img-objetivos{
      width: 280px;
    }
    .cuantrix .com-dig {
      color: var(--ctx-color);
      font-family: "Rubik One";
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 86.4px */
      text-transform: uppercase;
      text-align: center;
    }
    .cuantrix .com-dig-par {
      color: #1F282C;
      font-family: Lato;
      font-size: 26px;
      font-style: normal;
      font-weight: 800;
      line-height: 32px;
      text-align: left;
    }
    .cuantrix .com-dig-par .highlight {
      color: var(--ctx-color);
      font-family: "Rubik One";
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px; /* 118.421% */
    }
    .cuantrix .ctx-com-quote {
      color: #1F282C;
      font-family: Ruda;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
    }
    .cuantrix .com-h {
      min-height: 150px;
      padding: 5%;
    }

    .cuantrix .bg-com-dig-light {
      background-color: #F2F8FF;
    }
  }

  @media screen and (max-width: 600px) {
    .cuantrix .obj-tit {
      text-align: center;
      font-family: Lato;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      padding-top: 1rem;
    }
    .cuantrix .obj-par {
      text-align: center;
      color: #FFF;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
  
      b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: bolder;
        line-height: normal;
      }
    }
    .cuantrix .com-dig {
      color: #3F8EFF;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 86.4px */
      text-transform: uppercase;
      text-align: center;
    }
    .cuantrix .comunidades-digitales-titulo {
      color: #3F8EFF;
    }
    .cuantrix .com-dig-par {
      color: #1F282C;
      font-family: Lato;
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 30px;
      text-align: left;
    }
    .cuantrix .comunidades-digitales-items .highlight {
      color: var(--ctx-color);
    }

    .cuantrix .com-dig-par .highlight {
      color: var(--ctx-color);
      font-family: "Rubik One";
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 118.421% */
    }
    .cuantrix .ctx-com-quote {
      color: #1F282C;
      font-family: Ruda;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
    }
    .cuantrix .com-h {
      min-height: 150px;
      padding: 5%;
    }

    .cuantrix .img-objetivos {
      width: 140px;
      margin: .25rem;
    }

    .cuantrix .bg-com-dig-light {
      background-color: #F2F8FF;
    }
  }

  @media screen and (max-width: 360px) {
    .cuantrix .obj-tit {
      text-align: center;
      padding: 0;
    }
    .cuantrix .obj-par {
      font-size: 14px;
      line-height: 22px;
      text-align: start;
    }
    .cuantrix .com-dig {
      color: var(--ctx-color);
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 86.4px */
      text-transform: uppercase;
      text-align: center;
    }
    .cuantrix .com-dig-par {
      color: #1F282C;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 132%;
      text-align: center;
    }
    .cuantrix .com-dig-par .highlight {
      color: var(--ctx-color);
      font-family: "Rubik One";
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 132%; /* 118.421% */
    }
    .cuantrix .ctx-com-quote {
      color: #1F282C;
      font-family: Ruda;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
    }
    .cuantrix .com-h {
      min-height: 150px;
      padding: 5%;
    }

    .cuantrix .img-objetivos {
      width: 100px;
      margin: .25rem;
    }

    .cuantrix .bg-com-dig-light {
      background-color: #F2F8FF;
    }
  }  