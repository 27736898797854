.ProgramasObjetivosOnu {
  width: 100%;
  background: #E8BA4A;
}

.ProgramasObjetivosOnu .mobile-device {
  display: block;
}

.ProgramasObjetivosOnu .web-device {
  display: none;
}

.ProgramasObjetivosOnu .text-container-objetivos {
  padding: 2rem 1rem;
}

.ProgramasObjetivosOnu .title-objetivos {
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 28.6px */
}

.ProgramasObjetivosOnu .paragraph-objetivos {
  color: #FFF;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  text-align: left;
}

.ProgramasObjetivosOnu .paragraph-objetivos b {
  color: #FFF;
  font-family: 'Lato Bold';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 20px */
}
.ProgramasObjetivosOnu .paragraph-onu b{
  color: #FFF;

}

  @media screen and (min-width: 768px) {
    .ProgramasObjetivosOnu .text-container-objetivos {
        padding: 2rem 1rem;
    }
  
    .ProgramasObjetivosOnu .title-objetivos {
        color: #FFF;
        text-align: center;
        font-family: Lato;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 71.5px */
    }
  
    .ProgramasObjetivosOnu .paragraph-objetivos {
        color: #FFF;
        font-family: Lato;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
        text-align: left;
    }

    .ProgramasObjetivosOnu .paragraph-objetivos b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 125%; /* 35px */
    }
}

@media (min-width: 1024px) {
    .ProgramasObjetivosOnu .mobile-device {
        display: none;
    }
  
    .ProgramasObjetivosOnu .web-device {
        display: block;
    }
  
    .ProgramasObjetivosOnu .text-container-objetivos {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 6rem;
        padding-right: 0px;
    }
  
    .ProgramasObjetivosOnu .title-objetivos {
        color: #FFF;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 52px */
        text-align: left;
    }
  
    .ProgramasObjetivosOnu .paragraph-objetivos {
        color: #FFF;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .ProgramasObjetivosOnu .paragraph-objetivos b {
        color: #FFF;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

@media (min-width: 1365px) {
    .ProgramasObjetivosOnu .mobile-device {
        display: none;
    }
  
    .ProgramasObjetivosOnu .web-device {
        display: block;
    }
  
    .ProgramasObjetivosOnu .text-container-objetivos {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 2rem;
        padding-right: 0px;
    }
  
    .ProgramasObjetivosOnu .title-objetivos {
        color: #FFF;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 52px */
        text-align: left;
    }
  
    .ProgramasObjetivosOnu .paragraph-objetivos {
        color: #FFF;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .ProgramasObjetivosOnu .paragraph-objetivos b {
        color: #FFF;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

@media (min-width: 1440px) {
    .ProgramasObjetivosOnu .mobile-device {
        display: none;
    }
  
    .ProgramasObjetivosOnu .web-device {
        display: block;
    }
  
    .ProgramasObjetivosOnu .text-container-objetivos {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 6rem;
        padding-right: 0px;
    }
  
    .ProgramasObjetivosOnu .title-objetivos {
        color: #FFF;
        font-family: Lato;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 52px */
        text-align: left;
    }
  
    .ProgramasObjetivosOnu .paragraph-objetivos {
        color: #FFF;
        font-family: Lato;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .ProgramasObjetivosOnu .paragraph-objetivos b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}
