.HeaderFotograficaMx {
  width: 100%;
}

.HeaderFotograficaMx .mobile-device {
  display: block;
}

.HeaderFotograficaMx .web-device {
  display: none;
}

.HeaderFotograficaMx .bg-gradient-fotomx {
  background: linear-gradient(180deg, rgba(165, 92, 194, 0.46) -8.36%, rgba(255, 255, 255, 0.00) 80.54%);
}

.HeaderFotograficaMx .title {
  color: #A55CC2;
  text-align: center;
  font-family: "Rubik One";
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.HeaderFotograficaMx .subtitle {
  color: #1F282C;
  text-align: center;
  font-family: Ruda;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.HeaderFotograficaMx .paragraph-hf {
  color: #1F282C;
  text-align: center;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 138%; /* 16.56px */
}

.HeaderFotograficaMx .paragraph-hf b {
  color: #1F282C;
  font-family: 'Lato Bold';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 138%;
}

.HeaderFotograficaMx .bg-gray {
  background: #69747C;
}

.HeaderFotograficaMx .paragraph-hf-2 {
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  width: 100%;
}

.HeaderFotograficaMx .paragraph-hf-2 b {
  color: #FFF;
  text-align: center;
  font-family: 'Lato Bold';
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 23.75px */
}

.HeaderFotograficaMx .bg-purple {
  background: #A55CC2;
}

.main-title-programas {
  color: #A55CC2;
}

  @media (min-width: 768px) {
    .HeaderFotograficaMx {
      width: 100%;
    }
    
    .HeaderFotograficaMx .title {
      color: #A55CC2;
      text-align: center;
      font-family: "Rubik One";
      font-size: 68px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
    
    .HeaderFotograficaMx .subtitle {
      color: #1F282C;
      text-align: center;
      font-family: Ruda;
      font-size: 36px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
    
    .HeaderFotograficaMx .paragraph-hf {
      color: #1F282C;
      text-align: center;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 142%; /* 34.08px */
    }
    
    .HeaderFotograficaMx .paragraph-hf b {
      color: #1F282C;
      font-family: 'Lato Bold';
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 142%;
    }
    
    .HeaderFotograficaMx .bg-gray {
      background: #69747C;
    }
    
    .HeaderFotograficaMx .paragraph-hf-2 {
      color: #FFF;
    text-align: center;
      font-family: Lato;
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      width: 100%;
    }
    
    .HeaderFotograficaMx .paragraph-hf-2 b {
      color: #FFF;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 42px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%; /* 52.5px */
    }
    
    .HeaderFotograficaMx .bg-purple {
      background: #A55CC2;
    }
  }
  

  @media (min-width: 1024px) {
    .HeaderFotograficaMx .mobile-device {
        display: none;
    }

    .HeaderFotograficaMx .tablet-device {
        display: none;
    }
  
    .HeaderFotograficaMx .web-device {
        display: block;
    }

    .HeaderFotograficaMx .bg-gradient-fotomx {
        background: linear-gradient(180deg, rgba(165, 92, 194, 0.46) -8.36%, rgba(255, 255, 255, 0.00) 80.54%);
        width: auto;
    }

    .HeaderFotograficaMx .bg-hero {
        background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/hero.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
    }
  
    .HeaderFotograficaMx .title-fotomx {
        color: #A55CC2;
        font-family: "Rubik One";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: left;
    }
  
    .HeaderFotograficaMx .subtitle-fotomx {
        color: #1F282C;
        font-family: Ruda;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-align: left;
    }
  
    .HeaderFotograficaMx .paragraph-hf {
        color: #1F282C;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 138%; /* 38.64px */
        text-align: left;
    }

    .HeaderFotograficaMx .paragraph-hf b {
        font-family: 'Lato Bold';
        font-weight: bolder;
        color: #1F282C;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 138%;
    }
  
    .HeaderFotograficaMx .bg-gray {
        background: #69747C;
    }
  
    .HeaderFotograficaMx .paragraph-hf-2 {
        color: #FFF;
        font-family: Lato;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
        text-align: center;
        width: 80%;
    }

    .HeaderFotograficaMx .paragraph-hf-2 b {
        font-family: 'Lato Bold';
        font-weight: bolder;
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 125%; /* 52.5px */
    }
  
    .HeaderFotograficaMx .bg-purple {
        background: #A55CC2;
    }
}

@media (min-width: 1366px) {
    .HeaderFotograficaMx .mobile-device {
        display: none;
    }

    .HeaderFotograficaMx .tablet-device {
        display: none;
    }
  
    .HeaderFotograficaMx .web-device {
        display: block;
    }

    .HeaderFotograficaMx .bg-gradient-fotomx {
        background: linear-gradient(180deg, rgba(165, 92, 194, 0.46) -8.36%, rgba(255, 255, 255, 0.00) 80.54%);
        width: auto;
    }

    .HeaderFotograficaMx .bg-hero {
        background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/hero.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 450px;
    }
  
    .HeaderFotograficaMx .title-fotomx {
        color: #A55CC2;
        font-family: "Rubik One";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: left;
    }
  
    .HeaderFotograficaMx .subtitle-fotomx {
        color: #1F282C;
        font-family: Ruda;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-align: left;
    }
  
    .HeaderFotograficaMx .paragraph-hf {
        color: #1F282C;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 138%; /* 38.64px */
        text-align: left;
    }

    .HeaderFotograficaMx .paragraph-hf b {
        font-family: 'Lato Bold';
        font-weight: bolder;
        color: #1F282C;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 138%;
    }
  
    .HeaderFotograficaMx .bg-gray {
        background: #69747C;
    }
  
    .HeaderFotograficaMx .paragraph-hf-2 {
        color: #FFF;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
        text-align: left;
        width: 80%;
    }

    .HeaderFotograficaMx .paragraph-hf-2 b {
        font-family: 'Lato Bold';
        font-weight: bolder;
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 125%; /* 52.5px */
    }
  
    .HeaderFotograficaMx .bg-purple {
        background: #A55CC2;
    }
}

@media (min-width: 1440px) {
    .HeaderFotograficaMx .mobile-device {
        display: none;
    }

    .HeaderFotograficaMx .tablet-device {
        display: none;
    }
  
    .HeaderFotograficaMx .web-device {
        display: block;
    }

    .HeaderFotograficaMx .bg-gradient-fotomx {
        background: linear-gradient(180deg, rgba(165, 92, 194, 0.46) -8.36%, rgba(255, 255, 255, 0.00) 80.54%);
        width: auto;
    }

    .HeaderFotograficaMx .bg-hero {
        background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/hero.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        min-height: 500px;
    }
  
    .HeaderFotograficaMx .title-fotomx {
        color: #A55CC2;
        font-family: "Rubik One";
        font-size: 55px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: left;
    }
  
    .HeaderFotograficaMx .subtitle-fotomx {
        color: #1F282C;
        font-family: Ruda;
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-align: left;
    }
  
    .HeaderFotograficaMx .paragraph-hf {
        color: #1F282C;
        font-family: Lato;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 138%; /* 38.64px */
        text-align: left;
    }

    .HeaderFotograficaMx .paragraph-hf b {
        color: #1F282C;
        font-family: 'Lato Bold';
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        line-height: 138%;
    }
  
    .HeaderFotograficaMx .bg-gray {
        background: #69747C;
    }
  
    .HeaderFotograficaMx .paragraph-hf-2 {
        color: #FFF;
        font-family: Lato;
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
        text-align: left;
        width: 70%;
    }

    .HeaderFotograficaMx .paragraph-hf-2 b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 35px;
        font-style: normal;
        font-weight: 800;
        line-height: 125%; /* 43.75px */
    }
  
    .HeaderFotograficaMx .bg-purple {
        background: #A55CC2;
    }
}
