.mobile-device {
    display: block;
}

.web-device {
    display: none;
}

.al-bg-gr{
    background-color: #E9EDF0;
    background-image: url('../../../assets/images/home/alianza/gr-bg-al-mobile.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}
.al-title{
    color: #72CDBC;
    text-align: center;
    font-family: "Rubik One";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}
.al-num{
    color: #000;
    font-family: "Rubik One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.al-par{
    color: #1F282C;
    text-align: center;
    font-family: Lato;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem; /* 14.2px */
    
    b {
        font-family: 'Lato Bold';
        font-weight: bolder;
    }
}
.al-cont{
    width: 65%;
    /* margin: auto; */
}
.al-hist-bg{
    background-color: #00B9F1;
    background-image: none;
}
.al-hist-title{
    color: #FFF;
    text-align: center;
    font-family: "Rubik One";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}
.al-hist-title2{
    color: #FFF;
    font-family: Ruda;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.al-hist-img-move-up{
    position: relative;
    margin-top: 0%;
    margin-left: 0;
    width: 100%;
}

strong, b {
    font-weight: bolder;
}

.icon-width {
    width: 4rem;
}

@media (min-width: 768px) {
    .al-bg-gr{
        background-color: #E9EDF0;
        background-image: url('../../../assets/images/home/alianza/gr-bg-al-mobile.png');
        background-repeat: no-repeat;
        background-position: right bottom;
    }
    .al-title{
        color: #72CDBC;
        text-align: center;
        font-family: "Rubik One";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 48px */
    }
    .al-num{
        color: #1F282C;
        text-align: center;
        font-family: "Rubik One";
        font-size: 42px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 58.8px */
    }
    .al-par{
        color: #1F282C;
        text-align: center;
        font-family: Lato;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 42px; /* 27.72px */

        color: #1F282C;
        text-align: center;
        font-family: Lato;
        font-size: 31.286px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px; /* 27.72px */
        
        b {
            font-family: 'Lato Bold';
            font-weight: bolder;
        }
    }
    .al-cont{
        width: 65%;
        /* margin: auto; */
    }
    .al-hist-bg{
        background-color: #00B9F1;
        background-image: none;
    }
    .al-hist-title{
        color: #FFF;
        text-align: center;
        font-family: "Rubik One";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
    .al-hist-title2{
        color: #FFF;
        font-family: Ruda;
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .al-hist-img-move-up{
        position: relative;
        margin-top: 0%;
        margin-left: 0;
        width: 100%;
    }
    
    strong, b {
        font-weight: bolder;
        font-size: inherit;

    }
    
    .icon-width {
        width: 4rem;
    }
}

@media (min-width: 1024px) {
    .mobile-device {
        display: none;
    }
    
    .web-device {
        display: block;
    }

    .bg-ninios-web {
        background-image: url('../../../assets/images/home/alianza/Valores_4_WEB.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 665px;
    }

    .al-bg-gr{
        background-color: #E9EDF0;
        background-image: url('../../../assets/images/home/alianza/gr-bg-al.png');
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: cover;
    }
    .al-title{
        color: #72CDBC;
        font-family: "Rubik One";
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: left;
    }
    .al-num{
        color: #000;
        font-family: "Rubik One";
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
    }
    .al-par{
        color: #1F282C;
        font-family: Lato;
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-align: left;

        b {
            font-family: 'Lato Bold';
            font-weight: bolder;
        }
    }
    .al-cont{
        width: 85%;
        /* margin: 4rem; */
    }
    .al-hist-bg{
        background-color: transparent;
        background-image: url('../../../assets/images/home/alianza/DSC_0034-trans.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 340px;
        margin-top: -12vh;
    }
    .al-hist-title{
        color: #FFF;
        text-align: center;
        font-family: "Rubik One";
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
    .al-hist-title2{
        color: #FFF;
        font-family: Ruda;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .al-hist-img-move-up{
        position: relative;
        margin-top: -25%;
        margin-left: 0;
        width: 130%;
    }

    .padding-text {
        padding-top: 16%;
    }
}

@media (min-width: 1280px) {
    .al-bg-gr{
        background-color: #E9EDF0;
        background-image: url('../../../assets/images/home/alianza/gr-bg-al.png');
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: cover;
    }
    .al-title{
        color: #72CDBC;
        font-family: "Rubik One";
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: left;
    }
    .al-num{
        color: #000;
        font-family: "Rubik One";
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
    }
    .al-par{
        color: #1F282C;
        font-family: Lato;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-align: left;

        b {
            font-family: 'Lato Bold';
            font-weight: bolder;
        }
    }
    .al-cont{
        width: 65%;
        /* margin: 4rem; */
    }
    .al-hist-bg{
        background-color: transparent;
        background-image: url('../../../assets/images/home/alianza/DSC_0034-trans.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 420px;
        margin-top: -10vh;
    }
    .al-hist-title{
        color: #FFF;
        text-align: center;
        font-family: "Rubik One";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
    .al-hist-title2{
        color: #FFF;
        font-family: Ruda;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .al-hist-img-move-up{
        position: relative;
        margin-top: -25%;
        margin-left: 0;
        width: 130%;
    }

    .bg-ninios-web {
        background-image: url('../../../assets/images/home/alianza/Valores_4_WEB.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 830px;
    }

    .padding-text {
        padding-top: 17%;
    }
}

@media (min-width: 1366px) {
    .al-bg-gr{
        background-color: #E9EDF0;
        background-image: url('../../../assets/images/home/alianza/gr-bg-al.png');
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: cover;
    }
    .al-title{
        color: #72CDBC;
        font-family: "Rubik One";
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: left;
    }
    .al-num{
        color: #000;
        font-family: "Rubik One";
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
    }
    .al-par{
        color: #1F282C;
        font-family: Lato;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-align: left;

        b {
            font-family: 'Lato Bold';
            font-weight: bolder;
        }
    }
    .al-cont{
        width: 65%;
        /* margin: 4rem; */
    }
    .al-hist-bg{
        background-color: transparent;
        background-image: url('../../../assets/images/home/alianza/DSC_0034-trans.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 460px;
        margin-top: -14vh;
    }
    .al-hist-title{
        color: #FFF;
        text-align: center;
        font-family: "Rubik One";
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
    .al-hist-title2{
        color: #FFF;
        font-family: Ruda;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .al-hist-img-move-up{
        position: relative;
        margin-top: -25%;
        margin-left: 0;
        width: 130%;
    }

    .padding-text {
        padding-top: 15%;
    }

    .bg-ninios-web {
        background-image: url('../../../assets/images/home/alianza/Valores_4_WEB.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 880px;
    }
}

@media (min-width: 1440px) {
    .al-bg-gr{
        background-color: #E9EDF0;
        background-image: url('../../../assets/images/home/dddsffw.png');
        background-repeat: no-repeat;
        background-position: right top;
        background-size: cover;
    }
    .al-title{
        color: #72CDBC;
        font-family: "Rubik One";
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: 55px;
        text-transform: uppercase;
        text-align: left;
    }
    .al-num{
        color: #000;
        font-family: "Rubik One";
        font-size: 60px;
        font-style: normal;
        font-weight: 400;
        line-height: 65px;
        text-align: left;
    }
    .al-par{
        color: #1F282C;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 37px;
        text-align: left;

        b {
            font-family: 'Lato Bold';
            font-weight: bolder;
        }
    }
    .al-cont{
        width: 75%;
        /* margin: 4rem; */
    }
    .al-hist-bg{
        background-color: transparent;
        background-image: url('../../../assets/images/home/alianza/DSC_0034-trans.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 460px;
        margin-top: -12vh;
    }
    .al-hist-title{
        color: #FFF;
        text-align: center;
        font-family: "Rubik One";
        font-size: 35.502px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
    .al-hist-title2{
        color: #FFF;
        font-family: Ruda;
        font-size: 35.502px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .al-hist-img-move-up{
        position: relative;
        margin-top: -25%;
        margin-left: 0;
    }

    .padding-text {
        padding-top: 17%;
    }

    .bg-ninios-web {
        background-image: url('../../../assets/images/home/alianza/Valores_4_WEB.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 940px;
    }

    /* @media screen and (min-width: 1440px) and (max-width:1600px){
        .al-title{
            font-size: 60px;
            line-height: normal;
        }
        .al-num{
            font-size: 65px;
            line-height: normal;
        }
        .al-par{
            font-size: 25px;
            line-height: 160%;
        }
    } */
}

@media (min-width: 1600px) {
    .al-bg-gr{
        background-color: #E9EDF0;
        background-image: url('../../../assets/images/home/dddsffw.png');
        background-repeat: no-repeat;
        background-position: right top;
        background-size: cover;
    }
    .al-title{
        color: #72CDBC;
        font-family: "Rubik One";
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: 55px;
        text-transform: uppercase;
        text-align: left;
    }
    .al-num{
        color: #000;
        font-family: "Rubik One";
        font-size: 60px;
        font-style: normal;
        font-weight: 400;
        line-height: 70px;
        text-align: left;
    }
    .al-par{
        color: #1F282C;
        font-family: Lato;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 43px;
        text-align: left;

        b {
            font-family: 'Lato Bold';
            font-weight: bolder;
        }
    }
    .al-cont{
        width: 75%;
        /* margin: 4rem; */
    }
    .al-hist-bg{
        background-color: transparent;
        background-image: url('../../../assets/images/home/alianza/DSC_0034-trans.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 530px;
        margin-top: -12vh;
    }
    .al-hist-title{
        color: #FFF;
        text-align: center;
        font-family: "Rubik One";
        font-size: 37.502px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
    .al-hist-title2{
        color: #FFF;
        font-family: Ruda;
        font-size: 33.502px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .al-hist-img-move-up{
        position: relative;
        margin-top: -25%;
        margin-left: 0;
    }

    .padding-text {
        padding-top: 19%;
    }

    .bg-ninios-web {
        background-image: url('../../../assets/images/home/alianza/Valores_4_WEB.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 1030px;
    }
}

@media (min-width: 1920px) {
    .al-bg-gr{
        background-color: #E9EDF0;
        background-image: url('../../../assets/images/home/dddsffw.png');
        background-repeat: no-repeat;
        background-position: right top;
        background-size: cover;
    }
    .al-title{
        color: #72CDBC;
        font-family: "Rubik One";
        font-size: 73.222px;
        font-style: normal;
        font-weight: 400;
        line-height: 90px;
        text-transform: uppercase;
        text-align: left;
    }
    .al-num{
        color: #000;
        font-family: "Rubik One";
        font-size: 90px;
        font-style: normal;
        font-weight: 400;
        line-height: 110px;
        text-align: left;
    }
    .al-par{
        color: #1F282C;
        font-family: Lato;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 64px;
        text-align: left;

        b {
            font-family: 'Lato Bold';
            font-weight: bolder;
        }
    }
    .al-cont{
        width: 75%;
        /* margin: 4rem; */
    }
    .al-hist-bg{
        background-color: transparent;
        background-image: url('../../../assets/images/home/alianza/DSC_0034-trans.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 600px;
        margin-top: -12vh;
    }
    .al-hist-title{
        color: #FFF;
        text-align: center;
        font-family: "Rubik One";
        font-size: 46.502px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
    .al-hist-title2{
        color: #FFF;
        font-family: Ruda;
        font-size: 46.502px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .al-hist-img-move-up{
        position: relative;
        margin-top: -25%;
        margin-left: 0;
    }

    .padding-text {
        padding-top: 17%;
    }

    .bg-ninios-web {
        background-image: url('../../../assets/images/home/alianza/Valores_4_WEB.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 1245px;
    }
}

@media (min-width: 2560px) {
    .al-bg-gr{
        background-color: #E9EDF0;
        background-image: url('../../../assets/images/home/dddsffw.png');
        background-repeat: no-repeat;
        background-position: right top;
        background-size: cover;
    }
    .al-title{
        color: #72CDBC;
        font-family: "Rubik One";
        font-size: 73.222px;
        font-style: normal;
        font-weight: 400;
        line-height: 90px;
        text-transform: uppercase;
        text-align: left;
    }
    .al-num{
        color: #000;
        font-family: "Rubik One";
        font-size: 90px;
        font-style: normal;
        font-weight: 400;
        line-height: 110px;
        text-align: left;
    }
    .al-par{
        color: #1F282C;
        font-family: Lato;
        font-size: 31.286px;
        font-style: normal;
        font-weight: 400;
        line-height: 64px;
        text-align: left;

        b {
            font-family: 'Lato Bold';
            font-weight: bolder;
        }
    }
    .al-cont{
        width: 75%;
        /* margin: 4rem; */
    }
    .al-hist-bg{
        background-color: transparent;
        background-image: url('../../../assets/images/home/alianza/DSC_0034-trans.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 850px;
        margin-top: -14vh;
    }
    .al-hist-title{
        color: #FFF;
        text-align: center;
        font-family: "Rubik One";
        font-size: 46.502px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
    .al-hist-title2{
        color: #FFF;
        font-family: Ruda;
        font-size: 46.502px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .al-hist-img-move-up{
        position: relative;
        margin-top: -25%;
        margin-left: 0;
    }

    .padding-text {
        padding-top: 18%;
    }

    .bg-ninios-web {
        background-image: url('../../../assets/images/home/alianza/Valores_4_WEB.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 1600px;
    }
}