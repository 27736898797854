.tecnochicas .imp-par {
    color: #000;
    font-family: 'Ruda Bold';
    font-size: 32px;
    font-style: normal;
    font-weight: bolder;
    line-height: 120%;
    text-align: start;
}

.tecnochicas .imp-par .highlight {
    color: #7970C1;
    font-family: "Rubik One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 45.6px */
}

.tecnochicas .imp-par-2 {
    color: #F8F8FF;
    text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.25);
    font-family: 'Lato Bold';
    font-size: 36px;
    font-style: normal;
    font-weight: bolder;
    line-height: 45px; /* 125% */
}

.tecnochicas .imp-par-2 .highlight {
    color: #F8F8FF;
    font-family: "Rubik One";
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
}

.tecnochicas .bg-team {
    min-height: 75vh;
    background-image: url('../../../../assets/images/programas/tecnolochicas/WhatsApp Image 2023-08-29 at 18.24 3.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.tecnochicas .rect-gr {
    background-image: url('../../../../assets/images/programas/tecnolochicas/Rectanglegr.png');
    background-repeat: no-repeat;
    padding: 1rem;
}

.tecnochicas .bg-purple-tecnolochicas {
    background: #8780BD;
}

.tecnochicas .paragraph-description {
    color: #F8F8FF;
    text-align: center;
    font-family: Lato;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 108%; /* 37.8px */
}
    .tecnochicas .paragraph-description b {
        color: #F8F8FF;
        font-family: 'Lato Bold';
        font-size: 35px;
        font-style: normal;
        font-weight: bolder;
        line-height: 108%;
    }


@media screen and (max-width: 1440px){
    .tecnochicas .imp-par {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 30px;
        font-style: normal;
        font-weight: bolder;
        line-height: 120%;
        text-align: start;
    }
    
    .tecnochicas .imp-par .highlight {
        color: #7970C1;
        font-family: "Rubik One";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 45.6px */
    }
    
    .tecnochicas .imp-par.w-75 {
        width: 90% !important;
        padding: 1.5rem 0;
    }
    
    .tecnochicas .imp-par-2 {
        color: #F8F8FF;
        text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.25);
        font-family: 'Lato Bold';
        font-size: 26px;
        font-style: normal;
        font-weight: bolder;
        line-height: 36px; /* 125% */
    }
    
    .tecnochicas .imp-par-2 .highlight {
        color: #F8F8FF;
        font-family: "Rubik One";
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
    }

    .tecnochicas .bg-purple-tecnolochicas {
        background: #8780BD;
    }
    
    .tecnochicas .paragraph-description {
        color: #F8F8FF;
        text-align: center;
        font-family: Lato;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 108%; /* 37.8px */
    
    }
    .tecnochicas .paragraph-description b {
            color: #F8F8FF;
            font-family: 'Lato Bold';
            font-size: 30px;
            font-style: normal;
            font-weight: bolder;
            line-height: 108%;
        }
    
    
}

@media screen and (max-width: 1680px) {
    .tecnochicas .imp-par {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 32px;
        font-style: normal;
        font-weight: bolder;
        line-height: 120%;
        text-align: start;
    }
    
    .tecnochicas .imp-par .highlight {
        color: #7970C1;
        font-family: "Rubik One";
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 45.6px */
    }
    
    .tecnochicas .imp-par-2 {
        color: #F8F8FF;
        text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.25);
        font-family: 'Lato Bold';
        font-size: 32px;
        font-style: normal;
        font-weight: bolder;
        line-height: 45px; /* 125% */
    }
    
    .tecnochicas .imp-par-2 .highlight {
        color: #F8F8FF;
        font-family: "Rubik One";
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px;
    }
    
    .tecnochicas .bg-team {
        min-height: 75vh;
        background-image: url('../../../../assets/images/programas/tecnolochicas/WhatsApp Image 2023-08-29 at 18.24 3.png');
        background-size: cover;
        background-repeat: no-repeat;
    }
    
    .tecnochicas .rect-gr {
        background-image: url('../../../../assets/images/programas/tecnolochicas/Rectanglegr.png');
        background-repeat: no-repeat;
        padding: 1rem;
    }
    
    .tecnochicas .bg-purple-tecnolochicas {
        background: #8780BD;
    }
    
    .tecnochicas .paragraph-description {
        color: #F8F8FF;
        text-align: center;
        font-family: Lato;
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 108%; /* 37.8px */
    
    }
    .tecnochicas .paragraph-description b {
            color: #F8F8FF;
            font-family: 'Lato Bold';
            font-size: 35px;
            font-style: normal;
            font-weight: bolder;
            line-height: 108%;
        }
    }
    
    @media screen and (max-width: 1440px){
        .tecnochicas .imp-par {
            color: #000;
            font-family: 'Ruda Bold';
            font-size: 30px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%;
            text-align: start;
        }
        
        .tecnochicas .imp-par .highlight {
            color: #7970C1;
            font-family: "Rubik One";
            font-size: 30px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 45.6px */
        }
        
        .tecnochicas .imp-par.w-75 {
            width: 90% !important;
            padding: 1.5rem 0;
        }
        
        .tecnochicas .imp-par-2 {
            color: #F8F8FF;
            text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.25);
            font-family: 'Lato Bold';
            font-size: 26px;
            font-style: normal;
            font-weight: bolder;
            line-height: 36px; /* 125% */
        }
        
        .tecnochicas .imp-par-2 .highlight {
            color: #F8F8FF;
            font-family: "Rubik One";
            font-size: 26px;
            font-style: normal;
            font-weight: 400;
            line-height: 36px;
        }
    
        .tecnochicas .bg-purple-tecnolochicas {
            background: #8780BD;
        }
        
        .tecnochicas .paragraph-description {
            color: #F8F8FF;
            text-align: center;
            font-family: Lato;
            font-size: 30px;
            font-style: normal;
            font-weight: 400;
            line-height: 108%; /* 37.8px */
        
        }
        .tecnochicas .paragraph-description b {
                color: #F8F8FF;
                font-family: 'Lato Bold';
                font-size: 30px;
                font-style: normal;
                font-weight: bolder;
                line-height: 108%;
            }
        }
        
    



@media screen and (max-width: 1366px){
    .tecnochicas .imp-par {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 25px;
        font-style: normal;
        font-weight: bolder;
        line-height: 120%;
        text-align: start;
    }
    
    .tecnochicas .imp-par .highlight {
        color: #7970C1;
        font-family: "Rubik One";
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 45.6px */
    }
    
    .tecnochicas .imp-par.w-75 {
        width: 90% !important;
        padding: 1.5rem 0;
    }
    
    .tecnochicas .imp-par-2 {
        color: #F8F8FF;
        text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.25);
        font-family: 'Lato Bold';
        font-size: 22px;
        font-style: normal;
        font-weight: bolder;
        line-height: 32px; /* 125% */
    }
    
    .tecnochicas .imp-par-2 .highlight {
        color: #F8F8FF;
        font-family: "Rubik One";
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
    }

    .tecnochicas .paragraph-description {
        color: #F8F8FF;
        text-align: center;
        font-family: Lato;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 108%; /* 37.8px */
    
    }
    .tecnochicas .paragraph-description b {
            color: #F8F8FF;
            font-family: 'Lato Bold';
            font-size: 25px;
            font-style: normal;
            font-weight: bolder;
            line-height: 108%;
        }
    }
    


@media screen and (max-width: 1024px){
    .tecnochicas .imp-par {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 20px;
        font-style: normal;
        font-weight: bolder;
        line-height: 120%;
        text-align: start;
    }
    
    .tecnochicas .imp-par .highlight {
        color: #7970C1;
        font-family: "Rubik One";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 45.6px */
    }
    
    .tecnochicas .imp-par.w-75 {
        width: 90% !important;
        padding: 1.5rem 0;
    }
    
    .tecnochicas .imp-par-2 {
        color: #F8F8FF;
        text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.25);
        font-family: 'Lato Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: bolder;
        line-height: 26px; /* 125% */
        text-align: start;
    }
    
    .tecnochicas .imp-par-2 .highlight {
        color: #F8F8FF;
        font-family: "Rubik One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
    }

    .tecnochicas .paragraph-description {
        color: #F8F8FF;
        text-align: center;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 108%; /* 37.8px */
    
    }
    .tecnochicas .paragraph-description b {
            color: #F8F8FF;
            font-family: 'Lato Bold';
            font-size: 20px;
            font-style: normal;
            font-weight: bolder;
            line-height: 108%;
        }
    }
    


@media screen and (max-width:900px){
    .tecnochicas .imp-par {
        color: #1F282C;
        font-family: Ruda;
        font-size: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: 42px;
        text-align: center;
    }
    
    .tecnochicas .imp-par .highlight {
        color: #7970C1;
        font-family: "Rubik One";
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 107.143% */
    }
    
    .tecnochicas .imp-par.w-75 {
        width: 90% !important;
        padding: 1.5rem 0;
    }
    
    .tecnochicas .imp-par-2 {
        color: #F8F8FF;
        text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.25);
        font-family: 'Lato Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: bolder;
        line-height: 26px; /* 125% */
    }
    
    .tecnochicas .imp-par-2 .highlight {
        color: #F8F8FF;
        font-family: "Rubik One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
    }

    .tecnochicas .paragraph-description {
        color: #F8F8FF;
        text-align: center;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 108%; /* 37.8px */
    
    }
    .tecnochicas .paragraph-description b {
            color: #F8F8FF;
            font-family: 'Lato Bold';
            font-size: 20px;
            font-style: normal;
            font-weight: bolder;
            line-height: 108%;
        }
    }
    


@media screen and (max-width: 600px){
    .tecnochicas .imp-par {
        color: #1F282C;
        font-family: Ruda;
        font-size:16px;
        font-style: normal;
        font-weight: 800;
        line-height: 22px;
        text-align: center;
    }
    
    .tecnochicas .imp-par .highlight {
        color: #7970C1;
        font-family: "Rubik One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 107.143% */
    }
    
    .tecnochicas .imp-par.w-75 {
        width: 90% !important;
        padding: 1.5rem 0;
    }
    
    .tecnochicas .imp-par-2 {
        color: #F8F8FF;
        text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.25);
        font-family: 'Lato Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: bolder;
        line-height: 20px; /* 125% */
    }
    
    .tecnochicas .imp-par-2 .highlight {
        color: #F8F8FF;
        font-family: "Rubik One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    .tecnochicas .bg-team {
        min-height: 60vh;
        background-image: url('../../../../assets/images/programas/tecnolochicas/Group1186.png');
        background-size: cover;
        background-repeat: no-repeat;
    }

    .tecnochicas .paragraph-description {
        color: #F8F8FF;
        text-align: center;
        font-family: Lato;
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 108%; /* 37.8px */
    
    }
    .tecnochicas .paragraph-description b {
            color: #F8F8FF;
            font-family: 'Lato Bold';
            font-size: 19px;
            font-style: normal;
            font-weight: bolder;
            line-height: 108%;
        }
    }