/* Background settings for ini-bg within .temprano */
.temprano .ini-bg {
    min-height: 82vh;
    background-image: url('../../../../assets/images/programas/tecnolochicas/tchiniciativas.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
}

/* Minimum height setting for text-box-numbs within .temprano */
.temprano .text-box-numbs {
    min-height: 200px;
}

/* Styling for numbers within .temprano */
.temprano .numbers {
    background: linear-gradient(180deg, rgba(126, 157, 238, 0.30) 0%, rgba(199, 196, 223, 0.00) 100%);
    color: #1F282C;
    font-family: 'Ruda Bold';
    font-size: 28px;
    font-weight: 800;
    line-height: 46px;
    text-align: start;
}

/* Highlight styling within numbers of .temprano */
.temprano .numbers .highlight {
    color: var(--et-color);
    font-family: "Rubik One";
    font-size: 38px;
    font-weight: 400;
    line-height: 46px;
}

/* Title styling for et-in-title within .temprano */
.temprano .et-in-title {
    color: #53616A;
    text-align: center;
    font-family: "Rubik One";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
}

/* Paragraph styling for et-in-par within .temprano */
.temprano .et-in-par {
    color: #1F282C;
    text-align: center;
    font-family: Ruda;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
}

/* Description styling for ini-desc within .temprano */
.temprano .ini-desc {
    color: #1F282C;
    font-family: Ruda;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px;
}

/* Bold text styling within ini-desc of .temprano */
.temprano .ini-desc b {
    color: #000;
    text-align: center;
    font-family: 'Ruda Bold';
    font-size: 35px;
    font-style: normal;
    font-weight: 800;
    line-height: 46px; /* 131.429% */
}

/* Additional bold styling for bold elements within .temprano */
.temprano .ini-desc .bold {
    font-family: 'Ruda Bold';
    font-weight: 800;
}

/* Background gradient styling for bg-peques-gradient within .temprano */
.temprano .bg-peques-gradient {
    background: linear-gradient(0deg, rgba(245, 248, 255, 0.04) 0%, rgba(212, 86, 155, 0.18) 29.14%);
    width: 60%;
}

/* Description styling for peques-description within .temprano */
.temprano .peques-description {
    margin-top: 24%;
    color: #FFF;
    font-family: 'Ruda Bold';
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 114%;
}

/* Bold styling within peques-description of .temprano */
.temprano .peques-description b {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Rubik One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 114%; /* 41.04px */
}

/* Second description styling for peques-description2 within .temprano */
.temprano .peques-description2 {
    margin-top: 15%;
    color: #FFF;
    font-family: 'Ruda Bold';
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 114%;
}

/* Bold styling within peques-description2 of .temprano */
.temprano .peques-description2 b {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Rubik One";
    font-size: inherit;
    font-style: normal;
    font-weight: 400;
    line-height: 114%; /* 41.04px */
}

/* Display settings for desktop and mobile within .temprano */
.temprano .desk {
    display: block;
}

.temprano .mov {
    display: none;
}
@media screen and (max-width: 1440px) {
    /* Toggle display settings for desktop and mobile versions */
    .temprano .desk {
        display: none;
    }
    .temprano .mov {
        display: block;
    }

    /* Minimum height for text box numbers area */
    .temprano .text-box-numbs {
        min-height: 200px;
    }

    /* Background and font styles for number elements */
    .temprano .numbers {
        background: linear-gradient(180deg, rgba(126, 157, 238, 0.30) 0%, rgba(199, 196, 223, 0.00) 100%);
        color: #1F282C;
        font-family: 'Ruda Bold';
        font-size: 28px;
        font-weight: 800;
        line-height: 46px;
        text-align: start;
    }

    /* Highlight styles within numbers */
    .temprano .numbers .highlight {
        color: var(--et-color);
        font-family: "Rubik One";
        font-size: 38px;
        font-weight: 400;
        line-height: 46px;
    }

    /* Title styles */
    .temprano .et-in-title {
        color: #53616A;
        text-align: center;
        font-family: "Rubik One";
        font-size: 42px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-transform: uppercase;
    }

    /* Paragraph styles */
    .temprano .et-in-par {
        color: #1F282C;
        text-align: center;
        font-family: Ruda;
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px;
    }

    /* Description styles */
    .temprano .ini-desc {
        color: #1F282C;
        font-family: Ruda;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }

    .temprano .ini-desc b {
        color: #000;
        text-align: center;
        font-family: 'Ruda Bold';
        font-size: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: 130%;
    }

    /* Additional bold text styling */
    .temprano .ini-desc .bold {
        font-family: 'Ruda Bold';
        font-weight: 800;
    }

    /* Background gradient for smaller components */
    .temprano .bg-peques-gradient {
        background: linear-gradient(0deg, rgba(245, 248, 255, 0.04) 0%, rgba(212, 86, 155, 0.18) 29.14%);
        width: 60%;
    }

    /* Description styles for smaller components */
    .temprano .peques-description {
        margin-top: 24%;
        color: #FFF;
        font-family: 'Ruda Bold';
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 114%;
    }

    .temprano .peques-description b {
        color: #FFF;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: "Rubik One";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 114%; /* 41.04px */
    }

    /* Additional description styles for continuity */
    .temprano .peques-description2 {
        margin-top: 15%;
        color: #FFF;
        font-family: 'Ruda Bold';
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 114%;
    }

    
        /* Bold text styling within peques-description2 of .temprano */
        .temprano .peques-description2 b {
            color: #FFF;
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: "Rubik One";
            font-size: inherit;  
            font-style: normal;
            font-weight: 400;
            line-height: 114%; /* 41.04px */
        }
    
        /* Display settings for desktop and mobile specific components */
        .temprano .desk {
            display: block;  
        }
        
        .temprano .mov {
            display: none;  
        }
    }
    

    @media screen and (max-width: 1024px) {
        /* Toggle display settings for desktop and mobile versions */
        .temprano .desk {
            display: none;
        }
        .temprano .mov {
            display: block;
        }
    
        /* Minimum height for text box numbers area */
        .temprano .text-box-numbs {
            min-height: 200px;
        }
    
        /* Background and font styles for number elements */
        .temprano .numbers {
            background: linear-gradient(180deg, rgba(126, 157, 238, 0.30) 0%, rgba(199, 196, 223, 0.00) 100%);
            color: #1F282C;
            font-family: 'Ruda Bold';
            font-size: 28px;
            font-weight: 800;
            line-height: 46px;
            text-align: start;
        }
    
        /* Highlight styles within numbers */
        .temprano .numbers .highlight {
            color: var(--et-color);
            font-family: "Rubik One";
            font-size: 38px;
            font-weight: 400;
            line-height: 46px;
        }
    
        /* Title styles */
        .temprano .et-in-title {
            color: #53616A;
            text-align: center;
            font-family: "Rubik One";
            font-size: 30px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            text-transform: uppercase;
        }
    
        /* Paragraph styles */
        .temprano .et-in-par {
            color: #1F282C;
            text-align: center;
            font-family: Ruda;
            font-size: 35px;
            font-style: normal;
            font-weight: 400;
            line-height: 45px;
        }
    
        /* Description styles */
        .temprano .ini-desc {
            color: #1F282C;
            font-family: Ruda;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
        }
    
        .temprano .ini-desc b {
            color: #000;
            text-align: center;
            font-family: 'Ruda Bold';
            font-size: 25px;
            font-style: normal;
            font-weight: 800;
            line-height: 30px; /* 131.429% */
        }
    
        /* Additional bold text styling */
        .temprano .ini-desc .bold {
            font-family: 'Ruda Bold';
            font-weight: 800;
        }
    
        /* Background gradient for smaller components */
        .temprano .bg-peques-gradient {
            background: linear-gradient(0deg, rgba(245, 248, 255, 0.04) 0%, rgba(212, 86, 155, 0.18) 29.14%);
            width: 60%;
        }
    
        /* Description styles for smaller components */
        .temprano .peques-description {
            margin-top: 24%;
            color: #FFF;
            font-family: 'Ruda Bold';
            font-size: 20px;
            font-style: normal;
            font-weight: 800;
            line-height: 114%;
        }
    
        .temprano .peques-description b {
            color: #FFF;
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: "Rubik One";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 114%; /* 41.04px */
        }
    
        /* Additional description styles for continuity */
        .temprano .peques-description2 {
            margin-top: 15%;
            color: #FFF;
            font-family: 'Ruda Bold';
            font-size: 20px;
            font-style: normal;
            font-weight: 800;
            line-height: 114%;
        }
        .temprano .peques-description b,
        .temprano .peques-description2 b {
            color: #FFF;
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: "Rubik One";
            font-size: inherit;  
            font-style: normal;
            font-weight: 400;
            line-height: 114%; /* 41.04px */
        }
        
        .temprano .desk {
            display: block;  
        }
        
        .temprano .mov {
            display: none;  
        }
        
}
@media screen and (max-width: 900px) {
    /* Adjust the minimum height for text boxes */
    .temprano .text-box-numbs {
        min-height: 200px;
    }

    /* Styling for the numbers section */
    .temprano .numbers {
        background: linear-gradient(180deg, rgba(126, 157, 238, 0.30) 0%, rgba(199, 196, 223, 0.00) 100%);
        color: #1F282C;
        font-family: 'Ruda Bold';
        font-size: 28px;
        font-weight: 800;
        line-height: 46px;
        text-align: start;
    }

    /* Highlighted text within numbers */
    .temprano .numbers .highlight {
        color: var(--et-color);
        font-family: "Rubik One";
        font-size: 38px;
        font-weight: 400;
        line-height: 46px;
    }

    /* Title settings in the temprano section */
    .temprano .et-in-title {
        color: #53616A;
        text-align: center;
        font-family: "Rubik One";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-transform: uppercase;
    }

    /* Description settings */
    .temprano .ini-desc {
        color: #1F282C;
        font-family: Ruda;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;

        b {
            color: #000;
            text-align: center;
            font-family: 'Ruda Bold';
            font-size: 30px;
            font-style: normal;
            font-weight: 800;
            line-height: 130%; /* 131.429% */
        }
    }

    /* Background gradient adjustment */
    .temprano .bg-peques-gradient {
        background: linear-gradient(0deg, rgba(245, 248, 255, 0.04) 0%, rgba(212, 86, 155, 0.18) 29.14%);
        width: 80%;
    }

    /* Description styling for smaller screens */
    .temprano .peques-description {
        margin-top: 85%;
        color: #FFF;
        font-family: 'Ruda Bold';
        font-size: 22px;
        font-style: normal;
        font-weight: 800;
        line-height: 114%;

        b {
            color: #FFF;
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: "Rubik One";
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 114%; /* 41.04px */
        }
    }

    /* Additional description settings */
    .temprano .peques-description2 {
        margin-top: 50%;
        color: #FFF;
        font-family: 'Ruda Bold';
        font-size: 22px;
        font-style: normal;
        font-weight: 800;
        line-height: 114%;

        b {
            color: #FFF;
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: "Rubik One";
            font-size: inherit; 
            font-style: normal;
            font-weight: 400;
            line-height: 114%; /* 41.04px */
        }
    }

    /* Display adjustments for desktop and mobile */
    .temprano .desk {
        display: none;
    }

    .temprano .mov {
        display: block;
    }
}

@media screen and (max-width: 600px) {
    /* Minimum height for the numbers' text box */
    .temprano .text-box-numbs {
        min-height: 200px;
    }

    /* Styling for the numbers section */
    .temprano .numbers {
        background: linear-gradient(180deg, rgba(126, 157, 238, 0.30) 0%, rgba(199, 196, 223, 0.00) 100%);
        color: #1F282C;
        font-family: 'Ruda Bold';
        font-size: 28px;
        font-weight: 800;
        line-height: 46px;
        text-align: start;
    }

    /* Styling for highlighted numbers */
    .temprano .numbers .highlight {
        color: var(--et-color);
        font-family: "Rubik One";
        font-size: 38px;
        font-weight: 400;
        line-height: 46px;
    }

    /* Title styling adjustments */
    .temprano .et-in-title {
        color: #53616A;
        text-align: center;
        font-family: "Rubik One";
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-transform: uppercase;
    }

    /* Description styling within temprano */
    .temprano .ini-desc {
        color: #000;
        font-family: Ruda;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        b {
            color: #000;
            text-align: center;
            font-family: 'Ruda Bold';
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: 150%; /* 131.429% */
        }
    }

    /* Gradient background for specific sections */
    .temprano .bg-peques-gradient {
        background: linear-gradient(0deg, rgba(245, 248, 255, 0.04) 0%, rgba(212, 86, 155, 0.18) 29.14%);
        width: 90%;
    }

    /* Description styling for peques */
    .temprano .peques-description {
        margin-top: 83%;
        color: #FFF;
        font-family: 'Ruda Bold';
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 114%;

        b {
            color: #FFF;
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: "Rubik One";
            font-size: inherit;
            font-style: normal;
            font-weight: 400;
            line-height: 114%; /* 41.04px */
        }
    }

    /* Additional description styling for peques */
    .temprano .peques-description2 {
        margin-top: 50%;
        color: #FFF;
        font-family: 'Ruda Bold';
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 114%;

        b {
            color: #FFF;
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: "Rubik One";
            font-size: inherit;
            font-style: normal;
            font-weight: 400;
            line-height: 114%; /* 41.04px */
        }
    }
    /* Display settings for desktop and mobile */
    .temprano .desk {
        display: none;
    }

    .temprano .mov {
        display: block;
    }
}