.ProgramasValoresHeader {
  width: 100%;
}

.ProgramasValoresHeader .mobile-device {
  display: block;
}

.ProgramasValoresHeader .web-device {
  display: none;
}

.ProgramasValoresHeader .bg-header {
  background-image: url('../../../../../assets/images/programas/subsections/valores/header-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 287px;
}

.ProgramasValoresHeader .bg-rectangle {
  /* background-image: url('../../../../../assets/images/programas/subsections/valores/rectangle-header-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  background-position: center; */
  background: linear-gradient(0deg, rgba(219, 235, 244, 0.00) 0%, rgba(33, 108, 149, 0.32) 100%);
}

.ProgramasValoresHeader .title {
  color: #216C95;
  text-align: center;
  font-family: "Rubik One";
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.ProgramasValoresHeader .subtitle {
  color: #1F282C;
  text-align: center;
  font-family: Ruda;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.ProgramasValoresHeader .paragraph-valores {
  color: #1F282C;
  font-family: Lato;
  font-size: 18px; /* Changed from 12px */
  font-style: normal;
  font-weight: 400;
  line-height: 138%; /* Changed from 138% */
  }
  .ProgramasValoresHeader .paragraph-valores b {
    color: #1F282C;
    text-align: center;
    font-family: 'Lato Bold';
    font-size: 18px; /* Changed from 12px */
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* Changed from 138% */
  }

.ProgramasValoresHeader .bg-gray-valores {
  background: #69747C;
}

.ProgramasValoresHeader .bg-blue-valores {
  background: #216C95;
}

.ProgramasValoresHeader .paragraph-valores-2 {
  color: #FFF;
  font-family: Lato;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  }
  .ProgramasValoresHeader .paragraph-valores-2 b {
    color: #FFF;
    font-family: 'Lato Bold';
    font-size: 19px;
    font-style: normal;
    font-weight: 800;
    line-height: 125%; /* 25px */
  }


.ProgramasValoresHeader .paragraph-valores-3 {
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 23.75px */
}

.ProgramasValoresHeader .main-title-programas {
  color: #216C95;
}

    @media (min-width: 768px) {
        /* Header background settings */
        .ProgramasValoresHeader .bg-header {
            background-image: url('../../../../../assets/images/programas/subsections/valores/header-tablet.png');
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            height: 595px;
        }
    
        /* Title styling for larger screens */
        .ProgramasValoresHeader .title {
            color: #216C95;
            text-align: center;
            font-family: "Rubik One";
            font-size: 72px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
        }
    
        /* Subtitle adjustments */
        .ProgramasValoresHeader .subtitle {
            color: #1F282C;
            text-align: center;
            font-family: Ruda;
            font-size: 36px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
        }
    
        /* Paragraph with possible values listing */
        .ProgramasValoresHeader .paragraph-valores {
            color: #1F282C;
            font-family: Lato;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 138%;
        
            b {
                color: #1F282C;
                text-align: center;
                font-family: 'Lato Bold';
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 138%; /* 33.12px */
            }
        }
    
        /* Background colors for thematic sections */
        .ProgramasValoresHeader .bg-gray-valores {
            background: #69747C;
        }
      
        .ProgramasValoresHeader .bg-blue-valores {
            background: #216C95;
        }
      
        /* Additional paragraph styles for emphasizing key points */
        .ProgramasValoresHeader .paragraph-valores-2 {
            /* color: #FFF; */
            font-family: Lato;
            font-size: 38px;
            font-style: normal;
            font-weight: 400;
            line-height: 125%;
        }
            .ProgramasValoresHeader .paragraph-valores-2 b {
                font-family: 'Lato Bold';
                font-size: 38px;
                font-style: normal;
                font-weight: 800;
                line-height: 125%; /* 52.5px */
            }
        
      
        /* Styles for tertiary descriptive text */
        .ProgramasValoresHeader .paragraph-valores-3 {
            color: #FFF;
            text-align: center;
            font-family: Lato;
            font-size: 42px;
            font-style: normal;
            font-weight: 400;
            line-height: 125%; /* 52.5px */
        }
    }
    
    @media (min-width: 1024px) {
        /* Hide mobile-specific devices and show desktop-specific devices */
        .ProgramasValoresHeader .mobile-device {
            display: none;
        }
    
        .ProgramasValoresHeader .web-device {
            display: block;
        }
    
        /* Header background styling for larger screens */
        .ProgramasValoresHeader .bg-header {
            background-image: url('../../../../../assets/images/programas/subsections/valores/header.png');
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            height: 350px;
        }
    
        /* Title styling for desktop screens */
        .ProgramasValoresHeader .title {
            color: #216C95;
            font-family: "Rubik One";
            font-size: 35px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            text-align: left;
            padding-left: 0px;
        }
    
        /* Subtitle styling for larger screens */
        .ProgramasValoresHeader .subtitle {
            color: #1F282C;
            font-family: Ruda;
            font-size: 20px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            text-align: left;
        }
    
        /* Paragraph styling with detailed typography settings */
        .ProgramasValoresHeader .paragraph-valores {
            color: #1F282C;
            font-family: Lato;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 138%;
            text-align: left;
    
            b {
                color: #1F282C;
                font-family: 'Lato Bold';
                font-size: 18px;
                font-style: normal;
                font-weight: 800;
                line-height: 138%; /* 38.64px */
            }
        }
    
        /* Background color settings for design consistency */
        .ProgramasValoresHeader .bg-gray-valores {
            background: #69747C;
        }
    
        .ProgramasValoresHeader .bg-blue-valores {
            background: #216C95;
        }
    
        /* Additional paragraph styling with central alignment for key text */
        .ProgramasValoresHeader .paragraph-valores-2 {
            font-family: Lato;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 125%;
            text-align: center;
    }
            .ProgramasValoresHeader .paragraph-valores-2  b {
                color: #FFF;
                font-family: 'Lato Bold';
                font-size: 20px;
                font-style: normal;
                font-weight: 800;
                line-height: 125%; /* 43.75px */
            }
        
    
        /* Styling for additional descriptive text with left alignment */
        .ProgramasValoresHeader .paragraph-valores-3 {
            color: #FFF;
            text-align: left;
            font-family: Lato;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 125%; /* 52.5px */
        }
    }
    
    @media (min-width: 1366px) {
        /* Hide mobile-specific elements and display desktop-specific elements */
        .ProgramasValoresHeader .mobile-device {
            display: none;
        }
    
        .ProgramasValoresHeader .web-device {
            display: block;
        }
    
        /* Header background styling for large screens */
        .ProgramasValoresHeader .bg-header {
            background-image: url('../../../../../assets/images/programas/subsections/valores/header.png');
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            height: 350px;
        }
    
        /* Title styling adjustments for wider screens */
        .ProgramasValoresHeader .title {
            color: #216C95;
            font-family: "Rubik One";
            font-size: 35px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            text-align: left;
            padding-left: 0px;
        }
    
        /* Subtitle formatting for clarity and emphasis */
        .ProgramasValoresHeader .subtitle {
            color: #1F282C;
            font-family: Ruda;
            font-size: 20px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            text-align: left;
        }
    
        /* Paragraph values styling with detailed typography settings */
        .ProgramasValoresHeader .paragraph-valores {
            color: #1F282C;
            font-family: Lato;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 138%;
            text-align: left;
    
            b {
                color: #1F282C;
                font-family: 'Lato Bold';
                font-size: 18px;
                font-style: normal;
                font-weight: 800;
                line-height: 138%; /* 38.64px */
            }
        }
    
        /* Background colors defined for design consistency */
        .ProgramasValoresHeader .bg-gray-valores {
            background: #69747C;
        }
    
        .ProgramasValoresHeader .bg-blue-valores {
            background: #216C95;
        }
    
        /* Additional paragraph styles for emphasizing key points */
        .ProgramasValoresHeader .paragraph-valores-2 {
            font-family: Lato;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 125%;
            text-align: center;
    }
            .ProgramasValoresHeader .paragraph-valores-2 b {
                color: #FFF;
                font-family: 'Lato Bold';
                font-size: 25px;
                font-style: normal;
                font-weight: 800;
                line-height: 125%; /* 43.75px */
            }
        
    
        /* Styles for tertiary descriptive text */
        .ProgramasValoresHeader .paragraph-valores-3 {
            color: #FFF;
            text-align: left; /* Adjusting text-align to left for consistency */
            font-family: Lato;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 125%; /* 52.5px */
            text-align: left;
        }
    }
    
    @media (min-width: 1440px) {
        /* Hide mobile-specific elements and display desktop-specific elements */
        .ProgramasValoresHeader .mobile-device {
            display: none;
        }
      
        .ProgramasValoresHeader .web-device {
            display: block;
        }
    
        /* Background settings for the header to ensure proper display on larger screens */
        .ProgramasValoresHeader .bg-header {
            background-image: url('../../../../../assets/images/programas/subsections/valores/header.png');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 640px;
        }
    
        /* Styling for the main title with large font size for clear visibility */
        .ProgramasValoresHeader .title {
            color: #216C95;
            font-family: "Rubik One";
            font-size: 55px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            text-align: left;
        }
      
        /* Subtitle adjustments to maintain readability and emphasis */
        .ProgramasValoresHeader .subtitle {
            color: #1F282C;
            font-family: Ruda;
            font-size: 24px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            text-align: left;
        }
      
        /* Detailed paragraph styling with emphasis on key text elements */
        .ProgramasValoresHeader .paragraph-valores {
            color: #1F282C;
            font-family: Lato;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 138%;
            text-align: left;
      
            b {
                color: #1F282C;
                font-family: 'Lato Bold';
                font-size: 28px;
                font-style: normal;
                font-weight: 800;
                line-height: 138%; /* 38.64px */
            }
        }
      
        /* Background colors for design consistency */
        .ProgramasValoresHeader .bg-gray-valores {
            background: #69747C;
        }
      
        .ProgramasValoresHeader .bg-blue-valores {
            background: #216C95;
        }
      
        /* Additional paragraph styles for emphasizing key text in center alignment */
        .ProgramasValoresHeader .paragraph-valores-2 {
            font-family: Lato;
            font-size: 35px;
            font-style: normal;
            font-weight: 400;
            line-height: 125%;
            text-align: center;
      }
            .ProgramasValoresHeader .paragraph-valores-2 b {
                color: #FFF;
                font-family: 'Lato Bold';
                font-size: 35px;
                font-style: normal;
                font-weight: 800;
                line-height: 125%; /* 43.75px */
            }
        
      
        /* Styling for tertiary descriptive text with adjustments for better readability */
        .ProgramasValoresHeader .paragraph-valores-3 {
            color: #FFF;
            font-family: Lato;
            font-size: 35px;
            font-style: normal;
            font-weight: 500;
            line-height: 125%; /* 43.75px */
            text-align: left;
        }
    }
    @media (min-width: 1920px) {
        /* Hide mobile-specific elements and ensure web-specific elements are displayed */
        .ProgramasValoresHeader .mobile-device {
            display: none;
        }
    
        .ProgramasValoresHeader .web-device {
            display: block;
        }
    
        /* Header background styling for ultra-wide screens */
        .ProgramasValoresHeader .bg-header {
            background-image: url('../../../../../assets/images/programas/subsections/valores/header.png');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 640px;
        }
    
        /* Styling for main titles with larger text size for readability on large displays */
        .ProgramasValoresHeader .title {
            color: #216C95;
            font-family: "Rubik One";
            font-size: 55px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            text-align: left;
        }
    
        /* Subtitle adjustments for visual hierarchy and emphasis */
        .ProgramasValoresHeader .subtitle {
            color: #1F282C;
            font-family: Ruda;
            font-size: 24px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            text-align: left;
        }
    
        /* Paragraph styling with enhanced typography settings for clarity */
        .ProgramasValoresHeader .paragraph-valores {
            color: #1F282C;
            font-family: Lato;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 138%;
            text-align: left;
    
            b {
                color: #1F282C;
                font-family: 'Lato Bold';
                font-size: 28px;
                font-style: normal;
                font-weight: 800;
                line-height: 138%; /* 38.64px */
            }
        }
    
        /* Background color styling for design consistency */
        .ProgramasValoresHeader .bg-gray-valores {
            background: #69747C;
        }
    
        .ProgramasValoresHeader .bg-blue-valores {
            background: #216C95;
        }
    
        /* Additional paragraph styles for secondary text elements */
        .ProgramasValoresHeader .paragraph-valores-2 {
            font-family: Lato;
            font-size: 35px;
            font-style: normal;
            font-weight: 400;
            line-height: 125%;
            text-align: center;
    }
            .ProgramasValoresHeader .paragraph-valores-2 b {
                color: #FFF;
                font-family: 'Lato Bold';
                font-size: 35px;
                font-style: normal;
                font-weight: 800;
                line-height: 125%; /* 43.75px */
            }
        
    
        /* Tertiary text styling for detailed descriptive elements */
        .ProgramasValoresHeader .paragraph-valores-3 {
            color: #FFF;
            font-family: Lato;
            font-size: 35px;
            font-style: normal;
            font-weight: 500;
            line-height: 125%; /* 43.75px */
            text-align: left;
        }
    }
    