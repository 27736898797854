.golxmex .bg-nar {
    background-color: var(--gxm-color);
}

.golxmex .bg-nar-cl {
    background-color: #FFE2CF;
}

.golxmex .obj-tit {
    text-align: start;
}

.golxmex .obj-par {
    text-align: start;
}

.golxmex .com-dig {
    color: #FF8434;
    font-family: "Rubik One";
    font-size: 72px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 86.4px */
    text-transform: uppercase;
    text-align: start;
}

.golxmex .com-dig-par {
    color: #1F282C;
    font-family: 'Lato Bold';
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 45px;
    text-align: start;
}

.golxmex .com-dig-par .highlight {
    color: #FF8434;
    font-family: "Rubik One";
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px; /* 118.421% */
}

.golxmex .com-h {
    min-height: 250px;
}
.golxmex .par-comunidad-digital .highlight{
    color: #FF8434;
    font-size: inherit;
}
.golxmex .comunidades li{
    color: #FFB687;

}

@media (max-width: 1366px) {
    .golxmex .bg-nar {
        background-color: var(--gxm-color);
    }
    
    .golxmex .bg-nar-cl {
        background-color: #FFE2CF;
    }
    
    .golxmex .obj-tit {
        text-align: start;
    }
    
    .golxmex .obj-par {
        text-align: start;
    }
    
    .golxmex .com-dig {
        color: #FF8434;
        font-family: "Rubik One";
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 86.4px */
        text-transform: uppercase;
        text-align: left;
    }
    
    .golxmex .com-dig-par {
        color: #1F282C;
        font-family: Lato;
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        line-height: 40px;
        text-align: left;
    }
    
    .golxmex .com-dig-par .highlight {
        color: #FF8434;
        font-family: "Rubik One";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; /* 118.421% */
    }
    
    .golxmex .com-h {
        min-height: 250px;
    }
}

@media (max-width: 1024px) {
    .golxmex .bg-nar {
        background-color: var(--gxm-color);
    }
    
    .golxmex .bg-nar-cl {
        background-color: #FFE2CF;
    }
    
    .golxmex .obj-tit {
        text-align: start;
    }
    
    .golxmex .obj-par {
        text-align: start;
    }
    
    .golxmex .com-dig {
        color: #FF8434;
        font-family: "Rubik One";
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 86.4px */
        text-transform: uppercase;
        text-align: center;
    }
    
    .golxmex .com-dig-par {
        color: #1F282C;
        font-family: Lato;
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        line-height: 40px;
        text-align: center;
    }
    
    .golxmex .com-dig-par .highlight {
        color: #FF8434;
        font-family: "Rubik One";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; /* 118.421% */
    }
    
    .golxmex .com-h {
        min-height: 250px;
    }
}

@media screen and (min-width: 768px) and (orientation:portrait){
    .golxmex .bg-nar {
        background-color: var(--gxm-color);
    }
    
    .golxmex .bg-nar-cl {
        background-color: #FFE2CF;
    }
    
    .golxmex .obj-tit {
        text-align: center;
        font-family: Lato;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }
    
    .golxmex .obj-par {
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .golxmex .img-objetivos{
        width: 280px;
        padding: 0.25rem;
    }
    .golxmex .com-dig {
        color: #FF8434;
        font-family: "Rubik One";
        font-size: 72px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 86.4px */
        text-transform: uppercase;
        text-align: center;
    }
    
    .golxmex .com-dig-par {
        color: #1F282C;
        font-family: Lato;
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        line-height: 45px;
        text-align: center;
    }
    
    .golxmex .com-dig-par .highlight {
        color: #FF8434;
        font-family: "Rubik One";
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px; /* 118.421% */
    }
    
    .golxmex .com-h {
        min-height: 250px;
    }
}

@media screen and (max-width:600px){
    .golxmex .obj-tit {
        text-align: center;
        padding: 0;
    }
    
    .golxmex .obj-par {
        font-size: 22px;
        line-height: 32px;
        text-align: start;
    }
    
    .golxmex .com-dig {
        color: #FF8434;
        font-family: "Rubik One";
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 86.4px */
        text-transform: uppercase;
        text-align: center;
    }
    
    .golxmex .com-dig-par {
        color: #1F282C;
        font-family: Lato;
        font-size: 22px;
        font-style: normal;
        font-weight: 800;
        line-height: 40px;
        text-align: center;
    }
    
    .golxmex .com-dig-par .highlight {
        color: #FF8434;
        font-family: "Rubik One";
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; /* 118.421% */
    }
    .golxmex .img-objetivos{
        width: 140px;
        padding: 0.25rem;
    }
    .golxmex .com-h {
        min-height: 140px;
    }
}