.ProgramasComunidadesDigitales {
  width: 100%;
}

.ProgramasComunidadesDigitales .bg-light-comunidades {
  background: #F5EED9;
}

.ProgramasComunidadesDigitales .bg-light-comunidades2 {
  background: #F5F4EF;
}

.ProgramasComunidadesDigitales .comunidades-title {
  color: #E8BA4A;
  text-align: center;
  font-family: "Rubik One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.ProgramasComunidadesDigitales .comunidades-paragraph {
  color: #1F282C;
  text-align: center;
  font-family: 'Lato Bold';
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: start;
}
  .ProgramasComunidadesDigitales .comunidades-paragraph b {
    color: #E8BA4A;
    font-family: "Rubik One";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ProgramasComunidadesDigitales .comunidades-paragraph span {
    color: #E8BA4A;
    font-family: "Rubik One";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

.ProgramasComunidadesDigitales .par-comunidad-digital b,ProgramasComunidadesDigitales .par-comunidad-digital span{
  color: #E8BA4A;
}
.ProgramasComunidadesDigitales .comunidades-paragraph b {
  color: #E8BA4A;
  font-family: "Rubik One";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ProgramasComunidadesDigitales .comunidades-paragraph span {
  color: #E8BA4A;
  font-family: "Rubik One";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ProgramasComunidadesDigitales .bullet {
  width: 10px;
  height: 6px;
  background: #F1D99E;
  margin-top: 10px;
}


  @media (min-width: 768px) {
    .ProgramasComunidadesDigitales .comunidades-title {
        color: #E8BA4A;
        text-align: center;
        font-family: "Rubik One";
        font-size: 58px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
  
    .ProgramasComunidadesDigitales .comunidades-paragraph {
        color: #1F282C;
        text-align: center;
        font-family: Lato;
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-align: start;
    }

    .ProgramasComunidadesDigitales .comunidades-paragraph b,
    .ProgramasComunidadesDigitales .comunidades-paragraph span {
        color: #E8BA4A;
        font-family: "Rubik One";
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

@media (min-width: 1024px) {
    .ProgramasComunidadesDigitales .comunidades-title {
        color: #E8BA4A;
        font-family: "Rubik One";
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 86.4px */
        text-transform: uppercase;
        text-align: left;
        width: 80%;
    }
  
    .ProgramasComunidadesDigitales .comunidades-paragraph {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 20px;
        font-style: normal;
        font-weight: bolder;
        line-height: 26px; /* 132.353% */
        text-align: left;
        width: 80%;
    }

    .ProgramasComunidadesDigitales .comunidades-paragraph b,
    .ProgramasComunidadesDigitales .comunidades-paragraph span {
        color: #E8BA4A;
        font-family: "Rubik One";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
    }
}

@media (min-width: 1280px) {
    .ProgramasComunidadesDigitales .comunidades-title {
        color: #E8BA4A;
        font-family: "Rubik One";
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 86.4px */
        text-transform: uppercase;
        text-align: left;
        width: 85%;
    }
  
    .ProgramasComunidadesDigitales .comunidades-paragraph {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 26px;
        font-style: normal;
        font-weight: bolder;
        line-height: 32px; /* 132.353% */
        text-align: left;
        width: 80%;
    }

    .ProgramasComunidadesDigitales .comunidades-paragraph b,
    .ProgramasComunidadesDigitales .comunidades-paragraph span {
        color: #E8BA4A;
        font-family: "Rubik One";
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
    }
}

@media (min-width: 1440px) {
    .ProgramasComunidadesDigitales .comunidades-title {
        color: #E8BA4A;
        font-family: "Rubik One";
        font-size: 72px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 86.4px */
        text-transform: uppercase;
        text-align: left;
        width: 85%;
    }
  
    .ProgramasComunidadesDigitales .comunidades-paragraph {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 30px;
        font-style: normal;
        font-weight: bolder;
        line-height: 35px; /* 132.353% */
        text-align: left;
        width: 80%;
    }

    .ProgramasComunidadesDigitales .comunidades-paragraph b,
    .ProgramasComunidadesDigitales .comunidades-paragraph span {
        color: #E8BA4A;
        font-family: "Rubik One";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px;
    }
}

@media (min-width: 1920px) {
    .ProgramasComunidadesDigitales .comunidades-title {
        color: #E8BA4A;
        font-family: "Rubik One";
        font-size: 72px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 86.4px */
        text-transform: uppercase;
        text-align: left;
        width: 85%;
    }
  
    .ProgramasComunidadesDigitales .comunidades-paragraph {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 34px;
        font-style: normal;
        font-weight: bolder;
        line-height: 45px; /* 132.353% */
        text-align: left;
        width: 80%;
    }

    .ProgramasComunidadesDigitales .comunidades-paragraph b,
    .ProgramasComunidadesDigitales .comunidades-paragraph span {
        color: #E8BA4A;
        font-family: "Rubik One";
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px;
    }
}
