.Header {
  width: auto;
  height: 640px;
  background: url('../../assets/images/home/hero-banner-mobile.png'), lightgray 50%;
  background-size: cover;
  overflow-x: hidden;
}

.Header .mobile-device {
  display: block;
  background-color: transparent;
}

.Header .tablet-device {
  display: none;
}

.Header .web-device {
  display: none;
}

.Header .sus-triunfos-mobile {
  position: relative;
  z-index: 10;
  margin-top: 65%;
  margin-left: 15%;
  margin-right: 15%;
}

.Header .sus-triunfos-mobile .rectangle {
  position: relative;
  z-index: 10;
  border: 1px solid red;
}

.Header .sus-triunfos-mobile .title {
  position: relative;
  z-index: 50;
  color: #FFF;
  text-align: center;
  font-family: "Rubik One";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  width: 80%;
  margin-left: 10%;
  text-align: center;
}

.Header .sus-triunfos-mobile .subtitle {
  color: #FFF;
  font-family: Lato;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: lowercase;
  width: 100%;
  text-align: center;
}

.Header .en-2023-mobile {
  position: relative;
  z-index: 10;
  padding-top: 14%;
}

.Header .en-2023-mobile .title {
  position: relative;
  z-index: 50;
  color: #FFF;
  font-family: Lato;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 95%;
  text-align: center;
  padding-left: 0%;
  text-transform: none;
}

.Header .en-2023-mobile .title b {
  color: #FFF;
  text-align: center;
  font-family: "Rubik One";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Header .rectangle-bottom-mobile {
  position: relative;
  z-index: 1;
  margin-top: -8vh;
}

.Header .rectangle-bottom-mobile img {
  width: 100%;
}

.Header .subrayado {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}
  .Header .subrayado img {
    width: 42%;
  }

@media (min-width: 412px) {
  .Header {
    width: auto;
    height: 750px;
    background: url('../../assets/images/home/hero-banner-mobile.png'), lightgray 50%;
    background-size: cover;
    overflow-x: hidden;
  }

  .Header .en-2023-mobile {
    position: relative;
    z-index: 10;
    padding-top: 35%;
  }
}


  @media screen and (min-width: 768px){
    .Header {
      width: auto;
      height: 1194px;
      background: url('../../assets/images/home/hero-banner-tablet.png'), lightgray 50%;
      background-size: cover;
      background-position: center;
    }
    
    .Header .mobile-device {
      display: none;
    }
    
    .Header .tablet-device {
      display: block;
    }
    
    .Header .web-device {
      display: none;
    }
    
    .Header .sus-triunfos {
      position: absolute;
      z-index: 10;
      top: 30rem;
      left: 1rem;
    }
    
    .Header .sus-triunfos .rectangle {
      position: relative;
      z-index: 10;
    }
    
    .Header .sus-triunfos .title {
      position: relative;
      z-index: 50;
      color: #FFF;
      font-family: "Rubik One";
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      width: 18rem;
      text-align: left;
      margin-top: 0rem;
      padding-left: 2rem;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
    }
    
    .Header .sus-triunfos .subtitle {
      color: #FFF;
      font-family: Lato;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: lowercase;
      width: 17rem;
      text-align: left;
    }
    
    .Header .en-2023 {
      position: absolute;
      z-index: 10;
      top: 38rem;
      left: 12rem;
    }
    
    .Header .en-2023 .title {
      position: relative;
      z-index: 50;
      color: #FFF;
      font-family: Lato;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      width: 340px;
      text-align: left;
      margin-top: 0rem;
      padding-left: 3rem;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
    }
    
    .Header .en-2023 .title b {
      color: #FFF;
      font-family: 'Rubik One';
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    
    .Header .en-2023 .subtitle {
      color: #FFF;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 12rem;
      text-transform: lowercase;
    }
    
    .Header .setecientos-mil {
      position: absolute;
      z-index: 10;
      top: 45rem;
      left: 29rem;
    }
    
    .Header .setecientos-mil .title {
      position: relative;
      z-index: 50;
      color: #FFF;
      font-family: 'Rubik One';
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 15rem;
      text-align: left;
      margin-top: 0rem;
      padding-left: 1rem;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
    }
    
    .Header .setecientos-mil .strong-num {
      font-family: 'Rubik One';
      font-weight: 900;
    }
    
    .Header .setecientos-mil .subtitle {
      color: #FFF;
      font-family: Lato;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      width: 15rem;
      padding-left: 0rem;
      text-transform: lowercase;
    }
    
    .Header .rectangle-bottom {
      position: absolute;
      z-index: 10;
      top: 32rem;
      left: 27rem;
    }
    
    .Header .rectangle1 {
      width: 350px;
      height: auto;
    }
    
    .Header .rectangle2 {
      width: 250px;
      height: auto;
    }
    
    .Header .rectangle3 {
      width: 300px;
      height: auto;
    }
    
    .Header .rectangle4 {
      width: 600px;
      height: auto;
    }
    
    
  }
  @media screen and (min-width: 1024px) and (max-width:1080px) and (orientation: portrait){
    .Header {
      width: auto;
      height: 95vh;
      background: url('../../assets/images/home/hero-banner-tablet.png'), lightgray 50%;
      background-size: cover;
      background-position: center;
    }
    
    .Header .mobile-device {
      display: none;
    }
    
    .Header .tablet-device {
      display: block;
    }
    
    .Header .web-device {
      display: none;
    }
    
    .Header .sus-triunfos {
      position: absolute;
      z-index: 10;
      top: 40rem;
      left: 4rem;
    }
    
    .Header .sus-triunfos .rectangle {
      position: relative;
      z-index: 10;
    }
    
    .Header .sus-triunfos .title {
      position: relative;
      z-index: 50;
      color: #FFF;
      font-family: "Rubik One";
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      width: 10rem;
      text-align: left;
      margin-top: -12rem;
      padding-left: 2rem;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
    }
    
    .Header .sus-triunfos .subtitle {
      color: #FFF;
      font-family: Lato;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: lowercase;
      width: 17rem;
      text-align: left;
    }
    
    .Header .en-2023 {
      position: absolute;
      z-index: 10;
      top: 53rem;
      left: 25rem;
    }
    
    .Header .en-2023 .title {
      position: relative;
      z-index: 50;
      color: #FFF;
      font-family: Lato;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      width: 340px;
      text-align: left;
      margin-top: -9rem;
      padding-left: 3rem;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
    }
    
    .Header .en-2023 .title b {
      color: #FFF;
      font-family: 'Rubik One';
      font-size: 28px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
    
    .Header .en-2023 .subtitle {
      color: #FFF;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 12rem;
      text-transform: lowercase;
    }
    
    .Header .setecientos-mil {
      position: absolute;
      z-index: 10;
      top: 70rem;
      left: 35rem;
    }
    
    .Header .setecientos-mil .title {
      position: relative;
      z-index: 50;
      color: #FFF;
      font-family: 'Rubik Bold';
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 15rem;
      text-align: left;
      margin-top: -19rem;
      padding-left: 1rem;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
    }
    
    .Header .setecientos-mil .strong-num {
      font-family: 'Rubik One';
      font-weight: 900;
    }
    
    .Header .setecientos-mil .subtitle {
      color: #FFF;
      font-family: Lato;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      width: 20rem;
      padding-left: 0rem;
      text-transform: lowercase;
    }
    
    .Header .rectangle-bottom {
      position: absolute;
      z-index: 10;
      top: 32rem;
      left: 27rem;
    }
    
    .Header .rectangle1 {
      width: 350px;
      height: auto;
    }
    
    .Header .rectangle2 {
      width: 250px;
      height: auto;
    }
    
    .Header .rectangle3 {
      width: 300px;
      height: auto;
    }
    
    .Header .rectangle4 {
      width: 600px;
      height: auto;
    }

    .subrayado {
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;
      img {
        width: 40%;
      }
    }
    
    
  }
  /* web 1024 */
  @media (min-width: 1024px) and (orientation: landscape){
    .Header {
      height: 105vh;
      background: url('../../assets/images/home/hero-banner.png'), lightgray 50%;
      background-size: cover;
    }
    
    .Header .mobile-device {
      display: none;
    }
    
    .Header .tablet-device {
      display: none;
    }
    
    .Header .web-device {
      display: block;
    }
    
    .Header .sus-triunfos {
      position: absolute;
      z-index: 10;
      top: 25rem;
      left: 13rem;
    }
    
    .Header .sus-triunfos .rectangle {
      position: relative;
      z-index: 10;
    }
    
    .Header .sus-triunfos .title {
      position: relative;
      z-index: 50;
      color: #FFF;
      font-family: "Rubik One";
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      width: 10rem;
      text-align: left;
      margin-top: -12rem;
      padding-left: 2rem;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
    }
    
    .Header .sus-triunfos .subtitle {
      color: #FFF;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: lowercase;
      width: 13rem;
      text-align: left;
    }
    
    .Header .en-2023 {
      position: absolute;
      z-index: 10;
      top: 32rem;
      left: 28rem;
    }
    
    .Header .en-2023 .title {
      position: relative;
      z-index: 50;
      color: #FFF;
      font-family: 'Rubik Bold';
      font-size: 30px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      width: 20rem;
      text-align: left;
      margin-top: -9rem;
      padding-left: 3rem;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
    }
    
    .Header .en-2023 .subtitle {
      color: #FFF;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 14rem;
      text-transform: lowercase;
    }
    
    .Header .setecientos-mil {
      position: absolute;
      z-index: 10;
      top: 50rem;
      left: 39rem;
    }
    
    .Header .setecientos-mil .title {
      position: relative;
      z-index: 50;
      color: #FFF;
      font-family: 'Rubik Bold';
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 20rem;
      text-align: left;
      margin-top: -22rem;
      padding-left: 1rem;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
    }
    
    .Header .setecientos-mil .strong-num {
      font-family: 'Rubik One';
      font-weight: 900;
    }
    
    .Header .setecientos-mil .subtitle {
      color: #FFF;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 18rem;
      padding-left: 5rem;
      text-transform: lowercase;
    }
    
    .Header .rectangle-bottom {
      position: absolute;
      z-index: 10;
      top: 32rem;
      left: 27rem;
    }
    
    .Header .rectangle1 {
      width: 350px;
      height: auto;
    }
    
    .Header .rectangle2 {
      width: 250px;
      height: auto;
    }
    
    .Header .rectangle3 {
      width: 300px;
      height: auto;
    }
    
    .Header .rectangle4 {
      width: 600px;
      height: auto;
    }

    .subrayado {
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;
      img {
        width: 30%;
      }
    }
    
  }

  @media (min-width: 1024px) and (orientation: portrait){
    .Header {
      height: 105vh;
      background: url('../../assets/images/home/hero-banner.png'), lightgray 50%;
      background-size: cover;
      background-position: top center;
    }
    
    .Header .mobile-device {
      display: none;
    }
    
    .Header .tablet-device {
      display: none;
    }
    
    .Header .web-device {
      display: block;
    }
    
    .Header .sus-triunfos {
      position: absolute;
      z-index: 10;
      top: 25rem;
      left: 13rem;
    }
    
    .Header .sus-triunfos .rectangle {
      position: relative;
      z-index: 10;
    }
    
    .Header .sus-triunfos .title {
      position: relative;
      z-index: 50;
      color: #FFF;
      font-family: "Rubik One";
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      width: 10rem;
      text-align: left;
      margin-top: 9rem;/*-12rem;*/
      padding-left: 2rem;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
    }
    
    .Header .sus-triunfos .subtitle {
      color: #FFF;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: lowercase;
      width: 13rem;
      text-align: left;
    }
    
    .Header .en-2023 {
      position: absolute;
      z-index: 10;
      top: 60rem;/*32rem;*/
      left: 28rem;
    }
    
    .Header .en-2023 .title {
      position: relative;
      z-index: 50;
      color: #FFF;
      font-family: 'Rubik Bold';
      font-size: 30px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      width: 20rem;
      text-align: left;
      margin-top: -9rem;
      padding-left: 3rem;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
    }
    
    .Header .en-2023 .subtitle {
      color: #FFF;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 14rem;
      text-transform: lowercase;
    }
    
    .Header .setecientos-mil {
      position: absolute;
      z-index: 10;
      top: 78rem; /*50rem;*/
      left:39rem;
    }
    
    .Header .setecientos-mil .title {
      position: relative;
      z-index: 50;color: #FFF;
      font-family: 'Rubik Bold';
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 20rem;
      text-align: left;
      margin-top: -17rem;
      padding-left: 1rem;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
    }
    
    .Header .setecientos-mil .strong-num {
      font-family: 'Rubik One';
      font-weight: 900;
    }
    
    .Header .setecientos-mil .subtitle {
      color: #FFF;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 18rem;
      padding-left: 5rem;
      text-transform: lowercase;
    }
    
    .Header .rectangle-bottom {
      position: absolute;
      z-index: 10;
      top: 32rem;
      left: 27rem;
    }
    
    .Header .rectangle1 {
      width: 350px;
      height: auto;
    }
    
    .Header .rectangle2 {
      width: 250px;
      height: auto;
    }
    
    .Header .rectangle3 {
      width: 300px;
      height: auto;
    }
    
    .Header .rectangle4 {
      width: 600px;
      height: auto;
    }

    .subrayado {
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;
      img {
        width: 30%;
      }
    }
    
  }

  @media (min-width: 1366px) {
    .Header {
      min-height: 768px;
      height: 105vh;
      background: url('../../assets/images/home/hero-banner.png'), lightgray 50%;
      background-size: cover;
      overflow-y: hidden;
    }
    
    .Header .mobile-device {
      display: none;
    }
    
    .Header .tablet-device {
      display: none;
    }
    
    .Header .web-device {
      display: block;
    }
    
    .Header .sus-triunfos {
      position: absolute;
      z-index: 10;
      top: 30rem;
      left: 19rem;
    }
    
    .Header .sus-triunfos .rectangle {
      position: relative;
      z-index: 10;
    }
    
    .Header .sus-triunfos .title {
      position: relative;
      z-index: 50;
      color: #FFF;
      font-family: "Rubik One";
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      width: 25rem;
      text-align: left;
      margin-top: -12rem;
      padding-left: 2rem;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
    }
    
    .Header .sus-triunfos .subtitle {
      color: #FFF;
      font-family: Lato;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: lowercase;
      width: 13rem;
      text-align: left;
    }
    
    .Header .en-2023 {
      position: absolute;
      z-index: 10;
      top: 36rem;
      left: 29rem;
    }
    
    .Header .en-2023 .title {
      position: relative;
      z-index: 50;
      color: #FFF;
      font-family: 'Rubik Bold';
      font-size: 36px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      width: 20rem;
      text-align: left;
      margin-top: -9rem;
      padding-left: 3rem;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
    }
    
    .Header .en-2023 .subtitle {
      color: #FFF;
      font-family: Lato;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 18rem;
      text-transform: lowercase;
    }
    
    .Header .setecientos-mil {
      position: absolute;
      z-index: 10;
      top: 52rem;
      left: 43rem;
    }
    
    .Header .setecientos-mil .title {
      position: relative;
      z-index: 50;
      color: #FFF;
      font-family: 'Rubik Bold';
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 20rem;
      text-align: left;
      margin-top: -19rem;
      padding-left: 1rem;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
    }
    
    .Header .setecientos-mil .strong-num {
      font-family: 'Rubik One';
      font-weight: 900;
    }
    
    .Header .setecientos-mil .subtitle {
      color: #FFF;
      font-family: Lato;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 22rem;
      padding-left: 5rem;
      text-transform: lowercase;
    }
    
    .Header .rectangle-bottom {
      position: absolute;
      z-index: 10;
      top: 32rem;
      left: 27rem;
    }
    
    .Header .rectangle1 {
      width: 350px;
      height: auto;
    }
    
    .Header .rectangle2 {
      width: 250px;
      height: auto;
    }
    
    .Header .rectangle3 {
      width: 300px;
      height: auto;
    }
    
    .Header .rectangle4 {
      width: 600px;
      height: auto;
    }

    .subrayado {
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;
      img {
        width: 30%;
      }
    }
    
  }


  /* web 1440 */
  @media (min-width: 1440px) {
    .Header {
      width: 100%;
      height: 100vh;
      background: url('../../assets/images/home/hero-banner.png'), lightgray 50%;
      background-size: cover;
    }
    
    .Header .sus-triunfos {
      position: absolute;
      z-index: 10;
      top: 26rem;
      left: 13rem;
    }
    
    .Header .sus-triunfos .rectangle {
      position: relative;
      z-index: 10;
    }
    
    .Header .sus-triunfos .title {
      position: relative;
      z-index: 50;
      color: #FFF;
      font-family: "Rubik One";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      width: 25rem;
      text-align: left;
      margin-top: -14rem;
      padding-left: 2rem;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
    }
    
    .Header .sus-triunfos .subtitle {
      color: #FFF;
      font-family: Lato;
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: lowercase;
      width: 19rem;
    }
    
    .Header .en-2023 {
      position: absolute;
      z-index: 10;
      top: 33rem;
      left: 30rem;
    }
    
    .Header .en-2023 .title {
      position: relative;
      z-index: 50;
      color: #FFF;
      font-family: 'Rubik Bold';
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      width: 20rem;
      text-align: left;
      margin-top: -12rem;
      padding-left: 2rem;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
    }
    
    .Header .en-2023 .subtitle {
      color: #FFF;
      font-family: Lato;
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 25rem;
      text-transform: lowercase;
    }
    
    .Header .en-2023 .strong-num {
      font-family: 'Rubik One';
      font-weight: 900;
    }
    
    .Header .setecientos-mil {
      position: absolute;
      z-index: 10;
      top: 53rem;
      left: 44rem;
    }
    
    .Header .setecientos-mil .title {
      position: relative;
      z-index: 50;
      color: #FFF;
      font-family: 'Rubik Bold';
      font-size: 80px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 20rem;
      text-align: left;
      margin-top: -22rem;
      padding-left: 2rem;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
    }
    
    .Header .setecientos-mil .subtitle {
      color: #FFF;
      font-family: Lato;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 28rem;
      padding-left: 7rem;
      text-transform: lowercase;
    }
    
    .Header .rectangle-bottom {
      position: absolute;
      z-index: 10;
      top: 52rem;
      left: 53rem;
    }
    
    .Header .rectangle1 {
      width: 400px;
      height: auto;
    }
    
    .Header .rectangle2 {
      width: 350px;
      height: auto;
    }
    
    .Header .rectangle3 {
      width: 350px;
      height: auto;
    }
    
    .Header .rectangle4 {
      width: 600px;
      height: auto;
    }

    .subrayado {
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;
      img {
        width: 30%;
      }
    }
  }    


  @media (min-width: 1920px) {
    .Header {
      width: 100%;
      height: 100vh;
      background: url('../../assets/images/home/hero-banner.png'), lightgray 50%;
      background-size: cover;
    }
    
    .Header .sus-triunfos {
      position: absolute;
      z-index: 10;
      top: 19rem;
      left: 15rem;
    }
    
    .Header .sus-triunfos .rectangle {
      position: relative;
      z-index: 10;
    }
    
    .Header .sus-triunfos .title {
      position: relative;
      z-index: 50;
      color: #FFF;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      width: 35rem;
      text-align: left;
      margin-top: 0rem;
      padding-left: 5rem;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
    }
    
    .Header .sus-triunfos .subtitle {
      color: #FFF;
      font-family: Lato;
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: lowercase;
      width: 19rem;
    }
    
    .Header .en-2023 {
      position: absolute;
      z-index: 10;
      top: 30rem;
      left: 37rem;
    }
    
    .Header .en-2023 .title {
      position: relative;
      z-index: 50;
      color: #FFF;
      font-family: 'Rubik Bold';
      font-size: 50px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      width: 25rem;
      text-align: left;
      margin-top: 0rem;
      padding-left: 2rem;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
    }
    
    .Header .en-2023 .subtitle {
      color: #FFF;
      font-family: Lato;
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 25rem;
      text-transform: lowercase;
    }
    
    .Header .en-2023 .strong-num {
      font-family: 'Rubik One';
      font-weight: 900;
    }
    
    .Header .setecientos-mil {
      position: absolute;
      z-index: 10;
      top: 41rem;
      left: 53rem;
    }
    
    .Header .setecientos-mil .title {
      position: relative;
      z-index: 50;
      color: #FFF;
      font-family: 'Rubik Bold';
      font-size: 100px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 20rem;
      text-align: left;
      margin-top: 0rem;
      padding-left: 2rem;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
    }
    
    .Header .setecientos-mil .subtitle {
      color: #FFF;
      font-family: Lato;
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 32rem;
      padding-left: 7rem;
      text-transform: lowercase;
    }
    
    .Header .rectangle-bottom {
      position: absolute;
      z-index: 10;
      top: 57rem;
      left: 81rem;
    }
    
    .Header .rectangle1 {
      width: 400px;
      height: auto;
    }
    
    .Header .rectangle2 {
      width: 350px;
      height: auto;
    }
    
    .Header .rectangle3 {
      width: 350px;
      height: auto;
    }
    
    .Header .rectangle4 {
      width: 600px;
      height: auto;
    }

    .subrayado {
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;
      img {
        width: 30%;
      }
    }
}

@media (min-width: 2560px) {
  .Header {
    width: 100%;
    height: 1610px;
    background: url('../../assets/images/home/hero-banner.png'), lightgray 50%;
    background-size: cover;
  }
  
  .Header .sus-triunfos {
    position: absolute;
    z-index: 10;
    top: 28rem;
    left: 25rem;
  }
  
  .Header .sus-triunfos .rectangle {
    position: relative;
    z-index: 10;
  }
  
  .Header .sus-triunfos .title {
    position: relative;
    z-index: 50;
    color: #FFF;
    font-family: "Rubik One";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    width: 10rem;
    text-align: left;
    margin-top: 0rem;
    padding-left: 5rem;
    text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
  }
  
  .Header .sus-triunfos .subtitle {
    color: #FFF;
    font-family: Lato;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: lowercase;
    width: 19rem;
  }
  
  .Header .en-2023 {
    position: absolute;
    z-index: 10;
    top: 48rem;
    left: 55rem;
  }
  
  .Header .en-2023 .title {
    position: relative;
    z-index: 50;
    color: #FFF;
    font-family: 'Rubik Bold';
    font-size: 50px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    width: 25rem;
    text-align: left;
    margin-top: 0rem;
    padding-left: 2rem;
    text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
  }
  
  .Header .en-2023 .subtitle {
    color: #FFF;
    font-family: Lato;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 25rem;
    text-transform: lowercase;
  }
  
  .Header .en-2023 .strong-num {
    font-family: 'Rubik One';
    font-weight: 900;
  }
  
  .Header .setecientos-mil {
    position: absolute;
    z-index: 10;
    top: 63rem;
    left: 77rem;
  }
  
  .Header .setecientos-mil .title {
    position: relative;
    z-index: 50;
    color: #FFF;
    font-family: 'Rubik Bold';
    font-size: 100px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 20rem;
    text-align: left;
    margin-top: 0rem;
    padding-left: 2rem;
    text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.50);
  }
  
  .Header .setecientos-mil .subtitle {
    color: #FFF;
    font-family: Lato;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 32rem;
    padding-left: 7rem;
    text-transform: lowercase;
  }
  
  .Header .rectangle-bottom {
    position: absolute;
    z-index: 10;
    top: 57rem;
    left: 81rem;
  }
  
  .Header .rectangle1 {
    width: 400px;
    height: auto;
  }
  
  .Header .rectangle2 {
    width: 350px;
    height: auto;
  }
  
  .Header .rectangle3 {
    width: 350px;
    height: auto;
  }
  
  .Header .rectangle4 {
    width: 600px;
    height: auto;
  }

  .subrayado {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
    img {
      width: 30%;
    }
  }
}

