.Exposicion1 {
  width: 100%;
}

.Exposicion1 .mobile-device {
  display: block;
}

.Exposicion1 .web-device {
  display: none;
}

.Exposicion1 .bg1 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 420px;
}

.Exposicion1 .text-container-exposicion1 {
  position: relative;
  padding-top: 60%;
  padding-left: 5%;
  padding-right: 5%;
}

.Exposicion1 .gradient1 {
  background: linear-gradient(180deg, rgba(165, 92, 194, 0.61) 52.01%, rgba(255, 255, 255, 0.00) 122.07%);
  margin-left: 10%;
  margin-right: 10%;
}

.Exposicion1 .paragraph {
  color: #FFF;
  font-family: Ruda;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 19px;
}

.Exposicion1 .paragraph b {
  color: inherit;
  font-family: "Rubik One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 121.429% */
}

.Exposicion1 .paragraph2 {
  color: #1F282C;
  font-family: Ruda;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px;
}

.Exposicion1 .paragraph2 b {
  color: #A55CC2;
  text-align: center;
  font-family: "Rubik One";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 111.111% */
}

.Exposicion1 .bg2 {
  background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion2.png');
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 216px;
}

.Exposicion1 .bg3 {
  background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion3.png');
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 304px;
}

.Exposicion1 .bg4 {
  background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion4.png');
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 288px;
}

.Exposicion1 .bg5 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion5.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 320px;
}

.Exposicion1 .bg-fotografica {
  background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/fotograficamx.png');
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 374px;
}

.Exposicion1 .text-container-exposicion5 {
  position: relative;
  padding-top: 10%;
}

  @media (min-width: 768px) {
    .Exposicion1 .bg1 {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion-mobile.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 798px;
    }
  
    .Exposicion1 .text-container-exposicion1 {
        position: relative;
        padding-top: 50%;
        padding-left: 5%;
        padding-right: 5%;
    }
  
    .Exposicion1 .gradient1 {
        background: linear-gradient(180deg, rgba(165, 92, 194, 0.61) 52.01%, rgba(255, 255, 255, 0.00) 122.07%);
        margin-left: 5%;
        margin-right: 5%;
    }
  
    .Exposicion1 .paragraph {
        color: #FFF;
        font-family: Ruda;
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        line-height: 125%;
    }

    .Exposicion1 .paragraph b {
        color: #FFF;
        font-family: "Rubik One";
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 47.5px */
    }
  
    .Exposicion1 .paragraph2 {
        color: #1F282C;
        font-family: Ruda;
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        line-height: 42px;
    }

    .Exposicion1 .paragraph2 b {
        color: #A55CC2;
        text-align: center;
        font-family: "Rubik One";
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px; /* 110.526% */
    }
  
    .Exposicion1 .bg2 {
        background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion2.png');
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 467px;
    }
  
    .Exposicion1 .bg3 {
        background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion3.png');
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 523px;
    }
  
    .Exposicion1 .bg4 {
        background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion4.png');
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 670px;
    }
  
    .Exposicion1 .bg5 {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion5.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 679px;
    }

    .Exposicion1 .bg-fotografica {
        background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/fotograficamx.png');
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 866px;
    }
  
    .Exposicion1 .text-container-exposicion5 {
        position: relative;
        padding-top: 5%;
    }
}

@media (min-width: 1024px) {
    .Exposicion1 .mobile-device {
        display: none;
    }
  
    .Exposicion1 .web-device {
        display: block;
    }

    .Exposicion1 .bg1 {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion-mobile.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 798px;
    }
  
    .Exposicion1 .text-container-exposicion1 {
        position: relative;
        padding-top: 2%;
        padding-left: 5%;
        padding-right: 5%;
    }
  
    .Exposicion1 .gradient1 {
        background: linear-gradient(180deg, rgba(165, 92, 194, 0.61) 52.01%, rgba(255, 255, 255, 0.00) 122.07%);
        margin-left: 10%;
        margin-right: 10%;
    }
  
    .Exposicion1 .paragraph {
        color: #FFF;
        font-family: Ruda;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 125%;
        text-align: center;
    }

    .Exposicion1 .paragraph b {
        color: #FFF;
        font-family: "Rubik One";
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 47.5px */
    }
  
    .Exposicion1 .paragraph2 {
        color: #1F282C;
        font-family: Ruda;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 30px;
    }

    .Exposicion1 .paragraph2 b {
        color: #A55CC2;
        text-align: center;
        font-family: "Rubik One";
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 110.526% */
    }
  
    .Exposicion1 .bg2 {
        background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion2-web.png');
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 720px;
    }
  
    .Exposicion1 .bg3 {
        background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion3.png');
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 523px;
    }
  
    .Exposicion1 .bg4 {
        background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion4.png');
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 670px;
    }
  
    .Exposicion1 .bg5 {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion5-web.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 679px;
    }

    .Exposicion1 .bg-fotografica {
        background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/fotograficamx.png');
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 535px;
    }
  
    .Exposicion1 .text-container-exposicion5 {
        position: relative;
        padding-top: 40%;
    }
}

@media (min-width: 1366px) {
  .Exposicion1 .mobile-device {
      display: none;
  }

  .Exposicion1 .web-device {
      display: block;
  }

  .Exposicion1 .bg1 {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion-mobile.png');
      background-repeat: no-repeat;
      background-size: cover;
      height: 798px;
  }

  .Exposicion1 .text-container-exposicion1 {
      position: relative;
      padding-top: 2%;
      padding-left: 5%;
      padding-right: 5%;
  }

  .Exposicion1 .gradient1 {
      background: linear-gradient(180deg, rgba(165, 92, 194, 0.61) 52.01%, rgba(255, 255, 255, 0.00) 122.07%);
      margin-left: 10%;
      margin-right: 10%;
  }

  .Exposicion1 .paragraph {
      color: #FFF;
      font-family: Ruda;
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 125%;
      text-align: center;
  }

  .Exposicion1 .paragraph b {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 47.5px */
  }

  .Exposicion1 .paragraph2 {
      color: #1F282C;
      font-family: Ruda;
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: 30px;
  }

  .Exposicion1 .paragraph2 b {
      color: #A55CC2;
      text-align: center;
      font-family: "Rubik One";
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 110.526% */
  }

  .Exposicion1 .bg2 {
      background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion2-web.png');
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 720px;
  }

  .Exposicion1 .bg3 {
      background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion3.png');
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 523px;
  }

  .Exposicion1 .bg4 {
      background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion4.png');
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 670px;
  }

  .Exposicion1 .bg5 {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion5-web.png');
      background-repeat: no-repeat;
      background-size: cover;
      height: 679px;
  }

  .Exposicion1 .bg-fotografica {
      background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/fotograficamx.png');
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 635px;
  }

  .Exposicion1 .text-container-exposicion5 {
      position: relative;
      padding-top: 40%;
  }
}

@media (min-width: 1440px) {
  .Exposicion1 .bg1 {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion.png');
      background-repeat: no-repeat;
      background-size: cover;
      height: 798px;
  }

  .Exposicion1 .text-container-exposicion1 {
      position: relative;
      padding-top: 2%;
      padding-left: 10%;
      padding-right: 10%;
  }

  .Exposicion1 .gradient1 {
      background: linear-gradient(180deg, rgba(165, 92, 194, 0.61) 52.01%, rgba(255, 255, 255, 0.00) 122.07%);
      margin-left: 5%;
      margin-right: 5%;
  }

  .Exposicion1 .paragraph {
      color: #FFF;
      font-family: Ruda;
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: 45px;
  }

  .Exposicion1 .paragraph b {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(17, 17, 17, 0.25);
      font-family: "Rubik One";
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: 45px; /* 107.143% */
  }

  .Exposicion1 .paragraph2 {
      color: #1F282C;
      font-family: Ruda;
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 38px;
      text-align: left;
  }

  .Exposicion1 .paragraph2 b {
      color: #A55CC2;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 38px; /* 107.143% */
  }

  .Exposicion1 .bg2 {
      background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion2.png');
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 720px;
  }

  .Exposicion1 .bg3 {
      background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion3.png');
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 810px;
  }

  .Exposicion1 .bg4 {
      background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion4.png');
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 770px;
  }

  .Exposicion1 .bg5 {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion5-web.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 669px;
  }

  .Exposicion1 .bg-fotografica {
      background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/fotograficamx.png');
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 720px;
  }

  .Exposicion1 .text-container-exposicion5 {
      position: relative;
      padding-top: 22%;
      padding-left: 10%;
      padding-right: 10%;
  }
}

@media (min-width: 1920px) {
  .Exposicion1 .bg1 {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion.png');
      background-repeat: no-repeat;
      background-size: cover;
      height: 798px;
  }

  .Exposicion1 .text-container-exposicion1 {
      position: relative;
      padding-top: 2%;
      padding-left: 10%;
      padding-right: 10%;
  }

  .Exposicion1 .gradient1 {
      background: linear-gradient(180deg, rgba(165, 92, 194, 0.61) 52.01%, rgba(255, 255, 255, 0.00) 122.07%);
      margin-left: 5%;
      margin-right: 5%;
  }

  .Exposicion1 .paragraph {
      color: #FFF;
      font-family: Ruda;
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: 45px;
  }

  .Exposicion1 .paragraph b {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(17, 17, 17, 0.25);
      font-family: "Rubik One";
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: 45px; /* 107.143% */
  }

  .Exposicion1 .paragraph2 {
      color: #1F282C;
      font-family: Ruda;
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: 45px;
      text-align: left;
  }

  .Exposicion1 .paragraph2 b {
      color: #A55CC2;
      font-family: "Rubik One";
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: 45px; /* 107.143% */
  }

  .Exposicion1 .bg2 {
      background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion2.png');
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 720px;
  }

  .Exposicion1 .bg3 {
      background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion3.png');
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 810px;
  }

  .Exposicion1 .bg4 {
      background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion4.png');
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 770px;
  }

  .Exposicion1 .bg5 {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), url('../../../../../assets/images/programas/subsections/fotograficamx/exposicion5-web.png');
      background-repeat: no-repeat;
      background-size: cover;
      height: 669px;
  }

  .Exposicion1 .bg-fotografica {
      background-image: url('../../../../../assets/images/programas/subsections/fotograficamx/fotograficamx.png');
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 990px;
  }

  .Exposicion1 .text-container-exposicion5 {
      position: relative;
      padding-top: 3%;
      padding-left: 10%;
      padding-right: 10%;
  }
}
