:root{
  --azcl :#3390ff;
  --azosc:#3A55F9;
  --gris :#cccccc;
  --griscl:#d9d9d9;
  --ngo  :#000;
  --bco  :#fff;
  --nar  :#fb8500;
  --ama  :#ffb706;
  --verd :#00d689;
  --amacl:#e5e517;
  --rjo  :#f94e4e;
  --rjocl:#ffa4a4;
  --azcl2:#46cae4; 
  --azcl3:#D6EEF7;
  --azcl4:#caf0f8;
  --magn :#cb34c4; 
  --magn2:#BC46BF;
  --amcl2:#ffd362;
  --rjo3 :#D8645B;
  --ama_Ig :#E6E36A;
  --rjo_Ig :#D8645B;
  --azu_Ig :#6DCAE3;
  --ver_Ig :#80CF95;
  --rsa_Ig :#F2A8A6;
  --AzuOb_Ig :#5A93F0;
  --vde2: #63D28F;
  --nar2:#F5B845;
  --rjoosc : #E85B55;
}
@font-face {
  font-family: 'Rubik';
  src: url('./assets/fonts/woff/Rubik-Regular.woff2') format("woff2");
  font-display: swap;
}

@font-face {
  font-family: 'Rubik One';
  src: url('./assets/fonts/woff/RubikOne-Regular.woff2') format("woff2");
  font-display: swap;
}

@font-face {
  font-family: 'Rubik Bold';
  src: url('./assets/fonts/woff/Rubik-Bold.woff2') format("woff2");
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/woff/Lato-Regular.woff2') format("woff2");
  font-display: swap;
}
@font-face {
  font-family: 'Lato Bold';
  src: url('./assets/fonts/woff/Lato-Black.woff2') format("woff2");
  font-display: swap;
}

@font-face {
  font-family: 'Ruda';
  src: url('./assets/fonts/woff/Ruda-Regular.woff2') format("woff2");
  font-display: swap;
}
@font-face {
  font-family: 'Ruda Bold';
  src: url('./assets/fonts/woff/Ruda-ExtraBold.woff2') format("woff2");
  font-display: swap;
}
@font-face {
  font-family: 'Ruda Extrab';
  src: url('./assets/fonts/woff/Ruda-ExtraBold.woff2') format("woff2");
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/woff/Inter-Regular.woff2') format("woff2");
  font-display: swap;
}
@font-face {
  font-family: 'Inter Bold';
  src: url('./assets/fonts/woff/Inter-ExtraBold.woff2') format("woff2");
  font-display: swap;
}

body{
  font-family: 'Rubik One', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 17px ;
  overflow-x: hidden;
}
a{
  text-decoration: none;
}
strong{
  font-weight: 800;
}