.cuantrix .imp-par {
    color: #000;
    font-family: 'Ruda Bold';
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    text-align: start;
}

.cuantrix .imp-par .highlight {
    color: #3F8EFF;
    font-family: "Rubik One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 45.6px */
}

.cuantrix .bg-team {
    min-height: 75vh;
    background-image: url('../../../../assets/images/programas/cuantrix/fondocuantrix.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.cuantrix .rect-gr {
    background-image: url('../../../../assets/images/programas/cuantrix/Rectanglegpo.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    padding: 1rem;
}

.cuantrix .text-box-numbs-1 {
    transform: translate(0%, 61%);
}

.cuantrix .text-box-numbs-2 {
    transform: translate(0%, 110%)
}

.cuantrix .bg-gray-custom {
    background: #F2F8FF;
}

@media screen and (max-width:1800px){
    .cuantrix .imp-par {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
        text-align: start;
    }
    
    .cuantrix .imp-par .highlight {
        color: #3F8EFF;
        font-family: "Rubik One";
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 45.6px */
    }
    
    .cuantrix .imp-par.w-75 {
        width: 90% !important;
        padding: 2rem 0;
    }
    
    .cuantrix .text-box-numbs-1 {
        transform: translate(3%, 35%);
    }
    
    .cuantrix .text-box-numbs-2 {
        transform: translate(6%, -6%);
    }
    
    .cuantrix .bg-team {
        min-height: 128vh;
        background-image: url('../../../../assets/images/programas/cuantrix/PresentacionCuantrixEscPrimariaGertrudi.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
    }
    
    /* .cuantrix .rect-gr .imp-par,
    .cuantrix .rect-gr .imp-par .highlight {
        font-size: 28px;
        line-height: normal;
    } */
    
    .cuantrix .text-box-numbs-1 {
        transform: translate(0%, 160%)
    }
    
    .cuantrix .text-box-numbs-2 {
        transform: translate(0%, 225%);
    }
    
}

@media screen and (max-width:1600px){
    .cuantrix .imp-par {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 38px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
        text-align: start;
    }
    
    .cuantrix .imp-par .highlight {
        color: #3F8EFF;
        font-family: "Rubik One";
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 45.6px */
    }
    
    .cuantrix .imp-par.w-75 {
        width: 90% !important;
        padding: 2rem 0;
    }
    
    .cuantrix .text-box-numbs-1 {
        transform: translate(3%, 35%);
    }
    
    .cuantrix .text-box-numbs-2 {
        transform: translate(6%, -6%);
    }
    
    .cuantrix .bg-team {
        min-height: 100vh;
        background-image: url('../../../../assets/images/programas/cuantrix/PresentacionCuantrixEscPrimariaGertrudi.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
    }
    
    .cuantrix .rect-gr .imp-par,
    .cuantrix .rect-gr .imp-par .highlight {
        font-size: 28px;
        line-height: normal;
    }
    
    .cuantrix .text-box-numbs-1 {
        transform: translate(0%, 160%)
    }
    
    .cuantrix .text-box-numbs-2 {
        transform: translate(0%, 96%);
    }
    
}

@media screen and (max-width:1440px){
    .cuantrix .imp-par {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 38px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
        text-align: start;
    }
    
    .cuantrix .imp-par .highlight {
        color: #3F8EFF;
        font-family: "Rubik One";
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 45.6px */
    }
    
    .cuantrix .imp-par.w-75 {
        width: 90% !important;
        padding: 2rem 0;
    }
    
    .cuantrix .text-box-numbs-1 {
        transform: translate(3%, 35%);
    }
    
    .cuantrix .text-box-numbs-2 {
        transform: translate(6%, -6%);
    }
    
    .cuantrix .bg-team {
        min-height: 100vh;
        background-image: url('../../../../assets/images/programas/cuantrix/PresentacionCuantrixEscPrimariaGertrudi.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
    }
    
    .cuantrix .rect-gr .imp-par,
    .cuantrix .rect-gr .imp-par .highlight {
        font-size: 28px;
        line-height: normal;
    }
    
    .cuantrix .text-box-numbs-1 {
        transform: translate(0%, 160%)
    }
    
    .cuantrix .text-box-numbs-2 {
        transform: translate(0%, 96%);
    }
    
}

@media screen and (max-width:1366px){
    .cuantrix .imp-par {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
        text-align: start;
    }
    
    .cuantrix .imp-par .highlight {
        color: #3F8EFF;
        font-family: "Rubik One";
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 45.6px */
    }
    
    .cuantrix .imp-par.w-75 {
        width: 90% !important;
        padding: 2rem 0;
    }
    
    .cuantrix .text-box-numbs-1 {
        transform: translate(3%, 35%);
    }
    
    .cuantrix .text-box-numbs-2 {
        transform: translate(6%, -6%);
    }
    
    .cuantrix .bg-team {
        min-height: 100vh;
        background-image: url('../../../../assets/images/programas/cuantrix/PresentacionCuantrixEscPrimariaGertrudi.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
    }
    
    .cuantrix .rect-gr .imp-par,
    .cuantrix .rect-gr .imp-par .highlight {
        font-size: 30px;
        line-height: normal;
        text-align: left;
    }
    
    .cuantrix .text-box-numbs-1 {
        transform: translate(0%, 140%);
    }
    
    .cuantrix .text-box-numbs-2 {
        transform: translate(0%, 74%);
    }
    
}

@media screen and (max-width:1024px){
    .cuantrix .imp-par {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 22px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
        text-align: start;
    }
    
    .cuantrix .imp-par .highlight {
        color: #3F8EFF;
        font-family: "Rubik One";
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 45.6px */
    }
    
    .cuantrix .imp-par.w-75 {
        width: 90% !important;
        padding: 2rem 0;
    }
    
    .cuantrix .text-box-numbs-1 {
        transform: translate(3%, 35%);
    }
    
    .cuantrix .text-box-numbs-2 {
        transform: translate(6%, -6%);
    }
    
    .cuantrix .bg-team {
        min-height: 80vh;
        background-image: url('../../../../assets/images/programas/cuantrix/PresentacionCuantrixEscPrimariaGertrudi.png');
        background-size: cover;
        background-repeat: no-repeat;
    }
    
    .cuantrix .rect-gr .imp-par,
    .cuantrix .rect-gr .imp-par .highlight {
        font-size: 20px;
        line-height: normal;
        text-align: left;
    }
    
    .cuantrix .text-box-numbs-1 {
        transform: translate(-15%, 2%);
    }
    
    .cuantrix .text-box-numbs-2 {
        transform: translate(20%, 0%);
    }
    
}

@media screen and (max-width:900px){
    .cuantrix .imp-par {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
        text-align: center;
    }
    
    .cuantrix .imp-par .highlight {
        color: #3F8EFF;
        font-family: "Rubik One";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 45.6px */
    }
    
    .cuantrix .imp-par.w-75 {
        width: 90% !important;
        padding: 2rem 0;
    }
    
    .cuantrix .text-box-numbs-1 {
        transform: translate(3%, 35%);
    }
    
    .cuantrix .text-box-numbs-2 {
        transform: translate(6%, -6%);
    }
    
    .cuantrix .bg-team {
        min-height: 50vh;
        background-image: url('../../../../assets/images/programas/cuantrix/PresentacionCuantrixEscPrimariaGertrudi.png');
        background-size: cover;
        background-repeat: no-repeat;
    }
    
    .cuantrix .rect-gr .imp-par,
    .cuantrix .rect-gr .imp-par .highlight {
        font-size: 22px;
        line-height: normal;
    }
    
    .cuantrix .text-box-numbs-1 {
        transform: translate(0%, 75%);
    }
    
    .cuantrix .text-box-numbs-2 {
        transform: translate(0%, -5%);
    }
    
}

@media screen and (max-width:600px){
    .cuantrix .imp-par {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
        text-align: center;
    }
    
    .cuantrix .imp-par .highlight {
        color: #3F8EFF;
        font-family: "Rubik One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 45.6px */
    }
    
    .cuantrix .imp-par.w-75 {
        width: 90% !important;
        padding: 2rem 0;
    }
    
    .cuantrix .text-box-numbs-1 {
        transform: translate(3%, 35%);
    }
    
    .cuantrix .text-box-numbs-2 {
        transform: translate(6%, -6%);
    }
    
    .cuantrix .bg-team {
        min-height: 50vh;
        background-image: url('../../../../assets/images/programas/cuantrix/PresentacionCuantrixEscPrimariaGertrudi.png');
        background-size: cover;
        background-repeat: no-repeat;
    }
    
    .cuantrix .rect-gr .imp-par,
    .cuantrix .rect-gr .imp-par .highlight {
        font-size: 18px;
        line-height: normal;
    }
    
    .cuantrix .text-box-numbs-1 {
        transform: translate(0%, 23%);
    }
    
    .cuantrix .text-box-numbs-2 {
        transform: translate(0%, 0%);
    }
    
}
