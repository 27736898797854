.bg-color{
    background: #E9EDF0;
    padding:5%;
}
.h-prog{
    color: #000;
    font-family: Ruda;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 57.6px */
}
.Aliados .title{
    color: #000;
    font-family: Ruda;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; 
}
.Aliados .par{
        color: #00B9F1;
        font-family: "Rubik One";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 52.8px */
}
.Aliados .sub-title{
    color: #FFF;
    text-align: center;
    font-family: Lato;
    font-size: 49.249px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.bg-aliados{
    background: #00B9F1;
}
.Aliados .lbl-aliados{
    color: #FFF;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 45px; /* 150% */
    display: block;
    border-bottom:solid 1px #FFF;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
}
.par-aliados{
    color: #FFF;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px; /* 180% */
}
.bold{
    font-family: "Inter Bold";
    font-weight: bolder;
}

.custom-mobile-device {
    display: none;
}

.custom-web-device {
    display: block;
}
.fit-image{
    height: 350px;
    width: 80%;
    overflow-y: hidden;
    object-fit: cover;
}
img{
    text-align: center;
}
@media screen and (max-width: 1440px) {
    .Aliados .title{
        font-size: 35px;
    }
    .Aliados .par{
        color: #00B9F1;
        font-family: "Rubik One";
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
    }
    .Aliados .sub-title{
        font-size: 38px;
    }
    .Aliados  .lbl-aliados{
        font-size: 1.75rem;
    }
    .Aliados  .par-aliados{
        color: #FFF;
        font-family: Inter;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px;
    }
    .Aliados  .mh-mov{
        max-height:500px ;
    }
    .Aliados .h-prog {
        color: #000;
        font-family: Ruda;
        font-size: 35px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
    }
    .fit-image{
    height: 350px;
    width: 80%;
    overflow-y: hidden;
    object-fit: cover;
}
}
@media screen and (max-width: 1366px) {
    .Aliados .sub-title{
        font-size: 30px;
    }
    .fit-image{
    height: 350px;
    width: 80%;
    overflow-y: hidden;
    object-fit: cover;
}
}

@media screen and (max-width: 1024px) {
    .Aliados .mh-mov{
        max-height:unset;
        min-height: 400px;
        padding-top: 100px;
    }
    .Aliados .bg-color{
        background-color: #FFF;
    }

    .Aliados .custom-mobile-device {
        display: none;
    }
    
    .Aliados .custom-web-device {
        display: block;
    }

    .Aliados .h-prog{
        color: #000;
        font-family: Ruda;
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
    }

    .Aliados  .par{
        font-size: 1.75rem;
    }
}

@media screen and (max-width:768px) and (orientation:portrait){
    .custom-mobile-device {
        display: block;
    }
    
    .custom-web-device {
        display: none;
    }

    .Aliados .h-prog{
        color: #000;
        font-family: Ruda;
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        line-height: 110%;
        text-align: center;
    }
    .Aliados .title{
        font-size: 25px;
        text-align: center;
    }
    .Aliados .par{
        color: #00B9F1;
        font-family: "Rubik One";
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        text-align: center;
    }
    .Aliados .sub-title{
        font-size: 30px;
        padding-bottom: 0.5rem;
    }
    .Aliados .lbl-aliados{
        font-size: 1.5rem;
    }
    .Aliados .par-aliados{
        font-size: 1.5rem;
        line-height: 2rem
    }
    .Aliados .mh-mov{
        min-height:150px ;
        max-height: 200px;
        max-width: 70%;
        padding-top: 50px;
        padding-bottom: 30px;
    }
    .bg-color{
        background-color: #FFF;
        padding: 0 5% 5%;
    }
    .logos-aliados{
        max-width: 50%;
    }
    .bold.title-mov{
        color: #00B9F1;
        font-family: "Rubik One";
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: center;
        font-size: 30px;
    }
    .Aliados .col-lg-6{
        width: 100%;
    }
    .Aliados .col-lg-5{
        width: 100%;
    }
    .Aliados .col-lg-7{
        width: 100%;
    }
    .fit-image{
        height: unset;
        width:unset;
        overflow-y: hunset;
        object-fit: unset;
    }
}   

@media screen and (max-width:600px){
    .custom-mobile-device {
        display: block;
    }
    
    .custom-web-device {
        display: none;
    }

    .Aliados .h-prog{
        color: #000;
        font-family: Ruda;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 110%;
        text-align: center;
    }
    .Aliados .title{
        font-size: 20px;
        text-align: center;
    }
    .Aliados .par{
        color: #00B9F1;
        font-family: "Rubik One";
        font-size: 20px;
        text-align: center;
        line-height: 120%;
    }
    .Aliados .sub-title{
        font-size: 24px;
    }
    .Aliados .lbl-aliados{
        font-size: 1.125rem;
    }
    .Aliados .par-aliados{
        font-size: 16px;
        line-height:1.75rem
    }
    .Aliados .mh-mov{
        min-height:80px ;
        max-height: 200px;
        max-width: 70%;
        padding-top: 50px;
        padding-bottom: 20px;
    }
    .bg-color{
        background-color: #FFF;
        padding: 0 5% 5%;

    }
    .logos-aliados{
        max-width: 50%;
    }
    .bold.title-mov{
        color: #00B9F1;
        font-family: "Rubik One";
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: center;
        font-size: 30px;
    }
    .fit-image{
            height: unset;
            width:unset;
            overflow-y: hunset;
            object-fit: unset;
    }
    .Aliados .HeaderSection .hero-content{
        padding: 0 1rem;
    }
}