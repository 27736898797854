.cdn-bg-blue{
    background-color: #45BCD8;
}
.cdn-quote{
    color: #E9EDF0;
    text-align: center;
    font-family: Lato;
    font-size: 46px;
    font-style: normal;
    font-weight: 800;
    line-height: 125%; /* 57.5px */
}
.cdn-bg-gr{
    background-image: url('../../../assets/images/desastres/cdn-bg.png');
    background-repeat: no-repeat;
    background-position: left bottom;
    padding: 5rem 0;
}
.cdn-desc{
    color: #000;
    font-family: 'Lato Bold';
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding-bottom: 2.5rem;
    text-align: center;
}
.cdn-desc .bold{
    font-family:'Ruda Bold';
    font-weight: 800;
    font-size: inherit;
}
.cdn-title{
    color: #3DB5CF;
    text-align: center;
    font-family: "Rubik One";
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 72px */
    text-transform: uppercase;
    padding: 3rem 3rem 0;
}
.cdn-subtitle{
    color: #53616A;
    text-align: center;
    font-family: "Rubik One";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 127%; /* 63.5px */
    text-transform: uppercase;
}
.cdn-par{
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 134%; /* 40.2px */
}
.cdn-par .bold{
    font-family:'Lato Bold';
    font-weight: 800;
}
.cr-title{
    color: #E9EDF0;
    font-family: 'Ruda Bold';
    font-size: 42px;
    font-style: normal;
    font-weight: 800;
    line-height: 114%; /* 47.88px */
    text-align: start;

}
.cr-title .hyper{
    color: #E9EDF0;
    font-family: "Rubik One";
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 114%;
}
.cr-text-box{
    padding: 1rem 5rem;
}
.custom-cdn-br{
    display: none;
}

@media screen and (max-width:1440px){ 
    .cr-text-box{
        padding: 1rem 3rem;
    }
    .cdn-title{
        padding: 0;
    }
    .cdn-subtitle{
        font-size: 40px;
        margin-top: 1rem;
    }
    .cr-title{
        font-size: 38px;
    }
    .cr-title .hyper{
        font-size: inherit;
    }
}
@media screen and (max-width:1366px){
    .cdn-quote{
        color: #E9EDF0;
        text-align: center;
        font-family: Lato;
        font-size: 2.125rem;
        font-style: normal;
        font-weight: 700;
        line-height: 125%; /* 3.90625rem */
    }
    .cdn-par{
        font-size: 1.5rem;
        line-height: 150%;
    }
    .cr-title .hyper{
        font-size: 2.875rem;
    }

    .cdn-desc{
        color: #000;
        font-family: 'Lato Bold';
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        padding-bottom: 2.5rem;
    }

    .cdn-title{
        text-align: center;
        font-size: 40px;
        padding: 3rem 0 0;
    }

    .cr-title{
        color: #E9EDF0;
        font-family: "Ruda Bold";
        font-size: 1.95rem;
        font-style: normal;
        font-weight: 800;
        line-height: 2.625rem;
        text-align: start;
    }
    .cr-title .hyper{
        color: #E9EDF0;
        font-family: "Rubik One";
        font-size: 1.95rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.625rem; /* 93.548% */
    }
}
@media screen and (max-width:1024px){ 
.cdn-desc{
    font-size: 1.625rem;
}
.cdn-title{
    color: #3DB5CF;
    text-align: center;
    font-family: "Rubik One";
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.125rem; /* 136.667% */
    text-transform: uppercase;
    padding: 3rem;
}
.cdn-par{
    color: #1F282C;
    font-family: Lato;
    font-size: 1.1875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.0625rem;
}
.cdn-quote{
    font-size:2.75rem ;
}
.cr-title{
    color: #E9EDF0;
    font-family: "Ruda Bold";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 800;
    line-height: 140%;
    text-align: start;
}
.cr-title .hyper{
    color: #E9EDF0;
    font-family: "Rubik One";
    font-size: 1.175rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 93.548% */
}
.cr-text-box{
    padding: 1rem 4rem;
}
}

@media screen and (min-width:768px) and (orientation:portrait) { 
    .cdn-desc{
        font-size: 1.6rem;
        line-height: 140%;
        padding-bottom: 0rem;
    }
    .cdn-title{
        font-size: 1.75rem;
        line-height: 130%;
        color: #00B9F1;
        padding: 2rem 0 0;
    }
    .cdn-subtitle{
        font-size: 1.75rem;
        padding: 0 0 2rem ;
    }
    .cdn-par{
        font-size: 1.35rem;
        padding: 0 10%;
        line-height: 120%;
    }
    .cdn-quote{
        font-size:1.75rem ;
    }
    .cr-title{
        font-size: 1.75rem;
        text-align: center;
    }
    .cr-title .hyper{
        font-size: inherit;
    }
    .cr-text-box{
        padding: 1rem 4rem;
    }
    .dn .col-lg-6{
        width: 100%;
    }
    .cdn-bg-blue img{
        width: 100%;
    }
    .cr-text-box{
        padding: 4.5rem;
    }
    .custom-cdn-br{
        display: block;
    }
}

@media screen and (max-width:600px){ 
    .cdn-desc{
        color: #000;
        font-family: 'Lato Bold';
        font-size: 19px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        padding-bottom: 0rem;
    }
    .cdn-desc .bold{
        font-weight: 400;
    }
    .cdn-title{
        color: #3DB5CF;
        text-align: center;
        font-family: "Rubik One";
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 72px */
        text-transform: uppercase;
        padding: 1.5rem 0;
    }
    .cdn-subtitle{
        font-size: 1.25rem;
    }
    .cdn-par{
        color: #000;
        text-align: center;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 134%; /* 40.2px */
    }
    .cdn-quote{
        font-size: 19px ;
        
    }
    .cr-title{
        text-align: center;
        font-size: 1.15rem;
        line-height: 150%;
    }
    .cr-title .hyper{
        font-size: 1.25rem;
        line-height: 150%;
    }
    .cr-text-box{
        padding: 2rem 1rem;
    }
}