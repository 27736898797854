.ObjetivosOnu {
  width: 100%;
  background: rgba(114, 205, 188, 0.90);
}

.ObjetivosOnu .grid {
  display: grid;
}

.ObjetivosOnu .grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.ObjetivosOnu .grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.ObjetivosOnu .gap-4 {
  gap: 1rem; /* 16px */
}

.ObjetivosOnu .mobile-device {
  display: block;
}

.ObjetivosOnu .web-device {
  display: none;
}

.ObjetivosOnu .title {
  color: #FFF;
  text-align: center;
  font-family: 'Lato Bold';
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: 130%; /* 28.6px */
  margin: 0 10%;
}

.ObjetivosOnu .paragraph {
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 28.6px */
  margin: 0 10%;
}


.ObjetivosOnu .img-obj {
  max-width: 150px;
}

.ObjetivosOnu .w-100 {
  max-width: 80%;
}

  @media (min-width: 768px) {
    .ObjetivosOnu .title {
      color: #FFF;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 46px;
      font-style: normal;
      font-weight: 800;
      line-height: 130%; /* 28.6px */
      margin: unset;
    }
  
    .ObjetivosOnu .paragraph {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 28.6px */
      margin: unset;
    }
    .ObjetivosOnu .w-100{
      max-width: 80%;
    }
  }

  @media (min-width: 1024px) {
    .ObjetivosOnu .mobile-device {
      display: none;
    }
  
    .ObjetivosOnu .web-device {
      display: block;
    }

    .ObjetivosOnu .title {
      color: #FFF;
      text-align: start;
      font-family: 'Lato Bold';
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 130%; /* 28.6px */
    }
  
    .ObjetivosOnu .paragraph {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 28.6px */
    }

    .ObjetivosOnu .card-width {
      width: 100px;
      height: auto;
    }
  }

  @media (min-width: 1366px) {
    .ObjetivosOnu .mobile-device {
      display: none;
    }
  
    .ObjetivosOnu .web-device {
      display: block;
    }

    .ObjetivosOnu .title {
      color: #FFF;
      text-align: start;
      font-family: 'Lato Bold';
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 130%; /* 28.6px */
    }
  
    .ObjetivosOnu .paragraph {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 28.6px */
    }

    .ObjetivosOnu .card-width {
      width: 100px;
      height: auto;
    }

    .ObjetivosOnu .w-100{
      max-width: 94%;
    }
  }

  @media (min-width: 1440px) {
    .ObjetivosOnu .title {
      color: #FFF;
      text-align: start;
      font-family: 'Lato Bold';
      font-size: 34px;
      font-style: normal;
      font-weight: 800;
      line-height: 130%; /* 28.6px */
    }
  
    .ObjetivosOnu .paragraph {
      color: #FFF;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    .ObjetivosOnu .card-width {
      width: 122px;
      height: auto;
    }
  }

  @media (min-width: 1920px) {
    .ObjetivosOnu .title {
      color: #FFF;
      text-align: start;
      font-family: 'Lato Bold';
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 130%; /* 28.6px */
    }
  
    .ObjetivosOnu .paragraph {
      color: #FFF;
      font-family: Lato;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    .ObjetivosOnu .card-width {
      width: 122px;
      height: auto;
    }
  }
