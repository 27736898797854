.HeaderProgramasPosible {
  width: 100%;

  .play-icon {
    position: relative;
    padding-bottom: -50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}

.HeaderProgramasPosible .mobile-device {
  display: block;
}

.HeaderProgramasPosible .web-device {
  display: none;
}

.HeaderProgramasPosible .bg-header-img {
  background-image: url('../../../../../assets/images/programas/subsections/posible/header-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 290px;
}

.HeaderProgramasPosible .bg-rectangle {
  /* background-image: url('../../../../../assets/images/programas/subsections/posible/rectangle-header-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto; */
  background: linear-gradient(0deg, rgba(238, 228, 204, 0.00) 0%, rgba(232, 186, 74, 0.32) 100%);
}

.HeaderProgramasPosible .text-container {
  width: 90%;
}

.HeaderProgramasPosible .title {
  color: #E8BA4A;
  text-align: center;
  font-family: "Rubik One";
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.HeaderProgramasPosible .subtitle {
  color: #1F282C;
  text-align: center;
  font-family: Ruda;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.HeaderProgramasPosible .paragraph-header {
  color: #1F282C;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 138%;
  text-align: center;
}

.HeaderProgramasPosible .paragraph-header b {
  color: #1F282C;
  font-family: 'Lato Bold';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 138%; /* 38.64px */
}
.HeaderProgramasPosible .bg-gray-posible {
  background: #69747C;
}

.HeaderProgramasPosible .paragraph-header-two {
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 23.75px */
}

.HeaderProgramasPosible .paragraph-header-two b {
  color: #FFF;
  font-family: 'Lato Bold';
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

.HeaderProgramasPosible .bg-gold-posible {
  background: #E8BA4A;
}

.HeaderProgramasPosible .paragraph-header-three {
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 23.75px */
}

.HeaderProgramasPosible .main-title-programas {
  color: #E8BA4A;
}

.HeaderProgramasPosible .paragraph-header {
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 43.75px */

  b {
    color: #FFF;
    font-family: 'Lato Bold';
    font-size: 19px;
    font-style: normal;
    font-weight: 800;
    line-height: 125%;
  }
}

  @media (min-width: 768px) {
    .HeaderProgramasPosible .bg-header-img {
      background-image: url('../../../../../assets/images/programas/subsections/posible/header.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 512px;
    }
    
    /* .HeaderProgramasPosible .bg-rectangle {
      background-image: url('../../../../../assets/images/programas/subsections/posible/rectangle-header-mobile.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: auto;
    } */
    
    .HeaderProgramasPosible .text-container {
      width: 90%;
    }
    
    .HeaderProgramasPosible .title {
      color: #E8BA4A;
      text-align: center;
      font-family: "Rubik One";
      font-size: 68px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
    
    .HeaderProgramasPosible .subtitle {
      color: #1F282C;
      text-align: center;
      font-family: Ruda;
      font-size: 36px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
    
    .HeaderProgramasPosible .paragraph-header {
      color: #1F282C;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 142%;
      text-align: center;
    }
    
    .HeaderProgramasPosible .paragraph-header b {
      color: #1F282C;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 142%; /* 34.08px */
    }
    
    .HeaderProgramasPosible .bg-gray-posible {
      background: #69747C;
    }
    
    .HeaderProgramasPosible .paragraph-header-two {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 52.5px */
    }
    
    .HeaderProgramasPosible .paragraph-header-two b {
      color: #FFF;
      font-family: 'Lato Bold';
      font-size: 42px;
      font-style: normal;
      font-weight: 800;
      line-height: 125%;
    }
    
    .HeaderProgramasPosible .bg-gold-posible {
      background: #E8BA4A;
    }
    
    .HeaderProgramasPosible .paragraph-header-three {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 42px;
      font-style: normal;
      font-weight: 500;
      line-height: 125%; /* 52.5px */
    }

    .HeaderProgramasPosible .paragraph-header {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 43.75px */

      b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 26px;
        font-style: normal;
        font-weight: 800;
        line-height: 125%;
      }
    }
    
  }

  @media (min-width: 1024px) {
    .HeaderProgramasPosible .mobile-device {
      display: none;
    }
  
    .HeaderProgramasPosible .web-device {
      display: block;
    }

    .HeaderProgramasPosible .text-container {
      width: 90%;
    }
    
    .HeaderProgramasPosible .title {
      color: #E8BA4A;
      text-align: center;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
    
    .HeaderProgramasPosible .subtitle {
      color: #1F282C;
      text-align: center;
      font-family: Ruda;
      font-size: 25px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
    
    .HeaderProgramasPosible .paragraph-header {
      color: #1F282C;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 142%;
      text-align: center;
    }
    
    .HeaderProgramasPosible .paragraph-header b {
      color: #1F282C;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 142%; /* 34.08px */
    }
    
    .HeaderProgramasPosible .bg-gray-posible {
      background: #69747C;
    }
    
    .HeaderProgramasPosible .paragraph-header-two {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 52.5px */
    }
    
    .HeaderProgramasPosible .paragraph-header-two b {
      color: #FFF;
      font-family: 'Lato Bold';
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 125%;
    }
    
    .HeaderProgramasPosible .bg-gold-posible {
      background: #E8BA4A;
    }
    
    .HeaderProgramasPosible .paragraph-header-three {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 125%; /* 52.5px */
    }

    .HeaderProgramasPosible .paragraph-header {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 43.75px */

      b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 125%;
      }
    }

  }

  @media (min-width: 1280px) {
    .HeaderProgramasPosible .bg-header-img{
      min-height: 428px;
      height: unset;
    }
    .HeaderProgramasPosible .subtitle{
      font-size: 25px;
      padding: 0 1rem;
      text-align: left;
    }
    .HeaderProgramasPosible .paragraph-header{
      font-size: 18px;
      padding: 0 1rem;
      text-align: left;
    }
    .HeaderProgramasPosible .paragraph-header b{
      font-size: 18px;
      text-align: left;
    }

    .HeaderProgramasPosible .title {
      color: #E8BA4A;
      text-align: left;
      font-family: "Rubik One";
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }

    .HeaderProgramasPosible .paragraph-header-two {
      color: #FFF;
      text-align: left;
      font-family: Lato;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 52.5px */
    }
    
    .HeaderProgramasPosible .paragraph-header-two b {
      color: #FFF;
      font-family: 'Lato Bold';
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 125%;
    }

    .HeaderProgramasPosible .paragraph-header {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 43.75px */
    }
      .HeaderProgramasPosible .paragraph-header b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 26px;
        font-style: normal;
        font-weight: 800;
        line-height: 125%;
      }
    
  }

  @media (min-width: 1366px) {
    .HeaderProgramasPosible .bg-header-img{
      min-height: 428px;
      height: unset;
    }
    .HeaderProgramasPosible .subtitle{
      font-size: 25px;
      padding: 0 1rem;
      text-align: left;
    }
    .HeaderProgramasPosible .paragraph-header{
      font-size: 18px;
      padding: 0 1rem;
      text-align: left;
    }
    .HeaderProgramasPosible .paragraph-header b{
      font-size: 18px;
      text-align: left;
    }

    .HeaderProgramasPosible .title {
      color: #E8BA4A;
      text-align: left;
      font-family: "Rubik One";
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }

    .HeaderProgramasPosible .paragraph-header-two {
      color: #FFF;
      text-align: left;
      font-family: Lato;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 52.5px */
    }
    
    .HeaderProgramasPosible .paragraph-header-two b {
      color: #FFF;
      font-family: 'Lato Bold';
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 125%;
    }

    .paragraph-header {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 43.75px */

      b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 35px;
        font-style: normal;
        font-weight: 800;
        line-height: 125%;
      }
    }

    .HeaderProgramasPosible .paragraph-header {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 43.75px */

      b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 125%;
      }
    }
  }
  @media (min-width: 1440px) {
    .HeaderProgramasPosible .bg-header-img {
        background-image: url('../../../../../assets/images/programas/subsections/posible/header.png');
        width: 100%;
        height: 480px;
    }
  
    /* .HeaderProgramasPosible .bg-rectangle {
        background-image: url('../../../../../assets/images/programas/subsections/posible/rectangle-header.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: auto;
        height: auto;
    } */
  
    .HeaderProgramasPosible .text-container {
        width: 90%;
    }
  
    .HeaderProgramasPosible .title {
        color: #E8BA4A;
        font-family: "Rubik One";
        font-size: 55px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: left;
        padding: 0 1rem 0;
    }
  
    .HeaderProgramasPosible .subtitle {
        color: #1F282C;
        font-family: Ruda;
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-align: left;
        padding: 0 1rem 0;
    }
  
    .HeaderProgramasPosible .paragraph-header {
        color: #FFF;
        font-family: Lato;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
        text-align: left;
    }

    .HeaderProgramasPosible .paragraph-header b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 125%;
    }
  
    .HeaderProgramasPosible .bg-gray-posible {
        background: #69747C;
    }
  
    .HeaderProgramasPosible .paragraph-header-two {
        color: #FFF;
        font-family: Lato;
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
        text-align: left;
    }

    .HeaderProgramasPosible .paragraph-header-two b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 35px;
        font-style: normal;
        font-weight: 800;
        line-height: 125%;
    }
  
    .HeaderProgramasPosible .bg-gold-posible {
        background: #E8BA4A;
    }
  
    .HeaderProgramasPosible .paragraph-header-three {
        color: #FFF;
        font-family: Lato;
        font-size: 35px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%;
    }

    .HeaderProgramasPosible .paragraph-header {
        color: #FFF;
        text-align: center;
        font-family: Lato;
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
    }

    .HeaderProgramasPosible .paragraph-header b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 35px;
        font-style: normal;
        font-weight: 800;
        line-height: 125%;
    }
}

@media (min-width: 1920px) {
    .HeaderProgramasPosible .bg-header-img {
        background-image: url('../../../../../assets/images/programas/subsections/posible/header.png');
        width: 100%;
        height: 480px;
    }
  
    /* .HeaderProgramasPosible .bg-rectangle {
        background-image: url('../../../../../assets/images/programas/subsections/posible/rectangle-header.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: auto;
        height: auto;
    } */
  
    .HeaderProgramasPosible .text-container {
        width: 90%;
    }
  
    .HeaderProgramasPosible .title {
        color: #E8BA4A;
        font-family: "Rubik One";
        font-size: 55px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: left;
        padding: 0 1rem 0;
    }
  
    .HeaderProgramasPosible .subtitle {
        color: #1F282C;
        font-family: Ruda;
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-align: left;
        padding: 0 1rem 0;
    }
  
    .HeaderProgramasPosible .paragraph-header {
        color: #FFF;
        font-family: Lato;
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
        text-align: left;
    }

    .HeaderProgramasPosible .paragraph-header b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 35px;
        font-style: normal;
        font-weight: 800;
        line-height: 125%;
    }
  
    .HeaderProgramasPosible .bg-gray-posible {
        background: #69747C;
    }
  
    .HeaderProgramasPosible .paragraph-header-two {
        color: #FFF;
        font-family: Lato;
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
        text-align: left;
    }

    .HeaderProgramasPosible .paragraph-header-two b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 35px;
        font-style: normal;
        font-weight: 800;
        line-height: 125%;
    }
  
    .HeaderProgramasPosible .bg-gold-posible {
        background: #E8BA4A;
    }
  
    .HeaderProgramasPosible .paragraph-header-three {
        color: #FFF;
        font-family: Lato;
        font-size: 35px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%;
    }

    .HeaderProgramasPosible .paragraph-header {
        color: #FFF;
        text-align: center;
        font-family: Lato;
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
    }

    .HeaderProgramasPosible .paragraph-header b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 35px;
        font-style: normal;
        font-weight: 800;
        line-height: 125%;
    }
}
