.cuantrix .ini-bg {
    min-height: 85vh;
    background-image: url('../../../../assets/images/programas/cuantrix/6\ 8.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
}

.cuantrix .text-box-numbs-1.ini {
    transform: translate(-1px, 400px)
}

.cuantrix .text-box-numbs-2.ini {
    transform: translate(-33px, 429px)
}

.cuantrix .bg-number {
    background-image: url('../../../../assets/images/programas/cuantrix/Rectanglegpo.png');
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    min-height: 150px;
    padding: 20px;
    margin-top: 3rem;
}

.cuantrix .numbers {
    color: #fff;
    font-family: 'Ruda Bold';
    font-size: 28px;
    font-weight: 800;
    line-height: 46px;
    text-align: start;
}

.cuantrix .numbers .highlight {
    color: #fff;
    font-family: "Rubik One";
    font-size: 38px;
    font-weight: 400;
    line-height: 46px;
}

.cuantrix .ctx-in-title {
    color: #F2F7FF;
    text-align: center;
    font-family: "Rubik One";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 60px */
    text-transform: uppercase;
}

.cuantrix .ctx-in-par {
    color: #1F282C;
    text-align: center;
    font-family: Ruda;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px; /* 157.143% */
}
@media (max-width:1600px) {
    .cuantrix .ini-bg {
        min-height: 106vh;
        background-image: url('../../../../assets/images/programas/cuantrix/6\ 8.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
    }
    .cuantrix .text-box-numbs-1.ini {
        transform: translate(-1px, 300px)
    }
    
    .cuantrix .text-box-numbs-2.ini {
        transform: translate(-33px, 329px)
    }
}

@media (max-width: 1440px) {
    .cuantrix .ini-bg {
        min-height: 104vh;
        background-image: url('../../../../assets/images/programas/cuantrix/6\ 8.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
    }

    .cuantrix .text-box-numbs-1.ini {
        transform: translate(-1px, 340px)
    }
    
    .cuantrix .text-box-numbs-2.ini {
        transform: translate(-33px, 415px)
    }

    .cuantrix .numbers {
        color: #fff;
        font-family: 'Ruda Bold';
        font-size: 28px;
        font-weight: 800;
        line-height: 120%;
        text-align: start;
    }

    .cuantrix .ctx-in-title {
        color: #F2F7FF;
        text-align: center;
        font-family: "Rubik One";
        font-size: 42px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 60px */
        text-transform: uppercase;
    }

    .cuantrix .numbers .highlight {
        color: #fff;
        font-family: "Rubik One";
        font-size: 30px;
        font-weight: 400;
        line-height: 46px;
    }
}

@media (max-width: 1366px) {
    .cuantrix .ini-bg {
        min-height: 104vh;
        background-image: url('../../../../assets/images/programas/cuantrix/6\ 8.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
    }

    .cuantrix .text-box-numbs-1.ini {
        transform: translate(-1px, 140%)
    }
    
    .cuantrix .text-box-numbs-2.ini {
        transform: translate(-33px, 180%)
    }

    .cuantrix .numbers {
        color: #fff;
        font-family: 'Ruda Bold';
        font-size: 28px;
        font-weight: 800;
        line-height: 120%;
        text-align: start;
    }

    .cuantrix .ctx-in-title {
        color: #F2F7FF;
        text-align: center;
        font-family: "Rubik One";
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 60px */
        text-transform: uppercase;
    }

    .cuantrix .numbers .highlight {
        color: #fff;
        font-family: "Rubik One";
        font-size: 30px;
        font-weight: 400;
        line-height: 46px;
    }
}

@media screen and (max-width:1024px){
    .cuantrix .ini-bg {
        min-height: 85vh;
        background-image: url('../../../../assets/images/programas/cuantrix/6\ 8.png');
        background-size: cover;
        background-repeat: no-repeat;
        /* background-position: bottom; */
        width: 100%;
        height: 197px;
    }
    
    .cuantrix .bg-number {
        min-height: 100px;
        padding: 10px;
        margin-top: 3rem;
    }
    
    .cuantrix .ctx-in-title {
        font-size: 35px;
    }
    
    .cuantrix .numbers {
        font-size: 22px;
        line-height: 42px;
        text-align: start;
    }
    
    .cuantrix .numbers .highlight {
        font-size: 28px;
        line-height: 42px;
    }
    
    .cuantrix .text-box-numbs-1.ini {
        transform: translate(-16px, 115px)
    }
    
    .cuantrix .text-box-numbs-2.ini {
        transform: translate(20px, 115px)
    }
    
    .cuantrix .text-box-numbs-1 .numbers,
    .cuantrix .text-box-numbs-1 .numbers .highlight,
    .cuantrix .text-box-numbs-2 .numbers,
    .cuantrix .text-box-numbs-2 .numbers .highlight {
        line-height: normal;
        font-size: 22px;
        line-height: 32px;
    }
    
}

@media screen and (max-width:900px){
    .cuantrix .ini-bg {
        /* min-height: 51vh; */
        background-image: url('../../../../assets/images/programas/cuantrix/Group538\(1\)d.png');
        background-size: cover;
        background-repeat: no-repeat;
        /* background-position: bottom; */
        width: 100%;
        height: 93vh;
    }
    
    .cuantrix .bg-number {
        min-height: 100px;
        padding: 10px;
        margin-top: 3rem;
    }
    
    .cuantrix .ctx-in-title {
        font-size: 48px;
    }
    
    .cuantrix .numbers {
        font-size: 22px;
        line-height: 42px;
        text-align: start;
    }
    
    .cuantrix .numbers .highlight {
        font-size: 28px;
        line-height: 42px;
    }
    
    .cuantrix .text-box-numbs-1.ini {
        transform: translate(10px, 130px);
    }
    
    .cuantrix .text-box-numbs-2.ini {
        transform: translate(20px, 255px);
    }
    
    .cuantrix .text-box-numbs-1 .numbers,
    .cuantrix .text-box-numbs-2 .numbers
    {
        line-height: normal;
        font-size: 24px;
        line-height: 30px;
    }

    .cuantrix .text-box-numbs-1 .numbers .highlight,
    .cuantrix .text-box-numbs-2 .numbers .highlight {
        line-height: normal;
        font-size: 30px;
        line-height: 40px;
    }
    
}

@media screen and (max-width:600px){
    .cuantrix .ini-bg {
        /* min-height: 51vh; */
        background-image: url('../../../../assets/images/programas/cuantrix/chicos-mobile.png');
        background-size: cover;
        background-repeat: no-repeat;
        /* background-position: bottom; */
        width: 100vw;
        height: 397px;
    }
    
    .cuantrix .bg-number {
        min-height: 100px;
        padding: 10px;
        margin-top: 3rem;
    }
    
    .cuantrix .ctx-in-title {
        font-size: 35px;
    }
    
    .cuantrix .numbers {
        font-size: 22px;
        line-height: 42px;
        text-align: start;
    }
    
    .cuantrix .numbers .highlight {
        font-size: 28px;
        line-height: 42px;
    }
    
    .cuantrix .text-box-numbs-1.ini {
        transform: translate(-16px, 40px);
    }
    
    .cuantrix .text-box-numbs-2.ini {
        transform: translate(20px, 135px);
    }
    
    .cuantrix .text-box-numbs-1 .numbers,
    .cuantrix .text-box-numbs-1 .numbers .highlight,
    .cuantrix .text-box-numbs-2 .numbers,
    .cuantrix .text-box-numbs-2 .numbers .highlight {
        line-height: normal;
        font-size: 22px;
        line-height: 32px;
    }
    
}

@media screen and (max-width:412px){
    .cuantrix .ini-bg {
        /* min-height: 51vh; */
        background-image: url('../../../../assets/images/programas/cuantrix/chicos-mobile.png');
        background-size: cover;
        background-repeat: no-repeat;
        /* background-position: bottom; */
        width: 100vw;
        height: 397px;
    }
    
    .cuantrix .bg-number {
        min-height: 100px;
        padding: 10px;
        margin-top: 3rem;
    }
    
    .cuantrix .ctx-in-title {
        font-size: 22px;
    }
    
    .cuantrix .numbers {
        font-size: 22px;
        line-height: 42px;
        text-align: start;
    }
    
    .cuantrix .numbers .highlight {
        font-size: 28px;
        line-height: 42px;
    }
    
    .cuantrix .text-box-numbs-1.ini {
        transform: translate(0px, 99px);
    }
    
    .cuantrix .text-box-numbs-2.ini {
        transform: translate(0px, 215px);
    }
    
    .cuantrix .text-box-numbs-1 .numbers,
    .cuantrix .text-box-numbs-1 .numbers .highlight,
    .cuantrix .text-box-numbs-2 .numbers,
    .cuantrix .text-box-numbs-2 .numbers .highlight {
        line-height: normal;
        font-size: 22px;
        line-height: 32px;
    }
    
}

@media screen and (max-width:375px){
    .cuantrix .ini-bg {
        /* min-height: 51vh; */
        background-image: url('../../../../assets/images/programas/cuantrix/chicos-mobile.png');
        background-size: cover;
        background-repeat: no-repeat;
        /* background-position: bottom; */
        width: 100vw;
        height: 397px;
    }
    
    .cuantrix .bg-number {
        min-height: 100px;
        padding: 10px;
        margin-top: 3rem;
    }
    
    .cuantrix .ctx-in-title {
        font-size: 30px;
    }
    
    .cuantrix .numbers {
        font-size: 22px;
        line-height: 42px;
        text-align: start;
    }
    
    .cuantrix .numbers .highlight {
        font-size: 28px;
        line-height: 42px;
    }
    
    .cuantrix .text-box-numbs-1.ini {
        transform: translate(-16px, 4px);
    }
    
    .cuantrix .text-box-numbs-2.ini {
        transform: translate(20px, 115px);
    }
    
    .cuantrix .text-box-numbs-1 .numbers,
    .cuantrix .text-box-numbs-1 .numbers .highlight,
    .cuantrix .text-box-numbs-2 .numbers,
    .cuantrix .text-box-numbs-2 .numbers .highlight {
        line-height: normal;
        font-size: 22px;
        line-height: 32px;
    }
    
}