.HeaderIgualdad {
  width: auto;
  height: 100vh;
  background: url('../../../assets/images/igualdad/hero-mobile.png'), lightgray 50%;
  background-size: cover;
}
  .HeaderIgualdad .hero-gradient {
    background: linear-gradient(360deg, rgba(126, 119, 181, 0.90) 0%, rgba(126, 119, 181, 0.00) 100%);
    width: 100vw;
    height: 362px;
    flex-shrink: 0;
    position: absolute;
    bottom: 0;
  }

  .HeaderIgualdad .hero-title {
    width: 100%;
    position: absolute;
    top: 64vh;
    color: #FFF;
    text-align: center;
    font-family: "Rubik One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    color: #FFF;
    text-shadow: 0px 4px 4px rgba(80, 80, 80, 0.82);
  }
  .HeaderIgualdad{
  /* Tablet  */
  @media (min-width: 768px) {
    width: auto;
    height: 100vh;
    background: url('../../../assets/images/igualdad/hero-tablet.png'), lightgray 50%;
    background-size: cover;

    .hero-gradient {
      background: linear-gradient(360deg, rgba(126, 119, 181, 0.90) 0%, rgba(126, 119, 181, 0.00) 100%);
      width: 100vw;
      height: 362px;
      flex-shrink: 0;
      position: absolute;
      bottom: 0;
    }
  
    .hero-title {
      width: 100%;
      position: absolute;
      top: 70%;
      color: #FFF;
      text-align: center;
      font-family: "Rubik One";
      font-size: 72px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  /* web  */
  @media (min-width: 1440px) {
    width: auto;
    height: 100vh;
    background: url('../../../assets/images/igualdad/hero.png'), lightgray 50%;
    background-size: cover;

    .hero-gradient {
      background: linear-gradient(360deg, rgba(126, 119, 181, 0.90) 0%, rgba(126, 119, 181, 0.00) 100%);
      width: 100vw;
      height: 362px;
      flex-shrink: 0;
      position: absolute;
      bottom: 0;
    }
  
    .hero-title {
      width: 100%;
      position: absolute;
      top: 70%;
      color: #FFF;
      text-shadow: 0px 4px 4px rgba(80, 80, 80, 0.82);
      font-family: "Rubik One";
      font-size: 80px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  }

}