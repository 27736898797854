.esf-title{
    color: #FFF;
    font-family: "Rubik One";
    font-size: 3.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 4.0625rem; /* 104.839% */
    text-transform: uppercase;
    text-align: start;
}
.esf-par{
    color: #FFF;
    font-family:'Ruda' ;
    font-size: 2.14rem;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 2.975rem */
    text-align: start;

    b {
        color: #FFF;
        font-family: "Rubik One";
        font-size: 3rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 2.975rem */
    }
}
.esf-text-box{
    padding: 0 4rem;
}
.esf-text-box .esf-par{
    max-width: 85%;
    margin: auto;
    color: #FFF;
    font-family:'Ruda' ;
    font-size: 2.14rem;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 2.975rem */
    text-align: start;

    b {
        color: #FFF;
        font-family: "Rubik One";
        font-size: 3rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 2.975rem */
    }
}
.bg-extr{
    background-color:#E9EDF0 ;
    background-image: url('../../../assets/images/desastres/Gradiente_1extr.png');
    background-position: bottom left;
    background-repeat: no-repeat;
}
.extr-tit-2{
    color: #3DB5CF;
    font-family: "Rubik One";
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 5.125rem; /* 136.667% */
    text-transform: uppercase;
    margin: 3rem 0;
}
.extr-par-2{
    color: #1F282C;
    text-align: center;
    font-family: Lato;
    font-size: 1.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 134%; 
    margin: 3rem 0;
    /* 2.59625rem */
}

.extr-descr{
    color: #1F282C;
    font-family: Lato;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 152%;
    margin: 2rem 0;
    text-align: start;
}
.bold{
    font-family: 'Lato Bold';
    font-weight: 900;
}
.bg-cint-az{
    background-color: #45BCD8;
}
.tys-link{
    color: #FFF;
    text-align: center;
    font-family: "Rubik One";
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 3.0625rem */
}
.bg-default{
    background-color: #E9EDF0;
}
@media screen and (max-width:1440px){
    .esf-title{
        font-size: 3.5rem;
    }
    .esf-text-box{
        padding: 0 2rem;
    }
    .esf-text-box .ms-lg-5{
        margin-left:2rem !important ;
    }
    .esf-text-box .esf-par{
        max-width: 90%;
        font-size: 2rem;

        b {
            font-size: 2.75rem;
        }
    }

    .extr-tit-2{
        color: #3DB5CF;
        font-family: "Rubik One";
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 3rem; /* 136.667% */
        text-transform: uppercase;
        margin: 2rem 0;
    }
    
}
@media screen and (max-width:1366px){
    .esf-title{
        font-size: 2.5rem;
    }
    .esf-par{
        font-size: 2rem;
    }
    .esf-par b{
        font-size: 2.5rem;
    }

    .esf-text-box .esf-par{
        max-width: 90%;
        font-size: 1.5rem;

        b {
            font-size: 1.75rem;
        }
    }

    .extr-par-2{
        color: #1F282C;
        text-align: center;
        font-family: Lato;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 134%; 
        margin: 2rem 0;
        /* 2.59625rem */
    }

    .extr-descr{
        color: #1F282C;
        font-family: Lato;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 152%;
        margin: 1rem 0;
        text-align: start;
    }
}
@media screen and (max-width:1024px){
    .esf-title{
        font-size: 1.875rem;
        line-height: 2rem; /* 104.839% */
        text-align: left;
        margin-top: 2rem;
    }
    .esf-par{
        color: #FFF;
        
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 2.975rem */
        text-align: start;
    }
        .esf-par b {
            color: #FFF;
            
            font-size: 1.125rem;
            font-style: normal;
            line-height: 140%; /* 2.975rem */
        }
    
    .esf-text-box{
        padding: 0 2rem;
    }
    
    .extr-tit-2{
        font-size: 2.75rem;
        line-height: 3.125rem; /* 136.667% */
        margin: 3rem 0;
    }
    .extr-par-2{
        text-align: center;
        font-size: 1.375rem;
        line-height: 134%; 
        margin: 3rem 0;
        /* 2.59625rem */
    }
    .extr-descr{
        font-size: 1.375rem;
        line-height: 152%;
        margin: 2rem 0;
        text-align: start;
    }
    
    .tys-link{
        text-align: center;
        font-size: 2.1875rem;
        line-height: 140%; /* 3.0625rem */
    }
}

@media screen and (min-width:768px) and (orientation:portrait){
    .esf-title{
        font-size: 2.875rem;
        line-height: 4rem; /* 104.839% */
        text-align: center;
        margin-top: 2rem;
    }
    .esf-par{
        font-size: 2.15rem;
        line-height: 140%; /* 2.975rem */
        text-align: center;
    }
    .esf-par b{
        font-size: 2.25rem;
        line-height: 140%; /* 2.975rem */
    }
    .esf-text-box{
        padding: 0 2rem;
    }
    .esf-text-box .esf-par{
        max-width: 80%;
        margin: 2rem auto ;
        text-align: center;
    }
    
    .extr-tit-2{
        color: #00B9F1;
        font-size: 3rem;
        line-height: 3.125rem; /* 136.667% */
        margin: 3rem 18%;
    }
    .extr-par-2{
        text-align: center;
        font-size: 1.75rem;
        line-height: 134%; 
        margin: 3rem 0;
        /* 2.59625rem */
    }
    .extr-descr{
        font-size: 1.85rem;
        line-height: 152%;
        margin: 2rem 0;
        text-align: start;
    }
    
    .tys-link{
        text-align: center;
        font-size: 2.1875rem;
        line-height: 140%; /* 3.0625rem */
    }
    .dn .col-lg-4, .dn .col-lg-8, .dn .col-lg-10{
        width: 80%;
    }
}

@media screen and (max-width:600px){
    .esf-title{
        font-size: 1.4rem;
        line-height: 2.2rem; /* 104.839% */
        text-align: center;
        margin-top: 2rem;
    }
    .esf-text-box{
        padding: 0 1rem;
    }

    .esf-text-box .esf-par{
        max-width: 85%;
        margin: auto;
        color: #FFF;
        font-family:'Ruda' ;
        font-size: 2.14rem;
        font-style: normal;
        font-weight: 800;
        text-align: center;
        font-size: 1.12rem;
    
        b {
            font-size: inherit;
            font-weight: 400;
            font-size: 1.25rem;
        }
    }

    .esf-par{
        text-align: center;
        font-size: 1.12rem;
    }
    .esf-par b{
        font-size: inherit;
        font-weight: 400;
        font-size: 1.25rem;

    }
    .esf-text-box .esf-par{
        max-width: 90%;
        margin: 1rem auto;
    }
    .extr-tit-2{
        font-size: 1.4rem;
        line-height: 136.667%;
        margin: 0rem 16%;
        color: #00B9F1;
    }
    .extr-par-2{
        font-size: 1.25rem;
        margin: 1.5rem 0;
    }
    .extr-par-2 .bold{
        font-family: 'Lato Bold';
        font-weight: 400;
    }
    .extr-descr{
        font-size: 18px;
        margin: 1rem 0;
    }
    .extr-descr .bold{
        font-weight: 400;
    }

}