.Testimonios {

  overflow-x: hidden;
}
  .Testimonios .mobile-device {
    display: block;
  }
  
  .Testimonios .web-device {
    display: none;
  }
  
  .Testimonios .title {
    color: #00B9F1;
    text-align: center;
    font-family: "Rubik One";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  
  .Testimonios .custom-width {
    width: 80%;
    height: auto;
    position: relative;
    z-index: 3;
  }

  .Testimonios .Carousel .arrows-text {
    color: #000;
    text-align: center;
    font-family: 'Lato Bold';
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%;
    text-transform: uppercase;
  
    b {
      color: #00B9F1;
      font-family: 'Lato Bold';
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%;
      text-transform: uppercase;
    }
  }

  .Testimonios .testimonios-icon {
    width: 4rem;
  }

  .Testimonios {
  @media (min-width: 768px) {
    .title {
      color: #00B9F1;
    text-align: center;
    font-family: "Rubik One";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    }
    
    .custom-width {
      width: 80%;
      height: auto;
      position: relative;
      z-index: 3;
    }
  }
  
  @media (min-width: 1024px) {
    
    .mobile-device {
      display: none;
    }
  
    .web-device {
      display: block;
    }
  
    .title {
      color: #00B9F1;
      text-align: center;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  
    .custom-width {
      width: 80%;
      height: auto;
      position: relative;
      z-index: 3;
    }
  
    .rectangle-carousel {
      position: relative;
      z-index: 1;
      margin-top: 0%;
      display: flex;
      justify-content: flex-end;
    }
  
    .bg-rectangle {
      background-image: url('../../../assets/images/home/rectangle-carousel.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: auto;
    }
  }
  
  @media (min-width: 1280px) {
    .title {
      color: #00B9F1;
      text-align: center;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  
    .custom-width {
      width: 80%;
      height: auto;
      position: relative;
      z-index: 3;
    }
  
    .rectangle-carousel {
      position: relative;
      z-index: 1;
      margin-top: 0%;
      display: flex;
      justify-content: flex-end;
    }
  
    .bg-rectangle {
      background-image: url('../../../assets/images/home/rectangle-carousel.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: auto;
    }
  }
  
  @media (min-width: 1366px) {
    .title {
      color: #00B9F1;
      text-align: center;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  
    .custom-width {
      width: 80%;
      height: auto;
      position: relative;
      z-index: 3;
    }
  
    .rectangle-carousel {
      position: relative;
      z-index: 1;
      margin-top: 0%;
      display: flex;
      justify-content: flex-end;
    }
  
    .bg-rectangle {
      background-image: url('../../../assets/images/home/rectangle-carousel.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: auto;
    }
  }
  
  @media (min-width: 1440px) {
    .title {
      color: #00B9F1;
      text-align: center;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  
    .custom-width {
      width: 80%;
      height: auto;
      position: relative;
      z-index: 3;
    }
  
    .rectangle-carousel {
      position: relative;
      z-index: 1;
      margin-top: 0%;
      display: flex;
      justify-content: flex-end;
    }
  
    .bg-rectangle {
      background-image: url('../../../assets/images/home/rectangle-carousel.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: auto;
    }
  }
  
  @media (min-width: 1920px) {
    .title {
      color: #00B9F1;
      text-align: center;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  
    .custom-width {
      width: 80%;
      height: auto;
      position: relative;
      z-index: 3;
    }
  
    .rectangle-carousel {
      position: relative;
      z-index: 1;
      margin-top: 0%;
      display: flex;
      justify-content: flex-end;
    }
  
    .bg-rectangle {
      background-image: url('../../../assets/images/home/rectangle-carousel.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: auto;
    }

    .Carousel .arrows-text {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%;
      text-transform: uppercase;
    
      b {
        color: #00B9F1;
        font-family: 'Lato Bold';
        font-size: 35px;
        font-style: normal;
        font-weight: 800;
        line-height: 100%;
        text-transform: uppercase;
      }
    }
  }

}


