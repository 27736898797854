.temprano .bg-nar {
    background-color: var(--et-color);
}

.temprano .bg-cd-cl {
    background-color: #BCBBDA40;
}

.temprano .obj-tit {
    text-align: start;
}

.temprano .obj-par {
    text-align: start;
}

.temprano .com-dig {
    color: var(--et-color);
    font-family: "Rubik One";
    font-size: 72px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
    text-align: start;
}

.temprano .com-dig-par {
    color: #000;
    font-family: 'Ruda Bold';
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: 45px;
    text-align: start;
}

.temprano  .highlight {
    color: var(--et-color);
}

.temprano .com-h {
    min-height: 400px;
}

.temprano .img-objetivos{
    width: 115px;
}

.temprano .bullet {
    width: 10px;
    height: 10px;
    background: #EDACCF;
    margin-top: 10px;
}
.temprano .comunidades li{
    color: #EDACCF;

}
.temprano .par-comunidad-digital .highlight {
    color: var(--et-color);
}

@media screen and (max-width:1600px) {
    .temprano .bg-nar {
        background-color: var(--et-color);
    }
    
    .temprano .bg-cd-cl {
        background-color: #BCBBDA40;
        color: #FFF;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    
    .temprano .obj-tit {
        text-align: start;
        color: #FFF;
        font-family: Lato;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }
    
    .temprano .obj-par {
        text-align: start;
        color: #FFF;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    
    .temprano .com-dig {
        color: var(--et-color);
        font-family: "Rubik One";
        font-size: 72px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-transform: uppercase;
        text-align: start;
    }
    
    .temprano .com-dig-par {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 45px;
        text-align: start;
    }
    

    
    .temprano .com-h {
        min-height: 400px;
    }

    .temprano .img-objetivos{
        width: 95px;
    }
}

@media screen and (max-width:1440px) {
    .temprano .bg-nar {
        background-color: var(--et-color);
    }
    
    .temprano .bg-cd-cl {
        background-color: #BCBBDA40;
        color: #FFF;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    
    .temprano .obj-tit {
        text-align: start;
        color: #FFF;
        font-family: Lato;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }
    
    .temprano .obj-par {
        text-align: start;
        color: #FFF;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    
    .temprano .com-dig {
        color: var(--et-color);
        font-family: "Rubik One";
        font-size: 56px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-transform: uppercase;
        text-align: start;
    }
    
    .temprano .com-dig-par {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 45px;
        text-align: start;
    }
    

    
    .temprano .com-h {
        min-height: 400px;
    }

    .temprano .img-objetivos{
        width: 85px;
    }
}

@media screen and (max-width:1366px) {
    .temprano .bg-nar {
        background-color: var(--et-color);
    }
    
    .temprano .bg-cd-cl {
        background-color: #BCBBDA40;
        color: #FFF;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    
    .temprano .obj-tit {
        text-align: start;
        color: #FFF;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }
    
    .temprano .obj-par {
        text-align: start;
        color: #FFF;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    
    .temprano .com-dig {
        color: var(--et-color);
        font-family: "Rubik One";
        font-size: 42px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-transform: uppercase;
        text-align: start;
    }
    
    .temprano .com-dig-par {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: 35px;
        text-align: start;
    }
    

    
    .temprano .com-h {
        min-height: 220px;
    }

    .temprano .img-objetivos{
        width: 80px;
    }
}

@media screen and (max-width: 1024px) {
    .temprano .bg-nar {
        background-color: var(--et-color);
    }
    
    .temprano .bg-cd-cl {
        background-color: #BCBBDA40;
    }
    
    .temprano .obj-tit {
        text-align: start;
    }
    
    .temprano .obj-par {
        text-align: start;
    }
    
    .temprano .com-dig {
        color: var(--et-color);
        font-family: "Rubik One";
        font-size: 42px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-transform: uppercase;
        text-align: start;
    }
    
    .temprano .com-dig-par {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 26px;
        text-align: start;
    }
    

    
    .temprano .com-h {
        min-height: 200px;
    }

    .temprano .img-objetivos {
        width: 50px;
        margin: .25rem;
    }
}

@media screen and (max-width:768px) {
    .temprano .obj-tit {
        text-align: center;
        font-family: Lato;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        padding: 2rem 0;
    }
    
    .temprano .obj-par {
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .temprano .img-objetivos{
        width: 280px;
        padding: .25rem;
    }
    
    .temprano .com-dig {
        color: var(--et-color);
        font-family: "Rubik One";
        font-size: 44px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-transform: uppercase;
        text-align: center;
        padding: 2rem 0;
    }
    
    .temprano .com-dig-par {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 26px;
        font-style: normal;
        font-weight: 800;
        line-height: 45px;
        text-align: left;
    }

    
    
    
}

@media screen and (max-width:600px){
    .temprano .obj-tit {
        text-align: center;
        padding: 0;
    }
    
    .temprano .obj-par {
        font-size: 22px;
        line-height: 32px;
        text-align: start;
    }
    
    .temprano .com-dig {
        color: var(--et-color);
        font-family: "Rubik One";
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-transform: uppercase;
        text-align: center;
    }
    
    .temprano .com-dig-par {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 25px;
        text-align: left;
    }
    
    .temprano .highlight {
        color: var(--et-color);
    }

    .temprano .com-h {
        min-height: 160px;
    }
    .temprano .img-objetivos{
        width: 140px;
    }

    .temprano .obj-tit{
        color: #FFF;
        font-family: Lato;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; 
    }

    .temprano .obj-par {
        color: #FFF;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    
}