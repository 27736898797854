:root{
    --et-color: #D4569B;
    --et-gris:#69747C;

    .bg-redes {
        background-color: #E3E559;;
    }

    .bg-visita-sitio {
        background-color: #F84E4E;
    }

    .custom-prog-redes a {
            color: #505050;
            text-align: center;
            font-family: Lato;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 55px; /* 343.75% */
        }

    .play-icon {
        position: relative;
        padding-bottom: -50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }

}
