.mobile-device {
    display: block;
}

.web-device {
    display: none;
}

.pr-title{
    color: #00B9F1;
    font-family: "Rubik One";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 22px */
    text-align: center;
}
.pr-parrafo{
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */

    b {
        color: #000;
        font-family: 'Lato Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
    }
}
.pr-parrafo b{
    color: #000;
    font-family: 'Lato Bold';
    font-size: inherit;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
}
.pr-title-2{
    color: #000;
    text-align: center;
    font-family: 'Ruda Bold';
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;

    b {
        color: #000;
        text-align: center;
        font-family: "Rubik One";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 15.4px */
    }
}
.pr-title-2 b{
    color: #000;
    text-align: center;
    font-family: "Rubik One";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 15.4px */
}

.pr-nums{
    color: #1F282C;
    font-family: "Rubik One";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
}
.pr-bg-gradient{
    background-color:#E9EDF0;
    background-image: url('../../../assets/images/home/Gradient-prog-mobile.png');
    background-position: bottom right;
    background-repeat: no-repeat;
}
.pr-hat{
    width: 4rem;
}
.pr-img-nino{
    width: 100%;
    object-fit: cover;
}

strong {
    font-weight: bolder;
    line-height: inherit;
}

.bg-gray-text {
    background: #E9EDF0;
    background-image: url('../../../assets/images/home/rectangle-programas.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

.programa-bg-1 {
    border: 1px solid rgba(206, 206, 206, 0.33);
    background: #49BAD4;
}

.programa-bg-2 {
    border: 1px solid rgba(206, 206, 206, 0.33);
    background: #F3C042;
}

.programa-bg-3 {
    border: 1px solid rgba(206, 206, 206, 0.33);
    background: #FF8B40;
}

.programa-bg-4 {
    border: 1px solid rgba(206, 206, 206, 0.33);
    background: #B35DD6;
}

.programa-bg-text {
    color: #F5F5F5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 19.2px */
    text-transform: uppercase;

    a {
        color: #000;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: 'Lato Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 38.4px */
        text-transform: uppercase;
    }

    a:hover {
        color: #FFF;
    }
}
.programa-bg-text a {
    color: #000;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Lato Bold';
    font-size: inherit;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 38.4px */
    text-transform: uppercase;
}

.programa-bg-text a:hover {
    color: #FFF;
}

@media (min-width: 768px) {
    .pr-title{
        color: #00B9F1;
        font-family: "Rubik One";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 48px */
    }
    .pr-parrafo{
        color: #000;
        font-family: Lato;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px; /* 140.625% */
        text-align: center;

        b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 25px;
            font-style: normal;
            font-weight: 800;
            line-height: 35px;
        }
    }
    .pr-title-2{
        color: #000;
        font-family: Ruda;
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-align: center;

        b {
            color: #000;
            font-family: "Rubik One";
            font-size: 50px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px; /* 77.778% */
        }
    }
    .pr-title-2 b {
        color: #000;
        font-family: "Rubik One";
        font-size: inherit;
        font-style: normal;
        font-weight: 400;
        line-height: inherit; /* 77.778% */
    }
    .pr-nums{
        color: #1F282C;
        font-family: "Rubik One";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%;
    }
    .pr-bg-gradient{
        background-color:#E9EDF0;
        background-image: url('../../../assets/images/home/Gradient-prog-mobile.png');
        background-position: bottom;
        background-repeat: no-repeat;
    }
    .pr-hat{
        width: 5rem;
    }
    .pr-img-nino{
        width: unset;
        max-width: 100%;
        height: auto;
        object-fit: cover;
    }
    
    strong {
        font-weight: bolder;
    }

    .bg-gray-text {
        background: #E9EDF0;
    }

    .programa-bg-text {
        color: #F5F5F5;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Lato;
        font-size: 34px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 19.2px */
        text-transform: uppercase;

        a {
            /* color: #000; */
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: 'Lato Bold';
            font-size: 34px;
            font-style: normal;
            font-weight: 800;
            line-height: 120%; /* 19.2px */
            text-transform: uppercase;
        }
    }
}

@media screen and (min-width: 1024px) {
    .mobile-device {
        display: none;
    }
    
    .web-device {
        display: block;
    }

    .pr-title{
        font-size: 30px;
        line-height: normal;
        text-align: left;
        padding-top: 1rem;
    }
    .pr-parrafo{
        color: #000;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 140.625% */
        text-align: left;

        b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 16px;
            font-style: normal;
            font-weight: bolder;
            line-height: 26px;
        }
    }
    .pr-title-2{
        color: #000;
        font-family: Ruda;
        font-size: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-align: left;

        b {
            color: #000;
            font-family: "Rubik One";
            font-size: 45px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 77.778% */
        }
    }
    .pr-title-2 b {
        color: #000;
        font-family: "Rubik One";
        font-size: inherit;
        font-style: normal;
        font-weight: 400;
        line-height: inherit; /* 77.778% */
    }
    .pr-nums{
        font-size: 30px;
        line-height: 40px;
        text-align: left;
    }
    .pr-bg-gradient{
        background-image: unset;
        background-position: bottom;
        background-repeat: no-repeat;
    }
    .pr-hat-align{
        display: block;
        margin:0;
        max-width: 80px;
        padding-top: 2rem;
    }
    .pr-img-nino{
        width: 100%;
        object-fit: cover;
    }

    .bg-gray-text {
        background: #E9EDF0;
        background-image: url('../../../assets/images/home/rectangle-programas.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
    }

    .col-1-custom {
        width: 46%;
    }

    .col-2-custom {
        width: 54%;
    }

    .programa-bg-text {
        color: #FFF;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 38.4px */
        text-transform: uppercase;

        a {
            /* color: #000; */
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: 'Lato Bold';
            font-size: 14px;
            font-style: normal;
            font-weight: 800;
            line-height: 120%; /* 38.4px */
            text-transform: uppercase;
        }
    }
}

@media screen and (min-width: 1280px) {
    .pr-title{
        font-size: 43px;
        line-height: normal;
        text-align: left;
        padding-top: 1rem;
    }
    .pr-parrafo{
        color: #000;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 140.625% */

        b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 20px;
            font-style: normal;
            font-weight: 800;
            line-height: 30px;
        }
    }
    .pr-title-2{
        color: #000;
        font-family: Ruda;
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;

        b {
            color: #000;
            font-family: "Rubik One";
            font-size: 50px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px; /* 77.778% */
        }
    }
    .pr-title-2 b {
        color: #000;
        font-family: "Rubik One";
        font-size: inherit;
        font-style: normal;
        font-weight: 400;
        line-height: inherit; /* 77.778% */
    }
    .pr-nums{
        font-size: 45px;
        line-height: 50px;
        text-align: left;
    }
    .pr-bg-gradient{
        background-image: url('../../../assets/images/home/prog-gradient.png');
        background-position: bottom;
        background-repeat: no-repeat;
    }
    .pr-hat-align{
        display: block;
        margin: 0;
        max-width: 100px;
        padding-top: 2rem;
    }
    .pr-img-nino{
        width: 100%;
        object-fit: cover;
    }

    .bg-gray-text {
        background: #E9EDF0;
        background-image: url('../../../assets/images/home/rectangle-programas.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
    }

    .col-1-custom {
        width: 46%;
    }

    .col-2-custom {
        width: 54%;
    }

    .programa-bg-text {
        color: #FFF;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 38.4px */
        text-transform: uppercase;

        a {
            /* color: #000; */
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: 'Lato Bold';
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            line-height: 120%; /* 38.4px */
            text-transform: uppercase;
        }
    }
}

@media screen and (min-width: 1366px) {
    .pr-title{
        font-size: 45px;
        line-height: normal;
        text-align: left;
        padding-top: 1rem;
    }
    .pr-parrafo{
        color: #000;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 140.625% */

        b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 20px;
            font-style: normal;
            font-weight: 800;
            line-height: 30px;
        }
    }
    .pr-title-2{
        color: #000;
        font-family: Ruda;
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;

        b {
            color: #000;
            font-family: "Rubik One";
            font-size: 50px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px; /* 77.778% */
        }
    }
    .pr-title-2 b {
        color: #000;
        font-family: "Rubik One";
        font-size: inherit;
        font-style: normal;
        font-weight: 400;
        line-height: inherit; /* 77.778% */
    }
    .pr-nums{
        font-size: 45px;
        line-height: 60px;
        text-align: left;
    }
    .pr-bg-gradient{
        background-image: url('../../../assets/images/home/prog-gradient.png');
        background-position: bottom;
        background-repeat: no-repeat;
    }
    .pr-hat-align{
        display: block;
        margin: 0;
        max-width: 125px;
        padding-top: 2rem;
    }
    .pr-img-nino{
        width: 100%;
        object-fit: cover;
    }

    .bg-gray-text {
        background: #E9EDF0;
        background-image: url('../../../assets/images/home/rectangle-programas.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
    }

    .col-1-custom {
        width: 46%;
    }

    .col-2-custom {
        width: 54%;
    }

    .programa-bg-text {
        color: #FFF;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Lato;
        font-size: 22px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 38.4px */
        text-transform: uppercase;

        a {
            /* color: #FFF; */
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: 'Lato Bold';
            font-size: 22px;
            font-style: normal;
            font-weight: 800;
            line-height: 120%; /* 38.4px */
            text-transform: uppercase;
        }
    }
}

@media screen and (min-width: 1440px) {
    .pr-title{
        font-size: 53.22px;
        line-height: normal;
        text-align: left;
    }
    .pr-parrafo{
        color: #000;
        font-family: Lato;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px; /* 140.625% */

        b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 26px;
            font-style: normal;
            font-weight: 800;
            line-height: 36px;
        }
    }
    .pr-title-2{
        color: #000;
        font-family: Ruda;
        font-size: 35px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;

        b {
            color: #000;
            font-family: "Rubik One";
            font-size: 70px;
            font-style: normal;
            font-weight: 400;
            line-height: 60px; /* 77.778% */
        }
    }
    .pr-title-2 b {
        color: #000;
        font-family: "Rubik One";
        font-size: 70px;
        font-style: normal;
        font-weight: 400;
        line-height: 60px; /* 77.778% */
    }
    .pr-nums{
        font-size: 74px;
        line-height: 80px;
        text-align: left;
    }
    .pr-bg-gradient{
        background-image: url('../../../assets/images/home/prog-gradient.png');
        background-position: bottom right;
        background-repeat: no-repeat;
    }
    .pr-hat-align{
        display: block;
        margin: 0;
        max-width: 150px;
        padding-top: 2rem;
    }
    .pr-img-nino{
        width: 100%;
        object-fit: cover;
    }

    .bg-gray-text {
        background: #E9EDF0;
        background-image: url('../../../assets/images/home/rectangle-programas.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
    }

    .col-1-custom {
        width: 46%;
    }

    .col-2-custom {
        width: 54%;
    }

    .programa-bg-text {
        color: #FFF;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Lato;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 38.4px */
        text-transform: uppercase;

        a {
            /* color: #FFF; */
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Lato;
            font-size: 24px;
            font-style: normal;
            font-weight: 800;
            line-height: 120%; /* 38.4px */
            text-transform: uppercase;
        }
    }
}

@media screen and (min-width: 1600px) {
    .pr-title{
        font-size: 63.22px;
        line-height: normal;
        text-align: left;
    }
    .pr-parrafo{
        color: #000;
        font-family: Lato;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; /* 140.625% */

        b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 30px;
            font-style: normal;
            font-weight: 800;
            line-height: 40px;
        }
    }
    .pr-title-2{
        color: #000;
        font-family: Ruda;
        font-size: 40px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;

        b {
            color: #000;
            font-family: "Rubik One";
            font-size: 80px;
            font-style: normal;
            font-weight: 400;
            line-height: 70px; /* 77.778% */
        }
    }
    .pr-title-2 b {
        color: #000;
        font-family: "Rubik One";
        font-size: inherit;
        font-style: normal;
        font-weight: 400;
        line-height: inherit; /* 77.778% */
    }
    .pr-nums{
        font-size: 90px;
        line-height: 80px;
        text-align: left;
    }
    .pr-bg-gradient{
        background-image: url('../../../assets/images/home/prog-gradient.png');
        background-position: bottom right;
        background-repeat: no-repeat;
    }
    .pr-hat-align{
        display: block;
        margin: 0;
        max-width: 150px;
        padding-top: 2rem;
    }
    .pr-img-nino{
        width: 100%;
        object-fit: cover;
    }

    .bg-gray-text {
        background: #E9EDF0;
        background-image: url('../../../assets/images/home/rectangle-programas.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
    }

    .col-1-custom {
        width: 46%;
    }

    .col-2-custom {
        width: 54%;
    }

    .programa-bg-text {
        color: #FFF;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Lato;
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 38.4px */
        text-transform: uppercase;

        a {
            /* color: #FFF; */
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Lato;
            font-size: 30px;
            font-style: normal;
            font-weight: 800;
            line-height: 120%; /* 38.4px */
            text-transform: uppercase;
        }
    }
}

@media screen and (min-width: 1920px) {
    .pr-title{
        font-size: 73.22px;
        line-height: normal;
        text-align: left;
    }
    .pr-parrafo{
        color: #000;
        font-family: Lato;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px; /* 140.625% */
        text-align: left;

        b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 32px;
            font-style: normal;
            font-weight: 800;
            line-height: 45px;
        }
    }
    .pr-title-2{
        color: #000;
        font-family: Ruda;
        font-size: 44px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;

        b {
            color: #000;
            font-family: "Rubik One";
            font-size: 90px;
            font-style: normal;
            font-weight: 400;
            line-height: 70px; /* 77.778% */
        }
    }
    .pr-title-2 b {
        color: #000;
        font-family: "Rubik One";
        font-size: inherit;
        font-style: normal;
        font-weight: 400;
        line-height: inherit; /* 77.778% */
    }
    .pr-nums{
        font-size: 90px;
        line-height: 80px;
        text-align: left;
    }
    .pr-hat-align{
        display: block;
        margin: 0;
        max-width: 150px;
        padding-top: 2rem;
    }
    .pr-img-nino{
        width: 100%;
        object-fit: cover;
    }

    .bg-gray-text {
        background: #E9EDF0;
        background-image: url('../../../assets/images/home/rectangle-programas.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
    }

    .col-1-custom {
        width: 46%;
    }

    .col-2-custom {
        width: 54%;
    }

    .programa-bg-text {
        color: #FFF;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Lato;
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 38.4px */
        text-transform: uppercase;

        a {
            /* color: #FFF; */
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Lato;
            font-size: 32px;
            font-style: normal;
            font-weight: 800;
            line-height: 120%; /* 38.4px */
            text-transform: uppercase;
        }
    }
}