.Telenovelas {
  width: 100%;
  background-color: #8780BD;
}

.Telenovelas .mobile-device {
  display: block;
}

.Telenovelas .web-device {
  display: none;
}

.Telenovelas .title {
  color: #FFF;
  font-family: "Rubik One";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 108%; /* 87.5% */
  text-transform: uppercase;
  text-align: center;
}

.Telenovelas .causa {
  margin-left: 1rem;
}

.Telenovelas .causa img {
  margin-left: 1rem;
  width: 40%;
  margin-top: -1rem;
}

.Telenovelas .main-content {
  margin-top: 5rem;
}

.Telenovelas .paragraph {
  color: #FFF;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 42px */
  text-align: left;
}

.Telenovelas .paragraph b {
  color: #FFF;
  font-family: 'Lato Bold';
  font-size: 19px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
}

.Telenovelas .image-content {
  width: 100%;
  height: 100%;
}

.Telenovelas .image-content img {
  width: 100%;
}

.Telenovelas .bg-gray {
  background-color: #FBFBFF;
}

.Telenovelas .bg-teacher {
  background-image: url('../../../assets/images/comunicacion/teacher.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
  width: 100%;
  height: 290px;
}

.Telenovelas .second-content {
  padding-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.Telenovelas .second-title {
  color: #FFF;
  font-family: "Rubik One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.Telenovelas .second-paragraph {
  color: #000;
  font-family: 'Ruda Bold';
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: left;

  b {
    color: #8780BD;
    font-family: "Rubik One";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; /* 123.529% */
  }
}

.Telenovelas .bullet {
  width: 5px;
  height: 5px;
  background-color: #AFAADB;
  margin-top: 10px;
}


@media screen and (min-width: 768px) and (orientation: portrait) {
    .Telenovelas .title {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px; /* 87.5% */
      text-transform: uppercase;
      text-align: center;
    }
  
    .Telenovelas .causa {
      margin-left: 1rem;
  
      img {
        margin-left: 1rem;
        width: 40%;
        margin-top: -1rem;
      }
    }
  
    .Telenovelas .main-content {
      margin-top: 5rem;
    }
  
    .Telenovelas .paragraph {
      color: #FFF;
      font-family: Lato;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 42px */

      b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
      }
    }
  
    .Telenovelas .image-content {
      width: 100%;
      height: 100%;
  
      img {
        /* border: 10px solid #69747C; */
        width: 100%;
      }
    }
  
    .Telenovelas .bg-teacher {
      background-image: url('../../../assets/images/comunicacion/teacher.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top center;
      width: 100%;
      height: 600px;
    }
  
    .Telenovelas .second-content {
      padding-top: 2rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  
    .Telenovelas .second-title {
      color: #FFF;
      text-align: center;
      font-family: "Rubik One";
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 66px */
    }
  
    .Telenovelas .second-paragraph {
      color: #000;
      font-family: 'Ruda Bold';
      font-size: 35px;
      font-style: normal;
      font-weight: 800;
      line-height: 45px;
      text-align: left;

      b {
        color: #8780BD;
        font-family: "Rubik One";
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px; /* 123.529% */
      }
    }

    .Telenovelas .bullet {
      width: 10px;
      height: 6px;
      background-color: #AFAADB;
      margin-top: 15px;
    }

    .Telenovelas .bg-gray .col-lg-6{
      width: 100%;
    }
  }


  @media screen and (min-width: 1024px) and (orientation:landscape) {
    .Telenovelas .mobile-device {
      display: none;
    }
  
    .Telenovelas .web-device {
      display: block;
    }

    .Telenovelas .title {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px; /* 87.5% */
      text-transform: uppercase;
      text-align: left;
    }

    .Telenovelas .causa {
      margin-left: 1rem;

      img {
        margin-left: 2rem;
      }
    }

    .Telenovelas .main-content {
      margin-top: 0rem;
    }

    .Telenovelas .paragraph {
      color: #FFF;
      font-family: Lato;
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 42px */

      b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 19px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
      }
    }

    .Telenovelas .image-content {
      width: 90%;
      height: 100%;

      img {
        /* border: 20px solid #69747C; */
        width: 100%;
      }
    }

    .Telenovelas .bg-gray {
      background-color: #FBFBFF;
    }

    .Telenovelas .bg-teacher {
      background-image: url('../../../assets/images/comunicacion/teacher.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
    }

    .Telenovelas .second-content {
      padding-top: 5rem;
      padding-left: 5rem;
      padding-right: 10%;
    }

    .Telenovelas .second-title {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 35px; /* 100% */
      text-align: left;
    }

    .Telenovelas .second-paragraph {
      color: #000;
      font-family: 'Ruda Bold';
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: 20px;
      text-align: left;

      b {
        color: #8780BD;
        font-family: "Rubik One";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 123.529% */
      }
    }

    .Telenovelas .bullet {
      width: 5px;
      height: 5px;
      background-color: #AFAADB;
      margin-top: 10px;
    }
  }

  

  @media (min-width: 1280px) {
      .Telenovelas .title {
          color: #FFF;
          font-family: "Rubik One";
          font-size: 30px;
          font-style: normal;
          font-weight: 400;
          line-height: 35px; /* 87.5% */
          text-transform: uppercase;
          text-align: left;
      }
  
      .Telenovelas .causa {
          margin-left: 1rem;
      }
  
      .Telenovelas .causa img {
          margin-left: 2rem;
      }
  
      .Telenovelas .main-content {
          margin-top: 0rem;
      }
  
      .Telenovelas .paragraph {
          color: #FFF;
          font-family: Lato;
          font-size: 22px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 42px */
      }
  
      .Telenovelas .paragraph b {
          color: #FFF;
          font-family: 'Lato Bold';
          font-size: 22px;
          font-style: normal;
          font-weight: 800;
          line-height: 120%;
      }
  
      .Telenovelas .image-content {
          width: 90%;
          height: 100%;
      }
  
      .Telenovelas .image-content img {
          width: 100%;
      }
  
      .Telenovelas .bg-gray {
          background-color: #FBFBFF;
      }
  
      .Telenovelas .bg-teacher {
          background-image: url('../../../assets/images/comunicacion/teacher.png');
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
          height: 100%;
      }
  
      .Telenovelas .second-content {
          padding-top: 5rem;
          padding-left: 5rem;
          padding-right: 10%;
      }
  
      .Telenovelas .second-title {
          color: #FFF;
          font-family: "Rubik One";
          font-size: 50px;
          font-style: normal;
          font-weight: 400;
          line-height: 50px; /* 100% */
          text-align: left;
      }
  
      .Telenovelas .second-paragraph {
          color: #000;
          font-family: 'Ruda Bold';
          font-size: 24px;
          font-style: normal;
          font-weight: 800;
          line-height: 32px;
      }
  
      .Telenovelas .second-paragraph b {
          color: #8780BD;
          font-family: "Rubik One";
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px; /* 123.529% */
      }
  
      .Telenovelas .bullet {
          width: 10px;
          height: 6px;
          background-color: #AFAADB;
          margin-top: 15px;
      }
  }

  @media (min-width: 1440px) {
    .Telenovelas .title {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 45px; /* 87.5% */
      text-transform: uppercase;
      text-align: left;
    }

    .Telenovelas .causa {
      margin-left: 1rem;
    }

    .Telenovelas .causa img {
      margin-left: 2rem;
    }

    .Telenovelas .main-content {
      margin-top: 0rem;
    }

    .Telenovelas .paragraph {
      color: #FFF;
      font-family: Lato;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 42px */
    }

    .Telenovelas .paragraph b {
      color: #FFF;
      font-family: 'Lato Bold';
      font-size: 26px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
    }

    .Telenovelas .image-content {
      width: 90%;
      height: 100%;
    }

    .Telenovelas .image-content img {
      /* border: 20px solid #69747C; */
      width: 100%;
    }

    .Telenovelas .bg-gray {
      background-color: #FBFBFF;
    }

    .Telenovelas .bg-teacher {
      background-image: url('../../../assets/images/comunicacion/teacher.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
    }

    .Telenovelas .second-content {
      padding-top: 5rem;
      padding-left: 5rem;
      padding-right: 4rem;
    }

    .Telenovelas .second-title {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 50px; /* 100% */
      text-align: left;
    }

    .Telenovelas .second-paragraph {
      color: #000;
      font-family: 'Ruda Bold';
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 42px;
    }

    .Telenovelas .second-paragraph b {
      color: #8780BD;
      font-family: "Rubik One";
      font-size: 34px;
      font-style: normal;
      font-weight: 400;
      line-height: 42px; /* 123.529% */
    }

    .Telenovelas .bullet {
      width: 10px;
      height: 6px;
      background-color: #AFAADB;
      margin-top: 15px;
    }
}

@media (min-width: 1920px) {
  .Telenovelas .title {
    color: #FFF;
    font-family: "Rubik One";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px; /* 87.5% */
    text-transform: uppercase;
    text-align: left;
  }

  .Telenovelas .causa {
    margin-left: 1rem;
  }

  .Telenovelas .causa img {
    margin-left: 2rem;
  }

  .Telenovelas .main-content {
    margin-top: 0rem;
  }

  .Telenovelas .paragraph {
    color: #FFF;
    font-family: Lato;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 42px */
  }

  .Telenovelas .paragraph b {
    color: #FFF;
    font-family: 'Lato Bold';
    font-size: 35px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
  }

  .Telenovelas .image-content {
    width: 90%;
    height: 100%;
  }

  .Telenovelas .image-content img {
    /* border: 20px solid #69747C; */
    width: 100%;
  }

  .Telenovelas .bg-gray {
    background-color: #FBFBFF;
  }

  .Telenovelas .bg-teacher {
    background-image: url('../../../assets/images/comunicacion/teacher.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    width: 100%;
    height: 100%;
  }

  .Telenovelas .second-content {
    padding-top: 5%;
    padding-left: 7%;
    padding-right: 10%;
    padding-bottom: 5%;
  }

  .Telenovelas .second-title {
    color: #FFF;
    font-family: "Rubik One";
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: 55px; /* 100% */
    text-align: left;
  }

  .Telenovelas .second-paragraph {
    color: #000;
    font-family: 'Ruda Bold';
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: 42px;
  }

  .Telenovelas .second-paragraph b {
    color: #8780BD;
    font-family: "Rubik One";
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px; /* 123.529% */
  }

  .Telenovelas .bullet {
    width: 10px;
    height: 6px;
    background-color: #AFAADB;
    margin-top: 15px;
  }
}

@media screen and (min-width:2560px) {
  .Telenovelas .title {
    color: #FFF;
    font-family: "Rubik One";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px; /* 87.5% */
    text-transform: uppercase;
  }

  .Telenovelas .second-content {
    padding-top: 5rem;
    padding-left: 5rem;
    padding-right: 20%;
  }

  .Telenovelas .second-title {
    color: #FFF;
    font-family: "Rubik One";
    font-size: 75px;
    font-style: normal;
    font-weight: 400;
    line-height: 75px; /* 100% */
    text-align: left;
  }

  .Telenovelas .second-paragraph {
    color: #000;
    font-family: 'Ruda Bold';
    font-size: 34px;
    font-style: normal;
    font-weight: 800;
    line-height: 42px;

    b {
      color: #8780BD;
      font-family: "Rubik One";
      font-size: 34px;
      font-style: normal;
      font-weight: 400;
      line-height: 42px; /* 123.529% */
    }
  }

  .Telenovelas .bullet {
    width: 10px;
    height: 6px;
    background-color: #AFAADB;
    margin-top: 15px;
  }

}
