.ProgramasPosible {
  width: 100%;
  overflow-x: hidden;

  .mobile-device {
    display: block;
  }

  .web-device {
    display: none;
  }

  @media (min-width: 768px) {

  }

  @media (min-width: 1024px) {
    .mobile-device {
      display: none;
    }
  
    .web-device {
      display: block;
    }
  }

  @media (min-width: 1280px) {
    
  }

  @media (min-width: 1366px) {
    
  }

  @media (min-width: 1440px) {
    
  }

  @media (min-width: 1920px) {
    
  }
}