.VistaAguila {
  width: 100%;
  /* background: linear-gradient(0deg, rgba(114, 205, 188, 0.30) 0%, rgba(114, 205, 188, 0.00) 100%);
   */
   background-image: url('../../../assets/images/nuestrasAlas/vista-aguila-gradient.png');
   background-repeat: no-repeat;
   background-size: cover;
   width: 100%;
   height: auto;
   background-position: bottom;
}
  .VistaAguila .mobile-device {
    display: block;
  }

  .VistaAguila .web-device {
    display: none;
  }

  .VistaAguila .title {
    color: #085F60;
    text-align: center;
    font-family: "Rubik One";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  .VistaAguila .paragraph {
    color: #1F282C;
    font-family: Lato;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
  }
    .VistaAguila .paragraph b {
      color: #1F282C;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 27.5px */
    }
  .VistaAguila {

  @media (min-width: 768px) {
    background-color: #E9EDF0;

    .title {
      color: #085F60;
      text-align: center;
      font-family: "Rubik One";
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }

    .paragraph {
      color: #1F282C;
      font-family: Lato;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
  
      b {
        font-family: 'Lato Bold';
        font-weight: bolder;
        font-size: 26px;
      }
    }
  }

  @media (min-width: 1024px) {

    .mobile-device {
      display: none;
    }
  
    .web-device {
      display: block;
    }

    .title {
      color: #085F60;
      text-align: center;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }

    .paragraph {
      color: #1F282C;
      font-family: Lato;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
  
      b {
        font-family: 'Lato Bold';
        font-weight: bolder;
      }
    }

    .rectangle-bottom {
      margin-top: -80vh;
      position: relative;
      z-index: 1;
    }

  }

  @media (min-width: 1366px) {

    .mobile-device {
      display: none;
    }
  
    .web-device {
      display: block;
    }

    .title {
      color: #085F60;
      text-align: center;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }

    .paragraph {
      color: #1F282C;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
  
      b {
        font-family: 'Lato Bold';
        font-weight: bolder;
      }
    }

    .rectangle-bottom {
      margin-top: -80vh;
      position: relative;
      z-index: 1;
    }

  }

  @media (min-width: 1440px) {
    .mobile-device {
      display: none;
    }
  
    .web-device {
      display: block;
    }

    .title {
      color: #085F60;
      text-align: center;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }

    .paragraph {
      color: #1F282C;
      font-family: Lato;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
  
      b {
        color: #1F282C;
        text-align: center;
        font-family: 'Lato Bold';
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 140%; /* 42px */
      }
    }

    .rectangle-bottom {
      margin-top: -40vh;
      position: relative;
      z-index: 1;
    }
  }
}