.IniciativasPosible {
  width: 100%;
  background: #F5F4EF;
}

.IniciativasPosible .mobile-device {
  display: block;
}

.IniciativasPosible .web-device {
  display: none;
}

.IniciativasPosible .title {
  color: #69747C;
  text-align: center;
  font-family: "Rubik One";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 26.4px */
  text-transform: uppercase;
}

.IniciativasPosible .bg-iniciativas-posible {
  background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativas-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 709px;
}

.IniciativasPosible .container-iniciativa1 {
  position: relative;
  padding-top: 5%;
  padding-left: 10%;
}

.IniciativasPosible .container-iniciativa2 {
  position: relative;
  padding-top: 408px;
  padding-right: 10%;
}

.IniciativasPosible .iniciativas-text1 {
  background-image: url('../../../../../assets/images/programas/subsections/posible/rectangle-iniciativas1-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: auto;
  height: auto;
}

.IniciativasPosible .iniciativas-text2 {
  background-image: url('../../../../../assets/images/programas/subsections/posible/rectangle-iniciativas2-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: auto;
  height: auto;
  display: flex;
  justify-content: flex-end;
}

.IniciativasPosible .paragraph-iniciativas {
  color: #FFF;
  text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.25);
  font-family: 'Ruda Bold';
  font-size: 19px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 127.778% */
  text-align: center;
  width: 75%;

  
}

.IniciativasPosible .paragraph-iniciativas b {
  color: #FFF;
  font-family: "Rubik One";
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.IniciativasPosible .paragraph-iniciativas2 {
  color: #FFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Ruda;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 183.333% */
  text-align: center;
  width: 60%;
}

.IniciativasPosible .paragraph-iniciativas2 b {
  font-family: "Rubik One";
  font-size: 18px;
  font-weight: 400;
}

.IniciativasPosible .paragraph-iniciativas3 {
  color: #000;
  font-family: 'Ruda Bold';
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  text-align: center;
}
  .IniciativasPosible .paragraph-iniciativas3 b {
    color: #E2AF32;
    font-family: "Rubik One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 45.6px */
  }


.IniciativasPosible .bg-iniciativa3 {
  background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativa3-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 421px;
}

.IniciativasPosible .bg-iniciativa4 {
  background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativa4-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 421px;
}

.IniciativasPosible .bg-iniciativa5 {
  background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativa5-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 421px;
}

.IniciativasPosible .bg-iniciativa6 {
  background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativas6-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 314px;
}

.IniciativasPosible .bg-participacion-femenina {
  background-image: url('../../../../../assets/images/programas/subsections/posible/participacion_femenina-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 253px;
}

.IniciativasPosible .text-container-p6 {
  position: relative;
  padding-top: 175px;
}

.IniciativasPosible .bg-gradient-iniciativa6 {
  background: linear-gradient(180deg, rgba(238, 228, 204, 0.00) 0%, rgba(232, 186, 74, 0.90) 100%);
}

.IniciativasPosible .paragraph-iniciativas6 {
  color: #FFF;
  font-family: 'Ruda Bold';
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
  text-align: center;

}

.IniciativasPosible .paragraph-iniciativas6 b {
  color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(17, 17, 17, 0.25);
    font-family: "Rubik One";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 127.778% */
}

    @media (min-width: 768px) {
      .IniciativasPosible .title {
          color: #69747C;
          text-align: center;
          font-family: "Rubik One";
          font-size: 62px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 74.4px */
          text-transform: uppercase;
      }
  
      .IniciativasPosible .bg-iniciativas-posible {
          background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativas-tablet.png');
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
          height: 1351px;
      }
    
      .IniciativasPosible .container-iniciativa1 {
          position: relative;
          padding-top: 5%;
          padding-left: 10%;
      }
    
      .IniciativasPosible .container-iniciativa2 {
          position: relative;
          padding-top: 787px;
          padding-right: 10%;
      }
    
      .IniciativasPosible .iniciativas-text1 {
          background-image: url('../../../../../assets/images/programas/subsections/posible/rectangle-iniciativas1-mobile.png');
          background-repeat: no-repeat;
          background-size: cover;
          width: auto;
          height: auto;
      }
    
      .IniciativasPosible .iniciativas-text2 {
          background-image: url('../../../../../assets/images/programas/subsections/posible/rectangle-iniciativas2-mobile.png');
          background-repeat: no-repeat;
          background-size: cover;
          width: auto;
          height: auto;
          display: flex;
          justify-content: flex-end;
      }
    
      .IniciativasPosible .paragraph-iniciativas {
          color: #FFF;
          text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.25);
          font-family: 'Ruda Bold';
          font-size: 20px;
          font-style: normal;
          font-weight: 800;
          line-height: 30px; /* 127.778% */
          text-align: left;
          width: 75%;
      }
  
      .IniciativasPosible .paragraph-iniciativas b {
          color: #FFF;
          font-family: "Rubik One";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px;
      }
    
      .IniciativasPosible .paragraph-iniciativas2 {
          color: #FFF;
          font-family: Ruda;
          font-size: 40px;
          font-style: normal;
          font-weight: 800;
          line-height: 125%;
          text-align: right;
          width: 65%;
      }
  
      .IniciativasPosible .paragraph-iniciativas2 b {
          font-family: "Rubik One";
          font-size: 40px;
          font-weight: 400;
      }
  
      .IniciativasPosible .paragraph-iniciativas3 {
          color: #000;
          font-family: 'Ruda Bold';
          font-size: 26px;
          font-style: normal;
          font-weight: 800;
          line-height: 120%;
          text-align: center;
      }
  
      .IniciativasPosible .paragraph-iniciativas3 b {
          color: #E2AF32;
          font-family: "Rubik One";
          font-size: 26px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 45.6px */
      }
    
      .IniciativasPosible .bg-iniciativa3 {
          background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativa3-tablet.png');
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
          height: 794px;
      }
    
      .IniciativasPosible .bg-iniciativa4 {
          background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativa4-tablet.png');
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
          height: 507px;
      }
    
      .IniciativasPosible .bg-iniciativa5 {
          background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativa5-tablet.png');
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
          height: 468px;
      }
  
      .IniciativasPosible .bg-iniciativa6 {
          background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativas6-tablet.png');
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
          height: 741px;
      }
  
      .IniciativasPosible .bg-participacion-femenina {
          background-image: url('../../../../../assets/images/programas/subsections/posible/participacion_femenina-tablet.png');
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
          height: 677px;
      }
    
      .IniciativasPosible .text-container-p6 {
          position: relative;
          padding-top: 543px;
      }
    
      .IniciativasPosible .bg-gradient-iniciativa6 {
          background: linear-gradient(180deg, rgba(238, 228, 204, 0.00) 0%, rgba(232, 186, 74, 0.90) 100%);
      }
    
      .IniciativasPosible .paragraph-iniciativas6 {
          color: #FFF;
          font-family: 'Ruda Bold';
          font-size: 28px;
          font-style: normal;
          font-weight: 800;
          line-height: 34px;
          text-align: center;
      }
  
      .IniciativasPosible .paragraph-iniciativas6 b {
          color: #FFF;
          text-align: center;
          text-shadow: 0px 4px 4px rgba(17, 17, 17, 0.25);
          font-family: "Rubik One";
          font-size: 28px;
          font-style: normal;
          font-weight: 400;
          line-height: 34px; /* 127.778% */
      }
  }
  
  @media (min-width: 1024px) {
      .IniciativasPosible .mobile-device {
          display: none;
      }
    
      .IniciativasPosible .web-device {
          display: block;
      }
  
      .IniciativasPosible .title {
          color: #69747C;
          text-align: center;
          font-family: "Rubik One";
          font-size: 62px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 74.4px */
          text-transform: uppercase;
      }
  
      .IniciativasPosible .bg-iniciativas-posible {
          background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativas.png');
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
          height: 669px;
          background-position: center;
      }
    
      .IniciativasPosible .container-iniciativa1 {
          position: relative;
          padding-top: 10%;
          padding-left: 10%;
      }
    
      .IniciativasPosible .container-iniciativa2 {
          position: relative;
          padding-top: 5%;
          padding-right: 2rem;
      }
    
      .IniciativasPosible .iniciativas-text1 {
          background-image: url('../../../../../assets/images/programas/subsections/posible/rectangle-iniciativas1-mobile.png');
          background-repeat: no-repeat;
          background-size: cover;
          width: 40%;
          height: auto;
      }
    
      .IniciativasPosible .iniciativas-text2 {
          background-image: url('../../../../../assets/images/programas/subsections/posible/rectangle-iniciativas2-mobile.png');
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
          height: auto;
          display: flex;
          justify-content: flex-end;
      }
    
      .IniciativasPosible .paragraph-iniciativas {
          color: #FFF;
          text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.25);
          font-family: 'Ruda Bold';
          font-size: 20px;
          font-style: normal;
          font-weight: 800;
          line-height: 30px; /* 127.778% */
          text-align: left;
          width: 75%;
      }
  
      .IniciativasPosible .paragraph-iniciativas b {
          color: #FFF;
          font-family: "Rubik One";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px;
      }
    
      .IniciativasPosible .paragraph-iniciativas2 {
          color: #FFF;
          font-family: Ruda;
          font-size: 25px;
          font-style: normal;
          font-weight: 800;
          line-height: 40px;
          text-align: left;
      }
  
      .IniciativasPosible .paragraph-iniciativas2 b {
          font-family: "Rubik One";
          font-size: 25px;
          font-weight: 400;
      }
  
      .IniciativasPosible .paragraph-iniciativas3 {
          color: #000;
          font-family: 'Ruda Bold';
          font-size: 20px;
          font-style: normal;
          font-weight: 800;
          line-height: 120%;
          text-align: start;
      }
  
      .IniciativasPosible .paragraph-iniciativas3 b {
          color: #E2AF32;
          font-family: "Rubik One";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 45.6px */
      }
    
      .IniciativasPosible .bg-iniciativa3 {
          background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativa3.png');
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
          height: 350px;
      }
    
      .IniciativasPosible .bg-iniciativa4 {
          background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativa4.png');
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
          height: 350px;
      }
    
      .IniciativasPosible .bg-iniciativa5 {
          background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativa5.png');
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
          height: 350px;
      }
  
      .IniciativasPosible .bg-iniciativa6 {
          background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativas6.png');
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
          height: 350px;
      }
  
      .IniciativasPosible .bg-participacion-femenina {
          background-image: url('../../../../../assets/images/programas/subsections/posible/participacion_femenina.png');
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
          height: 350px;
      }
    
      .IniciativasPosible .text-container-p6 {
          position: relative;
          padding-top: 205px;
      }
    
      .IniciativasPosible .bg-gradient-iniciativa6 {
          background: linear-gradient(180deg, rgba(238, 228, 204, 0.00) 0%, rgba(232, 186, 74, 0.90) 100%);
      }
    
      .IniciativasPosible .paragraph-iniciativas6 {
          color: #FFF;
          font-family: 'Ruda Bold';
          font-size: 18px;
          font-style: normal;
          font-weight: 800;
          line-height: 28px;
          text-align: center;
      }
  
      .IniciativasPosible .paragraph-iniciativas6 b {
          color: #FFF;
          text-align: center;
          text-shadow: 0px 4px 4px rgba(17, 17, 17, 0.25);
          font-family: "Rubik One";
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px; /* 127.778% */
      }
  }
  

  @media (min-width: 1280px) {
    .IniciativasPosible .mobile-device {
        display: none;
    }
  
    .IniciativasPosible .web-device {
        display: block;
    }

    .IniciativasPosible .title {
        color: #69747C;
        text-align: center;
        font-family: "Rubik One";
        font-size: 62px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 74.4px */
        text-transform: uppercase;
    }

    .IniciativasPosible .bg-iniciativas-posible {
        background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativas.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 669px;
        background-position: center;
    }
  
    .IniciativasPosible .container-iniciativa1 {
        position: relative;
        padding-top: 10%;
        padding-left: 10%;
    }
  
    .IniciativasPosible .container-iniciativa2 {
        position: relative;
        padding-top: 5%;
        padding-right: 2rem;
    }
  
    .IniciativasPosible .iniciativas-text1 {
        background-image: url('../../../../../assets/images/programas/subsections/posible/rectangle-iniciativas1-mobile.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 40%;
        height: auto;
    }
  
    .IniciativasPosible .iniciativas-text2 {
        background-image: url('../../../../../assets/images/programas/subsections/posible/rectangle-iniciativas2-mobile.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-end;
    }
  
    .IniciativasPosible .paragraph-iniciativas {
        color: #FFF;
        text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.25);
        font-family: 'Ruda Bold';
        font-size: 26px;
        font-style: normal;
        font-weight: 800;
        line-height: 36px; /* 127.778% */
        text-align: left;
        width: 75%;
    }

    .IniciativasPosible .paragraph-iniciativas b {
        color: #FFF;
        font-family: "Rubik One";
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
    }
  
    .IniciativasPosible .paragraph-iniciativas2 {
        color: #FFF;
        font-family: Ruda;
        font-size: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: 40px;
        text-align: left;
    }

    .IniciativasPosible .paragraph-iniciativas2 b {
        font-family: "Rubik One";
        font-size: 25px;
        font-weight: 400;
    }

    .IniciativasPosible .paragraph-iniciativas3 {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
    }

    .IniciativasPosible .paragraph-iniciativas3 b {
        color: #E2AF32;
        font-family: "Rubik One";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 45.6px */
    }
  
    .IniciativasPosible .bg-iniciativa3 {
        background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativa3.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 350px;
    }
  
    .IniciativasPosible .bg-iniciativa4 {
        background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativa4.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 350px;
    }
  
    .IniciativasPosible .bg-iniciativa5 {
        background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativa5.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 350px;
    }

    .IniciativasPosible .bg-iniciativa6 {
        background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativas6.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 350px;
    }

    .IniciativasPosible .bg-participacion-femenina {
        background-image: url('../../../../../assets/images/programas/subsections/posible/participacion_femenina.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 350px;
    }
  
    .IniciativasPosible .text-container-p6 {
        position: relative;
        padding-top: 200px;
    }
  
    .IniciativasPosible .bg-gradient-iniciativa6 {
        background: linear-gradient(180deg, rgba(238, 228, 204, 0.00) 0%, rgba(232, 186, 74, 0.90) 100%);
    }
  
    .IniciativasPosible .paragraph-iniciativas6 {
        color: #FFF;
        font-family: 'Ruda Bold';
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 34px;
        text-align: center;
    }

    .IniciativasPosible .paragraph-iniciativas6 b {
        color: #FFF;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(17, 17, 17, 0.25);
        font-family: "Rubik One";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px; /* 127.778% */
    }
}

@media (min-width: 1366px) {
  .IniciativasPosible .mobile-device {
      display: none;
  }

  .IniciativasPosible .web-device {
      display: block;
  }

  .IniciativasPosible .title {
      color: #69747C;
      text-align: center;
      font-family: "Rubik One";
      font-size: 62px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 74.4px */
      text-transform: uppercase;
  }

  .IniciativasPosible .bg-iniciativas-posible {
      background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativas.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 669px;
      background-position: center;
  }

  .IniciativasPosible .container-iniciativa1 {
      position: relative;
      padding-top: 10%;
      padding-left: 10%;
  }

  .IniciativasPosible .container-iniciativa2 {
      position: relative;
      padding-top: 5%;
      padding-right: 2rem;
  }

  .IniciativasPosible .iniciativas-text1 {
      background-image: url('../../../../../assets/images/programas/subsections/posible/rectangle-iniciativas1-mobile.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 40%;
      height: auto;
  }

  .IniciativasPosible .iniciativas-text2 {
      background-image: url('../../../../../assets/images/programas/subsections/posible/rectangle-iniciativas2-mobile.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-end;
  }

  .IniciativasPosible .paragraph-iniciativas {
      color: #FFF;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.25);
      font-family: 'Ruda Bold';
      font-size: 26px;
      font-style: normal;
      font-weight: 800;
      line-height: 36px; /* 127.778% */
      text-align: left;
      width: 75%;
  }

  .IniciativasPosible .paragraph-iniciativas b {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
  }

  .IniciativasPosible .paragraph-iniciativas2 {
      color: #FFF;
      font-family: Ruda;
      font-size: 25px;
      font-style: normal;
      font-weight: 800;
      line-height: 40px;
      text-align: left;
  }

  .IniciativasPosible .paragraph-iniciativas2 b {
      font-family: "Rubik One";
      font-size: 25px;
      font-weight: 400;
  }

  .IniciativasPosible .paragraph-iniciativas3 {
      color: #000;
      font-family: 'Ruda Bold';
      font-size: 26px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
  }

  .IniciativasPosible .paragraph-iniciativas3 b {
      color: #E2AF32;
      font-family: "Rubik One";
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 45.6px */
  }

  .IniciativasPosible .bg-iniciativa3 {
      background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativa3.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 350px;
  }

  .IniciativasPosible .bg-iniciativa4 {
      background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativa4.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 350px;
  }

  .IniciativasPosible .bg-iniciativa5 {
      background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativa5.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 350px;
  }

  .IniciativasPosible .bg-iniciativa6 {
      background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativas6.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 350px;
  }

  .IniciativasPosible .bg-participacion-femenina {
      background-image: url('../../../../../assets/images/programas/subsections/posible/participacion_femenina.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 350px;
  }

  .IniciativasPosible .text-container-p6 {
      position: relative;
      padding-top: 200px;
  }

  .IniciativasPosible .bg-gradient-iniciativa6 {
      background: linear-gradient(180deg, rgba(238, 228, 204, 0.00) 0%, rgba(232, 186, 74, 0.90) 100%);
  }

  .IniciativasPosible .paragraph-iniciativas6 {
      color: #FFF;
      font-family: 'Ruda Bold';
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 34px;
      text-align: center;
  }

  .IniciativasPosible .paragraph-iniciativas6 b {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(17, 17, 17, 0.25);
      font-family: "Rubik One";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px; /* 127.778% */
  }
}

@media (min-width: 1440px) {
  .IniciativasPosible .title {
      color: #69747C;
      text-align: center;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 60px */
      text-transform: uppercase;
  }

  .IniciativasPosible .bg-iniciativas-posible {
      background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativas.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 669px;
      background-position: center;
  }

  .IniciativasPosible .container-iniciativa1 {
      position: relative;
      padding-top: 10%;
      padding-left: 10%;
  }

  .IniciativasPosible .container-iniciativa2 {
      position: relative;
      padding-top: 5%;
      padding-right: 2rem;
      margin-bottom: 2rem;
  }

  .IniciativasPosible .iniciativas-text1 {
      background-image: url('../../../../../assets/images/programas/subsections/posible/rectangle-iniciativas1-mobile.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 40%;
      height: auto;
  }

  .IniciativasPosible .iniciativas-text2 {
      background-image: url('../../../../../assets/images/programas/subsections/posible/rectangle-iniciativas2-mobile.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-end;
  }

  .IniciativasPosible .paragraph-iniciativas {
      color: #FFF;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.25);
      font-family: 'Ruda Bold';
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 40px; /* 127.778% */
      text-align: left;
      width: 75%;
  }

  .IniciativasPosible .paragraph-iniciativas b {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
  }

  .IniciativasPosible .paragraph-iniciativas2 {
      color: #FFF;
      font-family: Ruda;
      font-size: 35px;
      font-style: normal;
      font-weight: 800;
      line-height: 46px;
      text-align: left;
  }

  .IniciativasPosible .paragraph-iniciativas2 b {
      font-family: "Rubik One";
      font-size: 42px;
      font-weight: 400;
  }

  .IniciativasPosible .paragraph-iniciativas3 {
      color: #000;
      font-family: 'Ruda Bold';
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
  }

  .IniciativasPosible .paragraph-iniciativas3 b {
      color: #E2AF32;
      font-family: "Rubik One";
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 45.6px */
  }

  .IniciativasPosible .bg-iniciativa3 {
      background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativa3.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 590px;
  }

  .IniciativasPosible .bg-iniciativa4 {
      background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativa4.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 590px;
  }

  .IniciativasPosible .bg-iniciativa5 {
      background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativa5.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 590px;
  }

  .IniciativasPosible .bg-iniciativa6 {
      background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativas6.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 669px;
  }

  .IniciativasPosible .bg-participacion-femenina {
      background-image: url('../../../../../assets/images/programas/subsections/posible/participacion_femenina.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 590px;
  }

  .IniciativasPosible .text-container-p6 {
      position: relative;
      padding-top: 424px;
  }

  .IniciativasPosible .bg-gradient-iniciativa6 {
      background: linear-gradient(222deg, rgba(238, 228, 204, 0.00) 0%, rgba(232, 186, 74, 0.90) 100%);
  }

  .IniciativasPosible .paragraph-iniciativas6 {
      color: #FFF;
      font-family: 'Ruda Bold';
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: 46px;
      text-align: center;
  }

  .IniciativasPosible .paragraph-iniciativas6 b {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(17, 17, 17, 0.25);
      font-family: "Rubik One";
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 46px; /* 127.778% */
  }
}

@media (min-width: 1920px) {
  .IniciativasPosible .title {
      color: #69747C;
      text-align: center;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 60px */
      text-transform: uppercase;
  }

  .IniciativasPosible .bg-iniciativas-posible {
      background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativas.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 669px;
      background-position: center;
  }

  .IniciativasPosible .container-iniciativa1 {
      position: relative;
      padding-top: 10%;
      padding-left: 10%;
  }

  .IniciativasPosible .container-iniciativa2 {
      position: relative;
      padding-top: 5%;
      padding-right: 2rem;
      margin-bottom: 2rem;
  }

  .IniciativasPosible .iniciativas-text1 {
      background-image: url('../../../../../assets/images/programas/subsections/posible/rectangle-iniciativas1-mobile.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 40%;
      height: auto;
  }

  .IniciativasPosible .iniciativas-text2 {
      background-image: url('../../../../../assets/images/programas/subsections/posible/rectangle-iniciativas2-mobile.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-end;
  }

  .IniciativasPosible .paragraph-iniciativas {
      color: #FFF;
      text-shadow: 0px 4px 4px rgba(56, 56, 56, 0.25);
      font-family: 'Ruda Bold';
      font-size: 36px;
      font-style: normal;
      font-weight: 800;
      line-height: 46px; /* 127.778% */
      text-align: left;
      width: 75%;
  }

  .IniciativasPosible .paragraph-iniciativas b {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 46px;
  }

  .IniciativasPosible .paragraph-iniciativas2 {
      color: #FFF;
      font-family: Ruda;
      font-size: 35px;
      font-style: normal;
      font-weight: 800;
      line-height: 46px;
      text-align: left;
  }

  .IniciativasPosible .paragraph-iniciativas2 b {
      font-family: "Rubik One";
      font-size: 42px;
      font-weight: 400;
  }

  .IniciativasPosible .paragraph-iniciativas3 {
      color: #000;
      font-family: 'Ruda Bold';
      font-size: 38px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
  }

  .IniciativasPosible .paragraph-iniciativas3 b {
      color: #E2AF32;
      font-family: "Rubik One";
      font-size: 38px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 45.6px */
  }

  .IniciativasPosible .bg-iniciativa3 {
      background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativa3.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 590px;
  }

  .IniciativasPosible .bg-iniciativa4 {
      background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativa4.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 590px;
  }

  .IniciativasPosible .bg-iniciativa5 {
      background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativa5.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 590px;
  }

  .IniciativasPosible .bg-iniciativa6 {
      background-image: url('../../../../../assets/images/programas/subsections/posible/iniciativas6.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 669px;
  }

  .IniciativasPosible .bg-participacion-femenina {
      background-image: url('../../../../../assets/images/programas/subsections/posible/participacion_femenina.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 590px;
  }

  .IniciativasPosible .text-container-p6 {
      position: relative;
      padding-top: 424px;
  }

  .IniciativasPosible .bg-gradient-iniciativa6 {
      background: linear-gradient(222deg, rgba(238, 228, 204, 0.00) 0%, rgba(232, 186, 74, 0.90) 100%);
  }

  .IniciativasPosible .paragraph-iniciativas6 {
      color: #FFF;
      font-family: 'Ruda Bold';
      font-size: 36px;
      font-style: normal;
      font-weight: 800;
      line-height: 46px;
      text-align: center;
  }

  .IniciativasPosible .paragraph-iniciativas6 b {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(17, 17, 17, 0.25);
      font-family: "Rubik One";
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 46px; /* 127.778% */
  }
}
