.CardProgram {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}
  .CardProgram .card-container {
    width: 100%;
    height: auto;
  }

  .CardProgram img {
    width: 100%;
    height: auto;
    border: 10px solid #69747C;
  }

  .CardProgram .title {
    color: #8780BD;
    font-family: "Rubik One";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
    margin-top: 18px;
  }

  .CardProgram .description {
    color: #1F282C;
    font-family: Lato;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    margin-top: 5px;
    text-align: left;
  }
  .CardProgram .description b{
    font-family: 'Lato Bold';
    font-weight: 400;
  }

  .CardProgram .play-overlay{
    position: absolute;
    left: 50%;
    top: 50%;
    cursor: pointer;
    z-index: 20;
    -webkit-transform:translate(-50%,-50%);
    transform:translate(-50%,-50%);
    width: 2rem;
    height: auto;
    border: unset;
  }

  @media (min-width: 768px) {
    .CardProgram {
    padding-left: 10%;
    padding-right: 10%;
    }
    .CardProgram .card-container {
      width: 100%;
      height: auto;
    }

    /* img {
      max-width: 100%;
    } */
    .CardProgram img {
      width: 644px;
      height: 492px;
      border: 18px solid #69747C;
    }
  
    .CardProgram .title {
      color: #8780BD;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      text-align: left;
      margin-top: 26px;
    }
  
    .CardProgram .description {
      color: #1F282C;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 142%; /* 34.08px */
      margin-top: 14px;
      text-align: left;
    }

    .CardProgram .play-overlay{
      position: absolute;
      left: 50%;
      top: 50%;
      cursor: pointer;
      z-index: 20;
      -webkit-transform:translate(-50%,-50%);
      transform:translate(-50%,-50%);
      width: 4rem;
      height: auto;
      border: unset;
    }
  }

  @media (min-width: 1024px) {
    /* img {
      max-width: 100%;
      border: 20px solid #69747C;
    } */
    .CardProgram {
      padding-left: unset;
      padding-right: unset;
    }
    .CardProgram .card-container {
      width: 450px;
      height: auto;
    }
  
    .CardProgram img {
      width: 450px;
      height: 320px;
      border: 20px solid #69747C;
    }
  
    .CardProgram .title {
      color: #8780BD;
      font-family: "Rubik One";
      font-size: 46px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      text-align: left;
      margin-top: 12px;
    }
  
    .CardProgram .description {
      color: #1F282C;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
      margin-top: 5px;
      text-align: left;
    }

    .CardProgram .play-overlay{
      position: absolute;
      left: 50%;
      top: 50%;
      cursor: pointer;
      z-index: 20;
      -webkit-transform:translate(-50%,-50%);
      transform:translate(-50%,-50%);
      width: 3rem;
      height: auto;
      border: unset;
    }
  }

  @media (min-width: 1440px) {
    /* img {
      max-width: 100%;
      border: 20px solid #69747C;
    } */
    .CardProgram .card-container {
      width: 711px;
      height: auto;
      margin: auto;
    }
  
    .CardProgram img {
      width: 711px;
      height: 487px;
      border: 20px solid #69747C;
    }
  
    .CardProgram .title {
      color: #8780BD;
      font-family: "Rubik One";
      font-size: 46px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      text-align: left;
      margin-top: 12px;
    }
  
    .CardProgram .description {
      color: #1F282C;
      font-family: Lato;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 152%;
      margin-top: 5px;
      text-align: left;

      .CardProgram b,.CardProgram  strong {
        color: #1F282C;
        font-family: 'Lato Bold';
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 152%; /* 45.6px */
      }
    }

    .CardProgram .play-overlay{
      position: absolute;
      left: 50%;
      top: 50%;
      cursor: pointer;
      z-index: 20;
      -webkit-transform:translate(-50%,-50%);
      transform:translate(-50%,-50%);
      width: 5rem;
      height: auto;
      border: unset;
    }
  }
