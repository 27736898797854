.ProgramasIniciativasValores {
  width: 100%;

  .play-icon {
    position: relative;
    padding-bottom: -50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}

.ProgramasIniciativasValores .mobile-device {
  display: block;
}

.ProgramasIniciativasValores .web-device {
  display: none;
}

.ProgramasIniciativasValores .bg-rectangle-iniciativas {
  /* background-image: url('../../../../../assets/images/programas/subsections/valores/rectangle-iniciativas-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto; */
  background: linear-gradient(0deg, rgba(219, 235, 244, 0.00) 65.58%, rgba(33, 108, 149, 0.32) 100%);
}

.ProgramasIniciativasValores .paragraph-iniciativas-container {
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2.5rem;
  padding-bottom: 2.5rem;
}

.ProgramasIniciativasValores .paragraph-iniciativa {
  color: #1F282C;
  text-align: center;
  font-family: Ruda;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
}

.ProgramasIniciativasValores .paragraph-iniciativa b {
  color: #1F282C;
  font-family: 'Ruda Bold';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}

.ProgramasIniciativasValores .title-iniciativas {
  color: #69747C;
  text-align: center;
  font-family: "Rubik One";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 24px */
  text-transform: uppercase;
  padding-top: 2.5rem;

}

.ProgramasIniciativasValores .bg-iniciativas-ninios {
  background-image: url('../../../../../assets/images/programas/subsections/valores/iniciativas-ninios-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  width: 100%;
  height: 285px;
}

.ProgramasIniciativasValores .paragraph-iniciativas-valores1 {
  color: #1F282C;
  text-align: center;
  font-family: 'Ruda Bold';
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 20px */
}

.ProgramasIniciativasValores .paragraph-iniciativas-valores1 b {
  color: #216C95;
  font-family: "Rubik One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.ProgramasIniciativasValores .bg-iniciativa-valores1 {
  background-image: url('../../../../../assets/images/programas/subsections/valores/bg-iniciativa1.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 255px;
  
}

.ProgramasIniciativasValores .paragraph-iniciativas-valores2 {
  color: #1F282C;
  text-align: center;
  font-family: Ruda;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 160%; /* 22.4px */
}

.ProgramasIniciativasValores .paragraph-iniciativas-valores2 b {
  color: #216C95;
  font-family: "Rubik One";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.ProgramasIniciativasValores .paragraph-iniciativas-valores2 span {
  color: #216C95;
  font-family: "Rubik One";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.ProgramasIniciativasValores .bg-iniciativa-valores2 {
  background-image: url('../../../../../assets/images/programas/subsections/valores/bg-iniciativa2.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 267px;
}

.ProgramasIniciativasValores .paragraph-iniciativas-valores3 {
  color: #1F282C;
  text-align: center;
  font-family: Ruda;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
}

.ProgramasIniciativasValores .paragraph-iniciativas-valores3 b {
  color: #1F282C;
  font-family: 'Ruda Bold';
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 160%;
}

  @media (min-width: 768px) {
    /* .bg-rectangle-iniciativas {
      background-image: url('../../../../../assets/images/programas/subsections/valores/rectangle-iniciativas-tablet.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: auto;
    } */

    .ProgramasIniciativasValores .paragraph-iniciativas-container {
        padding-top: 3rem;
        padding-left: 3.5rem;
        padding-right: 3.5rem;
        padding-bottom: 3rem;
    }
  
    .ProgramasIniciativasValores .paragraph-iniciativa {
        color: #1F282C;
        text-align: center;
        font-family: Ruda;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 41.6px */
    }

    .ProgramasIniciativasValores .paragraph-iniciativa b {
        color: #1F282C;
        font-family: 'Ruda Bold';
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 160%;
    }

    .ProgramasIniciativasValores .title-iniciativas {
        color: #69747C;
        text-align: center;
        font-family: "Rubik One";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 74.4px */
        text-transform: uppercase;
    }
  
    .ProgramasIniciativasValores .bg-iniciativas-ninios {
        background-image: url('../../../../../assets/images/programas/subsections/valores/iniciativas-ninios-tablet.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 415px;
    }

    .ProgramasIniciativasValores .paragraph-iniciativas-valores1 {
        color: #1F282C;
        font-family: 'Ruda Bold';
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 150% */
        text-align: center;
    }

    .ProgramasIniciativasValores .paragraph-iniciativas-valores1 b {
        color: #216C95;
        font-family: "Rubik One";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
    }
  
    .ProgramasIniciativasValores .bg-iniciativa-valores1 {
        background-image: url('../../../../../assets/images/programas/subsections/valores/bg-iniciativa1-web.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position:center;
        width: 100%;
        height: 615px;
    }

    .ProgramasIniciativasValores .paragraph-iniciativas-valores2 {
        color: #1F282C;
        text-align: center;
        font-family: Ruda;
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        line-height: 42px; /* 150% */
    }

    .ProgramasIniciativasValores .paragraph-iniciativas-valores2 b {
        color: #216C95;
        font-family: "Rubik One";
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px;
    }

    .ProgramasIniciativasValores .paragraph-iniciativas-valores2 span {
        color: #216C95;
        font-family: "Rubik One";
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px;
    }
  
    .ProgramasIniciativasValores .bg-iniciativa-valores2 {
        background-image: url('../../../../../assets/images/programas/subsections/valores/bg-iniciativa2-web.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position:center;
        width: 100%;
        height: 597px;
    }

    .ProgramasIniciativasValores .paragraph-iniciativas-valores3 {
        color: #1F282C;
        text-align: center;
        font-family: Ruda;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 41.6px */
    }

    .ProgramasIniciativasValores .paragraph-iniciativas-valores3 b {
        color: #1F282C;
        font-family: Ruda;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
    }
}


@media (min-width: 1024px) {
  .ProgramasIniciativasValores .mobile-device {
      display: none;
  }

  .ProgramasIniciativasValores .web-device {
      display: block;
  }

  /* .bg-rectangle-iniciativas {
    background-image: url('../../../../../assets/images/programas/subsections/valores/rectangle-iniciativas.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
  } */

  .ProgramasIniciativasValores .paragraph-iniciativas-container {
      padding-top: 2rem;
      padding-left: 5rem;
      padding-right: 5rem;
      padding-bottom: 2rem;
  }

  .ProgramasIniciativasValores .paragraph-iniciativa {
      color: #1F282C;
      text-align: center;
      font-family: Ruda;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px; /* 131.429% */
  }

  .ProgramasIniciativasValores .paragraph-iniciativa b {
      color: #1F282C;
      font-family: 'Ruda Bold';
      font-size: 25px;
      font-style: normal;
      font-weight: 800;
      line-height: 36px;
  }

  .ProgramasIniciativasValores .title-iniciativas {
      color: #69747C;
      text-align: center;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 60px */
      text-transform: uppercase;
      padding-top: 4.5rem;
  }

  .ProgramasIniciativasValores .bg-iniciativas-ninios {
      background-image: url('../../../../../assets/images/programas/subsections/valores/iniciativas-ninios.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 669px;
  }

  .ProgramasIniciativasValores .paragraph-iniciativas-valores1 {
      color: #1F282C;
      font-family: Ruda;
      font-size: 25px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
      text-align: left;
  }

  .ProgramasIniciativasValores .paragraph-iniciativas-valores1 b {
      color: #216C95;
      font-family: "Rubik One";
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
  }

  .ProgramasIniciativasValores .bg-iniciativa-valores1 {
      background-image: url('../../../../../assets/images/programas/subsections/valores/bg-iniciativa1-web.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 420px;
      border: 0px;
  }

  .ProgramasIniciativasValores .paragraph-iniciativas-valores2 {
      color: #1F282C;
      font-family: Ruda;
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 35px; /* 164.286% */
      text-align: left;
  }

  .ProgramasIniciativasValores .paragraph-iniciativas-valores2 b {
      color: #216C95;
      font-family: "Rubik One";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 35px;
  }

  .ProgramasIniciativasValores .paragraph-iniciativas-valores2 span {
      color: #216C95;
      font-family: "Rubik One";
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: 46px;  
  }

  .ProgramasIniciativasValores .bg-iniciativa-valores2 {
      background-image: url('../../../../../assets/images/programas/subsections/valores/bg-iniciativa2-web.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 420px;
  }

  .ProgramasIniciativasValores .paragraph-iniciativas-valores3 {
      color: #1F282C;
      text-align: center;
      font-family: Ruda;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 47.5px */
  }

  .ProgramasIniciativasValores .paragraph-iniciativas-valores3 b {
      color: #1F282C;
      font-family: Ruda;
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 125%;
  }
}


@media (min-width: 1366px) {
  .ProgramasIniciativasValores .mobile-device {
    display: none;
  }

  .ProgramasIniciativasValores .web-device {
    display: block;
  }

  /* .ProgramasIniciativasValores .bg-rectangle-iniciativas {
    background-image: url('../../../../../assets/images/programas/subsections/valores/rectangle-iniciativas.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
  } */

  .ProgramasIniciativasValores .paragraph-iniciativas-container {
    padding-top: 5rem;
    padding-left: 10rem;
    padding-right: 10rem;
    padding-bottom: 5rem;
  }

  .ProgramasIniciativasValores .paragraph-iniciativa {
    color: #1F282C;
    text-align: center;
    font-family: Ruda;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px; /* 131.429% */
  }
    .ProgramasIniciativasValores .paragraph-iniciativa b {
      color: #1F282C;
      font-family: 'Ruda Bold';
      font-size: 25px;
      font-style: normal;
      font-weight: 800;
      line-height: 46px;
    }


  .ProgramasIniciativasValores .title-iniciativas {
    color: #69747C;
    text-align: center;
    font-family: "Rubik One";
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 60px */
    text-transform: uppercase;
  }

  .ProgramasIniciativasValores .bg-iniciativas-ninios {
    background-image: url('../../../../../assets/images/programas/subsections/valores/iniciativas-ninios.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 669px;
  }

  .ProgramasIniciativasValores .paragraph-iniciativas-valores1 {
    color: #1F282C;
    font-family: 'Ruda Bold';
    font-size: 25px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    text-align: left;
  }
    .ProgramasIniciativasValores .paragraph-iniciativas-valores1 b {
      color: #216C95;
      font-family: "Rubik One";
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }


  .ProgramasIniciativasValores .bg-iniciativa-valores1 {
    background-image: url('../../../../../assets/images/programas/subsections/valores/bg-iniciativa1-web.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 814px;
    border: 0px;
  }

  .ProgramasIniciativasValores .paragraph-iniciativas-valores2 {
    color: #1F282C;
    font-family: Ruda;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px; /* 164.286% */
    text-align: left;
  }
    .ProgramasIniciativasValores .paragraph-iniciativas-valores2 b {
      color: #216C95;
      font-family: "Rubik One";
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
    }

    .ProgramasIniciativasValores .paragraph-iniciativas-valores2 span {
      color: #216C95;
      font-family: "Rubik One";
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;  
    }


  .ProgramasIniciativasValores .bg-iniciativa-valores2 {
    background-image: url('../../../../../assets/images/programas/subsections/valores/bg-iniciativa2-web.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 597px;
  }

  .ProgramasIniciativasValores .paragraph-iniciativas-valores3 {
    color: #1F282C;
    text-align: center;
    font-family: Ruda;
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 47.5px */
  }
    .ProgramasIniciativasValores .paragraph-iniciativas-valores3 b {
      color: #1F282C;
      font-family: Ruda;
      font-size: 38px;
      font-style: normal;
      font-weight: 800;
      line-height: 125%;
    }
  

}


@media (min-width: 1440px) {
  .ProgramasIniciativasValores .mobile-device {
    display: none;
  }

  .ProgramasIniciativasValores .web-device {
    display: block;
  }

  /* .ProgramasIniciativasValores .bg-rectangle-iniciativas {
    background-image: url('../../../../../assets/images/programas/subsections/valores/rectangle-iniciativas.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
  } */

  .ProgramasIniciativasValores .paragraph-iniciativas-container {
    padding-top: 5rem;
    padding-left: 10rem;
    padding-right: 10rem;
    padding-bottom: 5rem;
  }

  .ProgramasIniciativasValores .paragraph-iniciativa {
    color: #1F282C;
    text-align: center;
    font-family: Ruda;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px; /* 131.429% */

    b {
      color: #1F282C;
      font-family: 'Ruda Bold';
      font-size: 25px;
      font-style: normal;
      font-weight: 800;
      line-height: 46px;
    }
  }

  .ProgramasIniciativasValores .title-iniciativas {
    color: #69747C;
    text-align: center;
    font-family: "Rubik One";
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 60px */
    text-transform: uppercase;
  }

  .ProgramasIniciativasValores .bg-iniciativas-ninios {
    background-image: url('../../../../../assets/images/programas/subsections/valores/iniciativas-ninios.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 669px;
  }

  .ProgramasIniciativasValores .paragraph-iniciativas-valores1 {
    color: #1F282C;
    font-family: 'Ruda Bold';
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    text-align: left;
}
    .ProgramasIniciativasValores .paragraph-iniciativas-valores1 b {
      color: #216C95;
      font-family: "Rubik One";
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  

  .ProgramasIniciativasValores .bg-iniciativa-valores1 {
    background-image: url('../../../../../assets/images/programas/subsections/valores/bg-iniciativa1-web.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 814px;
    border: 0px;
  }

  .ProgramasIniciativasValores .paragraph-iniciativas-valores2 {
    color: #1F282C;
    font-family: Ruda;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px; /* 164.286% */
    text-align: left;

    b {
      color: #216C95;
      font-family: "Rubik One";
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
    }

    span {
      color: #216C95;
      font-family: "Rubik One";
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;  
    }
  }

  .ProgramasIniciativasValores .bg-iniciativa-valores2 {
    background-image: url('../../../../../assets/images/programas/subsections/valores/bg-iniciativa2-web.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 597px;
  }

  .ProgramasIniciativasValores .paragraph-iniciativas-valores3 {
    color: #1F282C;
    text-align: center;
    font-family: Ruda;
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 47.5px */

    b {
      color: #1F282C;
      font-family: Ruda;
      font-size: 38px;
      font-style: normal;
      font-weight: 800;
      line-height: 125%;
    }
  }

}


@media (min-width: 1920px) {
  .ProgramasIniciativasValores .mobile-device {
    display: none;
  }

  .ProgramasIniciativasValores .web-device {
    display: block;
  }

  /* .ProgramasIniciativasValores .bg-rectangle-iniciativas {
    background-image: url('../../../../../assets/images/programas/subsections/valores/rectangle-iniciativas.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
  } */

  .ProgramasIniciativasValores .paragraph-iniciativas-container {
    padding-top: 5rem;
    padding-left: 10rem;
    padding-right: 10rem;
    padding-bottom: 5rem;
  }

  .ProgramasIniciativasValores .paragraph-iniciativa {
    color: #1F282C;
    text-align: center;
    font-family: Ruda;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px; /* 131.429% */
  }

  .ProgramasIniciativasValores .paragraph-iniciativa b {
    color: #1F282C;
    font-family: Ruda;
    font-size: 35px;
    font-style: normal;
    font-weight: 800;
    line-height: 46px;
  }

  .ProgramasIniciativasValores .title-iniciativas {
    color: #69747C;
    text-align: center;
    font-family: "Rubik One";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 60px */
    text-transform: uppercase;
  }

  .ProgramasIniciativasValores .bg-iniciativas-ninios {
    background-image: url('../../../../../assets/images/programas/subsections/valores/iniciativas-ninios.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 669px;
  }

  .ProgramasIniciativasValores .paragraph-iniciativas-valores1 {
    color: #1F282C;
    font-family: 'Ruda Bold';
    font-size: 42px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 164.286% */
    text-align: left;
  }

  .ProgramasIniciativasValores .paragraph-iniciativas-valores1 b {
    color: #216C95;
    font-family: "Rubik One";
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  .ProgramasIniciativasValores .bg-iniciativa-valores1 {
    background-image: url('../../../../../assets/images/programas/subsections/valores/bg-iniciativa1-web.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 814px;
    border: 0px;
  }

  .ProgramasIniciativasValores .paragraph-iniciativas-valores2 {
    color: #1F282C;
    font-family: Ruda;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 46px; /* 164.286% */
    text-align: left;
  }

  .ProgramasIniciativasValores .paragraph-iniciativas-valores2 b {
    color: #216C95;
    font-family: "Rubik One";
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px;
  }

  .ProgramasIniciativasValores .paragraph-iniciativas-valores2 span {
    color: #216C95;
    font-family: "Rubik One";
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px;  
  }

  .ProgramasIniciativasValores .bg-iniciativa-valores2 {
    background-image: url('../../../../../assets/images/programas/subsections/valores/bg-iniciativa2-web.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 597px;
  }

  .ProgramasIniciativasValores .paragraph-iniciativas-valores3 {
    color: #1F282C;
    text-align: center;
    font-family: Ruda;
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 47.5px */
  }

  .ProgramasIniciativasValores .paragraph-iniciativas-valores3 b {
    color: #1F282C;
    font-family: Ruda;
    font-size: 38px;
    font-style: normal;
    font-weight: 800;
    line-height: 125%;
  }

}
