.CardSlider {
  width: 100%;
  
  .web-device {
    display: none;
  }
  
  .item-img {
    width: 100%;
    height: auto;
  
  }
  
  .splide__slide {
    background: transparent;
  }
  
  .splide--nav {
    margin-top: 5vh;
  }
  
  .splide__pagination {
    position: relative !important;
    margin-top: 1rem !important;
  }
  
  .splide__pagination__page {
    background: #fff !important;
    border: 1px solid #55B0BC !important;
  }
  
  .splide__pagination__page.is-active {
    background: #55B0BC !important;
    transform: scale(1.4);
    z-index: 1;
  }
  
  .splide__arrows {
    display: block !important;
  }
  
  .arrows-text {
    color: #1F282C;
    font-family: 'Lato Bold';
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  
  .arrows-position {
    padding-top: -20px;
  }
  
  .arrows-width {
    width: 10px;
  }

  .splide__arrow {
    /* transform: translateY(-80%) !important; */
    top: 45%;
  }
  
  .splide__arrow--next {
    right: -2.5em;
    opacity: 0.5;
  }
  
  .splide__arrow--prev {
    left: -2.5em;
    opacity: 0.5;
  }
  
  .splide:not( .is-overflow ) .splide__list {
    justify-content: center;
  }
  
  .splide__slide img {
    width: 100%;
    height: 100%;
    object-fit: unset;
  }

  @media (min-width: 1440px) {
    .splide__arrow {
      width: 3em;
      height: 3em;
    }

    .splide__arrow--prev {
      left: -3em;
      opacity: 0.5;
    }

    .splide__arrow--next {
      right: -3em;
      opacity: 0.5;
    }
  }
}