.tecnochicas .bg-nar {
    background-color: var(--bc-color);
}

.tecnochicas .bg-nar-cl {
    background-color: #EFEFF4;
}

.tecnochicas .obj-tit {
    text-align: start;
}

.tecnochicas .obj-par {
    text-align: start;
    color: #FFF;
    font-family: Lato;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 25px;
        font-style: normal;
        font-weight: bolder;
        line-height: normal;
    }
}

.tecnochicas .com-dig {
    color: #8780BD;
    font-family: "Rubik One";
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 72px */
    text-transform: uppercase;
    text-align: start;
}

.tecnochicas .com-dig-par {
    color: #000;
    font-family: 'Ruda Bold';
    font-size: 34px;
    font-style: normal;
    font-weight: bolder;
    line-height: 45px;
    text-align: start;
}

.tecnochicas .com-dig-par .highlight {
    color: #7970C1;
    font-family: "Rubik One";
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px; /* 132.353% */
}

.tecnochicas .com-h {
    min-height: 250px;
}

.tecnochicas .bg-gray-comunidades {
    background: #EFEFF4;
}

.tecnochicas .img-objetivos {
    width: 140px;
    margin: .25rem;
}

.tecnochicas .bullet {
    width: 10px;
    height: 10px;
    background: #C6C0EE;
    margin-top: 10px;
}

@media screen and (max-width:1366px) {
    .tecnochicas .com-dig {
        color: var(--bc-color);
        font-family: "Rubik One";
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 86.4px */
        text-transform: uppercase;
        text-align: left;
        padding: 1.5rem 0;
    }
    
    .tecnochicas .com-dig-par {
        color: #1F282C;
        font-family: 'Ruda Bold';
        font-size: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: 32px;
        text-align: left;
    }
    
    .tecnochicas .com-dig-par .highlight {
        color: var(--bc-color);
        font-family: "Rubik One";
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 118.421% */
    }
    
    .tecnochicas .obj-tit {
        color: #FFF;
        font-family: Lato;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }

    .tecnochicas .bg-gray-comunidades {
        background: #EFEFF4;
    }

    .tecnochicas .obj-par {
        text-align: start;
        color: #FFF;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    
        b {
            color: #FFF;
            font-family: 'Lato Bold';
            font-size: 20px;
            font-style: normal;
            font-weight: bolder;
            line-height: normal;
        }
    }

    .tecnochicas .img-objetivos {
        width: 100px;
        margin: .25rem;
    }
}

@media screen and (max-width:1024px) {
    .tecnochicas .com-dig {
        color: var(--bc-color);
        font-family: "Rubik One";
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 86.4px */
        text-transform: uppercase;
        text-align: center;
        padding: 1.5rem 0;
    }

    .tecnochicas .obj-tit {
        color: #FFF;
        font-family: Lato;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }
    
    .tecnochicas .com-dig-par {
        color: #1F282C;
        font-family: 'Ruda Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 130%;
        text-align: left;
    }
    
    .tecnochicas .com-dig-par .highlight {
        color: var(--bc-color);
        font-family: "Rubik One";
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 118.421% */
    }  
    
    .tecnochicas .bg-gray-comunidades {
        background: #EFEFF4;
    }

    .tecnochicas .obj-par {
        text-align: start;
        color: #FFF;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    
        b {
            color: #FFF;
            font-family: 'Lato Bold';
            font-size: 14px;
            font-style: normal;
            font-weight: bolder;
            line-height: normal;
        }
    }

    .tecnochicas .img-objetivos {
        width: 90px;
        margin: .25rem;
    }
}

@media screen and (max-width:768px) {
    .tecnochicas .com-dig {
        color: var(--bc-color);
        font-family: "Rubik One";
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 86.4px */
        text-transform: uppercase;
        text-align: center;
        padding: 1.5rem 0;
    }
    
    .tecnochicas .com-dig-par {
        color: #1F282C;
        font-family: 'Ruda Bold';
        font-size: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: 130%;
        text-align: left;
    }
    
    .tecnochicas .com-dig-par .highlight {
        color: var(--bc-color);
        font-family: "Rubik One";
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 118.421% */
    }
    .tecnochicas .obj-tit {
        text-align: center;
        font-family: Lato;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        padding-top: 1rem;
      }
      .tecnochicas .obj-par {
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: start;

        b {
            color: #FFF;
            font-family: 'Lato Bold';
            font-size: 25px;
            font-style: normal;
            font-weight: bolder;
            line-height: normal;
        }
      }
      .tecnochicas .img-objetivos{
        width: 280px;
      } 
      
      .tecnochicas .bg-gray-comunidades {
        background: #EFEFF4;
    }

    .tecnochicas .img-objetivos {
        width: 250px;
        margin: .25rem;
    }
}

@media screen and (max-width:600px){
    .tecnochicas .com-dig {
        color: var(--bc-color);
        font-family: "Rubik One";
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 86.4px */
        text-transform: uppercase;
        text-align: center;
        padding: 1.5rem 0;
    }
    
    .tecnochicas .com-dig-par {
        color: #1F282C;
        font-family: 'Ruda Bold';
        font-size:16px;
        font-style: normal;
        font-weight: 800;
        line-height: 32px;
        text-align: left;
    }
    
    .tecnochicas .com-dig-par .highlight {
        color: var(--bc-color);
        font-family: "Rubik One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 118.421% */
    }    

      .tecnochicas .obj-tit {
        text-align: center;
        font-family: Lato;
        font-size: 25px;
        padding: 1rem 0;
      } 
      
      .tecnochicas .bg-gray-comunidades {
        background: #EFEFF4;
    }

    .tecnochicas .img-objetivos {
        width: 140px;
        margin: .25rem;
    }

    .tecnochicas .obj-par {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: start;

        b {
            color: #FFF;
            font-family: 'Lato Bold';
            font-size: 16px;
            font-style: normal;
            font-weight: bolder;
            line-height: normal;
        }
      }
}