.tecnochicas .bg-suave {
    background: linear-gradient(180deg, rgba(126, 119, 181, 0.80) -50.88%, rgba(255, 255, 255, 0.00) 55.55%);
    /* padding: 1.5rem 3rem; */
}

.tecnochicas .tch-title {
    color: var(--bc-color);
    font-family: "Rubik One";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    text-align: start;
}

.tecnochicas .tch-par {
    color: #1F282C;
    font-family: Lato;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 38.64px */
    text-align: start;
}

.tecnochicas .tch-par .highlight {
    color: var(--bc-color);
    font-family: 'Lato Bold';
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 138%; /* 52.44px */
}

.tecnochicas .tch-img {
    width: 100%;
}

.tecnochicas .tch-quote {
    color: #FFF;
    font-family: Lato;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 43.75px */
    text-align: center;
}

.tecnochicas .mh-250 {
    min-height: 200px;
    padding: 3rem;
}

.tecnochicas .tch-par .bold,
.tecnochicas .tch-quote .bold {
    font-family: 'Lato Bold';
    font-weight: bolder;
}

.tecnochicas .bg-tch {
    background-color: var(--bc-color);
}

.tecnochicas .bg-tch-gris {
    background-color: var(--bc-gris);
}

.tecnochicas .main-title-programas {
    color: var(--bc-color);
}

.tecnochicas .main-paragraph-programas {
    .highlight {
        color: var(--bc-color);
    }
  }

@media screen and (max-width:1366px){
    .tecnochicas .bg-suave {
        padding: 0;
    }
    
    .tecnochicas .tch-title {
        color: var(--bc-color);
        font-family: "Rubik One";
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-transform: uppercase;
        text-align: left;
    }
    
    .tecnochicas .tch-par {
        color: #1F282C;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
    }
    
    .tecnochicas .tch-par .highlight {
        color: var(--bc-color);
        font-family: 'Lato Bold';
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 30px;
        /* line-height: 52.44px; */
    }
    
    .tecnochicas .tch-quote {
        color: #FFF;
        font-family: Lato;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 43.75px */
        text-align: center;
    }
    
    .tecnochicas .mh-250 {
        min-height: 200px;
        padding: 2rem;
    }   
    
    .tecnochicas .tch-img {
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width:1024px){
    .tecnochicas .bg-suave {
        padding: 0rem;
    }
    
    .tecnochicas .tch-title {
        color: var(--bc-color);
        font-family: "Rubik One";
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-transform: uppercase;
        text-align: left;
    }
    
    .tecnochicas .tch-par {
        color: #1F282C;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
    }
    
    .tecnochicas .tch-par .highlight {
        color: var(--bc-color);
        font-family: 'Lato Bold';
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 30px;
        /* line-height: 52.44px; */
    }
    
    .tecnochicas .tch-quote {
        color: #FFF;
        font-family: Lato;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 43.75px */
        text-align: center;
    }
    
    .tecnochicas .mh-250 {
        min-height: 200px;
        padding: 2rem;
    }   
    
    .tecnochicas .tch-img {
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width:900px){
    .tecnochicas .bg-suave {
        padding: 1.5rem;
    }
    
    .tecnochicas .tch-title {
        color: var(--bc-color);
        font-family: "Rubik One";
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-transform: uppercase;
        text-align: center;
    }
    
    .tecnochicas .tch-par {
        color: #1F282C;
        font-family: Lato;
        font-size:25px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
        text-align: center;
    }
    
    .tecnochicas .tch-par .highlight {
        color: var(--bc-color);
        font-family: 'Lato Bold';
        font-size:25px;
        font-style: normal;
        font-weight: 800;
        /* line-height: 52.44px; */
    }
    
    .tecnochicas .tch-quote {
        color: #FFF;
        font-family: Lato;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 43.75px */
        text-align: center;
    }
    
    .tecnochicas .mh-250 {
        min-height: 200px;
        padding: 2rem;
    }    
}

@media screen and (max-width:600px){
    .tecnochicas .bg-suave {
        padding: 0rem;
    }
    
    .tecnochicas .tch-title {
        color: var(--bc-color);
        font-family: "Rubik One";
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-transform: uppercase;
        text-align: center;
    }
    
    .tecnochicas .tch-par {
        color: #1F282C;
        font-family: Lato;
        font-size:16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
    }
    
    .tecnochicas .tch-par .highlight {
        color: var(--bc-color);
        font-family: 'Lato Bold';
        font-size:16px;
        font-style: normal;
        font-weight: 800;
        /* line-height: 52.44px; */
    }
    
    .tecnochicas .tch-quote {
        color: #FFF;
        font-family: Lato;
        font-size: 19px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%; /* 43.75px */
        text-align: center;
    }
    
    .tecnochicas .mh-250 {
        min-height: 100px;
        padding: 2rem;
    }    
}