.Carousel {
  width: 100%;
}

.Carousel .mobile-device {
  display: block;
}

.Carousel .tablet-device {
  display: none;
}

.Carousel .web-device {
  display: none;
}

.Carousel .item-img {
  width: 100%;
  height: auto;

}

.Carousel .splide__slide {
  background: transparent;
}

.Carousel .splide--nav {
  margin-top: 0vh;
}

.Carousel .splide__pagination {
  position: relative !important;
  margin-top: 1rem !important;
}

.Carousel .splide__pagination__page {
  background: #fff !important;
  border: 1px solid #000000 !important;
}

.Carousel .splide__pagination__page.is-active {
  background: #000000 !important;
  transform: scale(1.4);
  z-index: 1;
}

.Carousel .splide__arrows {
  display: none !important;
}

.Carousel .thumbnails-section .splide__arrows {
  display: block !important;
}

/* .Carousel .arrows-text {
  color: #000;
  text-align: center;
  font-family: 'Lato Bold';
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%;
  text-transform: uppercase;

  b {
    color: #00B9F1;
    font-family: 'Lato Bold';
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%;
    text-transform: uppercase;
  }
} */

.Carousel .arrows-position {
  padding-top: -20px !important;
}

.Carousel .arrows-width {
  width: 10px;
}

.Carousel .splide__arrow--next {
  right: 0em !important;
}

.Carousel .splide__arrow--prev {
  left: 0em !important;
}

.splide:not( .is-overflow ) .splide__list {
  justify-content: center;
}

.Carousel .splide__slide img {
  width: 100%;
  height: 100%;
  object-fit: unset;
}

.Carousel .splide__arrow {
  top: 140% !important;
}

.Carousel {
  @media (min-width: 768px) {
    .mobile-device {
      display: none;
    }

    .tablet-device {
      display: block;
    }
  
    .web-device {
      display: none;
    }

    .splide--nav {
      margin-top: 0vh;
    }
  
    .splide__pagination {
      position: relative !important;
      margin-top: 1rem !important;
    }
  
    .splide__pagination__page {
      background: #fff !important;
      border: 1px solid #000000 !important;
    }
  
    .splide__pagination__page.is-active  {
      background: #000000 !important;
      transform: scale(1.4);
      z-index: 1;
    }
  
    .splide__arrows {
      display: none !important;
    }

    .thumbnails-section {
      .splide__arrows {
        display: block !important;
      }
    }

    .splide__arrow--next {
      right: -3em !important;
    }

    .splide__arrow--prev {
      left: -3em !important;
    }

    .splide:not( .is-overflow ) .splide__list {
      justify-content: center;
    }

    .splide__slide img {
      width: 100%;
      height: 100%;
      object-fit: unset;
    }

    .splide--nav {
      margin-top: 1vh;
    }

    .splide__arrow {
      top: 55% !important;
    }
  }

  @media (min-width: 1024px) {
    .mobile-device {
      display: none;
    }

    .tablet-device {
      display: none;
    }
  
    .web-device {
      display: block;
    }

    .splide--nav {
      margin-top: 0vh;
    }
  
    .splide__pagination {
      position: relative !important;
      margin-top: 1rem !important;
    }
  
    .splide__pagination__page {
      background: #fff !important;
      border: 1px solid #000000 !important;
    }
  
    .splide__pagination__page.is-active  {
      background: #000000 !important;
      transform: scale(1.4);
      z-index: 1;
    }
  
    .splide__arrows {
      display: none !important;
    }

    .thumbnails-section {
      .splide__arrows {
        display: block !important;
      }
    }

    /* .arrows-text {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%;
      text-transform: uppercase;

      b {
        color: #00B9F1;
        font-family: 'Lato Bold';
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 100%;
        text-transform: uppercase;
      }
    } */

    .back {
      padding-left: 3rem;
      cursor: pointer;
    }

    .next {
      padding-right: 3rem;
      cursor: pointer;
    }

    .arrows-position {
      margin-top: 0.5rem;
    }

    .arrows-width {
      width: 100%;
    }
    .splide__slide img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .splide__track--nav>.splide__list>.splide__slide.is-active{
      border: 2px solid #00B9F1;
    }

    .splide--nav {
      margin-top: 1vh;
    }

  }

  @media (min-width: 1366px) {
    .mobile-device {
      display: none;
    }

    .tablet-device {
      display: none;
    }
  
    .web-device {
      display: block;
    }

    .splide--nav {
      margin-top: 0vh;
    }
  
    .splide__pagination {
      position: relative !important;
      margin-top: 1rem !important;
    }
  
    .splide__pagination__page {
      background: #fff !important;
      border: 1px solid #000000 !important;
    }
  
    .splide__pagination__page.is-active  {
      background: #000000 !important;
      transform: scale(1.4);
      z-index: 1;
    }
  
    .splide__arrows {
      display: none !important;
    }

    .thumbnails-section {
      .splide__arrows {
        display: block !important;
      }
    }

    /* .arrows-text {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%;
      text-transform: uppercase;

      b {
        color: #00B9F1;
        font-family: 'Lato Bold';
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 100%;
        text-transform: uppercase;
      }
    } */

    .back {
      padding-left: 3rem;
      cursor: pointer;
    }

    .next {
      padding-right: 3rem;
      cursor: pointer;
    }

    .arrows-position {
      margin-top: 0.5rem;
    }

    .arrows-width {
      width: 100%;
    }
    .splide__slide img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .splide__track--nav>.splide__list>.splide__slide.is-active{
      border: 2px solid #00B9F1;
    }

    .splide--nav {
      margin-top: 1vh;
    }

  }

  @media (min-width: 1920px) {
    .mobile-device {
      display: none;
    }

    .tablet-device {
      display: none;
    }
  
    .web-device {
      display: block;
    }

    .splide--nav {
      margin-top: 0vh;
    }
  
    .splide__pagination {
      position: relative !important;
      margin-top: 1rem !important;
    }
  
    .splide__pagination__page {
      background: #fff !important;
      border: 1px solid #000000 !important;
    }
  
    .splide__pagination__page.is-active  {
      background: #000000 !important;
      transform: scale(1.4);
      z-index: 1;
    }
  
    .splide__arrows {
      display: none !important;
    }

    .thumbnails-section {
      .splide__arrows {
        display: block !important;
      }
    }

    /* .arrows-text {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%;
      text-transform: uppercase;

      b {
        color: #00B9F1;
        font-family: 'Lato Bold';
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 100%;
        text-transform: uppercase;
      }
    } */

    .back {
      padding-left: 3rem;
      cursor: pointer;
    }

    .next {
      padding-right: 3rem;
      cursor: pointer;
    }

    .arrows-position {
      margin-top: 0.5rem;
    }

    .arrows-width {
      width: 100%;
    }
    .splide__slide img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .splide__track--nav>.splide__list>.splide__slide.is-active{
      border: 2px solid #00B9F1;
    }

    .splide--nav {
      margin-top: 3vh;
    }

  }
}