.CardGral {
  width: 100%;
  padding-right: 5%;
  padding-left: 5%;
  padding-top: 1rem;
  padding-bottom: 1rem;

}
  .CardGral img {
    max-width: 100%;
    height: auto;
    border: 10px solid #69747C;
  }

  .CardGral .title-card {
    
    font-family: "Rubik One";
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.7rem;
    text-transform: uppercase;
    text-align: left;
    
  }

  .CardGral .description {
    color: #1F282C;
    font-family: Lato;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    text-align: left;
  }
  .CardGral .description b{
    font-family: "Lato Bold";
    font-weight: 800;
    font-size: inherit;
  }
  .CardGral .play-overlay{
    position: absolute;
    left: 50%;
    top: 50%;
    cursor: pointer;
    z-index: 20;
    -webkit-transform:translate(-50%,-50%);
    transform:translate(-50%,-50%);
    width: 30px;
    border: unset;
  }
  .CardGral .iframe-h{
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .CardGral .iframe-s{
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  @media (min-width: 768px) {
    .CardGral {
    padding-left: 7%;
    padding-right: 7%;
    padding-top: unset;
    padding-bottom: unset;
  }
    .CardGral img {
      max-width: 100%;
      height: auto;
      border: 10px solid #69747C;
    }
  
    .CardGral .title-card {
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      text-align: left;
      margin-top: 1rem;
    }
  
    .CardGral .description {
      color: #1F282C;
      font-family: Lato;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      text-align: left;
    }
  }

  @media (min-width: 1024px) {
    .CardGral .play-overlay{
      width: 50px;
    }

    .CardGral {
    padding-left: 0px;
    padding-right: 0px;
  }
    .CardGral img {
      max-width: 100%;
      height: auto;
      border: 10px solid #69747C;
    }
  
    .CardGral .title-card {
      font-size: 34px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      text-align: left;
      margin-top: 1rem;
    }
  
    .CardGral .description {
      color: #1F282C;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      text-align: left;
    }
  }

  @media (min-width: 1366px) {
    .CardGral .play-overlay{
      width: 50px;
    }

    .CardGral {
    padding-left: 0px;
    padding-right: 0px;
  }
    .CardGral img {
      max-width: 100%;
      height: auto;
      border: 10px solid #69747C;
    }
  
    .CardGral .title-card {
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      text-align: left;
      margin-top: 1rem;
    }
  
    .CardGral .description {
      color: #1F282C;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      text-align: left;
    }
  }

  @media (min-width: 1440px) {
    .CardGral {
    padding-left: 0px;
    padding-right: 0px;
  }
    .CardGral img {
      max-width: 100%;
      height: auto;
      border: 10px solid #69747C;
    }
  
    .CardGral .title-card {
      
      font-family: "Rubik One";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      text-align: left;
      margin-top: 1rem;
    }
  
    .CardGral .description {
      color: #1F282C;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      text-align: left;

      .CardGral b {
        color: #1F282C;
        font-family: 'Lato Bold';
        font-size: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: 130%; /* 45.6px */
      }
    }
    .CardGral .description .bold{
      font-family: 'Lato Bold';
      font-weight: 800;
      font-size: inherit;
    }
  }