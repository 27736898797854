:root{
    --az-bec:#00B9F1
}
.becalos .title {
    color: var(--az-bec);
    font-family: "Rubik One";
    font-size: 3.4375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    text-align: start;
    padding: 1rem 0;
  }
  
  .becalos .sub-title {
    color: #1F282C;
    text-align: center;
    font-family: 'Ruda Bold';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-align: start;
    padding-bottom: 2rem;
  }
  
  .becalos .tit-par {
    color: #1F282C;
    text-align: center;
    font-family: Ruda;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
    padding-bottom: 1rem;
  }
  
  .becalos .par {
    color: #1F282C;
    font-family: Lato;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 138%;
    text-align: start;
  }
  
  .becalos .title-quote {
    color: #FFF;
    font-family: Lato;
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    text-align: center;
    padding: 0 10%;
  }
  
  .becalos .title-quote .bold,
  .becalos .par .bold {
    font-family: 'Lato Bold';
    font-weight: 900;
  }
  
  .becalos .bec-pres-par {
    color: #1F282C;
    font-family: "Ruda Bold";
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 800;
    line-height: 3.4375rem; /* 157.143% */
    text-align: start;
    padding: 3rem;
  }
  .becalos .bec-pres-par .bold {
    color: #00B9F1;
    font-family: "Rubik One";
    font-size: inherit;
    font-style: normal;
    font-weight: 400;
    line-height: 138%;
  }
  .becalos .sub-title .bold,
  .becalos .tit-par .bold {
    font-family: 'Ruda Bold';
    font-weight: 900;
  }
  
  .becalos .bg-azbec {
    background-color: var(--az-bec);
  }
  
  .becalos .bg-gr-bec {
    background-color: #69747C;
  }
  
  .becalos .bg-grad {
    background-image: url('../../../../assets/images/programas/becalos/bg-gradient.png');
    min-width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .becalos .mh-250 {
    min-height: 200px;
  }

  .becalos .main-title-programas {
    color: #00B9F1;
  }
.becalos{

  @media screen and (max-width:1600px){
    .title{
        font-size: 3rem;
        line-height: normal;
        padding-bottom: 0;
    }
    .sub-title{
        text-align: start;
        font-size: 1.3rem;
        line-height: normal;
    }
    .par{
        text-align: start;
        font-size: 1.5rem;
        line-height: normal;
    }
    .title-quote{
        font-size: 2.1875rem;
        line-height: 125%; 
    }
    .tit-par{
        font-size: 1.5rem;
    }

    .bec-pres-par{
        font-size: 1.5rem;
        line-height: 2.5rem; /* 157.143% */
    }
    }

    @media screen and (max-width:1440px){
    .title{
        font-size: 3rem;
        line-height: normal;
        padding-bottom: 0;
    }
    .sub-title{
        text-align: start;
        font-size: 1.3rem;
        line-height: normal;
    }
    .par{
        text-align: start;
        font-size: 1.2rem;
        line-height: normal;
    }
    .title-quote{
        font-size: 2.1875rem;
        line-height: 125%; 
    }
    .tit-par{
        font-size: 1.5rem;
    }

    .bec-pres-par{
        font-size: 1.5rem;
        line-height: 2.5rem; /* 157.143% */
    }
    }
    @media screen and (max-width:1280px){
    .title{
        font-size: 3.4375rem;
        line-height: normal;
    }
    .sub-title{
        text-align: center;
        font-size: 1.5rem;
        line-height: normal;
    }
    .par{
        text-align: center;
        font-size: 1.75rem;
        line-height: normal;
    }
    .title-quote{
        font-size: 2.1875rem;
        line-height: 125%; 
    }

    .bec-pres-par{
        font-size: 2.1875rem;
        line-height: 3.4375rem; /* 157.143% */
    }
    }
}

@media screen and (max-width:1366px){
  .becalos .title {
      font-size: 2rem;
      line-height: normal;
      text-align: center;
    }
    
    .becalos .sub-title {
      text-align: left;
      font-size: 1.5rem;
      line-height: normal;
    }
    
    .becalos .par {
      text-align: left;
      font-size: 1rem;
      line-height: normal;
    }
    
    .becalos .title-quote {
      font-size: 30px;
      line-height: 125%;
      text-align: center;
    }
    
    .becalos .bec-pres-par {
      font-size: 1.1875rem;
      line-height: 1.4375rem; /* 157.143% */
    }

    .becalos .tit-par{
      font-size: 1.5rem;
      text-align: left;
  }
  .becalos .mh-250{
    min-height: 150px;
  }
}

@media screen and (max-width:1024px){
  .becalos .title {
      font-size: 2rem;
      line-height: normal;
      text-align: center;
    }
    
    .becalos .sub-title {
      text-align: center;
      font-size: 1rem;
      line-height: normal;
    }
    
    .becalos .par {
      text-align: center;
      font-size: 0.7rem;
      line-height: normal;
    }
    
    .becalos .title-quote {
      font-size: 1.5rem;
      line-height: 125%;
    }
    
    .becalos .bec-pres-par {
      font-size: 1.1875rem;
      line-height: 1.4375rem; /* 157.143% */
    }

    .becalos .tit-par{
      font-size: 1.5rem;
      text-align: center;
  }
    
}

    @media screen and (max-width:900px){
        .becalos .title {
            font-size: 3.4375rem;
            line-height: normal;
            text-align: center;
          }
          
          .becalos .sub-title {
            text-align: center;
            font-size: 1.5rem;
            line-height: normal;
          }
          
          .becalos .par {
            text-align: center;
            font-size: 1.75rem;
            line-height: normal;
          }
          
          .becalos .title-quote {
            font-size: 2.1875rem;
            line-height: 125%;
          }
          
          .becalos .bec-pres-par {
            font-size: 2.1875rem;
            line-height: 3.4375rem; /* 157.143% */
          }
          
}
    @media screen and (max-width:600px){
        .becalos .title {
            font-size: 2.125rem;
            line-height: normal;
            text-align: center;
            padding-top: 2.5rem;
          }
          .becalos .tit-par {
            text-align: center;
            font-size: 1rem;
            line-height: normal;
            padding: 1rem 0;
            font-weight: 400;
          }
          .becalos .sub-title {
            text-align: center;
            font-size: 1.2rem;
            line-height: normal;
            padding: 1rem 5%;
            font-weight: 400;
          }
          .becalos .par {
            text-align: center;
            font-size: 1rem;
            line-height: 1.5rem;
            padding: 0 5%;
          }
          .becalos .title-quote .bold,
          .becalos .par .bold {
            font-weight: 400;
          }
          .becalos .title-quote {
            font-size: 1.2rem;
            line-height: 145%;
            text-align: center;
            padding: 0 5%;
          }
          .becalos .bec-pres-par {
            font-size: 1rem;
            line-height: 1.5rem; /* 157.143% */
            text-align: center;
            padding: 3rem 1.75rem 0; 
          }
          .becalos .mh-250 {
            min-height: 125px;
          }

          .becalos .main-title-programas {
            padding-top: 2rem;
            color: #00B9F1;
            text-align: center;
          }
          
}
