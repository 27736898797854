/* Base styles for Impactamos class */
.Impactamos {
  width: 100%;
  background: #F8F8F8;
}

/* Styles for mobile devices within Impactamos */
.Impactamos .mobile-device {
  display: block;
}

/* Styles for web devices within Impactamos, hidden by default */
.Impactamos .web-device {
  display: none;
}

/* Styling for the first content section within Impactamos */
.Impactamos .first-content {
  padding-top: 32px;
  padding-left: 10%;
  text-align: center;
}

/* Base styles for title within Impactamos */
.Impactamos .title {
  color: #000;
  text-align: center;
  font-family: 'Ruda Bold';
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 114%; /* 54.72px */
}
.Impactamos .title b {
  color: #8780BD;
  font-family: "Rubik One";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 114%;  
}

/* Base styles for subtitle within Impactamos */
.Impactamos .subtitle {
  color: #FFF;
  font-family: 'Ruda Bold';
  font-size: 19px;
  font-style: normal;
  font-weight: 800;
  line-height: 114%; /* 54.72px */
}

.Impactamos .subtitle b {
  color: #FFF;
  font-family: "Rubik One";
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 114%;
}

/* Base styles for second-content within Impactamos */
.Impactamos .second-content {
  padding: 10px 30px;
  text-align: center;
}

/* Base styles for paragraph within Impactamos */
.Impactamos .paragraph {
  color: #000;
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.Impactamos .paragraph b {
  color: #000;
  font-family: 'Lato Bold';
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 18px;
}

/* Base styles for paragraph1 within Impactamos */
.Impactamos .paragraph1 {
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 22.5px */
}

.Impactamos .paragraph1 b {
  color: #FFF;
  font-family: 'Lato Bold';
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 125%;
}

/* Background color styles within Impactamos */
.Impactamos .bg1,
.Impactamos .bg2,
.Impactamos .bg-purple {
  background-color: #8780BD;
}

.Impactamos .bg3 {
  background-color: #69747C;
  background-image: url('../../../assets/images/comunicacion/gradient-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

/* Base styles for rectangle1 within Impactamos */
.Impactamos .rectangle1 {
  position: relative;
  height: auto;
  z-index: 10;
}

/* Base styles for third-content within Impactamos */
.Impactamos .third-content {
  margin-top: -55%;
  margin-bottom: 5rem;
  position: relative;
  z-index: 50;
}

/* Base styles for paragraph2 within Impactamos */
.Impactamos .paragraph2 {
  color: #FFF;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}

/* Base styles for bg-gray within Impactamos */
.Impactamos .bg-gray {
  background: #69747C;
}

/* Base styles for fourth-content within Impactamos */
.Impactamos .fourth-content {
  margin-top: 0%;
  position: relative;
  z-index: 50;
}

/* Base styles for paragraph3 within Impactamos */
.Impactamos .paragraph3 {
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 26.25px */
}

/* Base styles for text-big within Impactamos */
.Impactamos .text-big {
  color: #FFF;
  text-align: center;
  font-family: 'Lato Bold';
  font-size: 21px;
  font-style: normal;
  font-weight: 800;
  line-height: 125%; /* 26.25px */
}

/* Base styles for text-medium within Impactamos */
.Impactamos .text-medium {
  color: #FFF;
  font-family: Ruda;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
}

  @media (min-width: 768px) {
    /* Base styles for first-content within Impactamos */
    .Impactamos .first-content {
      padding-top: 32px;
      padding-left: 60px;
      text-align: center;
    }

    /* Base styles for title within Impactamos */
    .Impactamos .title {
      color: #000;
      text-align: center;
      font-family: 'Ruda Bold';
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 114%; /* 54.72px */
    }

    .Impactamos .title b {
      color: #8780BD;
      font-family: "Rubik One";
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 114%;  
    }

    /* Base styles for subtitle within Impactamos */
    .Impactamos .subtitle {
      color: #FFF;
      font-family: 'Ruda Bold';
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 114%; /* 54.72px */
    }

    .Impactamos .subtitle b {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 114%;
    }

    /* Base styles for second-content within Impactamos */
    .Impactamos .second-content {
      padding: 30px;
      text-align: center;
    }

    /* Base styles for paragraph within Impactamos */
    .Impactamos .paragraph {
      color: #000;
      text-align: center;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 142%; /* 34.08px */
    }

    .Impactamos .paragraph b {
      color: #000;
      font-family: 'Lato Bold';
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 142%;
    }

    /* Base styles for rectangle1 within Impactamos */
    .Impactamos .rectangle1 {
      position: relative;
      height: auto;
      z-index: 10;
    }

    /* Base styles for bg-purple within Impactamos */
    .Impactamos .bg-purple {
      background-color: #8780BD;
    }

    /* Base styles for third-content within Impactamos */
    .Impactamos .third-content {
      margin-top: -55%;
      margin-bottom: 5rem;
      position: relative;
      z-index: 50;
    }

    /* Base styles for paragraph2 within Impactamos */
    .Impactamos .paragraph2 {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 52.5px */
      margin-left: 50px;
    }

    /* Base styles for bg-gray within Impactamos */
    .Impactamos .bg-gray {
      background: #69747C;
    }
  }

/* Base styles within Impactamos */
.Impactamos .fourth-content {
  margin-top: 0%;
  position: relative;
  z-index: 50;
}

.Impactamos .paragraph1 {
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 52.5px */
}

.Impactamos .paragraph1 b {
  color: #FFF;
  font-family: 'Lato Bold';
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

.Impactamos .bg1,
.Impactamos .bg2 {
  background-color: #8780BD;
}

.Impactamos .bg3 {
  background-color: #69747C;
  background-image: url('../../../assets/images/comunicacion/gradient-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.Impactamos .paragraph3 {
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 52.5px */
}

.Impactamos .text-big {
  color: #FFF;
  font-family: "Rubik One";
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

.Impactamos .text-medium {
  color: #FFF;
  font-family: Lato;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

@media (min-width: 1024px) {
  /* Base styles for mobile-device within Impactamos */
  .Impactamos .mobile-device {
    display: none;
  }

  /* Base styles for web-device within Impactamos */
  .Impactamos .web-device {
    display: block;
  }

  /* Base styles for first-content within Impactamos */
  .Impactamos .first-content {
    padding-top: 59px;
    padding-left: 58px;
    text-align: left;
  }

  /* Base styles for title within Impactamos */
  .Impactamos .title {
    color: #000;
    text-align: center;
    font-family: 'Ruda Bold';
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 114%; /* 54.72px */
  }

  .Impactamos .title b {
    color: #8780BD;
    font-family: "Rubik One";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 114%;  
  }

  /* Base styles for subtitle within Impactamos */
  .Impactamos .subtitle {
    color: #FFF;
    font-family: 'Ruda Bold';
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: 114%; /* 54.72px */
    text-align: left;
  }

  .Impactamos .subtitle b {
    color: #FFF;
    font-family: "Rubik One";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 114%;
  }
}

/* Base styles for second-content within Impactamos */
.Impactamos .second-content {
  padding-top: 59px;
  text-align: left;
}

/* Base styles for paragraph within Impactamos */
.Impactamos .paragraph {
  color: #000000;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 183.333% */
  text-align: left;
}

.Impactamos .paragraph b {
  font-family: 'Lato Bold';
  font-weight: bolder;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 183.333% */
}

/* Base styles for bg-gray within Impactamos */
.Impactamos .bg-gray {
  background: #69747C;
}

/* Base styles for third-content within Impactamos */
.Impactamos .third-content {
  margin-top: -19rem;
  margin-bottom: 5rem;
  position: relative;
  z-index: 50;
}

/* Base styles for paragraph2 within Impactamos */
.Impactamos .paragraph2 {
  color: #FFF;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 114.286% */
  text-align: left;
}

/* Base styles for fourth-content within Impactamos */
.Impactamos .fourth-content {
  margin-top: 0rem;
  position: relative;
  z-index: 50;
}

/* Base styles for paragraph1 within Impactamos */
.Impactamos .paragraph1 {
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 52.5px */
}

.Impactamos .paragraph1 b {
  color: #FFF;
  font-family: 'Lato Bold';
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

/* Base styles for bg1 and bg2 within Impactamos */
.Impactamos .bg1,
.Impactamos .bg2 {
  background-color: #8780BD;
}

/* Base styles for bg3 within Impactamos */
.Impactamos .bg3 {
  background-color: #69747C;
  background-image: url('../../../assets/images/comunicacion/gradient-web.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

/* Base styles for paragraph3 within Impactamos */
.Impactamos .paragraph3 {
  color: #FFF;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}

/* Base styles for rectangle1 within Impactamos */
.Impactamos .rectangle1 {
  position: relative;
  width: 100%;
  height: auto;
  margin-left: -15rem;
  z-index: 10;
}

/* Base styles for rectangle2 within Impactamos */
.Impactamos .rectangle2 {
  position: relative;
  width: 50%;
  height: auto;
  margin-left: -2rem;
  z-index: 10;
}

/* Base styles for text-big within Impactamos */
.Impactamos .text-big {
  color: #FFF;
  font-family: "Rubik One";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 72.727% */
}

/* Base styles for text-medium within Impactamos */
.Impactamos .text-medium {
  color: #FFF;
  font-family: Ruda;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
}

@media (min-width: 1280px) {
  /* Base styles for mobile-device within Impactamos */
  .Impactamos .mobile-device {
    display: none;
  }

  /* Base styles for web-device within Impactamos */
  .Impactamos .web-device {
    display: block;
  }

  /* Base styles for first-content within Impactamos */
  .Impactamos .first-content {
    padding-top: 59px;
    padding-left: 58px;
    text-align: left;
  }

  /* Base styles for title within Impactamos */
  .Impactamos .title {
    color: #000;
    text-align: center;
    font-family: Ruda;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 114%; /* 54.72px */
  }

  .Impactamos .title b {
    color: #8780BD;
    font-family: "Rubik One";
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 114%;  
  }

  /* Base styles for subtitle within Impactamos */
  .Impactamos .subtitle {
    color: #FFF;
    font-family: 'Ruda Bold';
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 114%; /* 54.72px */
  }

  .Impactamos .subtitle b {
    color: #FFF;
    font-family: "Rubik One";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 114%;
  }

  /* Base styles for second-content within Impactamos */
  .Impactamos .second-content {
    padding-top: 59px;
    text-align: left;
  }

  /* Base styles for paragraph within Impactamos */
  .Impactamos .paragraph {
    color: #000000;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 183.333% */
    text-align: left;
  }
}

/* Base styles for paragraph elements with bold within Impactamos */
.Impactamos b {
  font-family: 'Lato Bold';
  font-weight: bolder;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 183.333% */
}

/* Base styles for bg-gray within Impactamos */
.Impactamos .bg-gray {
  background: #69747C;
}

/* Base styles for third-content within Impactamos */
.Impactamos .third-content {
  margin-top: -19rem;
  margin-bottom: 5rem;
  position: relative;
  z-index: 50;
}

/* Base styles for paragraph2 within Impactamos */
.Impactamos .paragraph2 {
  color: #FFF;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 114.286% */
  text-align: left;
}

/* Base styles for fourth-content within Impactamos */
.Impactamos .fourth-content {
  margin-top: 0rem;
  position: relative;
  z-index: 50;
}

/* Base styles for paragraph1 within Impactamos */
.Impactamos .paragraph1 {
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 52.5px */
}

.Impactamos .paragraph1 b {
  color: #FFF;
  font-family: 'Lato Bold';
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

/* Base styles for bg1 and bg2 within Impactamos */
.Impactamos .bg1,
.Impactamos .bg2 {
  background-color: #8780BD;
}

/* Base styles for bg3 within Impactamos */
.Impactamos .bg3 {
  background-color: #69747C;
  background-image: url('../../../assets/images/comunicacion/gradient-web.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

/* Base styles for paragraph3 within Impactamos */
.Impactamos .paragraph3 {
  color: #FFF;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}

/* Base styles for rectangle1 within Impactamos */
.Impactamos .rectangle1 {
  position: relative;
  width: 100%;
  height: auto;
  margin-left: -15rem;
  z-index: 10;
}

/* Base styles for rectangle2 within Impactamos */
.Impactamos .rectangle2 {
  position: relative;
  width: 50%;
  height: auto;
  margin-left: -2rem;
  z-index: 10;
}

/* Base styles for text-big within Impactamos */
.Impactamos .text-big {
  color: #FFF;
  font-family: "Rubik One";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 72.727% */
}

@media (min-width: 1366px) {
  /* Base styles for mobile-device within Impactamos */
  .Impactamos .mobile-device {
    display: none;
  }

  /* Base styles for web-device within Impactamos */
  .Impactamos .web-device {
    display: block;
  }

  /* Base styles for first-content within Impactamos */
  .Impactamos .first-content {
    padding-top: 59px;
    padding-left: 58px;
    text-align: left;
  }

  /* Base styles for title within Impactamos */
  .Impactamos .title {
    color: #000;
    text-align: center;
    font-family: Ruda;
    font-size: 35px;
    font-style: normal;
    font-weight: 800;
    line-height: 114%; /* 54.72px */
  }

  .Impactamos .title b {
    color: #8780BD;
    font-family: "Rubik One";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 114%;  
  }

  /* Base styles for subtitle within Impactamos */
  .Impactamos .subtitle {
    color: #FFF;
    font-family: 'Ruda Bold';
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 114%; /* 54.72px */
  }

  .Impactamos .subtitle b {
    color: #FFF;
    font-family: "Rubik One";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 114%;
  }

  /* Base styles for second-content within Impactamos */
  .Impactamos .second-content {
    padding-top: 59px;
    text-align: left;
  }

  /* Base styles for paragraph within Impactamos */
  .Impactamos .paragraph {
    color: #000000;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 183.333% */
    text-align: left;
  }

  .Impactamos .paragraph b {
    font-family: 'Lato Bold';
    font-weight: bolder;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 183.333% */
  }

  /* Base styles for bg-gray within Impactamos */
  .Impactamos .bg-gray {
    background: #69747C;
  }

  /* Base styles for text-medium within Impactamos */
  .Impactamos .text-medium {
    color: #FFF;
    font-family: Ruda;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 30px;
  }
}

/* Base styles for third-content within Impactamos */
.Impactamos .third-content {
  margin-top: -19rem;
  margin-bottom: 5rem;
  position: relative;
  z-index: 50;
}

/* Base styles for paragraph2 within Impactamos */
.Impactamos .paragraph2 {
  color: #FFF;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 114.286% */
  text-align: left;
}

/* Base styles for fourth-content within Impactamos */
.Impactamos .fourth-content {
  margin-top: 0rem;
  position: relative;
  z-index: 50;
}

/* Base styles for paragraph1 within Impactamos */
.Impactamos .paragraph1 {
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 52.5px */
}

.Impactamos .paragraph1 b {
  color: #FFF;
  font-family: 'Lato Bold';
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

/* Base styles for bg1 and bg2 within Impactamos */
.Impactamos .bg1,
.Impactamos .bg2 {
  background-color: #8780BD;
}

/* Base styles for bg3 within Impactamos */
.Impactamos .bg3 {
  background-color: #69747C;
  background-image: url('../../../assets/images/comunicacion/gradient-web.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

/* Base styles for paragraph3 within Impactamos */
.Impactamos .paragraph3 {
  color: #FFF;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}

/* Base styles for rectangle1 within Impactamos */
.Impactamos .rectangle1 {
  position: relative;
  width: 100%;
  height: auto;
  margin-left: -15rem;
  z-index: 10;
}

/* Base styles for rectangle2 within Impactamos */
.Impactamos .rectangle2 {
  position: relative;
  width: 50%;
  height: auto;
  margin-left: -2rem;
  z-index: 10;
}

/* Base styles for text-big within Impactamos */
.Impactamos .text-big {
  color: #FFF;
  font-family: "Rubik One";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 72.727% */
}

@media (min-width: 1440px) {
  /* Base styles for first-content within Impactamos */
  .Impactamos .first-content {
    padding-top: 59px;
    padding-left: 58px;
    text-align: left;
  }

  /* Base styles for title within Impactamos */
  .Impactamos .title {
    color: #000;
    text-align: center;
    font-family: Ruda;
    font-size: 38px;
    font-style: normal;
    font-weight: 800;
    line-height: 114%; /* 54.72px */
  }

  .Impactamos .title b {
    color: #8780BD;
    font-family: "Rubik One";
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
    line-height: 114%;  
  }

  /* Base styles for subtitle within Impactamos */
  .Impactamos .subtitle {
    color: #FFF;
    font-family: 'Ruda Bold';
    font-size: 38px;
    font-style: normal;
    font-weight: 800;
    line-height: 114%; /* 54.72px */
  }

  .Impactamos .subtitle b {
    color: #FFF;
    font-family: "Rubik One";
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
    line-height: 114%;
  }

  /* Base styles for second-content within Impactamos */
  .Impactamos .second-content {
    padding-top: 59px;
    text-align: left;
  }

  /* Base styles for paragraph within Impactamos */
  .Impactamos .paragraph {
    color: #000000;
    font-family: Lato;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 134%; /* 40.2px */
    text-align: left;
  }

  .Impactamos .paragraph b {
    color: #1F282C;
    font-family: 'Lato Bold';
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 134%;
  }

  /* Base styles for bg-gray within Impactamos */
  .Impactamos .bg-gray {
    background: #69747C;
  }

  /* Base styles for third-content within Impactamos */
  .Impactamos .third-content {
    margin-top: -20rem;
    margin-bottom: 5rem;
    padding-left: 5rem;
    position: relative;
    z-index: 50;
  }

  /* Base styles for paragraph2 within Impactamos */
  .Impactamos .paragraph2 {
    color: #FFF;
    font-family: Lato;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px; /* 148.571% */
  }

  /* Base styles for fourth-content within Impactamos */
  .Impactamos .fourth-content {
    margin-top: 0rem;
    position: relative;
    z-index: 50;
  }

  /* Base styles for text-medium within Impactamos */
  .Impactamos .text-medium {
    color: #FFF;
    font-family: Ruda;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 30px;
  }
}


/* Base styles for paragraph1 within Impactamos */
.Impactamos .paragraph1 {
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 52.5px */
}

.Impactamos .paragraph1 b {
  color: #FFF;
  font-family: 'Lato Bold';
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

/* Base styles for bg1 and bg2 within Impactamos */
.Impactamos .bg1,
.Impactamos .bg2 {
  background-color: #8780BD;
}

/* Base styles for bg3 within Impactamos */
.Impactamos .bg3 {
  background-color: #69747C;
  background-image: url('../../../assets/images/comunicacion/gradient-web.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

/* Base styles for paragraph3 within Impactamos */
.Impactamos .paragraph3 {
  color: #FFF;
  font-family: Lato;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
}

/* Base styles for rectangle1 within Impactamos */
.Impactamos .rectangle1 {
  position: relative;
  width: 100%;
  height: auto;
  margin-left: -10rem;
  z-index: 10;
}

/* Base styles for rectangle2 within Impactamos */
.Impactamos .rectangle2 {
  position: relative;
  width: 100%;
  height: auto;
  margin-left: -9rem;
  z-index: 10;
}

/* Base styles for text-big within Impactamos */
.Impactamos .text-big {
  color: #FFF;
  font-family: "Rubik One";
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
}

/* Base styles for text-medium within Impactamos */
.Impactamos .text-medium {
  color: #FFF;
  font-family: Ruda;
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: 52px;
}


  @media (min-width: 1920px) {
    /* Base styles for first-content within Impactamos */
    .Impactamos .first-content {
      padding-top: 59px;
      padding-left: 58px;
      text-align: left;
    }

    /* Base styles for title within Impactamos */
    .Impactamos .title {
      color: #000;
      text-align: center;
      font-family: Ruda;
      font-size: 48px;
      font-style: normal;
      font-weight: 800;
      line-height: 114%; /* 54.72px */
    }

    .Impactamos .title b {
      color: #8780BD;
      font-family: "Rubik One";
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 114%;
    }

    /* Base styles for subtitle within Impactamos */
    .Impactamos .subtitle {
      color: #FFF;
      font-family: 'Ruda Bold';
      font-size: 48px;
      font-style: normal;
      font-weight: 800;
      line-height: 114%; /* 54.72px */
    }

    .Impactamos .subtitle b {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 114%;
    }

    /* Base styles for second-content within Impactamos */
    .Impactamos .second-content {
      padding-top: 59px;
      text-align: left;
    }

    /* Base styles for paragraph within Impactamos */
    .Impactamos .paragraph {
      color: #000;
      font-family: Lato;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 134%; /* 40.2px */
      text-align: left;
    }

    .Impactamos .paragraph b {
      color: #000;
      font-family: 'Lato Bold';
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 134%;
    }

    /* Base styles for bg-gray within Impactamos */
    .Impactamos .bg-gray {
      background: #69747C;
    }

    /* Base styles for third-content within Impactamos */
    .Impactamos .third-content {
      margin-top: -20rem;
      margin-bottom: 5rem;
      padding-left: 5rem;
      position: relative;
      z-index: 50;
    }
  }

/* Base styles for paragraph2 within Impactamos */
.Impactamos .paragraph2 {
  color: #FFF;
  font-family: Lato;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px; /* 148.571% */
}

/* Base styles for fourth-content within Impactamos */
.Impactamos .fourth-content {
  margin-top: 0rem;
  position: relative;
  z-index: 50;
}

/* Base styles for paragraph1 within Impactamos */
.Impactamos .paragraph1 {
  color: #FFF;
  font-family: Lato;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 43.75px */
  text-align: left;
}

.Impactamos .paragraph1 b {
  color: #FFF;
  font-family: 'Lato Bold';
  font-size: 35px;
  font-style: normal;
  font-weight: 900;
  line-height: 125%;
}

/* Base styles for bg1 within Impactamos */
.Impactamos .bg1 {
  background-color: #8780BD;
}

/* Base styles for bg2 within Impactamos */
.Impactamos .bg2 {
  background-color: #8780BD;
}

/* Base styles for bg3 within Impactamos */
.Impactamos .bg3 {
  background-color: #69747C;
  background-image: url('../../../assets/images/comunicacion/gradient-web.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

/* Base styles for paragraph3 within Impactamos */
.Impactamos .paragraph3 {
  color: #FFF;
  font-family: Lato;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 118%;
  text-align: center;
}


/* Base styles for paragraph3 within Impactamos */
.Impactamos .paragraph3 {
  color: #FFF;
  font-family: Lato;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 118%;
  text-align: center;
}

.Impactamos .paragraph3 b {
  color: #FFF;
  font-family: 'Lato Bold';
  font-size: 50px;
  font-style: normal;
  font-weight: 900;
  line-height: 118%; /* 59px */
}

/* Base styles for rectangle1 within Impactamos */
.Impactamos .rectangle1 {
  position: relative;
  width: 100%;
  height: auto;
  margin-left: -10rem;
  z-index: 10;
}

/* Base styles for rectangle2 within Impactamos */
.Impactamos .rectangle2 {
  position: relative;
  width: 100%;
  height: auto;
  margin-left: -9rem;
  z-index: 10;
}

/* Base styles for text-big within Impactamos */
.Impactamos .text-big {
  color: #FFF;
  font-family: "Rubik One";
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
}

/* Base styles for text-medium within Impactamos */
.Impactamos .text-medium {
  color: #FFF;
  font-family: Ruda;
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: 52px;
}
