.CardAcciones {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  /* img {
    max-width: 100%;
    height: auto;
    border: 10px solid #69747C;
  } */
}
  .CardAcciones .card-container {
    width: 90%;
    height: auto;
    padding-left: 0%;
    padding-right: 0;
  }

  .CardAcciones img {
    width: 100%;
    height: auto;
    border: 10px solid #69747C;
  }

  .CardAcciones .title-card {
    color: #72CDBC;
    font-family: "Rubik One";
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.5rem;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 1rem;
  }

  .CardAcciones .title-card-aqua {
    color: #72CDBC;
    font-family: "Rubik One";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
    padding-top: 1rem;
  }

  .CardAcciones .description {
    color: #1F282C;
    font-family: Lato;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
  }
    .CardAcciones .description b {
      color: #000;
      font-family: 'Lato Bold';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 152%; /* 38px */
    }
  .CardAcciones .play-overlay{
    position: absolute;
    left: 50%;
    top: 50%;
    cursor: pointer;
    z-index: 20;
    -webkit-transform:translate(-50%,-50%);
    transform:translate(-50%,-50%);
    width: 4.5rem;
    height: auto;
    border: unset;
  }
  .CardAcciones .iframe-h{
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .CardAcciones .iframe-s{
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .splide__arrow--next{

    right:0rem !important;
  }

  .splide__arrow--prev{

    left:0rem !important;
  }

  .splide__arrow{

    top:94% !important;
  }

  @media (min-width: 768px) {
    .CardAcciones {
        padding-left: 5%;
        padding-right: 5%;
    }

    .CardAcciones .card-container {
        width: 644px;
        height: auto;
        padding-left: 0;
    }

    .CardAcciones img {
        width: 644px;
        height: 492px;
        border: 18px solid #69747C;
    }

    .CardAcciones .title-card {
        color: #72CDBC;
        font-family: "Rubik One";
        font-size: 2rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.5rem;
        text-transform: uppercase;
        text-align: left;
        margin-bottom: 1rem;
    }

    .CardAcciones .title-card-aqua {
        color: #72CDBC;
        font-family: "Rubik One";
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: left;
        padding-top: 1rem;
    }

    .CardAcciones .description {
        color: #1F282C;
        font-family: Lato;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-align: left;
    }

    .CardAcciones .description b {
        color: #000;
        font-family: 'Lato Bold';
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 152%; /* 38px */
    }

    .CardAcciones .splide__arrow--next {
        right: -2.5rem !important;
    }

    .CardAcciones .splide__arrow--prev {
        left: -2.5rem !important;
    }

    .CardAcciones .splide__arrow {
        top: 45% !important;
    }
}

@media (min-width: 1024px) {
  .CardAcciones {
      width: 100%;
      padding-right: 0%;
      padding-left: 0%;
  }

  .CardAcciones .card-container {
      width: 85%;
      height: auto;
  }

  .CardAcciones img {
      width: 100%;
      height: auto;
      border: 14px solid #69747C;
  }

  .CardAcciones .title-card {
      color: #72CDBC;
      font-family: "Rubik One";
      font-size: 2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.5rem;
      text-transform: uppercase;
      text-align: left;
      margin-bottom: 1rem;
  }

  .CardAcciones .title-card-aqua {
      color: #72CDBC;
      font-family: "Rubik One";
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      text-align: left;
      padding-top: 1rem;
  }

  .CardAcciones .description {
      color: #1F282C;
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      text-align: left;
  }

  .CardAcciones .description b {
      color: #000;
      font-family: 'Lato Bold';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 152%; /* 38px */
  }
}

@media (min-width: 1440px) {
  .CardAcciones {
      padding-left: 0px;
      padding-right: 0px;
  }

  .CardAcciones .card-container {
      width: 90%;
      height: auto;
      margin: auto;
  }

  .CardAcciones img {
      width: 100%;
      height: auto;
      border: 17px solid #69747C;
  }

  .CardAcciones .title-card {
      color: #8780BD;
      font-family: "Rubik One";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      text-align: left;
      margin-top: 1rem;
  }

  .CardAcciones .title-card-aqua {
      color: #72CDBC;
      font-family: "Rubik One";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      text-align: left;
      margin-top: 1rem;
  }

  .CardAcciones .description {
      color: #000;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 152%;
  }

  .CardAcciones .description b {
      color: #000;
      font-family: 'Lato Bold';
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 152%; /* 38px */
  }

  .CardAcciones .description .bold {
      font-family: 'Lato Bold';
      font-weight: 800;
  }
}

@media (min-width: 1920px) {
  .CardAcciones {
      padding-left: 0px;
      padding-right: 0px;
  }

  .CardAcciones .card-container {
      width: 90%;
      height: auto;
      margin: auto;
  }

  .CardAcciones img {
      width: 100%;
      height: auto;
      border: 20px solid #69747C;
  }

  .CardAcciones .title-card {
      color: #8780BD;
      font-family: "Rubik One";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      text-align: left;
      margin-top: 1rem;
  }

  .CardAcciones .title-card-aqua {
      color: #72CDBC;
      font-family: "Rubik One";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      text-align: left;
      margin-top: 1rem;
  }

  .CardAcciones .description {
      color: #000;
      font-family: Lato;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 152%;
  }

  .CardAcciones .description b {
      color: #000;
      font-family: 'Lato Bold';
      font-size: 25px;
      font-style: normal;
      font-weight: 800;
      line-height: 152%; /* 38px */
  }

  .CardAcciones .description .bold {
      font-family: 'Lato Bold';
      font-weight: 800;
  }
}