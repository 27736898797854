.Iniciativas {
  width: 100%;
  background: linear-gradient(0deg, rgba(114, 205, 188, 0.18) 0%, rgba(197, 244, 255, 0.00) 100%);
}

.Iniciativas .mobile-device {
  display: block;
}

.Iniciativas .web-device {
  display: none;
}

.Iniciativas .title {
  color: #72CDBC;
  text-align: center;
  font-family: "Rubik One";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  padding-top: 10%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 10%;
}

.Iniciativas .bg-one {
  background-color: #72CDBC;
}

.Iniciativas .bg-two {
  background-color: rgba(86, 214, 249, 0.90);
}

.Iniciativas .bg-three {
  background-color: #019294;
}

.Iniciativas .bg-four {
  background-color: #8780BD;
}

.Iniciativas .rectangle {
  width: 100%;
  position: relative;
  z-index: 10;
}

.Iniciativas .rectangle img {
  width: 100%;
}

.Iniciativas .text-container {
  margin-top: 0%;
  padding-left: 15%;
  padding-right: 5%;
  padding-bottom: 10%;
  text-align: left;
  position: relative;
  z-index: 50;
}

.Iniciativas .title2 {
  color: #81D2C3;
  text-align: center;
  font-family: "Rubik One";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.Iniciativas .paragraph {
  color: #FFF;
  font-family: Lato;
  font-size: 22px;
  font-style: normal;
  font-weight: lighter;
  line-height: 125%;

}

.Iniciativas .paragraph b {
  color: #FFF;
  font-family: 'Lato Bold';
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

.Iniciativas .bg-gray {
  background: #E9EDF0;
}

.Iniciativas .title3 {
  color: #085F60;
  font-family: "Rubik One";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 40px */
  text-transform: uppercase;
}

.Iniciativas .paragraph2 {
  color: #2E9B87;
  font-family: 'Ruda Bold';
  font-size: 19px;
  font-style: normal;
  font-weight: 800;
  line-height: 114%;

}

.Iniciativas .paragraph2 b {
  color: #2E9B87;
  text-align: center;
  font-family: "Rubik One";
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 114%; /* 21.66px */
}

.Iniciativas .bg-vista-aguila {
  background-image: url('../../../assets/images/nuestrasAlas/vista_de_aguila_mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 379px;
}

.Iniciativas .bg-gray-dark {
  background-color: #69747C;
}

.Iniciativas .title4 {
  color: #FFF;
  font-family: "Rubik One";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 26.4px */
  text-transform: uppercase;
}

.Iniciativas .paragraph3 {
  color: #FFF;
  font-family: Lato;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: none;
}

.Iniciativas .paragraph3 b {
  color: #FFF;
  font-family: 'Lato Bold';
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
}

.Iniciativas .bg-nuestras-alas-vuelan {
  background-image: url('../../../assets/images/nuestrasAlas/nuestras_alas_vuelan_mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 379px;
}

.Iniciativas .paragraph-5 {
  color: #FFF;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.Iniciativas .paragraph-5 b {
  color: #FFF;
  font-family: 'Lato Bold';
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.Iniciativas .text-container2 {
  margin-top: -75%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 10%;
  text-align: left;
  position: relative;
  z-index: 50;
}

.Iniciativas .bg-tenis-con-alas {
  background-image: url('../../../assets/images/nuestrasAlas/rectangle-iniciativas.png');
  background-repeat: no-repeat;
  background-size: auto;
  width: 100%;
  height: auto;
}

.Iniciativas .bg-becas-aguilas-ext {
  background-image: url('../../../assets/images/nuestrasAlas/rectangle-iniciativas-becas-aguilas-ext.png');
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
}

.Iniciativas .bg-aguilas-tecnologicas {
  background-image: url('../../../assets/images/nuestrasAlas/rectangle-iniciativas-aguilas-tecnologicas.png');
  background-repeat: no-repeat;
  background-size: auto;
  width: 100%;
  height: auto;
}

.Iniciativas .bg-prevencion-violencia {
  background-image: url('../../../assets/images/nuestrasAlas/rectangle-iniciativas-prevencion-violencia.png');
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
}

.Iniciativas .bg-guerrero {
  background-image: url('../../../assets/images/nuestrasAlas/rectangle-iniciativas-guerrero.png');
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
}

.Iniciativas .bg-programa-nuestras-alas {
  background-image: url('../../../assets/images/nuestrasAlas/rectangle-programa-nuestras-alas.png');
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
}

  @media (min-width: 768px) {
    .Iniciativas .title {
        color: #72CDBC;
        text-align: center;
        font-family: "Rubik One";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        padding-top: 10%;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 5%;
    }

    .Iniciativas .bg-one {
        background-color: #72CDBC;
    }

    .Iniciativas .bg-two {
        background-color: rgba(86, 214, 249, 0.90);
    }

    .Iniciativas .bg-three {
        background-color: #019294;
    }

    .Iniciativas .bg-four {
        background-color: #8780BD;
    }

    .Iniciativas .rectangle {
        width: 50%;
        position: relative;
        z-index: 10;
    }

    .Iniciativas .rectangle img {
        width: 100%;
    }

    .Iniciativas .text-container {
        margin-top: 0%;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 10%;
        text-align: left;
        position: relative;
        z-index: 50;
    }

    .Iniciativas .title2 {
        color: #81D2C3;
        text-align: center;
        font-family: "Rubik One";
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }

    .Iniciativas .paragraph {
        color: #FFF;
        font-family: Lato;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
    }

    .Iniciativas .paragraph b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 26px;
        font-style: normal;
        font-weight: bold;
        line-height: 160%;
    }

    .Iniciativas .bg-gray {
        background: #E9EDF0;
    }

    .Iniciativas .title3 {
        color: #085F60;
        font-family: "Rubik One";
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        text-transform: uppercase;
    }

    .Iniciativas .paragraph2 {
        color: #2E9B87;
        font-family: 'Ruda Bold';
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 114%;
    }

    .Iniciativas .paragraph2 b {
        color: #2E9B87;
        text-align: center;
        font-family: "Rubik One";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 114%;
    }

    .Iniciativas .bg-vista-aguila {
        background-image: url('../../../assets/images/nuestrasAlas/vista_de_aguila_mobile.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100vh;
    }

    .Iniciativas .bg-gray-dark {
        background-color: #69747C;
    }

    .Iniciativas .title4 {
        color: #FFF;
        font-family: "Rubik One";
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-transform: uppercase;
    }

    .Iniciativas .paragraph3 {
        color: #FFF;
        font-family: Lato;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-top: 1.5rem;
        text-transform: none;
    }

    .Iniciativas .paragraph3 b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 26px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
    }

    .Iniciativas .bg-nuestras-alas-vuelan {
        background-image: url('../../../assets/images/nuestrasAlas/nuestras_alas_vuelan_mobile.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 379px;
    }

    .Iniciativas .paragraph-5 {
        color: #FFF;
        font-family: Lato;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
    }

    .Iniciativas .paragraph-5 b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 26px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }

    .Iniciativas .text-container2 {
        margin-top: 0%;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 10%;
        text-align: left;
        position: relative;
        z-index: 50;
    }
}


@media (min-width: 1024px) {
  .Iniciativas .mobile-device {
      display: none;
  }

  .Iniciativas .web-device {
      display: block;
  }

  .Iniciativas .title {
      color: #72CDBC;
      text-align: center;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      padding-top: 5%;
      padding-left: 5%;
      padding-right: 5%;
      padding-bottom: 5%;
  }

  .Iniciativas .bg-one {
      background-color: #72CDBC;
  }

  .Iniciativas .bg-two {
      background-color: rgba(86, 214, 249, 0.90);
  }

  .Iniciativas .bg-three {
      background-color: #019294;
  }

  .Iniciativas .bg-four {
      background-color: #8780BD;
  }

  .Iniciativas .rectangle {
      width: 100%;
      position: relative;
      z-index: 10;
  }

  .Iniciativas .rectangle img {
      width: 100%;
  }

  .Iniciativas .text-container {
      padding-top: 5%;
      padding-left: 15%;
      padding-right: 5%;
      padding-bottom: 10%;
      text-align: left;
      position: relative;
      z-index: 50;
  }

  .Iniciativas .title2 {
      color: #81D2C3;
      text-align: center;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
  }

  .Iniciativas .paragraph {
      color: #FFF;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      margin-top: 1.5rem;
  }

  .Iniciativas .paragraph b {
      color: #FFF;
      font-family: 'Lato Bold';
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 125%;
  }

  .Iniciativas .bg-gray {
      background: #E9EDF0;
  }

  .Iniciativas .title3 {
      color: #085F60;
      font-family: "Rubik One";
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      text-transform: uppercase;
  }

  .Iniciativas .paragraph2 {
      color: #2E9B87;
      font-family: 'Ruda Bold';
      font-size: 25px;
      font-style: normal;
      font-weight: 800;
      line-height: 114%;
      text-align: left;
  }

  .Iniciativas .paragraph2 b {
      color: #2E9B87;
      font-family: "Rubik One";
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 114%;
  }

  .Iniciativas .bg-vista-aguila {
      background-image: url('../../../assets/images/nuestrasAlas/vista_de_aguila_mobile.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 80vh;
  }

  .Iniciativas .bg-gray-dark {
      background-color: #69747C;
  }

  .Iniciativas .title4 {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      text-transform: uppercase;
  }

  .Iniciativas .paragraph3 {
      color: #FFF;
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      margin-top: 1.5rem;
      text-transform: none;
  }

  .Iniciativas .paragraph3 b {
      color: #FFF;
      font-family: 'Lato Bold';
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
  }

  .Iniciativas .bg-nuestras-alas-vuelan {
      background-image: url('../../../assets/images/nuestrasAlas/nuestras_alas_vuelan_mobile.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 40vh;
  }

  .Iniciativas .paragraph-5 {
      color: #FFF;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      text-align: center;
  }

  .Iniciativas .paragraph-5 b {
      color: #FFF;
      font-family: 'Lato Bold';
      font-size: 25px;
      font-style: normal;
      font-weight: 800;
      line-height: 125%;
  }

  .Iniciativas .rectangle2 {
      width: 50%;
  }

  .Iniciativas .rectangle2 img {
      width: 100%;
  }

  .Iniciativas .text-container2 {
      margin-top: 0%;
      padding-left: 10%;
      padding-right: 10%;
      padding-bottom: 10%;
      text-align: left;
      position: relative;
      z-index: 50;
  }
}

@media (min-width: 1366px) {
  .Iniciativas .mobile-device {
      display: none;
  }

  .Iniciativas .web-device {
      display: block;
  }

  .Iniciativas .title {
      color: #72CDBC;
      text-align: center;
      font-family: "Rubik One";
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      padding-top: 5%;
      padding-left: 5%;
      padding-right: 5%;
      padding-bottom: 5%;
  }

  .Iniciativas .bg-one {
      background-color: #72CDBC;
  }

  .Iniciativas .bg-two {
      background-color: rgba(86, 214, 249, 0.90);
  }

  .Iniciativas .bg-three {
      background-color: #019294;
  }

  .Iniciativas .bg-four {
      background-color: #8780BD;
  }

  .Iniciativas .rectangle {
      width: 100%;
      position: relative;
      z-index: 10;
  }

  .Iniciativas .rectangle img {
      width: 100%;
  }

  .Iniciativas .text-container {
      padding-top: 5%;
      padding-left: 15%;
      padding-right: 5%;
      padding-bottom: 10%;
      text-align: left;
      position: relative;
      z-index: 50;
  }

  .Iniciativas .title2 {
      color: #81D2C3;
      text-align: center;
      font-family: "Rubik One";
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
  }

  .Iniciativas .paragraph {
      color: #FFF;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      margin-top: 1.5rem;
  }

  .Iniciativas .paragraph b {
      color: #FFF;
      font-family: 'Lato Bold';
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 125%;
  }

  .Iniciativas .bg-gray {
      background: #E9EDF0;
  }

  .Iniciativas .title3 {
      color: #085F60;
      font-family: "Rubik One";
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      text-transform: uppercase;
  }

  .Iniciativas .paragraph2 {
      color: #2E9B87;
      font-family: 'Ruda Bold';
      font-size: 25px;
      font-style: normal;
      font-weight: 800;
      line-height: 114%;
      text-align: left;
  }

  .Iniciativas .paragraph2 b {
      color: #2E9B87;
      font-family: "Rubik One";
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 114%;
  }

  .Iniciativas .bg-vista-aguila {
      background-image: url('../../../assets/images/nuestrasAlas/vista_de_aguila_mobile.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 80vh;
  }

  .Iniciativas .bg-gray-dark {
      background-color: #69747C;
  }

  .Iniciativas .title4 {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      text-transform: uppercase;
  }

  .Iniciativas .paragraph3 {
      color: #FFF;
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      margin-top: 1.5rem;
      text-transform: none;
  }

  .Iniciativas .paragraph3 b {
      color: #FFF;
      font-family: 'Lato Bold';
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
  }

  .Iniciativas .bg-nuestras-alas-vuelan {
      background-image: url('../../../assets/images/nuestrasAlas/nuestras_alas_vuelan_mobile.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 40vh;
  }

  .Iniciativas .paragraph-5 {
      color: #FFF;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      text-align: center;
  }

  .Iniciativas .paragraph-5 b {
      color: #FFF;
      font-family: 'Lato Bold';
      font-size: 25px;
      font-style: normal;
      font-weight: 800;
      line-height: 125%;
  }

  .Iniciativas .rectangle2 {
      width: 50%;
  }

  .Iniciativas .rectangle2 img {
      width: 100%;
  }

  .Iniciativas .text-container2 {
      margin-top: 0%;
      padding-left: 10%;
      padding-right: 10%;
      padding-bottom: 5%;
      text-align: left;
      position: relative;
      z-index: 50;
  }
}

@media (min-width: 1440px) {
  .Iniciativas .title {
      color: #72CDBC;
      text-align: center;
      font-family: "Rubik One";
      font-size: 55.222px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      padding-top: 5%;
      padding-left: 10%;
      padding-right: 10%;
      padding-bottom: 5%;
  }

  .Iniciativas .bg-one {
      background-color: #72CDBC;
  }

  .Iniciativas .bg-two {
      background-color: rgba(86, 214, 249, 0.90);
  }

  .Iniciativas .bg-three {
      background-color: #019294;
  }

  .Iniciativas .bg-four {
      background-color: #8780BD;
  }

  .Iniciativas .rectangle {
      width: 100%;
      position: relative;
  }

  .Iniciativas .rectangle img {
      width: 100%;
  }

  .Iniciativas .text-container {
      margin-top: 0%;
      padding-top: 5rem;
      padding-left: 7rem;
      padding-right: 7rem;
      padding-bottom: 5rem;
      text-align: left;
  }

  .Iniciativas .title2 {
      color: #81D2C3;
      text-align: center;
      font-family: "Rubik One";
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
  }

  .Iniciativas .paragraph {
      color: #FFF;
      font-family: Lato;
      font-size: 27px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      margin-top: 1.5rem;
  }

  .Iniciativas .paragraph b {
      color: #FFF;
      font-family: 'Lato Bold';
      font-size: 27px;
      font-style: normal;
      font-weight: 800;
      line-height: 140%;
  }

  .Iniciativas .bg-gray {
      background: #E9EDF0;
  }

  .Iniciativas .title3 {
      color: #085F60;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      text-transform: uppercase;
  }

  .Iniciativas .paragraph2 {
      color: #2E9B87;
      font-family: 'Ruda Bold';
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 114%;
      text-align: left;
  }

  .Iniciativas .paragraph2 b {
      color: #2E9B87;
      font-family: "Rubik One";
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 114%;
  }

  .Iniciativas .bg-vista-aguila {
      background-image: url('../../../assets/images/nuestrasAlas/vista_de_aguila_mobile.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100vh;
  }

  .Iniciativas .bg-gray-dark {
      background-color: #69747C;
  }

  .Iniciativas .title4 {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      text-transform: uppercase;
  }

  .Iniciativas .paragraph3 {
      color: #FFF;
      font-family: Lato;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      margin-top: 1.5rem;
      text-transform: none;
  }

  .Iniciativas .paragraph3 b {
      color: #FFF;
      font-family: 'Lato Bold';
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
  }

  .Iniciativas .bg-nuestras-alas-vuelan {
      background-image: url('../../../assets/images/nuestrasAlas/nuestras_alas_vuelan.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 555px;
  }

  .Iniciativas .paragraph-5 {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      letter-spacing: -0.5px;
      text-align: center;
  }

  .Iniciativas .paragraph-5 b {
      color: #FFF;
      font-family: 'Lato Bold';
      font-size: 35px;
      font-style: normal;
      font-weight: 800;
      line-height: 125%;
      letter-spacing: -0.5px;
  }

  .Iniciativas .rectangle2 {
      width: 30%;
  }

  .Iniciativas .rectangle2 img {
      width: 100%;
  }

  .Iniciativas .text-container2 {
      margin-top: 0%;
      padding-top: 5rem;
      padding-left: 10%;
      padding-right: 10%;
      padding-bottom: 5rem;
      text-align: left;
      position: relative;
      z-index: 50;
  }

  .Iniciativas .bg-tenis-con-alas {
      background-image: url('../../../assets/images/nuestrasAlas/rectangle-iniciativas.png');
      background-repeat: no-repeat;
      background-size: auto;
      width: 100%;
      height: auto;
  }
  .Iniciativas .bg-becas-aguilas-ext {
      background-image: url('../../../assets/images/nuestrasAlas/rectangle-iniciativas-becas-aguilas-ext.png');
      background-repeat: no-repeat;
      /* background-size: cover; */
      width: 100%;
      height: auto;
  }
  .Iniciativas .bg-aguilas-tecnologicas {
      background-image: url('../../../assets/images/nuestrasAlas/rectangle-iniciativas-aguilas-tecnologicas.png');
      background-repeat: no-repeat;
      background-size: auto;
      width: 100%;
      height: auto;
  }
  .Iniciativas .bg-prevencion-violencia {
      background-image: url('../../../assets/images/nuestrasAlas/rectangle-iniciativas-prevencion-violencia.png');
      background-repeat: no-repeat;
      /* background-size: cover; */
      width: 100%;
      height: auto;
  }
  .Iniciativas .bg-guerrero {
      background-image: url('../../../assets/images/nuestrasAlas/rectangle-iniciativas-guerrero.png');
      background-repeat: no-repeat;
      /* background-size: cover; */
      width: 100%;
      height: auto;
  }
  .Iniciativas .bg-programa-nuestras-alas {
      background-image: url('../../../assets/images/nuestrasAlas/rectangle-programa-nuestras-alas.png');
      background-repeat: no-repeat;
      width: 100%;
      height: auto;
  }
}


@media (min-width: 1920px) {
  .Iniciativas .title {
      color: #72CDBC;
      text-align: center;
      font-family: "Rubik One";
      font-size: 73.222px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      padding-top: 5%;
      padding-left: 10%;
      padding-right: 10%;
      padding-bottom: 5%;
  }

  .Iniciativas .bg-one {
      background-color: #72CDBC;
  }

  .Iniciativas .bg-two {
      background-color: rgba(86, 214, 249, 0.90);
  }

  .Iniciativas .bg-three {
      background-color: #019294;
  }

  .Iniciativas .bg-four {
      background-color: #8780BD;
  }

  .Iniciativas .rectangle {
      width: 100%;
      position: relative;
  }

  .Iniciativas .rectangle img {
      width: 100%;
  }

  .Iniciativas .text-container {
      margin-top: 0%;
      padding-top: 5rem;
      padding-left: 7rem;
      padding-right: 7rem;
      padding-bottom: 5rem;
      text-align: left;
  }

  .Iniciativas .title2 {
      color: #81D2C3;
      text-align: center;
      font-family: "Rubik One";
      font-size: 55px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
  }

  .Iniciativas .paragraph {
      color: #FFF;
      font-family: Lato;
      font-size: 27px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      margin-top: 1.5rem;
  }

  .Iniciativas .paragraph b {
      color: #FFF;
      font-family: 'Lato Bold';
      font-size: 27px;
      font-style: normal;
      font-weight: 800;
      line-height: 140%;
  }

  .Iniciativas .bg-gray {
      background: #E9EDF0;
  }

  .Iniciativas .title3 {
      color: #085F60;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      text-transform: uppercase;
  }

  .Iniciativas .paragraph2 {
      color: #2E9B87;
      font-family: 'Ruda Bold';
      font-size: 42px;
      font-style: normal;
      font-weight: 800;
      line-height: 114%;
      text-align: left;
  }

  .Iniciativas .paragraph2 b {
      color: #2E9B87;
      font-family: "Rubik One";
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: 114%;
  }

  .Iniciativas .bg-vista-aguila {
      background-image: url('../../../assets/images/nuestrasAlas/vista_de_aguila_mobile.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100vh;
  }

  .Iniciativas .bg-gray-dark {
      background-color: #69747C;
  }

  .Iniciativas .title4 {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      text-transform: uppercase;
  }

  .Iniciativas .paragraph3 {
      color: #FFF;
      font-family: Lato;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      margin-top: 1.5rem;
      text-transform: none;
  }

  .Iniciativas .paragraph3 b {
      color: #FFF;
      font-family: 'Lato Bold';
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
  }

  .Iniciativas .bg-nuestras-alas-vuelan {
      background-image: url('../../../assets/images/nuestrasAlas/nuestras_alas_vuelan.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 555px;
  }

  .Iniciativas .paragraph-5 {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      letter-spacing: -0.5px;
      text-align: center;
  }

  .Iniciativas .paragraph-5 b {
      color: #FFF;
      font-family: 'Lato Bold';
      font-size: 50px;
      font-style: normal;
      font-weight: 800;
      line-height: 125%;
      letter-spacing: -0.5px;
  }

  .Iniciativas .rectangle2 {
      width: 30%;
  }

  .Iniciativas .rectangle2 img {
      width: 100%;
  }

  .Iniciativas .text-container2 {
      margin-top: 0%;
      padding-top: 5rem;
      padding-left: 10%;
      padding-right: 10%;
      padding-bottom: 5rem;
      text-align: left;
      position: relative;
      z-index: 50;
  }

  .Iniciativas .bg-tenis-con-alas {
      background-image: url('../../../assets/images/nuestrasAlas/rectangle-iniciativas.png');
      background-repeat: no-repeat;
      background-size: auto;
      width: 100%;
      height: auto;
  }
  .Iniciativas .bg-becas-aguilas-ext {
      background-image: url('../../../assets/images/nuestrasAlas/rectangle-iniciativas-becas-aguilas-ext.png');
      background-repeat: no-repeat;
      /* background-size: cover; */
      width: 100%;
      height: auto;
  }
  .Iniciativas .bg-aguilas-tecnologicas {
      background-image: url('../../../assets/images/nuestrasAlas/rectangle-iniciativas-aguilas-tecnologicas.png');
      background-repeat: no-repeat;
      background-size: auto;
      width: 100%;
      height: auto;
  }
  .Iniciativas .bg-prevencion-violencia {
      background-image: url('../../../assets/images/nuestrasAlas/rectangle-iniciativas-prevencion-violencia.png');
      background-repeat: no-repeat;
      /* background-size: cover; */
      width: 100%;
      height: auto;
  }
  .Iniciativas .bg-guerrero {
      background-image: url('../../../assets/images/nuestrasAlas/rectangle-iniciativas-guerrero.png');
      background-repeat: no-repeat;
      /* background-size: cover; */
      width: 100%;
      height: auto;
  }
  .Iniciativas .bg-programa-nuestras-alas {
      background-image: url('../../../assets/images/nuestrasAlas/rectangle-programa-nuestras-alas.png');
      background-repeat: no-repeat;
      width: 100%;
      height: auto;
  }
}
