.tch-title{
    color: #8780BD;
    text-align: center;
    font-family: "Rubik One";
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: 127%; /* 69.85px */
    text-transform: uppercase;
}
.square{
    min-height: 400px;
    max-height: 500px;
    padding: 5rem 3rem;
}
.mint{
    background-color: #5DBAA9;
}
.green{
    background-color: #019294;
}
.sky-blue{
    background-color: #00B9F1;
}
.square .tch-par{
    color: #FFF;
    font-family: Lato;
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}
.tch-par strong{
    font-family: "Lato Bold", 'Lato Bold';
    font-weight: 900;
}

.bg-tch{
    background-color: #8780BD;
}
.tch-link{
    color: #FFF;
    text-align: center;
    font-family: "Rubik One";
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 49px */
}
.onu-title{
    color: #FFF;
    font-family: 'Lato Bold';
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
    text-align: start;
}
.onu-par{
    color: #FFF;
    font-family: Lato;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}
.ini-title{
    color: #5DBAA9;
    text-align: center;
    font-family: "Rubik One";
    font-size: 62px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}
.tf-compromiso{
    color: #000;
    text-align: center;
    font-family: 'Ruda Bold';
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%; /* 52.8px */
}
.text-mint{
    color: #5DBAA9;
    font-family: "Rubik One";
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
}
.bg-tf-compromiso{
    background-color: #F8F8F8;
}
@media screen and (max-width:1440px){
    .tch-title{
        font-size: 48px;
    }
    .square{
        min-height: auto;
        padding: 3rem;
    }
    .onu-title{
        padding-right: 6rem;
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: 130%;
        text-align: start;
    }

    .onu-par{
        color: #FFF;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .img-thumbnial{
        width: 100px;
        height: auto;
        padding: 1.5rem;
    }

    .square .tch-par{
        color: #FFF;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }
    .ini-title{
        color: #5DBAA9;
        text-align: center;
        font-family: "Rubik One";
        font-size: 52px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
    .tf-compromiso{
        text-align: center;
        font-family: Ruda;
        font-size: 40px;
    }
    .text-mint{
        font-family: "Rubik One";
        font-size: 40px;
    }
}
@media screen and (max-width:1366px){
    .square{
        min-height: auto;
        padding: 3rem;
    }
    .onu-title{
        padding-right: 6rem;
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 130%;
        text-align: start;
    }

    .onu-par{
        color: #FFF;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .img-thumbnial{
        width: 108px;
        height: auto;
        padding: 1.5rem;
    }

    .square .tch-par{
        color: #FFF;
        font-family: Lato;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }
    .tf-compromiso{
        text-align: center;
        font-family: Ruda;
        font-size: 34px;
    }
    .text-mint{
        font-family: "Rubik One";
        font-size: 34px;
    }
}

@media screen and (max-width:1024px){
    .square{
        min-height: auto;
        padding: 3rem;
    }
    .onu-title{
        padding-right: 6rem;
    }

    .img-thumbnial{
        width: 250px;
        height: auto;
        padding: 1.5rem;
    }

    .square .tch-par{
        color: #FFF;
        font-family: Lato;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }
    .tf-compromiso{
        text-align: center;
        font-family: Ruda;
        font-size: 30px;
    }
    .text-mint{
        font-family: "Rubik One";
        font-size: 30px;
    }
}

@media screen and (min-width:768px) and (orientation:portrait){
    .square{
        min-height: auto;
        padding: 3rem;
    }
    .onu-title{
        padding-right: 3rem;
        padding-left: 3rem;
        text-align: center;
        color: #FFF;
        text-align: center;
        font-family: 'Lato Bold';
        font-size: 46px;
        font-style: normal;
        font-weight: 800;
        line-height: 130%; /* 28.6px */
    }

    .onu-par{
        color: #FFF;
        text-align: center;
        font-family: Lato;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 28.6px */
    }

    .img-thumbnial{
        width: 48%;
        height: auto;
        padding: 1rem;
    }
    .tf-tch .col-lg-4{
        width: 100%;
    }
    .tf-compromiso{
        text-align: center;
        font-family: Ruda;
        font-size: 32px;
    }
    .text-mint{
        font-family: "Rubik One";
        font-size: 32px;
    }
    .ini-title {
        text-align: center;
        font-family: "Rubik One";
        font-size: 48px;
    }
    .tch-title {
        font-size: 32px;
    }

    .tch-link{
        color: #FFF;
        text-align: center;
        font-family: "Rubik One";
        font-size: 46px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; /* 49px */
    }
}
@media screen and (max-width:600px){
    .tch-title{
        font-size: 1.785rem;
    }
    .square{
        min-height: 210px;
        max-height: 500px;
        padding: 2rem 2rem;
    }
    .onu-title{
        color: #FFF;
        text-align: center;
        font-family: 'Lato Bold';
        font-size: 22px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        padding-right: unset;
    }
    .onu-par{
        font-size: 20px;
    }
    .img-thumbnial{
        width: 160px;
        height: auto;
        padding: 1rem;
    }
    .square .tch-par{
        font-size: 18px;
        line-height: 1.725rem;
    }
    .tch-link{
        font-size: 22px;
    }
    .sub-title .bold{
        font-weight: 400;
    }
    .tf-compromiso {
        text-align: center;
        font-family: Ruda;
        font-size: 20px;
    }
    .text-mint {
        font-family: "Rubik One";
        font-size: 20px;
    }
    .bg-tf-compromiso{
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .ini-title{
        font-size: 22px;
        padding-top: 1.5rem;
    }
    .tch-title {
        font-size: 20px;

    }
    .square .tch-par{
        text-align: center;
    }
}