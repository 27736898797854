.Acciones {
  width: 100%;
  background-color: #E9EDF0;
  background-image: url('../../../assets/images/acciones/bg-actions.png');
}
  .Acciones .title {
    color: #69747C;
    font-family: "Rubik One";
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    text-transform: uppercase;
    width: 100%;
    margin-top: 36px;
    text-align: center;
    padding: 0 3.5rem;
  }

  .Acciones .cards-gallery {
    margin-top: 36px;
    background-image: url('../../../assets/images/acciones/bg-actions-btm-m.png');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 70%;
  }

  /* Tablet  */
  @media screen and (min-width: 768px) {
    .Acciones {
      background-repeat: no-repeat;
    .title {
      color: #69747C;
      text-align: center;
      font-family: "Rubik One";
      font-size: 62px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      width: 100%;
      margin-top: 80px;
    }
  
    .cards-gallery {
      margin-top: 66px;
      padding-bottom: 6rem;
    }
  }
  }
  /* web  */
  @media (min-width: 1024px) {
    .Acciones {
      background-image: url('../../../assets/images/acciones/bg-actions-desk.png');
      background-position: right top;
    
    .cards-gallery {
      margin-top: 36px;
      background-image: url('../../../assets/images/acciones/bg-actions-btm.png');
      background-repeat: no-repeat;
      background-position: bottom right;
      padding-bottom: 1.5rem;
    }
  }
  }

/* web  */
@media (min-width: 1440px) {
  .Acciones {
    background-image: url('../../../assets/images/acciones/bg-actions-desk.png');
    background-position: right top;

    .title {
      color: #69747C;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  
    .cards-gallery {
      margin-top: 36px;
      background-image: url('../../../assets/images/acciones/bg-actions-btm.png');
      background-repeat: no-repeat;
      background-position: bottom right;
      padding-bottom: 1.5rem;
    }
  }
}