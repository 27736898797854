.HeaderSection {
  position: relative;
  height: 100vh; /* Set the hero height to cover the entire viewport */
  overflow: hidden; /* Hide any overflow */
}

.HeaderSection .hero-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire hero area */
}

.HeaderSection .hero-content {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the content horizontally and vertically */
  text-align: center;
  color: #fff; /* Set text color to white */
  width: 100%;
}

.HeaderSection h1 {
  color: #FFF;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(80, 80, 80, 0.82);
  font-family: "Rubik One";
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.mov-35{
  object-position: 35%;
}
.mov-38{
  object-position: 38%;
}
.mov-40{
  object-position: 40%;
}


  @media (min-width: 768px) {
    .HeaderSection .hero-content {
      position: absolute;
      top: 85%;
      left: 50%;
      transform: translate(-50%, -50%); /* Center the content horizontally and vertically */
      text-align: center;
      color: #fff; /* Set text color to white */
      width: 100%;
    }
    
    .HeaderSection h1 {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(80, 80, 80, 0.82);
      font-family: "Rubik One";
      font-size: 56px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  @media (min-width: 1024px) {
    .HeaderSection .hero-content {
      position: absolute;
      top: 85%;
      left: 50%;
      transform: translate(-50%, -50%); /* Center the content horizontally and vertically */
      text-align: center;
      color: #fff; /* Set text color to white */
      width: 100%;
    }
    
    .HeaderSection h1 {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(80, 80, 80, 0.82);
      font-family: "Rubik One";
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  @media (min-width: 1280px) {
    .HeaderSection .hero-content {
      position: absolute;
      top: 85%;
      left: 50%;
      transform: translate(-50%, -50%); /* Center the content horizontally and vertically */
      text-align: center;
      color: #fff; /* Set text color to white */
      width: 100%;
    }
    
    .HeaderSection h1 {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(80, 80, 80, 0.82);
      font-family: "Rubik One";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  @media (min-width: 1366px) {
    .HeaderSection .hero-content {
      position: absolute;
      top: 80%;
      left: 50%;
      transform: translate(-50%, -50%); /* Center the content horizontally and vertically */
      text-align: center;
      color: #fff; /* Set text color to white */
      width: 100%;
    }
    
    .HeaderSection h1 {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(80, 80, 80, 0.82);
      font-family: "Rubik One";
      font-size: 76px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  @media (min-width: 1440px) {
    .HeaderSection .hero-content {
      position: absolute;
      top: 80%;
      left: 50%;
      transform: translate(-50%, -50%); /* Center the content horizontally and vertically */
      text-align: center;
      color: #fff; /* Set text color to white */
      width: 100%;
    }
    
    .HeaderSection h1 {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(80, 80, 80, 0.82);
      font-family: "Rubik One";
      font-size: 70px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  @media (min-width: 1920px) {
    .HeaderSection .hero-content {
      position: absolute;
      top: 85%;
      left: 50%;
      transform: translate(-50%, -50%); /* Center the content horizontally and vertically */
      text-align: center;
      color: #fff; /* Set text color to white */
      width: 100%;
    }
    
    .HeaderSection h1 {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(80, 80, 80, 0.82);
      font-family: "Rubik One";
      font-size: 82px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  @media (min-width: 2560px) {
    .HeaderSection .hero-content {
      position: absolute;
      top: 85%;
      left: 50%;
      transform: translate(-50%, -50%); /* Center the content horizontally and vertically */
      text-align: center;
      color: #fff; /* Set text color to white */
      width: 100%;
    }
    
    .HeaderSection h1 {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(80, 80, 80, 0.82);
      font-family: "Rubik One";
      font-size: 82px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  }
