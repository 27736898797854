.NuestrosProgramas {
  width: 100%;
}

.NuestrosProgramas .title {
  color: #019294;
  font-family: "Rubik One";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 1rem;
}

.NuestrosProgramas .paragraph {
  color: #1F282C;
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 150% */
  padding-left: 3rem;
  padding-right: 3rem;
}

.NuestrosProgramas .paragraph b {
  font-family: 'Lato Bold';
  font-weight: bolder;
}

.NuestrosProgramas .custom-width {
  width: 80%;
}

.NuestrosProgramas .bg-blue {
  background-color: #56BBA8;
}

.NuestrosProgramas .paragraph-2 {
  color: #E9EDF0;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 2rem;
}

.NuestrosProgramas .paragraph-2 b {
  font-family: 'Lato Bold';
  font-weight: bolder;
}

.NuestrosProgramas .subtitle-programas {
  color: #000;
  text-align: center;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 42px */

  width: 90%;

  b {
    color: #000;
    font-family: 'Lato Bold';
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
  }
}

.NuestrosProgramas .title-programas {
  color: #56BBA8;
  font-family: "Rubik One";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 74.4px */
  text-transform: uppercase;
  text-align: center;

  width: 70%;
}

.NuestrosProgramas .bg-bluelight {
  background: #56BBA8;
}

.NuestrosProgramas .programas-list {
  text-align: left;
  .programa-name {
    color: #E9EDF0;
    font-family: 'Lato Bold';
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 60px */
  }
}

.NuestrosProgramas .bg-programa1 {
  border: 1px solid rgba(206, 206, 206, 0.33);
  background: #45BCD8;
}
.NuestrosProgramas .bg-programa1.icon-mas:hover {
  background: #4d9fba;
}
.NuestrosProgramas .bg-programa2 {
  border: 1px solid rgba(206, 206, 206, 0.33);
  background: #E8BA4A;
}
.NuestrosProgramas .bg-programa2.icon-mas:hover {
  background: #c59d40;
}

.NuestrosProgramas .bg-programa3 {
  border: 1px solid rgba(206, 206, 206, 0.33);
  background: #FF8434;
}
.NuestrosProgramas .bg-programa3.icon-mas:hover {
  background: #cb6626;
}

.NuestrosProgramas .bg-programa4 {
  border: 1px solid rgba(206, 206, 206, 0.33);
  background: #A55CC2;
}
.NuestrosProgramas .bg-programa4.icon-mas:hover {
  background: #7b3ca0;
}

.NuestrosProgramas .programa-tab-name {
  color: #000;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  font-family: 'Lato Bold';
  font-size: 17px;
  font-style: normal;
  font-weight: bolder;
  line-height: 120%; /* 36px */
  text-transform: uppercase;
}

.NuestrosProgramas .programa-tab-name:hover, 
.NuestrosProgramas .programa-tab-name2:hover,
.NuestrosProgramas .programa-tab-name3:hover,
.NuestrosProgramas .programa-tab-name4:hover {
  color: #FFF;
}

.NuestrosProgramas .programa-tab-name2 {
  color: #000;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  font-family: 'Lato Bold';
  font-size: 17px;
  font-style: normal;
  font-weight: bolder;
  line-height: 120%; /* 36px */
  text-transform: uppercase;
}

.NuestrosProgramas .programa-tab-name3 {
  color: #000;
  text-align: center;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  font-family: 'Lato Bold';
  font-size: 17px;
  font-style: normal;
  font-weight: bolder;
  line-height: 120%; /* 36px */
  text-transform: uppercase;
}

.NuestrosProgramas .programa-tab-name4 {
  color: #000;
  text-align: center;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  font-family: 'Lato Bold';
  font-size: 17px;
  font-style: normal;
  font-weight: bolder;
  line-height: 120%; /* 36px */
  text-transform: uppercase;
}

.NuestrosProgramas .programa-tab-name-active {
  /* border-bottom: 2px solid #FFF;
  padding-bottom: 2px; */
  font-size: 26px;
  color: #FFF;
}

.NuestrosProgramas .programa-tab-circle {
  border-radius: 20px;
  background: #F5F5F5;
  width: 40px;
  height: 40px;
}

.NuestrosProgramas .tab-active {
  background: #363636;
}

.NuestrosProgramas .tab-programa-title {
  color: #FFFFFF;
  font-family: "Rubik One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 60px */
}

.NuestrosProgramas .tab-programa-description {
  color: #FFFFFF;
  text-align: center;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 36px */

  b {
    color: #FFFFFF;
    font-family: 'Lato Bold';
    font-size: 16px;
    font-style: normal;
    font-weight: bolder;
    line-height: 120%;
  }
}
.NuestrosProgramas .tab-programa-description b{
  color: #FFFFFF;
  font-family: 'Lato Bold';
  font-size: inherit;
  font-style: normal;
  font-weight: bolder;
  line-height: 120%;
}

.NuestrosProgramas .program-text-description {
  color: #000;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 157.143% */
  text-align: center;

  b {
    color: #000;
    font-family: 'Lato Bold';
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
  }
}
.NuestrosProgramas .program-text-description   b {
  color: #000;
  font-family: 'Lato Bold';
  font-size: inherit;
  font-style: normal;
  font-weight: 800;
  line-height: 28px;
}

.NuestrosProgramas .program-button-conoce-mas-blue {
  padding: 1rem 3rem;
  background-color: #45BCD8;
  color: #000000;
  font-size: 1rem;
  width: 15rem;
  border-radius: 50px;
  font-family: 'Lato';
  font-weight: 800;
}

.NuestrosProgramas .program-button-conoce-mas-yellow {
  padding: 1rem 3rem;
  background-color: #E8BA4A;
  color: #000000;
  font-size: 1rem;
  width: 15rem;
  border-radius: 50px;
  font-family: 'Lato';
  font-weight: 800;
}

.NuestrosProgramas .program-button-conoce-mas-orange {
  padding: 1rem 3rem;
  background-color: #FF8434;
  color: #000000;
  font-size: 1rem;
  width: 15rem;
  border-radius: 50px;
  font-family: 'Lato';
  font-weight: 800;
}

.NuestrosProgramas .program-button-conoce-mas-purple {
  padding: 1rem 3rem;
  background-color: #A55CC2;
  color: #000000;
  font-size: 1rem;
  width: 15rem;
  border-radius: 50px;
  font-family: 'Lato';
  font-weight: 800;
}

.NuestrosProgramas .program-button-conoce-mas-blue:hover {
  border: solid 2px #45BCD8;
  color: #fff;
  background-color: #45BCD880;
}

.NuestrosProgramas .program-button-conoce-mas-yellow:hover {
  border: solid 2px #E8BA4A;
  color: #fff;
  background-color: #E8BA4A80;
}

.NuestrosProgramas .program-button-conoce-mas-orange:hover {
  border: solid 2px #FF8434;
  color: #fff;
  background-color: #FF843480;
}

.NuestrosProgramas .program-button-conoce-mas-purple:hover {
  border: solid 2px #A55CC2;
  color: #fff;
  background-color: #A55CC280;
}

.NuestrosProgramas .bg-close {
  background-color: #216C95;
  color: #ffffff;
  font-size: 2rem;
  font-family: 'Lato Bold';
}

.NuestrosProgramas .programa-logo {
  width: 280px;
  max-height: 170px;
  object-fit: cover;
}
.NuestrosProgramas .programa-logo#empieza-temprano{
  max-height: 140px;
  width: auto;
}

.NuestrosProgramas .bg-gray-title {
  background-color: #E9EDF0;
}
.NuestrosProgramas .bg-programa1.icon-mas{
  background-image: url('../../../assets/images/iconos/masaz.svg');
  background-position: 95% 50%;
  background-repeat: no-repeat;
}

.NuestrosProgramas .bg-programa4.icon-mas{
  background-image: url('../../../assets/images/iconos/masmo.svg');
  background-position: 95% 50%;
  background-repeat: no-repeat;
}

.NuestrosProgramas .bg-programa3.icon-mas{
  background-image: url('../../../assets/images/iconos/masna.svg');
  background-position: 95% 50%;
  background-repeat: no-repeat;
}

.NuestrosProgramas .bg-programa2.icon-mas{
  background-image: url('../../../assets/images/iconos/masam.svg');
  background-position: 95% 50%;
  background-repeat: no-repeat;
}

.NuestrosProgramas .container-float-button {
  height: auto; /* Just for demonstration to create scroll */
  position: relative;
}

.NuestrosProgramas .floating-button {
  position: fixed;
  top: 5px;
  left: 1px;
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 999; /* Ensure it's above other content */
}

.NuestrosProgramas .back-button {
  width: 100px;
}

.NuestrosProgramas .programa-active {
  border-bottom: 3px solid #FFF;
  padding-top: 0px;
  width: 35%;
  margin-top: -15px;
  
}


  @media (min-width: 768px) {
    .NuestrosProgramas .title {
      color: #019294;
      font-family: "Rubik One";
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background: none;
      padding: 1rem;
    }

    .NuestrosProgramas .paragraph {
      color: #1F282C;
      text-align: center;
      font-family: Lato;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 124%; /* 29.76px */
      padding-left: 3rem;
      padding-right: 3rem;
    }

    .NuestrosProgramas .paragraph b {
      font-family: 'Lato Bold';
      font-weight: bolder;
    }

    .NuestrosProgramas .custom-width {
      width: 90%;
    }

    .NuestrosProgramas .bg-blue {
      background-color: #56BBA8;
    }

    .NuestrosProgramas .paragraph-2 {
      color: #E9EDF0;
      font-family: Lato;
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 108%;
      padding: 3rem;
    }

    .NuestrosProgramas .paragraph-2 b {
      font-family: 'Lato Bold';
      font-weight: bolder;
    }

    .NuestrosProgramas .subtitle-programas {
      color: #000;
      text-align: center;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 42px */
      width: 90%;
    }

    .NuestrosProgramas .subtitle-programas b {
      color: #000;
      font-family: 'Lato Bold';
      font-size: 25px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
    }

    .NuestrosProgramas .title-programas {
      color: #56BBA8;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 74.4px */
      text-transform: uppercase;
      text-align: center;
      width: 70%;
    }

    .NuestrosProgramas .bg-bluelight {
      background: #56BBA8;
    }

    .NuestrosProgramas .programas-list {
      text-align: left;
    }

    .NuestrosProgramas .programa-name {
      color: #E9EDF0;
      font-family: 'Lato Bold';
      font-size: 35px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 60px */
    }

    .NuestrosProgramas .bg-programa1 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #45BCD8;
    }

    .NuestrosProgramas .bg-programa2 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #E8BA4A;
    }

    .NuestrosProgramas .bg-programa3 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #FF8434;
    }

    .NuestrosProgramas .bg-programa4 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #A55CC2;
    }

    .NuestrosProgramas .programa-tab-name,
    .NuestrosProgramas .programa-tab-name2,
    .NuestrosProgramas .programa-tab-name3,
    .NuestrosProgramas .programa-tab-name4 {
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      font-family: 'Lato Bold';
      font-size: 22px;
      font-style: normal;
      font-weight: bolder;
      line-height: 120%; /* 43.2px */
      text-transform: uppercase;
    }

    .NuestrosProgramas .programa-tab-name-active {
      font-size: 32px;
    }

    .NuestrosProgramas .programa-tab-circle {
      border-radius: 20px;
      background: #F5F5F5;
      width: 40px;
      height: 40px;
    }

    .NuestrosProgramas .tab-active {
      background: #363636;
    }

    .NuestrosProgramas .tab-programa-title {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 44px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 60px */
    }

    .NuestrosProgramas .tab-programa-description {
      color: #FFFFFF;
      text-align: center;
      font-family: Lato;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 36px */
    }

    .NuestrosProgramas .tab-programa-description b {
      color: #FFFFFF;
      font-family: 'Lato Bold';
      font-size: 30px;
      font-style: normal;
      font-weight: bolder;
      line-height: 120%;
    }

    .NuestrosProgramas .program-text-description {
      color: #000;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px; /* 157.143% */
      text-align: center;
    }

    .NuestrosProgramas .program-text-description b {
      color: #000;
      font-family: 'Lato Bold';
      font-size: 25px;
      font-style: normal;
      font-weight: 800;
      line-height: 40px;
    }

    .NuestrosProgramas .program-button-conoce-mas-blue,
    .NuestrosProgramas .program-button-conoce-mas-yellow,
    .NuestrosProgramas .program-button-conoce-mas-orange,
    .NuestrosProgramas .program-button-conoce-mas-purple {
      padding: 1rem 3rem;
      color: #000000;
      font-size: 1.5rem;
      width: 20rem;
      border-radius: 50px;
      font-family: 'Lato';
      font-weight: 800;
    }

    .NuestrosProgramas .program-button-conoce-mas-blue {
      background-color: #45BCD8;
    }

    .NuestrosProgramas .program-button-conoce-mas-yellow {
      background-color: #E8BA4A;
    }

    .NuestrosProgramas .program-button-conoce-mas-orange {
      background-color: #FF8434;
    }

    .NuestrosProgramas .program-button-conoce-mas-purple {
      background-color: #A55CC2;
    }

    .NuestrosProgramas .program-button-conoce-mas-blue:hover {
      border: solid 2px #45BCD8;
      color: #fff;
      background-color: #45BCD880;
    }

    .NuestrosProgramas .program-button-conoce-mas-yellow:hover {
      border: solid 2px #E8BA4A;
      color: #fff;
      background-color: #E8BA4A80;
    }

    .NuestrosProgramas .program-button-conoce-mas-orange:hover {
      border: solid 2px #FF8434;
      color: #fff;
      background-color: #FF843480;
    }

    .NuestrosProgramas .program-button-conoce-mas-purple:hover {
      border: solid 2px #A55CC2;
      color: #fff;
      background-color: #A55CC280;
    }

    .NuestrosProgramas .bg-close {
      background-color: #216C95;
      color: #ffffff;
      font-size: 2rem;
      font-family: 'Lato Bold';
    }

    .NuestrosProgramas .programa-logo {
      width: 250px;
    }

    .NuestrosProgramas .programa-logo {
      width: 300px;
      max-height: unset;
      object-fit: unset;
    }

    .NuestrosProgramas .programa-logo#empieza-temprano {
      max-height: unset;
    }

    .NuestrosProgramas .floating-button {
      position: fixed;
      top: 10px;
      left: 10px;
      background-color: transparent;
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      z-index: 999; /* Ensure it's above other content */
    }

    .NuestrosProgramas .back-button {
      width: 150px;
    }

    .NuestrosProgramas .programa-logo#empieza-temprano {
      max-height: 200px;
      width: auto;
    }
}

@media (min-width: 1024px) {
  .NuestrosProgramas .bg-gray {
      background: #E9EDF0;
  }

  .NuestrosProgramas .title {
      color: #019294;
      font-family: "Rubik One";
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
  }

  .NuestrosProgramas .paragraph {
      color: #1F282C;
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 124%; /* 29.76px */
      text-align: left;
      padding-left: 3rem;
      padding-right: 5rem;
  }

  .NuestrosProgramas .paragraph b {
      font-family: 'Lato Bold';
      font-weight: bolder;
  }

  .NuestrosProgramas .custom-width {
      width: 60%;
  }

  .NuestrosProgramas .bg-blue {
      background-color: #56BBA8;
  }

  .NuestrosProgramas .paragraph-2 {
      color: #E9EDF0;
      font-family: Lato;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 108%;
      padding: 3rem 3rem;
  }

  .NuestrosProgramas .paragraph-2 b {
      font-family: 'Lato Bold';
      font-weight: bolder;
  }

  .NuestrosProgramas .subtitle-programas {
      color: #000;
      text-align: center;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 42px */
      width: 90%;
  }

  .NuestrosProgramas .subtitle-programas b {
      color: #000;
      font-family: 'Lato Bold';
      font-size: 25px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
  }

  .NuestrosProgramas .title-programas {
      color: #56BBA8;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 74.4px */
      text-transform: uppercase;
      text-align: center;
      width: 70%;
  }

  .NuestrosProgramas .bg-bluelight {
      background: #56BBA8;
  }

  .NuestrosProgramas .programas-list {
      text-align: left;
  }

  .NuestrosProgramas .programa-name {
      color: #E9EDF0;
      font-family: 'Lato Bold';
      font-size: 25px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 60px */
  }

  .NuestrosProgramas .bg-programa1 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #45BCD8;
  }

  .NuestrosProgramas .bg-programa2 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #E8BA4A;
  }

  .NuestrosProgramas .bg-programa3 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #FF8434;
  }

  .NuestrosProgramas .bg-programa4 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #A55CC2;
  }

  .NuestrosProgramas .programa-tab-name,
  .NuestrosProgramas .programa-tab-name2,
  .NuestrosProgramas .programa-tab-name3,
  .NuestrosProgramas .programa-tab-name4 {
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      font-family: 'Lato Bold';
      font-size: 12px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 36px */
      text-transform: uppercase;
  }

  .NuestrosProgramas .programa-tab-name-active {
      font-size: 14px;
  }

  .NuestrosProgramas .programa-tab-circle {
      border-radius: 20px;
      background: #F5F5F5;
      width: 40px;
      height: 40px;
  }

  .NuestrosProgramas .tab-active {
      background: #363636;
  }

  .NuestrosProgramas .tab-programa-title {
      color: #E9EDF0;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 60px */
  }

  .NuestrosProgramas .tab-programa-description {
      color: #FFFFFF;
      text-align: center;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 36px */
  }

  .NuestrosProgramas .tab-programa-description b {
      color: #FFFFFF;
      font-family: 'Lato Bold';
      font-size: 20px;
      font-style: normal;
      font-weight: bolder;
      line-height: 120%;
  }

  .NuestrosProgramas .program-text-description {
      color: #000;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px; /* 157.143% */
      text-align: left;
  }

  .NuestrosProgramas .program-text-description b {
      color: #000;
      font-family: 'Lato Bold';
      font-size: 25px;
      font-style: normal;
      font-weight: 800;
      line-height: 40px;
  }

  .NuestrosProgramas .program-button-conoce-mas-blue,
  .NuestrosProgramas .program-button-conoce-mas-yellow,
  .NuestrosProgramas .program-button-conoce-mas-orange,
  .NuestrosProgramas .program-button-conoce-mas-purple {
      padding: 1rem 3rem;
      color: #000000;
      font-size: 1.5rem;
      width: 20rem;
      border-radius: 50px;
      font-family: 'Lato';
      font-weight: 800;
  }

  .NuestrosProgramas .program-button-conoce-mas-blue {
      background-color: #45BCD8;
  }

  .NuestrosProgramas .program-button-conoce-mas-yellow {
      background-color: #E8BA4A;
  }

  .NuestrosProgramas .program-button-conoce-mas-orange {
      background-color: #FF8434;
  }

  .NuestrosProgramas .program-button-conoce-mas-purple {
      background-color: #A55CC2;
  }

  .NuestrosProgramas .program-button-conoce-mas-blue:hover {
      border: solid 2px #45BCD8;
      color: #fff;
      background-color: #45BCD880;
  }

  .NuestrosProgramas .program-button-conoce-mas-yellow:hover {
      border: solid 2px #E8BA4A;
      color: #fff;
      background-color: #E8BA4A80;
  }

  .NuestrosProgramas .program-button-conoce-mas-orange:hover {
      border: solid 2px #FF8434;
      color: #fff;
      background-color: #FF843480;
  }

  .NuestrosProgramas .program-button-conoce-mas-purple:hover {
      border: solid 2px #A55CC2;
      color: #fff;
      background-color: #A55CC280;
  }

  .NuestrosProgramas .bg-close {
      background-color: #216C95;
      color: #ffffff;
      font-size: 2rem;
      font-family: 'Lato Bold';
  }

  .NuestrosProgramas .programa-logo {
      width: 340px;
  }

  .NuestrosProgramas .floating-button {
      position: fixed;
      top: 20px;
      left: 20px;
      background-color: transparent;
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      z-index: 999; /* Ensure it's above other content */
  }

  .NuestrosProgramas .programa-logo#empieza-temprano {
      max-height: 150px;
      width: auto;
  }
}


@media (min-width: 1280px) {
  .NuestrosProgramas .z-10 {
      z-index: 10;
  }

  .NuestrosProgramas .bg-gray {
      background: #E9EDF0;
  }

  .NuestrosProgramas .title {
      color: #019294;
      font-family: "Rubik One";
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
  }

  .NuestrosProgramas .paragraph {
      color: #1F282C;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 124%; /* 29.76px */
      text-align: left;
      padding-left: 3rem;
      padding-right: 5rem;
  }

  .NuestrosProgramas .paragraph b {
      font-family: 'Lato Bold';
      font-weight: bolder;
  }

  .NuestrosProgramas .custom-width {
      width: 60%;
  }

  .NuestrosProgramas .bg-blue {
      background-color: #56BBA8;
  }

  .NuestrosProgramas .paragraph-2 {
      color: #E9EDF0;
      font-family: Lato;
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 108%;
      padding: 3rem 4rem;
  }

  .NuestrosProgramas .paragraph-2 b {
      font-family: 'Lato Bold';
      font-weight: bolder;
  }

  .NuestrosProgramas .subtitle-programas {
      color: #000;
      text-align: center;
      font-family: Lato;
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 42px */
      width: 90%;
  }

  .NuestrosProgramas .subtitle-programas b {
      color: #000;
      font-family: 'Lato Bold';
      font-size: 35px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
  }

  .NuestrosProgramas .title-programas {
      color: #56BBA8;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 74.4px */
      text-transform: uppercase;
      text-align: center;
      width: 70%;
  }

  .NuestrosProgramas .bg-bluelight {
      background: #56BBA8;
  }

  .NuestrosProgramas .programas-list {
      text-align: left;
  }

  .NuestrosProgramas .programa-name {
      color: #E9EDF0;
      font-family: 'Lato Bold';
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 60px */
  }

  .NuestrosProgramas .bg-programa1 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #45BCD8;
  }

  .NuestrosProgramas .bg-programa2 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #E8BA4A;
  }

  .NuestrosProgramas .bg-programa3 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #FF8434;
  }

  .NuestrosProgramas .bg-programa4 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #A55CC2;
  }

  .NuestrosProgramas .programa-tab-name,
  .NuestrosProgramas .programa-tab-name2,
  .NuestrosProgramas .programa-tab-name3,
  .NuestrosProgramas .programa-tab-name4 {
      font-family: 'Lato Bold';
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 28.8px */
  }

  .NuestrosProgramas .programa-tab-name2 {
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      text-transform: uppercase;
  }

  .NuestrosProgramas .programa-tab-name3,
  .NuestrosProgramas .programa-tab-name4 {
      text-align: center;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      text-transform: uppercase;
  }

  .NuestrosProgramas .programa-tab-name-active {
      font-size: 22px;
  }

  .NuestrosProgramas .programa-tab-circle {
      border-radius: 20px;
      background: #F5F5F5;
      width: 40px;
      height: 40px;
  }

  .NuestrosProgramas .tab-active {
      background: #363636;
  }

  .NuestrosProgramas .tab-programa-title {
      color: #E9EDF0;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 60px */
  }

  .NuestrosProgramas .tab-programa-description {
      color: #FFFFFF;
      text-align: center;
      font-family: Lato;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 36px */
  }

  .NuestrosProgramas .tab-programa-description b {
      color: #FFFFFF;
      font-family: 'Lato Bold';
      font-size: 30px;
      font-style: normal;
      font-weight: bolder;
      line-height: 120%;
  }

  .NuestrosProgramas .program-text-description {
      color: #000;
      font-family: Lato;
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 55px; /* 157.143% */
      text-align: left;
  }

  .NuestrosProgramas .program-text-description b {
      color: #000;
      font-family: 'Lato Bold';
      font-size: 35px;
      font-style: normal;
      font-weight: 800;
      line-height: 55px;
  }

  .NuestrosProgramas .program-button-conoce-mas-blue,
  .NuestrosProgramas .program-button-conoce-mas-yellow,
  .NuestrosProgramas .program-button-conoce-mas-orange,
  .NuestrosProgramas .program-button-conoce-mas-purple {
      padding: 1rem 3rem;
      color: #000000;
      font-size: 1.5rem;
      width: 20rem;
      border-radius: 50px;
      font-family: 'Lato';
      font-weight: 800;
  }

  .NuestrosProgramas .program-button-conoce-mas-blue {
      background-color: #45BCD8;
  }

  .NuestrosProgramas .program-button-conoce-mas-yellow {
      background-color: #E8BA4A;
  }

  .NuestrosProgramas .program-button-conoce-mas-orange {
      background-color: #FF8434;
  }

  .NuestrosProgramas .program-button-conoce-mas-purple {
      background-color: #A55CC2;
  }

  .NuestrosProgramas .program-button-conoce-mas-blue:hover {
      border: solid 2px #45BCD8;
      color: #fff;
      background-color: #45BCD880;
  }

  .NuestrosProgramas .program-button-conoce-mas-yellow:hover {
      border: solid 2px #E8BA4A;
      color: #fff;
      background-color: #E8BA4A80;
  }

  .NuestrosProgramas .program-button-conoce-mas-orange:hover {
      border: solid 2px #FF8434;
      color: #fff;
      background-color: #FF843480;
  }

  .NuestrosProgramas .program-button-conoce-mas-purple:hover {
      border: solid 2px #A55CC2;
      color: #fff;
      background-color: #A55CC280;
  }

  .NuestrosProgramas .bg-close {
      background-color: #216C95;
      color: #ffffff;
      font-size: 2rem;
      font-family: 'Lato Bold';
  }

  .NuestrosProgramas .floating-button {
      position: fixed;
      top: 20px;
      left: 20px;
      background-color: transparent;
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      z-index: 999; /* Ensure it's above other content */
  }

  .NuestrosProgramas .programa-logo#empieza-temprano {
      max-height: 150px;
      width: auto;
  }
}


@media (min-width: 1366px) {
  .NuestrosProgramas .bg-gray {
      background: #E9EDF0;
  }

  .NuestrosProgramas .title {
      color: #019294;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
  }

  .NuestrosProgramas .paragraph {
      color: #1F282C;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 124%; /* 29.76px */
      text-align: left;
      padding-left: 3rem;
      padding-right: 5rem;
  }

  .NuestrosProgramas .paragraph b {
      font-family: 'Lato Bold';
      font-weight: bolder;
  }

  .NuestrosProgramas .custom-width {
      width: 60%;
  }

  .NuestrosProgramas .bg-blue {
      background-color: #56BBA8;
  }

  .NuestrosProgramas .paragraph-2 {
      color: #E9EDF0;
      font-family: Lato;
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      line-height: 108%;
      padding: 3rem 7rem;
  }

  .NuestrosProgramas .paragraph-2 b {
      font-family: 'Lato Bold';
      font-weight: bolder;
  }

  .NuestrosProgramas .subtitle-programas {
      color: #000;
      text-align: center;
      font-family: Lato;
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 42px */
      width: 90%;
  }

  .NuestrosProgramas .subtitle-programas b {
      color: #000;
      font-family: 'Lato Bold';
      font-size: 35px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
  }

  .NuestrosProgramas .title-programas {
      color: #56BBA8;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 74.4px */
      text-transform: uppercase;
      text-align: center;
      width: 70%;
  }

  .NuestrosProgramas .bg-bluelight {
      background: #56BBA8;
  }

  .NuestrosProgramas .programas-list {
      text-align: left;
  }

  .NuestrosProgramas .programa-name {
      color: #E9EDF0;
      font-family: 'Lato Bold';
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 60px */
  }

  .NuestrosProgramas .bg-programa1 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #45BCD8;
  }

  .NuestrosProgramas .bg-programa2 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #E8BA4A;
  }

  .NuestrosProgramas .bg-programa3 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #FF8434;
  }

  .NuestrosProgramas .bg-programa4 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #A55CC2;
  }

  .NuestrosProgramas .programa-tab-name,
  .NuestrosProgramas .programa-tab-name2,
  .NuestrosProgramas .programa-tab-name3,
  .NuestrosProgramas .programa-tab-name4 {
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      font-family: 'Lato Bold';
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 36px */
      text-transform: uppercase;
  }

  .NuestrosProgramas .programa-tab-name-active {
      font-size: 26px;
  }

  .NuestrosProgramas .programa-tab-circle {
      border-radius: 20px;
      background: #F5F5F5;
      width: 40px;
      height: 40px;
  }

  .NuestrosProgramas .tab-active {
      background: #363636;
  }

  .NuestrosProgramas .tab-programa-title {
      color: #FFFFFF;
      font-family: "Rubik One";
      font-size: 57px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 60px */
  }

  .NuestrosProgramas .tab-programa-description {
      color: #FFFFFF;
      text-align: center;
      font-family: Lato;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 36px */
  }

  .NuestrosProgramas .tab-programa-description b {
      color: #FFFFFF;
      font-family: 'Lato Bold';
      font-size: 30px;
      font-style: normal;
      font-weight: bolder;
      line-height: 120%;
  }

  .NuestrosProgramas .program-text-description {
      color: #000;
      font-family: Lato;
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 45px; /* 157.143% */
      text-align: left;
  }

  .NuestrosProgramas .program-text-description b {
      color: #000;
      font-family: 'Lato Bold';
      font-size: 35px;
      font-style: normal;
      font-weight: 800;
      line-height: 45px;
  }

  .NuestrosProgramas .program-button-conoce-mas-blue,
  .NuestrosProgramas .program-button-conoce-mas-yellow,
  .NuestrosProgramas .program-button-conoce-mas-orange,
  .NuestrosProgramas .program-button-conoce-mas-purple {
      padding: 1rem 3rem;
      color: #000000;
      font-size: 1.5rem;
      width: 20rem;
      border-radius: 50px;
      font-family: 'Lato';
      font-weight: 800;
  }

  .NuestrosProgramas .program-button-conoce-mas-blue {
      background-color: #45BCD8;
  }

  .NuestrosProgramas .program-button-conoce-mas-yellow {
      background-color: #E8BA4A;
  }

  .NuestrosProgramas .program-button-conoce-mas-orange {
      background-color: #FF8434;
  }

  .NuestrosProgramas .program-button-conoce-mas-purple {
      background-color: #A55CC2;
  }

  .NuestrosProgramas .program-button-conoce-mas-blue:hover {
      border: solid 2px #45BCD8;
      color: #fff;
      background-color: #45BCD880;
  }

  .NuestrosProgramas .program-button-conoce-mas-yellow:hover {
      border: solid 2px #E8BA4A;
      color: #fff;
      background-color: #E8BA4A80;
  }

  .NuestrosProgramas .program-button-conoce-mas-orange:hover {
      border: solid 2px #FF8434;
      color: #fff;
      background-color: #FF843480;
  }

  .NuestrosProgramas .program-button-conoce-mas-purple:hover {
      border: solid 2px #A55CC2;
      color: #fff;
      background-color: #A55CC280;
  }

  .NuestrosProgramas .bg-close {
      background-color: #216C95;
      color: #ffffff;
      font-size: 2rem;
      font-family: 'Lato Bold';
  }

  .NuestrosProgramas .floating-button {
      position: fixed;
      top: 20px;
      left: 20px;
      background-color: transparent;
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      z-index: 999; /* Ensure it's above other content */
  }

  .NuestrosProgramas .programa-logo#empieza-temprano {
      max-height: 240px;
      width: auto;
  }
}


@media (min-width: 1440px) {
  .NuestrosProgramas .bg-gray {
      background: #E9EDF0;
  }

  .NuestrosProgramas .title {
      color: #019294;
      font-family: "Rubik One";
      font-size: 38px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
  }

  .NuestrosProgramas .paragraph {
      color: #1F282C;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 124%; /* 29.76px */
      text-align: left;
      padding-left: 3rem;
      padding-right: 10rem;
  }

  .NuestrosProgramas .paragraph b {
      font-family: 'Lato Bold';
      font-weight: bolder;
  }

  .NuestrosProgramas .custom-width {
      width: 60%;
  }

  .NuestrosProgramas .bg-blue {
      background-color: #56BBA8;
  }

  .NuestrosProgramas .paragraph-2 {
      color: #E9EDF0;
      font-family: Lato;
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      line-height: 108%;
      padding: 3rem 8rem;
  }

  .NuestrosProgramas .paragraph-2 b {
      font-family: 'Lato Bold';
      font-weight: bolder;
  }

  .NuestrosProgramas .subtitle-programas {
      color: #000;
      text-align: center;
      font-family: Lato;
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 42px */
      width: 90%;
  }

  .NuestrosProgramas .subtitle-programas b {
      color: #000;
      font-family: 'Lato Bold';
      font-size: 35px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
  }

  .NuestrosProgramas .title-programas {
      color: #56BBA8;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 74.4px */
      text-transform: uppercase;
      text-align: center;
      width: 70%;
  }

  .NuestrosProgramas .bg-bluelight {
      background: #56BBA8;
  }

  .NuestrosProgramas .programas-list {
      text-align: left;
  }

  .NuestrosProgramas .programa-name {
      color: #E9EDF0;
      font-family: 'Lato Bold';
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 60px */
  }

  .NuestrosProgramas .bg-programa1 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #45BCD8;
  }

  .NuestrosProgramas .bg-programa2 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #E8BA4A;
  }

  .NuestrosProgramas .bg-programa3 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #FF8434;
  }

  .NuestrosProgramas .bg-programa4 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #A55CC2;
  }

  .NuestrosProgramas .programa-tab-name,
  .NuestrosProgramas .programa-tab-name2,
  .NuestrosProgramas .programa-tab-name3,
  .NuestrosProgramas .programa-tab-name4 {
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      font-family: 'Lato Bold';
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 36px */
      text-transform: uppercase;
  }

  .NuestrosProgramas .programa-tab-name-active {
      font-size: 26px;
  }

  .NuestrosProgramas .programa-tab-circle {
      border-radius: 20px;
      background: #F5F5F5;
      width: 40px;
      height: 40px;
  }

  .NuestrosProgramas .tab-active {
      background: #363636;
  }

  .NuestrosProgramas .tab-programa-title {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 58px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 60px */
  }

  .NuestrosProgramas .tab-programa-description {
      color: #FFFFFF;
      text-align: center;
      font-family: Lato;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 36px */
  }

  .NuestrosProgramas .tab-programa-description b {
      color: #FFFFFF;
      font-family: 'Lato Bold';
      font-size: 30px;
      font-style: normal;
      font-weight: bolder;
      line-height: 120%;
  }

  .NuestrosProgramas .program-text-description {
      color: #000;
      font-family: Lato;
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 50px; /* 157.143% */
      text-align: left;
  }

  .NuestrosProgramas .program-text-description b {
      color: #000;
      font-family: 'Lato Bold';
      font-size: 35px;
      font-style: normal;
      font-weight: 800;
      line-height: 50px;
  }

  .NuestrosProgramas .program-button-conoce-mas-blue,
  .NuestrosProgramas .program-button-conoce-mas-yellow,
  .NuestrosProgramas .program-button-conoce-mas-orange,
  .NuestrosProgramas .program-button-conoce-mas-purple {
      padding: 1rem 3rem;
      color: #000000;
      font-size: 1.5rem;
      width: 20rem;
      border-radius: 50px;
      font-family: 'Lato';
      font-weight: 800;
  }

  .NuestrosProgramas .program-button-conoce-mas-blue {
      background-color: #45BCD8;
  }

  .NuestrosProgramas .program-button-conoce-mas-yellow {
      background-color: #E8BA4A;
  }

  .NuestrosProgramas .program-button-conoce-mas-orange {
      background-color: #FF8434;
  }

  .NuestrosProgramas .program-button-conoce-mas-purple {
      background-color: #A55CC2;
  }

  .NuestrosProgramas .program-button-conoce-mas-blue:hover {
      border: solid 2px #45BCD8;
      color: #fff;
      background-color: #45BCD880;
  }

  .NuestrosProgramas .program-button-conoce-mas-yellow:hover {
      border: solid 2px #E8BA4A;
      color: #fff;
      background-color: #E8BA4A80;
  }

  .NuestrosProgramas .program-button-conoce-mas-orange:hover {
      border: solid 2px #FF8434;
      color: #fff;
      background-color: #FF843480;
  }

  .NuestrosProgramas .program-button-conoce-mas-purple:hover {
      border: solid 2px #A55CC2;
      color: #fff;
      background-color: #A55CC280;
  }

  .NuestrosProgramas .bg-close {
      background-color: #216C95;
      color: #ffffff;
      font-size: 2rem;
      font-family: 'Lato Bold';
  }

  .NuestrosProgramas .programa-logo {
      width: 350px;
  }

  .NuestrosProgramas .floating-button {
      position: fixed;
      top: 20px;
      left: 20px;
      background-color: transparent;
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      z-index: 999; /* Ensure it's above other content */
  }

  .NuestrosProgramas .programa-logo#empieza-temprano {
      max-height: 240px;
      width: auto;
  }
}


@media (min-width: 1920px) {
  .NuestrosProgramas .bg-gray {
      background: #E9EDF0;
  }

  .NuestrosProgramas .title {
      color: #019294;
      font-family: "Rubik One";
      font-size: 41px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 49.2px */
      text-transform: uppercase;
  }

  .NuestrosProgramas .paragraph {
      color: #1F282C;
      font-family: Lato;
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 55px; /* 157.143% */
      text-align: left;
      padding-left: 3rem;
      padding-right: 10rem;
  }

  .NuestrosProgramas .paragraph b {
      font-family: 'Lato Bold';
      font-weight: bolder;
  }

  .NuestrosProgramas .custom-width {
      width: 60%;
  }

  .NuestrosProgramas .bg-blue {
      background-color: #56BBA8;
  }

  .NuestrosProgramas .paragraph-2 {
      color: #E9EDF0;
      font-family: Lato;
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 108%;
      padding: 3rem 17rem;
  }

  .NuestrosProgramas .paragraph-2 b {
      font-family: 'Lato Bold';
      font-weight: bolder;
  }

  .NuestrosProgramas .subtitle-programas {
      color: #000;
      text-align: center;
      font-family: Lato;
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 42px */
      width: 90%;
  }

  .NuestrosProgramas .subtitle-programas b {
      color: #000;
      font-family: 'Lato Bold';
      font-size: 35px;
      font-style: normal;
      font-weight: bolder;
      line-height: 120%;
  }

  .NuestrosProgramas .title-programas {
      color: #56BBA8;
      text-align: center;
      font-family: "Rubik One";
      font-size: 62px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 74.4px */
      text-transform: uppercase;
  }

  .NuestrosProgramas .bg-bluelight {
      background: #56BBA8;
  }

  .NuestrosProgramas .programas-list {
      text-align: left;
  }

  .NuestrosProgramas .programa-name {
      color: #E9EDF0;
      font-family: 'Lato Bold';
      font-size: 50px;
      font-style: normal;
      font-weight: bolder;
      line-height: 120%; /* 60px */
  }

  .NuestrosProgramas .bg-programa1 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #45BCD8;
  }

  .NuestrosProgramas .bg-programa2 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #E8BA4A;
  }

  .NuestrosProgramas .bg-programa3 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #FF8434;
  }

  .NuestrosProgramas .bg-programa4 {
      border: 1px solid rgba(206, 206, 206, 0.33);
      background: #A55CC2;
  }

  .NuestrosProgramas .programa-tab-name,
  .NuestrosProgramas .programa-tab-name2,
  .NuestrosProgramas .programa-tab-name3,
  .NuestrosProgramas .programa-tab-name4 {
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      font-family: 'Lato Bold';
      font-size: 32px;
      font-style: normal;
      font-weight: bolder;
      line-height: 120%; /* 43.2px */
      text-transform: uppercase;
  }

  .NuestrosProgramas .programa-tab-name-active {
      font-size: 36px;
  }

  .NuestrosProgramas .programa-tab-circle {
      border-radius: 20px;
      background: #F5F5F5;
      width: 40px;
      height: 40px;
  }

  .NuestrosProgramas .tab-active {
      background: #363636;
  }

  .NuestrosProgramas .tab-programa-title {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 60px */
  }

  .NuestrosProgramas .tab-programa-description {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 42px */
  }

  .NuestrosProgramas .tab-programa-description b {
      color: #FFF;
      font-family: 'Lato Bold';
      font-size: 35px;
      font-style: normal;
      font-weight: bolder;
      line-height: 120%;
  }

  .NuestrosProgramas .program-text-description {
      color: #000;
      font-family: Lato;
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 55px; /* 157.143% */
      text-align: left;
  }

  .NuestrosProgramas .program-text-description b {
      color: #000;
      font-family: 'Lato Bold';
      font-size: 35px;
      font-style: normal;
      font-weight: 800;
      line-height: 55px;
  }

  .NuestrosProgramas .program-button-conoce-mas-blue,
  .NuestrosProgramas .program-button-conoce-mas-yellow,
  .NuestrosProgramas .program-button-conoce-mas-orange,
  .NuestrosProgramas .program-button-conoce-mas-purple {
      padding: 1rem 3rem;
      color: #000000;
      font-size: 1.5rem;
      width: 20rem;
      border-radius: 50px;
      font-family: 'Lato Bold';
      font-weight: 800;
  }

  .NuestrosProgramas .program-button-conoce-mas-blue {
      background-color: #45BCD8;
  }

  .NuestrosProgramas .program-button-conoce-mas-yellow {
      background-color: #E8BA4A;
  }

  .NuestrosProgramas .program-button-conoce-mas-orange {
      background-color: #FF8434;
  }

  .NuestrosProgramas .program-button-conoce-mas-purple {
      background-color: #A55CC2;
  }

  .NuestrosProgramas .program-button-conoce-mas-blue:hover {
      border: solid 2px #45BCD8;
      color: #fff;
      background-color: #45BCD880;
  }

  .NuestrosProgramas .program-button-conoce-mas-yellow:hover {
      border: solid 2px #E8BA4A;
      color: #fff;
      background-color: #E8BA4A80;
  }

  .NuestrosProgramas .program-button-conoce-mas-orange:hover {
      border: solid 2px #FF8434;
      color: #fff;
      background-color: #FF843480;
  }

  .NuestrosProgramas .program-button-conoce-mas-purple:hover {
      border: solid 2px #A55CC2;
      color: #fff;
      background-color: #A55CC280;
  }

  .NuestrosProgramas .bg-close {
      background-color: #216C95;
      color: #ffffff;
      font-size: 2rem;
      font-family: 'Lato Bold';
  }

  .NuestrosProgramas .programa-logo {
      width: 390px;
  }

  .NuestrosProgramas .floating-button {
      position: fixed;
      top: 20px;
      left: 20px;
      background-color: transparent;
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      z-index: 999; /* Ensure it's above other content */
  }

  .NuestrosProgramas .programa-logo#empieza-temprano {
      max-height: 250px;
      width: auto;
  }
}

