.Impacto {
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 79.55%, rgba(85, 188, 169, 0.14) 99.39%);
}
  .Impacto .mobile-device {
    display: block;
  }

  .Impacto .web-device {
    display: none;
  }

  .Impacto .rectangle-mobile {
    margin-top: -5rem;
    margin-right: -4rem;
    opacity: 0.6;
  }

  .Impacto .title {
    color: #69747C;
    text-align: center;
    font-family: "Rubik One";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
@media (min-width: 768px) {
    .Impacto .rectangle-mobile {
        margin-top: -3rem;
        margin-right: 0rem;
    }

    .Impacto .title {
        color: #69747C;
        text-align: center;
        font-family: "Rubik One";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
}

@media (min-width: 1024px) {
    .Impacto .mobile-device {
        display: none;
    }
  
    .Impacto .web-device {
        display: block;
    }

    .Impacto .title {
        color: #69747C;
        text-align: center;
        font-family: "Rubik One";
        font-size: 42px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }

    .Impacto .cards-content {
        padding-left: 5%;
        padding-right: 5%;
    }

    .Impacto .rectangle {
        padding-top: 10rem;
        padding-left: 5rem;
        margin-right: -5rem;
    }
}

@media (min-width: 1440px) {
    .Impacto .title {
        color: #69747C;
        text-align: center;
        font-family: "Rubik One";
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        width: 100%;
    }

    .Impacto .cards-content {
        padding-left: 5%;
        padding-right: 5%;
    }

    .Impacto .rectangle {
        padding-top: 0rem;
        padding-left: 5rem;
    }

    .Impacto .rectangle img {
        opacity: 0.6;
    }

    .Impacto .card-custom-mb img {
        margin-bottom: 2.35rem;
    }
}
