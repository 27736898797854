.Agradecimientos {
  width: 100%;
  background-color: #E9EDF0;  
  
}

.Agradecimientos .custom-bg {
  background-color: #FFFFFF;
  /* margin-left: 5%;
  margin-top: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
  width: 100%; */
  background-image: url('../../../assets/images/home/gradient-agradecimiento.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  width: 100%;

}

.Agradecimientos .custom-bg img {
  height: 100px;
}

.Agradecimientos .agradecimiento-title {
  color: #00B9F1;
  text-align: center;
  font-family: "Rubik One";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

  @media (min-width: 768px) {
    .Agradecimientos .agradecimiento-title {
      color: #00B9F1;
      text-align: center;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  }
  @media (min-width: 1024px) {
  
  }
  
  @media (min-width: 1280px) {
  
  }
  @media (min-width: 1366px) {
    .Agradecimientos .custom-bg {
      background-color: #FFFFFF;
      margin-left: 10%;
      margin-top: 5%;
      margin-right: 10%;
      margin-bottom: 5%;
      width: 100%;
    }

    .Agradecimientos .custom-bg img {
      height: 100px;
    }

    .Agradecimientos .agradecimiento-title {
      color: #00B9F1;
      text-align: center;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  @media (min-width: 1440px) {
    .Agradecimientos .custom-bg {
      background-color: #FFFFFF;
      margin-left: 10%;
      margin-top: 5%;
      margin-right: 10%;
      margin-bottom: 5%;
      width: 100%;
    }

    .Agradecimientos .custom-bg img {
      height: 100px;
    }

    .Agradecimientos .agradecimiento-title {
      color: #00B9F1;
      text-align: center;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  @media (min-width: 1600px) {
    .Agradecimientos .custom-bg {
      background-color: #FFFFFF;
      margin-left: 10%;
      margin-top: 5%;
      margin-right: 10%;
      margin-bottom: 5%;
      width: 100%;
    }

    .Agradecimientos .custom-bg img {
      height: 100px;
    }

    .Agradecimientos .agradecimiento-title {
      color: #00B9F1;
      text-align: center;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  @media (min-width: 1920px) {
    .Agradecimientos .custom-bg {
      background-color: #FFFFFF;
      margin-left: 10%;
      margin-top: 5%;
      margin-right: 10%;
      margin-bottom: 5%;
      width: 100%;
    }

    .Agradecimientos .custom-bg img {
      height: 100px;
    }

    .Agradecimientos .agradecimiento-title {
      color: #00B9F1;
      text-align: center;
      font-family: "Rubik One";
      font-size: 68px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  }
