.golxmex .ini-bg{
    min-height: 105vh;
    background-image: url('../../../../assets/images/programas/golxmex/iniciativas.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;

}
.golxmex .text-box-numbs{
   min-height: 200px;
}
.golxmex .numbers{
    background: linear-gradient(270deg, rgba(225, 204, 187, 0.00) 0%, rgba(234, 112, 33, 0.25) 100%);   
    color: #1F282C;
    font-family: 'Ruda Bold';
    font-size: 28px;
    font-weight: 800;
    line-height: 46px;
    text-align: start;
    padding: 10px;
}
.golxmex .numbers .highlight{
    color: #FF8434;
    font-family: "Rubik One";
    font-size: 40px;
    font-weight: 400;
    line-height: 46px;
}
.golxmex .gxm-in-title{
    color: #53616A;
    text-align: center;
    font-family: "Rubik One";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 60px */
    text-transform: uppercase;
}
.golxmex .gxm-in-par{
    color: #1F282C;
    text-align: center;
    font-family: Ruda;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px; /* 157.143% */
}
.gol-mex-img-ini{
    display: block;
}

@media screen and (min-width:768px) {
    .golxmex .gxm-in-title{
        font-size: 48px;
    }
    .golxmex .numbers .highlight , .golxmex .numbers{
        font-size: 1.75rem;
        line-height: 1.875rem;
        max-width: 80%;
    }

    .golxmex .ini-bg{
        background-image: unset;
    
    }

    .golxmex .text-box-numbs{
        min-height: 150px;
     }
     .golxmex .gxm-in-par{
        font-size: 30px;
     }
}

@media screen and (min-width:1024px) {
    .golxmex .ini-bg{
        min-height: unset;
        background-image: unset;
    }
    .golxmex .gxm-in-par{
        font-size:1.1rem;
        line-height:1.75rem;
    }
    .golxmex .gxm-in-title{
        font-size: 1.875rem;
    }
    .golxmex .numbers{
        padding: 10px;
        font-size: 1.5rem;
    }
    .golxmex .numbers .highlight , .golxmex .numbers{
        font-size: 1.5rem;
        line-height: 1.875rem;
    }
    .golxmex .text-box-numbs{
        max-width: 80%;
        margin: auto;
        min-height: 150px;
        padding: .5rem;
    }
    .golxmex .mv-ini-bg{
        min-height: 30vh;
        background-image: url('../../../../assets/images/programas/golxmex/inic-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
    }
}

@media screen and (min-width:1280px) {
    .golxmex .ini-bg{
        min-height: 75vh;
        background-image: url('../../../../assets/images/programas/golxmex/iniciativas.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
    }
    .golxmex .numbers{
        background: linear-gradient(270deg, rgba(225, 204, 187, 0.00) 0%, rgba(234, 112, 33, 0.25) 100%);       color: #1F282C;
        font-family: 'Ruda Bold';
        font-size: 20px;
        font-weight: 800;
        line-height: 36px;
        text-align: start;
    padding: 10px;
    max-width: 90%;

    }
    .golxmex .numbers .highlight{
        color: #FF8434;
        font-family: "Rubik One";
        font-size: 25px;
        font-weight: 400;
        line-height: 35px;
    }
    .golxmex .gxm-in-title{
        color: #53616A;
        text-align: center;
        font-family: "Rubik One";
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 60px */
        text-transform: uppercase;
    }
    .golxmex .gxm-in-par{
        color: #1F282C;
        text-align: center;
        font-family: Ruda;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px; /* 157.143% */
    }
    .golxmex .pb-desk{
        padding-bottom: 48vh;
    }
    .gol-mex-img-ini{
        display: none;
    }

}

@media screen and (min-width:1366px) {
    .golxmex .ini-bg{
        min-height: 140vh;
        background-image: url('../../../../assets/images/programas/golxmex/iniciativas.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
    }
    .golxmex .numbers{
        background: linear-gradient(270deg, rgba(225, 204, 187, 0.00) 0%, rgba(234, 112, 33, 0.25) 100%);       color: #1F282C;
        font-family: 'Ruda Bold';
        font-size: 20px;
        font-weight: 800;
        line-height: 36px;
        text-align: start;
    padding: 10px;

    }
    .golxmex .numbers .highlight{
        color: #FF8434;
        font-family: "Rubik One";
        font-size: 25px;
        font-weight: 400;
        line-height: 35px;
    }
    .golxmex .gxm-in-title{
        color: #53616A;
        text-align: center;
        font-family: "Rubik One";
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 60px */
        text-transform: uppercase;
    }
    .golxmex .gxm-in-par{
        color: #1F282C;
        text-align: center;
        font-family: Ruda;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px; /* 157.143% */
    }
    .golxmex .pb-desk{
        padding-bottom: 48vh;
    }
    .gol-mex-img-ini{
        display: none;
    }
}

@media screen and (min-width:1440px) {
    .golxmex .ini-bg{
        min-height: 105vh;
    }
    .golxmex .text-box-numbs{
        max-width: 90%;
        margin: auto;
        min-height: 150px;
        padding: .5rem;
    }
}

@media screen and (min-width:1920px) {
    .golxmex .ini-bg{
        min-height: 95vh;
    }
    .golxmex .pb-desk{
        padding-bottom: 48vh;
    }
}
@media screen and (min-width:2400px) {
    .golxmex .pb-desk{
        padding-bottom: 44vh;
    }
    .golxmex .ini-bg{
        min-height: 60vh;
    }
    .golxmex .ini-bg .row{
        max-width: 1920px;
        margin: auto;
    }
}

@media screen and (min-width:300px) and (max-width:600px){
    .golxmex .ini-bg{
        min-height: unset;
        background-image: unset;
    }
    .golxmex .gxm-in-par{
        font-size:1.1rem;
        line-height:1.75rem;
    }
    .golxmex .gxm-in-title{
        font-size: 22px;
    }
    .golxmex .numbers{
        font-size: 1.125rem;
        margin-bottom: 0;
        text-align: center;
    }
    .golxmex .numbers .highlight , .golxmex .numbers{
        font-size: 1.125rem;
        line-height: 130%
    }
    .golxmex .text-box-numbs{
        max-width: 80%;
        margin: auto;
        min-height: 100px;
        padding: .5rem;
    }
    .golxmex .mv-ini-bg{
        min-height: 30vh;
        background-image: url('../../../../assets/images/programas/golxmex/inic-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
    }
}