.cuantrix .bg-suave {
  /* opacity: 0.8; */
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(50, 114, 204, 0.32) 100%);
  /* padding: 1.5rem 3rem; */
}
  
  .cuantrix .ctx-title {
    color: var(--ctx-color);
    font-family: "Rubik One";
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    text-align: start;
  }
  
  .cuantrix .ctx-subt {
    color: #1F282C;
    text-align: center;
    font-family: "Ruda Bold";
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-align: start;
  }
  
  .cuantrix .ctx-par {
    color: #1F282C;
    font-family: Lato;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 38.64px */
    text-align: start;
  }
  
  .cuantrix .ctx-par .bold {
    font-family: 'Lato Bold';
    font-weight: bolder;
  }
  
  .cuantrix .ctx-par .bold.numb {
    font-size: 38px;
  }
  
  .cuantrix .ctx-par .highlight {
    color: var(--ctx-color);
    font-family: 'Lato Bold';
    font-size: 38px;
    font-style: normal;
    font-weight: 800;
    line-height: 138%;
  }
  
  .cuantrix .ctx-img {
    width: 100%;
  }
  
  .cuantrix .ctx-quote {
    color: #FFF;
    text-align: center;
    font-family: Lato;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 43.75px */
}
    .cuantrix .ctx-quote b {
      color: #FFF;
      font-family: 'Lato Bold';
      font-size: 35px;
      font-style: normal;
      font-weight: bolder;
      line-height: 125%;
    }
  
  
  .cuantrix .mh-250 {
    min-height: 300px;
    padding: 3rem;
  }
  
  .cuantrix .ctx-par .bold,
  .cuantrix .ctx-quote .bold {
    font-family: 'Lato Bold';
    font-weight: bolder;
  }
  
  .cuantrix .bg-ctx {
    background-color: var(--ctx-color);
  }
  
  .cuantrix .bg-ctx-gris {
    background-color: var(--ctx-gris);
  }

  .cuantrix .main-title-programas {
    color: var(--ctx-color);
  }

  @media screen and (max-width:1366px){
    .cuantrix .bg-suave {
        padding: 1.5rem;
    }
    
    .cuantrix .ctx-title {
        color: var(--ctx-color);
        font-family: "Rubik One";
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: left;
    }
    
    .cuantrix .ctx-subt {
      color: #1F282C;
      text-align: center;
      font-family: "Ruda Bold";
      font-size: 20px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-align: start;
    }
    
    .cuantrix .ctx-par {
        font-size: 18px;
        text-align: left;
        line-height: 22px;
    }
    
    .cuantrix .ctx-par .bold.numb {
        font-size: 38px;
    }
    
    .cuantrix .ctx-par .highlight {
        color: var(--ctx-color);
        font-family: 'Lato Bold';
        font-size: 19px;
        font-style: normal;
        font-weight: 800;
        line-height: 138%;
    }
    
    .cuantrix .ctx-quote {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 43.75px */

    }
    .cuantrix .ctx-quote b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 25px;
        font-style: normal;
        font-weight: bolder;
        line-height: 125%;
      }
    
    
    .cuantrix .mh-250 {
        min-height: 300px;
        padding: 1.5rem;
    }
}

  @media screen and (max-width:1024px){
    .cuantrix .bg-suave {
        padding: 0rem;
    }
    
    .cuantrix .ctx-title {
        color: var(--ctx-color);
        font-family: "Rubik One";
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: left;
    }
    
    .cuantrix .ctx-subt {
      color: #1F282C;
      text-align: center;
      font-family: "Ruda Bold";
      font-size: 20px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-align: start;
    }
    
    .cuantrix .ctx-par {
        font-size: 18px;
        text-align: left;
        line-height: 22px;
    }
    
    .cuantrix .ctx-par .bold.numb {
        font-size: 38px;
    }
    
    .cuantrix .ctx-par .highlight {
        color: var(--ctx-color);
        font-family: 'Lato Bold';
        font-size: 19px;
        font-style: normal;
        font-weight: 800;
        line-height: 138%;
    }
    
    .cuantrix .ctx-quote {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 43.75px */

    }
    .cuantrix .ctx-quote b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 20px;
        font-style: normal;
        font-weight: bolder;
        line-height: 125%;
      }
    
    
    .cuantrix .mh-250 {
        min-height: 300px;
        padding: 1.5rem;
    }
}

  @media screen and (max-width:900px){
    .cuantrix .bg-suave {
        padding: 1.5rem;
    }
    
    .cuantrix .ctx-title {
        color: var(--ctx-color);
        font-family: "Rubik One";
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: center;
    }
    
    .cuantrix .ctx-subt {
        text-align: center;
    }
    
    .cuantrix .ctx-par {
        font-size: 22px;
        text-align: center;
        line-height: 32px;
    }
    
    .cuantrix .ctx-par .bold.numb {
        font-size: 38px;
    }
    
    .cuantrix .ctx-par .highlight {
        color: var(--ctx-color);
        font-family: 'Lato Bold';
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        line-height: 138%;
    }
    
    .cuantrix .ctx-quote {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 38px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 43.75px */

    }
    .cuantrix .ctx-quote b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 38px;
        font-style: normal;
        font-weight: bolder;
        line-height: 125%;
      }
    
    
    .cuantrix .mh-250 {
        min-height: 300px;
        padding: 1.5rem;
    }
}
  

@media screen and (max-width:600px){
    .cuantrix .bg-suave {
        padding: 1.5rem;
    }
    
    .cuantrix .ctx-title {
        color: var(--ctx-color);
        font-family: "Rubik One";
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: center;
    }
    
    .cuantrix .ctx-subt {
        text-align: center;
    }
    
    .cuantrix .ctx-par {
        font-size: 14px;
        text-align: center;
        line-height: 138%;
    }
    
    .cuantrix .ctx-par .bold.numb {
        font-size: 38px;
    }
    
    .cuantrix .ctx-par .highlight {
        color: var(--ctx-color);
        font-family: 'Lato Bold';
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 138%;
    }
    
    .cuantrix .ctx-quote {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 43.75px */
  
    }
    .cuantrix .ctx-quote b b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 19px;
        font-style: normal;
        font-weight: bolder;
        line-height: 125%;
      }
    
    
    .cuantrix .mh-250 {
        min-height: 200px;
        padding: 1rem;
    }
}