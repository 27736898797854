.testimonios-title{
    color: #00A2D3;
    text-align: center;
    font-family: "Rubik One";
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}
.img-logo{
    width: 98px;
    margin: auto;
    padding: 3rem 0;
}
.cdig-tit{
    color: #1F282C;
    text-align: center;
    font-family: Ruda;
    font-size: 2.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 2.96875rem */
}

@media screen and (max-width:1366px){
    .testimonios-title{
        color: #00A2D3;
        text-align: center;
        font-family: "Rubik One";
        font-size: 2.1875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
    .img-logo{
        width: 98px;
        margin: auto;
        padding: 3rem 0;
    }
    .cdig-tit{
        color: #1F282C;
        text-align: center;
        font-family: Ruda;
        font-size: 2rem;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 2.96875rem */
    }
}

@media screen and (max-width:768px){
    .testimonios-title{
        color: #00A2D3;
        text-align: center;
        font-family: "Rubik One";
        font-size: 2.1875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
    .img-logo{
        width: 98px;
        margin: auto;
        padding: 3rem 0;
    }
    .cdig-tit{
        color: #1F282C;
        text-align: center;
        font-family: Ruda;
        font-size: 2.375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 2.96875rem */
    }
}

@media screen and (max-width:600px){
    .testimonios-title{
        text-align: center;
        font-size: 1.375rem;
    }
    .img-logo{
        width: 68px;
        margin: auto;
        padding: 1rem 0;
    }
    .cdig-tit{
        text-align: center;
        font-size: 18px;
    }
}