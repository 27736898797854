.tecnochicas .ini-bg {
    min-height: 60vh;
    background-image: url('../../../../assets/images/programas/tecnolochicas/tchiniciativas.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
}

.tecnochicas .text-box-numbs {
    min-height: 200px;
}

.tecnochicas .numbers {
    background: linear-gradient(132deg, rgba(126, 157, 238, 0.30) 0%, rgba(199, 196, 223, 0.00) 100%);
    color: #000;
    font-family: 'Ruda Bold';
    font-size: 36px;
    font-style: normal;
    font-weight: bolder;
    line-height: 45px;
    text-align: start;
    padding: 7px 24px;
}

.tecnochicas .numbers .highlight {
    color: #7970C1;
    font-family: "Rubik One";
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px; /* 125% */
}

.tecnochicas .gxm-in-title {
    color: #53616A;
    text-align: center;
    font-family: "Rubik One";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 60px */
    text-transform: uppercase;
}

.tecnochicas .gxm-in-par {
    color: #1F282C;
    text-align: center;
    font-family: Ruda;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px; /* 157.143% */
}

@media screen and (max-width: 1920px) {
    .tecnochicas .ini-bg {
        min-height: 90vh;
        background-image: url('../../../../assets/images/programas/tecnolochicas/tchiniciativas.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
    }
    
    .tecnochicas .text-box-numbs {
        min-height: 200px;
    }
    
    .tecnochicas .numbers {
        background: linear-gradient(132deg, rgba(126, 157, 238, 0.30) 0%, rgba(199, 196, 223, 0.00) 100%);
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 36px;
        font-style: normal;
        font-weight: bolder;
        line-height: 45px;
        text-align: start;
        padding: 7px 24px;
    }
    
    .tecnochicas .numbers .highlight {
        color: #7970C1;
        font-family: "Rubik One";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px; /* 125% */
    }
    
    .tecnochicas .gxm-in-title {
        color: #53616A;
        text-align: center;
        font-family: "Rubik One";
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 60px */
        text-transform: uppercase;
    }
    
    .tecnochicas .gxm-in-par {
        color: #1F282C;
        text-align: center;
        font-family: Ruda;
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px; /* 157.143% */
    }
}

@media screen and (max-width: 1600px){
    .tecnochicas .ini-bg {
        min-height: 90vh;
        background-image: url('../../../../assets/images/programas/tecnolochicas/tchiniciativas.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 100%;
        height: 100%;
    }
    
    .tecnochicas .text-box-numbs {
        min-height: 170px;
    }
    
    .tecnochicas .numbers {
        background: linear-gradient(132deg, rgba(126, 157, 238, 0.30) 0%, rgba(199, 196, 223, 0.00) 100%);
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 23px;
        font-style: normal;
        font-weight: bolder;
        line-height: 45px;
        text-align: start;
        padding: 7px 24px;
    }
    
    .tecnochicas .numbers .highlight {
        color: #7970C1;
        font-family: "Rubik One";
        font-size: 23px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px; /* 125% */
    }
    
    .tecnochicas .gxm-in-title {
        color: #53616A;
        text-align: center;
        font-family: "Rubik One";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 60px */
        text-transform: uppercase;
    }
    
    .tecnochicas .gxm-in-par {
        color: #1F282C;
        text-align: center;
        font-family: Ruda;
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px; /* 157.143% */
    }
    
}

@media screen and (max-width: 1366px){
    .tecnochicas .ini-bg {
        min-height: 90vh;
        background-image: url('../../../../assets/images/programas/tecnolochicas/tchiniciativas.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 100%;
        height: 100%;
    }
    
    .tecnochicas .text-box-numbs {
        min-height: 170px;
    }
    
    .tecnochicas .numbers {
        background: linear-gradient(132deg, rgba(126, 157, 238, 0.30) 0%, rgba(199, 196, 223, 0.00) 100%);
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 20px;
        font-style: normal;
        font-weight: bolder;
        line-height: 30px;
        text-align: start;
        padding: 7px 24px;
    }
    
    .tecnochicas .numbers .highlight {
        color: #7970C1;
        font-family: "Rubik One";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 125% */
    }
    
    .tecnochicas .gxm-in-title {
        color: #53616A;
        text-align: center;
        font-family: "Rubik One";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 60px */
        text-transform: uppercase;
    }
    
    .tecnochicas .gxm-in-par {
        color: #1F282C;
        text-align: center;
        font-family: Ruda;
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px; /* 157.143% */
    }
    
}

@media screen and (max-width: 1024px){
    .tecnochicas .ini-bg {
        min-height: 75vh;
        background-image: url('../../../../assets/images/programas/tecnolochicas/tchiniciativas.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 100%;
        height: 100%;
    }
    
    .tecnochicas .text-box-numbs {
        min-height: 170px;
    }
    
    .tecnochicas .numbers {
        background: linear-gradient(132deg, rgba(126, 157, 238, 0.30) 0%, rgba(199, 196, 223, 0.00) 100%);
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: bolder;
        line-height: 24px;
        text-align: start;
        padding: 7px 20px;
    }
    
    .tecnochicas .numbers .highlight {
        color: #7970C1;
        font-family: "Rubik One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 125% */
    }
    
    .tecnochicas .gxm-in-title {
        color: #53616A;
        text-align: center;
        font-family: "Rubik One";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 60px */
        text-transform: uppercase;
    }
    
    .tecnochicas .gxm-in-par {
        color: #1F282C;
        text-align: center;
        font-family: Ruda;
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px; /* 157.143% */
    }
    
}

@media screen and (max-width:900px){
    .tecnochicas .ini-bg {
        min-height: 60vh;
        background-image: url('../../../../assets/images/programas/tecnolochicas/tchinibgm.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
    }
    
    .tecnochicas .text-box-numbs {
        min-height: 170px;
    }
    
    .tecnochicas .numbers {
        background: linear-gradient(180deg, rgba(126, 157, 238, 0.30) 0%, rgba(199, 196, 223, 0.00) 100%);
        color: #1F282C;
        font-family: 'Ruda Bold';
        font-size:16px;
        font-weight: 800;
        line-height: 46px;
        text-align: start;
    }
    
    .tecnochicas .numbers .highlight {
        color: var(--bc-color);
        font-family: "Rubik One";
        font-size: 30px;
        font-weight: 400;
        line-height: 46px;
    }
    
    .tecnochicas .gxm-in-title {
        color: #53616A;
        text-align: center;
        font-family: "Rubik One";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 60px */
        text-transform: uppercase;
    }
    
    .tecnochicas .gxm-in-par {
        color: #1F282C;
        text-align: center;
        font-family: Ruda;
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px; /* 157.143% */
    }
    
}

@media screen and (max-width:600px){
    .tecnochicas .ini-bg {
        background-image: url('../../../../assets/images/programas/tecnolochicas/tchinibgm.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
    }
    
    .tecnochicas .text-box-numbs {
        min-height: 110px;
    }
    
    .tecnochicas .numbers {
        background: linear-gradient(180deg, rgba(126, 157, 238, 0.30) 0%, rgba(199, 196, 223, 0.00) 100%);
        color: #1F282C;
        font-family: 'Ruda Bold';
        font-size: 18px;
        font-weight: 800;
        line-height: 24px;
        text-align: center;
    }
    
    .tecnochicas .numbers .highlight {
        color: var(--bc-color);
        font-family: "Rubik One";
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
    }
    
    .tecnochicas .gxm-in-title {
        color: #53616A;
        text-align: center;
        font-family: "Rubik One";
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 60px */
        text-transform: uppercase;
    }
    
    .tecnochicas .gxm-in-par {
        color: #1F282C;
        text-align: center;
        font-family: Ruda;
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px; /* 157.143% */
    }
    
}

