.IniciativasFotograficaMx {
  width: 100%;
  background: linear-gradient(180deg, rgba(165, 92, 194, 0.38) -11.59%, rgba(255, 255, 255, 0.00) 80.7%);
  height: auto;
}

.IniciativasFotograficaMx .mobile-device {
  display: block;
}

.IniciativasFotograficaMx .web-device {
  display: none;
}

.IniciativasFotograficaMx .title {
  color: #53616a;
  text-align: center;
  font-family: "Rubik One";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 26.4px */
  text-transform: uppercase;
}

 .paragraph-inif {
  color: #1F282C;
  text-align: center;
  font-family: 'Ruda Bold';
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 21px; /* 166.667% */
}

 .paragraph-inif b {
  color: #A55CC2;
  font-family: "Rubik One";
  font-size: inherit;
  font-style: normal;
  font-weight: 400;
  line-height: inherit;
}
 .paragraph-inif em{
  font-style: italic;
  font-weight: 800;
  font-family: "Lato Bold";
  font-size: inherit;
}

.IniciativasFotograficaMx .bg-purple {
  background: #A55CC2;
}
@media (min-width: 768px) {
  .IniciativasFotograficaMx .title {
    color: #53616a;
    text-align: center;
    font-family: "Rubik One";
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 57.6px */
    text-transform: uppercase;
  }
  
 .paragraph-inif {
    color: #1F282C;
    font-family: 'Ruda Bold';
    font-size: 29px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%; /* 30.8px */
    text-align: center;
  }
  
 .paragraph-inif b {
    color: #A55CC2;
    font-family: "Rubik One";
    font-size: 29px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }
 .paragraph-inif em{
    font-style: italic;
    font-weight: 800;
    font-family: "Lato Bold";
    font-size: inherit;
  }
  
  .IniciativasFotograficaMx .bg-purple {
    background: #A55CC2;
  }
  
  }

  @media (min-width: 1024px) {
    .IniciativasFotograficaMx .mobile-device {
      display: none;
    }
  
    .IniciativasFotograficaMx .web-device {
      display: block;
    }

    .IniciativasFotograficaMx .title {
      color: #53616a;
      text-align: center;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 60px */
      text-transform: uppercase;
    }
  
    .paragraph-inif {
      color: #1F282C;
      font-family: 'Ruda Bold';

      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 26px; /* 160.714% */
      text-align: start;
    }
    .paragraph-inif b {
        color: #A55CC2;
        font-family: "Rubik One";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        
      }
      .paragraph-inif em{
        font-style: italic;
        font-weight: 800;
        font-family: "Lato Bold";
        font-size: inherit;
      }
  
    .bg-purple {
      background: #A55CC2;
    }
  }

  @media (min-width: 1366px) {
    .IniciativasFotograficaMx .title {
      color: #53616a;
      text-align: center;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 60px */
      text-transform: uppercase;
    }
  
    .paragraph-inif {
      color: #1F282C;
      font-family: 'Ruda Bold';
      font-size: 34px;
      font-style: normal;
      font-weight: 800;
      line-height: 40px; /* 160.714% */
      text-align: start;
    }
      .paragraph-inif b {
        color: #A55CC2;
        font-family: "Rubik One";
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
      }
      .paragraph-inif em{
        font-style: italic;
        font-weight: 800;
        font-family: "Lato Bold";
        font-size: inherit;
      }
    
  
    .bg-purple {
      background: #A55CC2;
    }
  }

  @media (min-width: 1440px) {
    .IniciativasFotograficaMx .title {
      color: #53616a;
      text-align: center;
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 60px */
      text-transform: uppercase;
    }
  
    .paragraph-inif {
      color: #1F282C;
      font-family: 'Ruda Bold';

      font-size: 38px;
      font-style: normal;
      font-weight: 800;
      line-height: 45px; /* 160.714% */
      text-align: start;
    }
    .paragraph-inif b {
        color: #A55CC2;
        font-family: "Rubik One";
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px;
      }
      .paragraph-inif em{
        font-style: italic;
        font-weight: 800;
        font-family: "Lato Bold";
        font-size: inherit;
      }
    }
  
    .bg-purple {
      background: #A55CC2;
    }
