.Nav {
  width: 100%;
  font-family: Lato;
  position: relative;
  z-index: 100;
}

.Nav .mobile-device {
  display: block;
}

.Nav .web-device {
  display: none;
}

.Nav .logo-container {
  padding-left: 5%;
  padding-top: 5%;
}

.Nav .logo {
  width: 150px;
  height: auto;
}

.Nav .menu-icon-container {
  padding-top: 5%;
  margin-left: 40%;
}

.Nav .menu-icon {
  width: 32px;
  height: auto;
}

.Nav .lang-text {
  display: flex;
  color: #ffffff;
  font-size: 0.7rem;
  font-weight: 900;
  align-items: center;
}

.Nav .lang-text > .ver{
  color: #ffffff;
  font-size: 0.7rem;
  font-weight: 900;
}

.Nav .menu-container {
  position: absolute;
  width: 100%;
  height: 100vh;
  padding: 0.2rem;
  background-color: #C3C3C3;
  opacity: 0.9;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
  right: 0px;
  top: 0rem;
  z-index: 100;
  max-width: 360px;
  overflow-y: scroll;
}

.Nav .close-icon {
  cursor: pointer;
  border-radius: 4px;
  background: #00A6D9;
  width: 30px;
  height: auto;
  flex-shrink: 0;
}

.Nav .close-icon-text {
  color: #FFF;
  font-family: 'Lato Bold';
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 10px; /* 45.455% */
  letter-spacing: 2.2px;
}

.Nav .logo-menu {
  width: 4rem;
}

.Nav .separator {
  border-bottom: 2px solid #000000;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.Nav .nav-item {
  text-align: left !important;
}

.Nav .past-years {
  color: #000;
  font-family: 'Lato Bold';
  font-size: 0.5rem;
  font-style: normal;
  font-weight: bolder;
  line-height: normal;
  cursor: pointer;
}

.Nav a {
  color: #000;
  font-family: 'Lato Bold';
  font-size: 0.865rem;
  font-style: normal;
  font-weight: bolder;
  line-height: normal;
  cursor: pointer;
}

.Nav .nav-link a {
  padding: .2rem 0.5em;
}

.Nav .nav-link:hover,
.Nav .nav-link:focus {
  color: #fff;
  background-color: #56BBA8;
}

.Nav .nav-link:hover a,
.Nav .nav-link:focus a {
  color: #fff;
}

.Nav .gap-custom {
  gap: 0.2rem;
}

.Nav .bullet {
  background: #00A6D9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 10px;
  height: 10px;
  flex-shrink: 0;
}

.Nav .bullet-inner {
  background: #00A6D9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.Nav .text-normal {
  text-transform: none;
}

  @media (min-width: 768px) {

    .Nav {
      width: 100%;
    }
    .Nav .menu-icon-container {
      padding-top: 5%;
      margin-left: 45%;
    }
    .Nav .mobile-device {
      display: block;
    }
    
    .Nav .web-device {
      display: none;
    }
    
    .Nav .logo-container {
      padding-left: 6%;
      padding-top: 5%;
    }
    
    .Nav .logo {
      width: 250px;
      height: auto;
    }
    
    .Nav .menu-container {
      position: absolute;
      width: 360px;
      height: 100vh;
      padding: 0.1rem;
      background-color: #C3C3C3;
      opacity: 0.9;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
      right: 0px;
      top: 0rem;
      z-index: 100;

      max-width: 360px;
    }
    
    .Nav .menu-icon {
      width: 50px;
      height: auto;
    }
    
    .Nav .lang-text {
      display: flex;
      color: #ffffff;
      font-size: 1rem;
      font-weight: 900;
      align-items: center;
    }

    .Nav .lang-text > .ver{
      color: #ffffff;
      font-size: 1rem;
      font-weight: 900;
    }

    .Nav .past-years {
      color: #000;
      font-family: 'Lato Bold';
      font-size: 0.5rem;
      font-style: normal;
      font-weight: bolder;
      line-height: normal;
      cursor: pointer;
    }

    .Nav a {
      color: #0F0F0F;
      font-family: 'Lato Bold';
      font-size: 14px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase;
      cursor: pointer;
    }
    
    .Nav .nav-link a {
      padding: 0.2rem 0.3em;
    }
    
    .Nav .nav-link:hover,
    .Nav .nav-link:focus {
      color: #fff;
      background-color: #56BBA8;
    }
    
    .Nav .nav-link:hover a,
    .Nav .nav-link:focus a {
      color: #fff;
    }
    
    .Nav .gap-custom {
      gap: 0.2rem;
    }
    
  }

  @media (min-width: 1024px) {
    .Nav {
      width: 100%;
      padding: 2rem 2rem;
    }
    
    .Nav .mobile-device {
      display: none;
    }
    
    .Nav .web-device {
      display: block;
    }
    
    .Nav .logo {
      width: 280px;
      height: auto;
    }
    
    .Nav .menu-icon {
      width: 50px;
      height: auto;
      cursor: pointer;
    }
    
    .Nav .lang-text {
      display: flex;
      color: #ffffff;
      font-size: 1.5rem;
      font-weight: 900;
      align-items: center;
    }

    .Nav .lang-text > .ver{
      color: #ffffff;
      font-size: 1.5rem;
      font-weight: 900;
    }
    
    .Nav .menu-container {
      position: absolute;
      width: 642px;
      height: 100vh;
      padding: 1rem;
      background: rgba(143, 143, 143, 0.90);
      /* opacity: 0.8; */
      /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07)); */
      right: 0px;
      top: 0rem;
      z-index: 100;

      max-width: 642px;
    }
    
    .Nav .close-icon {
      cursor: pointer;
      border-radius: 4px;
      background: #00A6D9;
      width: 195px;
      height: auto;
      flex-shrink: 0;
    }

    .Nav .close-icon-text {
      color: #FFF;
      font-family: 'Lato Bold';
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 10px; /* 45.455% */
      letter-spacing: 2.2px;
    }
    
    .Nav .logo-menu {
      width: 4rem;
    }
    
    .Nav .separator {
      border-bottom: 2px solid #000000;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    
    .Nav .nav-item {
      text-align: left !important;
    }

    .Nav .nav-link a {
      padding: 0.2rem 0.3em;
    }
    
    .Nav .nav-link:hover,
    .Nav .nav-link:focus {
      color: #fff;
      background-color: #56BBA8;
    }
    
    .Nav .nav-link:hover a,
    .Nav .nav-link:focus a {
      color: #fff;
    }
    
    .Nav .gap-custom {
      gap: 0.5rem;
    }
    
  }

  @media (min-width: 1440px) {
    .Nav {
      width: 100%;
      padding: 4rem 4rem;
    }
    
    .Nav .mobile-device {
      display: none;
    }
    
    .Nav .web-device {
      display: block;
    }
    
    .Nav .logo {
      width: 390px;
      height: auto;
    }
    
    .Nav .menu-icon {
      width: 72px;
      height: auto;
      cursor: pointer;
    }
    
    .Nav .lang-text {
      display: flex;
      color: #ffffff;
      font-size: 1rem;
      font-weight: 900;
      align-items: center;
    }

    .Nav .lang-text > .ver{
      color: #ffffff;
      font-size: 1rem;
      font-weight: 900;
    }
    
    .Nav .menu-container {
      position: absolute;
      width: 642px;
      height: 100vh;
      padding: 1rem;
      background: rgba(143, 143, 143, 0.90);
      /* opacity: 0.8; */
      /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07)); */
      right: 0px;
      top: 0rem;
      z-index: 100;
    }
    
    .Nav .close-icon {
      font-size: 3rem;
      font-weight: 800;
      font-family: Arial, Helvetica, sans-serif;
      cursor: pointer;
    }
    
    .Nav .logo-menu {
      width: 8rem;
    }
    
    .Nav .separator {
      border-bottom: 2px solid #000000;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    
    .Nav .nav-item {
      text-align: left !important;
    }
    
    .Nav .past-years {
      color: #000;
      font-family: 'Lato Bold';
      font-size: 2rem;
      font-style: normal;
      font-weight: bolder;
      line-height: 2.5rem;
      cursor: pointer;
    }
    
    .Nav a {
      color: #000;
      font-family: 'Lato Bold';
      font-size: 1.5rem;
      font-style: normal;
      font-weight: bolder;
      line-height: 1.5rem;
    }
    
    .Nav .gap-custom {
      gap: 1rem;
    }
    
}

@media (min-width: 1600px) {
  .Nav {
    width: 100%;
    padding: 4rem 4rem;
  }
  
  .Nav .mobile-device {
    display: none;
  }
  
  .Nav .web-device {
    display: block;
  }
  
  .Nav .logo {
    width: 390px;
    height: auto;
  }
  
  .Nav .menu-icon {
    width: 72px;
    height: auto;
    cursor: pointer;
  }
  
  .Nav .lang-text {
    display: flex;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 900;
    align-items: center;
  }

  .Nav .lang-text > .ver{
    color: #ffffff;
    font-size: 1rem;
    font-weight: 900;
  }
  
  .Nav .menu-container {
    position: absolute;
      width: 642px;
      height: 100vh;
      padding: 1rem;
      background: rgba(143, 143, 143, 0.90);
      /* opacity: 0.8; */
      /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07)); */
      right: 0px;
      top: 0rem;
      z-index: 100;
  }
  
  .Nav .close-icon {
    font-size: 3rem;
    font-weight: 800;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
  }
  
  .Nav .logo-menu {
    width: 8rem;
  }
  
  .Nav .separator {
    border-bottom: 2px solid #000000;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .Nav .nav-item {
    text-align: left !important;
  }
  
  .Nav .past-years {
    color: #000;
    font-family: 'Lato Bold';
    font-size: 2rem;
    font-style: normal;
    font-weight: bolder;
    line-height: 2.5rem;
    cursor: pointer;
  }
  
  .Nav a {
    color: #000;
    font-family: 'Lato Bold';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: bolder;
    line-height: 1.5rem;
  }
  
  .Nav .gap-custom {
    gap: 1rem;
  }
  
}

@media (min-width: 1920px) {
  .Nav {
    width: 100%;
    padding: 4rem 4rem;
  }
  
  .Nav .mobile-device {
    display: none;
  }
  
  .Nav .web-device {
    display: block;
  }
  
  .Nav .logo {
    width: 390px;
    height: auto;
  }
  
  .Nav .menu-icon {
    width: 72px;
    height: auto;
    cursor: pointer;
  }
  
  .Nav .lang-text {
    display: flex;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 900;
    align-items: center;
  }

  .Nav .lang-text > .ver{
    color: #ffffff;
    font-size: 1rem;
    font-weight: 900;
  }
  
  .Nav .menu-container {
    position: absolute;
      width: 642px;
      height: 100vh;
      padding: 1rem;
      background: rgba(143, 143, 143, 0.90);
      /* opacity: 0.8; */
      /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07)); */
      right: 0px;
      top: 0rem;
      z-index: 100;
  }
  
  .Nav .close-icon {
    font-size: 3rem;
    font-weight: 800;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
  }
  
  .Nav .logo-menu {
    width: 8rem;
  }
  
  .Nav .separator {
    border-bottom: 2px solid #000000;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .Nav .nav-item {
    text-align: left !important;
  }
  
  .Nav .past-years {
    color: #000;
    font-family: 'Lato Bold';
    font-size: 2rem;
    font-style: normal;
    font-weight: bolder;
    line-height: 2.5rem;
    cursor: pointer;
  }
  
  .Nav a {
    color: #0F0F0F;
    font-family: 'Lato Bold';
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
  }
  
  .Nav .gap-custom {
    gap: 2rem;
  }
  
}

@media (min-width: 2560px) {
  .Nav {
    width: 100%;
    padding: 4rem 4rem;
  }
  
  .Nav .mobile-device {
    display: none;
  }
  
  .Nav .web-device {
    display: block;
  }
  
  .Nav .logo {
    width: 450px;
    height: auto;
  }
  
  .Nav .menu-icon {
    width: 100px;
    height: auto;
    cursor: pointer;
  }
  
  .Nav .lang-text {
    display: flex;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 900;
    align-items: center;
  }

  .Nav .lang-text > .ver{
    color: #ffffff;
    font-size: 1rem;
    font-weight: 900;
  }
  
  .Nav .menu-container {
    position: absolute;
      width: 700px;
      height: 100vh;
      padding: 1rem;
      background: rgba(143, 143, 143, 0.90);
      /* opacity: 0.8; */
      /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07)); */
      right: 0px;
      top: 0rem;
      z-index: 100;

      max-width: 700px;
  }
  
  .Nav .close-icon {
    font-size: 3rem;
    font-weight: 800;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
  }
  
  .Nav .logo-menu {
    width: 8rem;
  }
  
  .Nav .separator {
    border-bottom: 2px solid #000000;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .Nav .nav-item {
    text-align: left !important;
  }
  
  .Nav .past-years {
    color: #000;
    font-family: 'Lato Bold';
    font-size: 2rem;
    font-style: normal;
    font-weight: bolder;
    line-height: 2.5rem;
    cursor: pointer;
  }
  
  .Nav a {
    color: #0F0F0F;
    font-family: 'Lato Bold';
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
  }
  
  .Nav .gap-custom {
    gap: 2rem;
  }
  
}