.technochicas .rc-t-tch{
    color: #69747C;
    text-align: center;
    font-family: "Rubik One";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 60px */
    text-transform: uppercase;
}
.technochicas .rc-p-tch{
    color: #1F282C;
    text-align: center;
    font-family: Lato;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 47px; /* 167.857% */
}
    .technochicas .rc-p-tch b {
        color: #1F282C;
        font-family: 'Lato Bold';
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        line-height: 47px;
    }
.technochicas .rc-p-tch .bold{
    font-family: 'Lato Bold';
}
.rec-tch .card-rec{
    background-image: url('../../../../assets/images/programas/tecnolochicas/Card-tch.png');
    background-position: bottom;
    background-size: cover;
    min-height: 350px;
    background-repeat: no-repeat;
}

@media screen and (max-width: 1366px) {
    .technochicas .rc-t-tch{
        color: #69747C;
        text-align: center;
        font-family: "Rubik One";
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 60px */
        text-transform: uppercase;
    }
    .technochicas .rc-p-tch b {
        color: #1F282C;
        font-family: 'Lato Bold';
        font-size: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
    }
    .technochicas .rc-p-tch{
        color: #1F282C;
        text-align: center;
        font-family: Lato;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px;
    }
    .technochicas .rc-p-tch .bold{
        font-family: 'Lato Bold';
    }
    .rec-tch .card-rec{
        background-image: url('../../../../assets/images/programas/tecnolochicas/Card-tch.png');
        background-position: bottom;
        background-size: cover;
        min-height: 350px;
        background-repeat: no-repeat;
    }
}

@media screen and (max-width:900px){
    .technochicas .rc-t-tch{
        color: #69747C;
        text-align: center;
        font-family: "Rubik One";
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 60px */
        text-transform: uppercase;
    }
    .technochicas .rc-p-tch{
        color: #1F282C;
        text-align: center;
        font-family: Lato;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px;
    }
    .technochicas .rc-p-tch .bold{
        font-family: 'Lato Bold';
    }
    .rec-tch .card-rec{
        background-image: url('../../../../assets/images/programas/tecnolochicas/Card-tch.png');
        background-position: bottom;
        background-size: cover;
        min-height: 350px;
        background-repeat: no-repeat;
    }
}


@media screen and (max-width:600px){
    .technochicas .rc-t-tch{
        color: #69747C;
        text-align: center;
        font-family: "Rubik One";
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 60px */
        text-transform: uppercase;
    }
    .technochicas .rc-p-tch{
        color: #1F282C;
        text-align: center;
        font-family: Lato;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
    }

    .technochicas .rc-p-tch b {
        color: #1F282C;
        font-family: 'Lato Bold';
        font-size: 22px;
        font-style: normal;
        font-weight: 800;
        line-height: 28px;
    }

    .technochicas .rc-p-tch .bold{
        font-family: 'Lato Bold';
    }
    .rec-tch .card-rec{
        background-image: url('../../../../assets/images/programas/tecnolochicas/Card-tch.png');
        background-position: bottom;
        background-size: cover;
        min-height: 350px;
        background-repeat: no-repeat;
    }
}