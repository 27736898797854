.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Rubik';
  src: url('./assets/fonts/woff/Rubik-Regular.woff2')format("woff2");
}

@font-face {
  font-family: 'Rubik One';
  src: url('./assets/fonts/woff/RubikOne-Regular.woff2')format("woff2");
}

@font-face {
  font-family: 'Rubik Bold';
  src: url('./assets/fonts/woff/Rubik-Bold.woff2')format("woff2");
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/woff/Lato-Regular.woff2')format("woff2");
}
@font-face {
  font-family: 'Lato Bold';
  src: url('./assets/fonts/woff/Lato-Black.woff2')format("woff2");
}

@font-face {
  font-family: 'Ruda';
  src: url('./assets/fonts/woff/Ruda-Regular.woff2')format("woff2");
}
@font-face {
  font-family: 'Ruda Bold';
  src: url('./assets/fonts/woff/Ruda-ExtraBold.woff2')format("woff2");
}
@font-face {
  font-family: 'Ruda Extrab';
  src: url('./assets/fonts/woff/Ruda-ExtraBold.woff2')format("woff2");
}
@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/woff/Inter-Regular.woff2')format("woff2");
}
@font-face {
  font-family: 'Inter Bold';
  src: url('./assets/fonts/woff/Inter-ExtraBold.woff2')format("woff2");
}


.cursor-pointer {
  cursor: pointer;
}
.VideoPlayer iframe{
  max-height: 500px;
}
.VideoPlayer{
  max-height: 500px;
}
.back-to-top{
  color: #fff;
  font-weight: 600;
  background-color: #00B9F1;
  position: fixed;
  display: block;
  top: 75vh;
  right: 0;
  margin: 2rem;
  border-radius: 50px;
  padding: 1rem 1.2rem;
  border: 0px  solid #00B9F1;
  -webkit-box-shadow: -2px 0px 61px 5px rgba(99, 99, 99, 0.5);
  -moz-box-shadow: -2px 0px 61px 5px rgba(99, 99, 99, 0.5);
  box-shadow: -2px 0px 61px 5px rgba(99, 99, 99, 0.5);

  .arrow svg{
    width: 18px;
    color: #fff;
  }
}
.img-vid{
  position: relative;
  width: fit-content;
  height: fit-content;
}
.img-vid .overlay-icon{
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -44px 0 0 -44px;
}
@media screen and (min-width:2880px) {
  .App{
    max-width: 2560px;
    margin: auto;
    -webkit-box-shadow: 13px 13px 69px -2px rgba(0,0,0,0.3);
    -moz-box-shadow: 13px 13px 69px -2px rgba(0,0,0,0.3);
    box-shadow: 13px 13px 69px -2px rgba(0,0,0,0.3);
    background: #E9EDF0;
  }
}

.header-bg-global .row {
  height: 100vh;
}
  .header-bg-global .row .title-global {
    bottom: 50px;
    height: 100%;
    text-align: center;
    width: 100%;
  }


/* Global para homologar programas*/

  .ProgramasGlobal  .main-title-programas  {
    font-family: "Rubik One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  .ProgramasGlobal  .main-subtitle-programas {
    color: #000000;
    text-align: center;
    font-family: "Ruda Bold";
    font-size: 18px;
    font-style: normal;
    font-weight: bolder;
    line-height: normal;
  }

  .ProgramasGlobal  .main-paragraph-programas {
    color: #000;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    line-height: 138%;
  }
    .ProgramasGlobal  .main-paragraph-programas b {
      color: #000;
      font-family: 'Lato Bold';
      font-style: normal;
      font-weight: bolder;
      font-size: 16px;
      text-align: center;
      line-height: 138%;
    }

    .ProgramasGlobal  .main-paragraph-programas .highlight {
      font-family: 'Lato Bold';
      font-size: 20px;
      font-style: normal;
      font-weight: bolder;
      line-height: 138%;
    }
  

  @media (min-width: 768px) {
    .ProgramasGlobal  .main-title-programas  {
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }

    .ProgramasGlobal  .main-subtitle-programas {
      color: #1F282C;
      text-align: center;
      font-family: "Ruda Bold";
      font-size: 24px;
      font-style: normal;
      font-weight: bolder;
      line-height: normal;
    }

    .ProgramasGlobal  .main-paragraph-programas {
      color: #000;
      font-family: Lato;
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      text-align: center;
      line-height: 138%;
    }
    .ProgramasGlobal  .main-paragraph-programas b {
        color: #000;
        font-family: 'Lato Bold';
        font-style: normal;
        font-weight: bolder;
        font-size: 22px;
      line-height: 138%;
      }
  
      .ProgramasGlobal  .main-paragraph-programas .highlight {
        font-family: 'Lato Bold';
        font-size: 28px;
        font-style: normal;
        font-weight: bolder;
        line-height: 138%;
      }
    
    .back-to-top .arrow svg{
      width: 35px;
      color: #fff;
    }
  }

  @media (min-width: 1024px) {
    .ProgramasGlobal  .main-title-programas  {
      font-family: "Rubik One";
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      text-align: left;
    }

    .ProgramasGlobal  .main-subtitle-programas {
      color: #1F282C;
      font-family: "Ruda Bold";
      font-size: 16px;
      font-style: normal;
      font-weight: bolder;
      line-height: normal;
      text-align: start;
    }

    .ProgramasGlobal  .main-paragraph-programas {
      color: #000;
      font-family: Lato;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      text-align: left;
      line-height: 138%;
    }
      .ProgramasGlobal  .main-paragraph-programas b {
        color: #000;
        font-family: 'Lato Bold';
        font-style: normal;
        font-weight: bolder;
        font-size: 14px;
        line-height: 138%;
      }
  
      .ProgramasGlobal  .main-paragraph-programas .highlight {
        font-family: 'Lato Bold';
        font-size: 12px;
        font-style: normal;
        font-weight: bolder;
        line-height: 138%;
      }
    }
  

  @media (min-width: 1280px) {
    .ProgramasGlobal  .main-title-programas  {
      font-family: "Rubik One";
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      text-align: left;
    }

    .ProgramasGlobal  .main-subtitle-programas {
      color: #1F282C;
      font-family: "Ruda Bold";
      font-size: 24px;
      font-style: normal;
      font-weight: bolder;
      line-height: normal;
      text-align: start;
    }

    .ProgramasGlobal  .main-paragraph-programas {
      color: #000;
      font-family: Lato;
      font-style: normal;
      font-weight: 400;
      font-size: 21px;
      text-align: left;
      line-height: 138%;
    }
      .ProgramasGlobal  .main-paragraph-programas b {
        color: #000;
        font-family: 'Lato Bold';
        font-style: normal;
        font-weight: bolder;
        font-size: 21px;
      line-height: 138%;
      }
  
      .ProgramasGlobal  .main-paragraph-programas .highlight {
        font-family: 'Lato Bold';
        font-size: 20px;
        font-style: normal;
        font-weight: bolder;
        line-height: 138%;
      }
    }
  

  @media (min-width: 1366px) {
    .ProgramasGlobal  .main-title-programas  {
      font-family: "Rubik One";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      text-align: left;
    }

    .ProgramasGlobal  .main-subtitle-programas {
      color: #1F282C;
      font-family: "Ruda Bold";
      font-size: 24px;
      font-style: normal;
      font-weight: bolder;
      line-height: normal;
      text-align: start;
    }

    .ProgramasGlobal  .main-paragraph-programas {
      color: #000;
      font-family: Lato;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      text-align: left;
      line-height: 120%;
    }
      .ProgramasGlobal  .main-paragraph-programas b {
        color: #000;
        font-family: 'Lato Bold';
        font-style: normal;
        font-weight: bolder;
        font-size: 20px;
        line-height: 120%;
      }
  
      .ProgramasGlobal  .main-paragraph-programas .highlight {
        font-family: 'Lato Bold';
        font-size: 20px;
        font-style: normal;
        font-weight: bolder;
        line-height: 138%;
      }
    }
  

  @media (min-width: 1440px) {
    .ProgramasGlobal  .main-title-programas  {
      font-family: "Rubik One";
      font-size: 44px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      text-align: left;
    }

    .ProgramasGlobal  .main-subtitle-programas {
      color: #1F282C;
      font-family: "Ruda Bold";
      font-size: 24px;
      font-style: normal;
      font-weight: bolder;
      line-height: normal;
      text-align: start;
    }

    .ProgramasGlobal  .main-paragraph-programas {
      color: #000;
      font-family: Lato;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 138%;
      text-align: start;
    }
      .ProgramasGlobal  .main-paragraph-programas b {
        color: #000;
        font-family: 'Lato Bold';
        font-size: 22px;
        font-style: normal;
        font-weight: bolder;
        line-height: 138%;
        text-align: start;
      }
  
      .ProgramasGlobal  .main-paragraph-programas .highlight {
        font-family: 'Lato Bold';
        font-size: 28px;
        font-style: normal;
        font-weight: bolder;
        line-height: 138%;
      }
    }
  

  @media (min-width: 1600px) {
    .ProgramasGlobal  .main-title-programas  {
      font-family: "Rubik One";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      text-align: left;
    }

    .ProgramasGlobal  .main-subtitle-programas {
      color: #000;
      text-align: left;
      font-family: 'Ruda Bold';
      font-size: 26px;
      font-style: normal;
      font-weight: bolder;
      line-height: normal;
    }

    .ProgramasGlobal  .main-paragraph-programas {
      color: #000;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 138%;
      text-align: start;
    }
      .ProgramasGlobal  .main-paragraph-programas b {
        color: #000;
        font-family: 'Lato Bold';
        font-size: 25px;
        font-style: normal;
        font-weight: bolder;
        line-height: 138%;
        text-align: start;
      }
  
      .ProgramasGlobal  .main-paragraph-programas .highlight {
        font-family: 'Lato Bold';
        font-size: 34px;
        font-style: normal;
        font-weight: bolder;
        line-height: 138%;
      }
    }
  

  @media (min-width: 1920px) {
    .ProgramasGlobal  .main-title-programas  {
      font-family: "Rubik One";
      font-size: 55px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      text-align: left;
    }

    .ProgramasGlobal  .main-subtitle-programas {
      color: #000;
      text-align: left;
      font-family: 'Ruda Bold';
      font-size: 28px;
      font-style: normal;
      font-weight: bolder;
      line-height: normal;
    }

    .ProgramasGlobal  .main-paragraph-programas {
      color: #000;
      font-family: Lato;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 138%;
    }
     .ProgramasGlobal  .main-paragraph-programas b {
        color: #000;
        font-family: 'Lato Bold';
        font-size: 26px;
        font-style: normal;
        font-weight: bolder;
        line-height: 138%; /* 35.88px */
      }
  
     .ProgramasGlobal  .main-paragraph-programas .highlight {
        color: #3F8EFF;
        font-family: 'Lato Bold';
        font-size: 26px;
        font-style: normal;
        font-weight: bolder;
        line-height: 138%;
      }
    }