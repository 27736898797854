.temprano .testimonios-title{
    color: #CB3F8B;
    text-align: center;
    font-family: "Rubik One";
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}
.temprano .img-logo{
    width: 98px;
    margin: auto;
    padding: 3rem 0;
}
.temprano .cdig-tit{
    color: #1F282C;
    text-align: center;
    font-family: Ruda;
    font-size: 2.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 2.96875rem */
}
.temprano .testim-bg{
    background: #FFEBF6;
}

.temprano .Carousel .arrows-text {
    color: #000;
    text-align: center;
    font-family: 'Lato Bold';
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%;
    text-transform: uppercase;
  
    b {
      color: #D4569B;
      font-family: 'Lato Bold';
      font-size: 35px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%;
      text-transform: uppercase;
    }
}
.temprano .testimonios-titulo{
    color: #CB3F8B;
}

@media (max-width: 1440px) {
    .temprano .testimonios-title{
        color: #CB3F8B;
        text-align: center;
        font-family: "Rubik One";
        font-size: 2.1875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
    .temprano .img-logo{
        width: 98px;
        margin: auto;
        padding: 3rem 0;
    }
    .temprano .cdig-tit{
        color: #1F282C;
        text-align: center;
        font-family: Ruda;
        font-size: 2.375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 2.96875rem */
    }
    .temprano .testim-bg{
        background: #FFEBF6;
    }
    
    .temprano .Carousel .arrows-text {
        color: #000;
        text-align: center;
        font-family: 'Lato Bold';
        font-size: 15px;
        font-style: normal;
        font-weight: 800;
        line-height: 100%;
        text-transform: uppercase;
      
        b {
          color: #D4569B;
          font-family: 'Lato Bold';
          font-size: 20px;
          font-style: normal;
          font-weight: 800;
          line-height: 100%;
          text-transform: uppercase;
        }
    }
}

@media screen and (max-width:900px){
    .temprano .img-logo{
        width: 68px;
        margin: auto;
        padding: 1.5rem 0;
    }
}


@media screen and (max-width:600px){
    .temprano .img-logo{
        width: 50px;
        margin: auto;
        padding: 1.5rem 0;
    }

    .temprano .testimonios-title{
        color: var(--et-color);
        text-align: center;
        font-family: "Rubik One";
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
}