.FooterProgramas {
  width: 100%;
  height: 172px;
  overflow: hidden;

}

.FooterProgramas .next-text {
  color: #000;
  text-align: center;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 20px */
  text-decoration-line: underline;
  text-transform: uppercase;
}

.FooterProgramas .next-title {
  font-family: "Rubik One";
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 40.8px */
  text-transform: uppercase;
  padding-top: 10px;
}

.FooterProgramas .bg-programa-cuantrix {
  opacity: 0.8;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(50, 114, 204, 0.32) 100%);
}

.FooterProgramas .bg-programa-valores {
  opacity: 0.8;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(33, 108, 149, 0.32) 100%);
}

.FooterProgramas .bg-programa-tecnolochicas {
  opacity: 0.8;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(135, 128, 189, 0.32) 100%);
}

.FooterProgramas .bg-programa-becalos {
  opacity: 0.8;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(0, 185, 241, 0.32) 100%);
}

.FooterProgramas .bg-programa-empieza-temprano {
  opacity: 0.8;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(212, 86, 155, 0.32) 100%);
}

.FooterProgramas .bg-programa-golmex {
  opacity: 0.8;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 132, 52, 0.31) 96.5%, rgba(232, 186, 74, 0.32) 100%);
}

.FooterProgramas .bg-programa-fotograficamx {
  opacity: 0.8;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(173, 109, 201, 0.32) 100%);
}

.FooterProgramas .bg-programa-posible {
  opacity: 0.8;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(232, 186, 74, 0.32) 100%);
}

.FooterProgramas .next-arrows-width {
  width: 18px;
}

.FooterProgramas {
  @media (min-width: 768px) {
    width: 100%;
    height: 220px;
    .next-text {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 32px */
      text-transform: uppercase;
    }

    .next-title {
      font-family: "Rubik One";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 86.4px */
      text-transform: uppercase;
      padding-top: 10px;
    }

    .bg-programa-cuantrix {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(50, 114, 204, 0.32) 100%);
    }
    
    .bg-programa-valores {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(33, 108, 149, 0.32) 100%);
    }
    
    .bg-programa-tecnolochicas {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(135, 128, 189, 0.32) 100%);
    }
    
    .bg-programa-becalos {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(0, 185, 241, 0.32) 100%);
    }
    
    .bg-programa-empieza-temprano {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(212, 86, 155, 0.32) 100%);
    }
    
    .bg-programa-golmex {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 132, 52, 0.31) 96.5%, rgba(232, 186, 74, 0.32) 100%);
    }
    
    .bg-programa-fotograficamx {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(173, 109, 201, 0.32) 100%);
    }
    
    .bg-programa-posible {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(232, 186, 74, 0.32) 100%);
    }

    .next-arrows-width {
      width: 40px;
    }

  }

  @media (min-width: 1024px) {
    width: 100%;
    height: auto;
    .next-text {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 25px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 32px */
      text-transform: uppercase;
    }

    .next-title {
      font-family: "Rubik One";
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 86.4px */
      text-transform: uppercase;
    }

    .bg-programa-cuantrix {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(50, 114, 204, 0.32) 100%);
    }
    
    .bg-program-image-cuantrix {
      background-image: url('../../../assets/images/programas/footer/bg-cuantrix.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 200px;
    }
    
    .bg-programa-valores {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(33, 108, 149, 0.32) 100%);
    }
    
    .bg-program-image-valores {
      background-image: url('../../../assets/images/programas/footer/bg-valores.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 200px;
    }
    
    .bg-programa-tecnolochicas {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(135, 128, 189, 0.32) 100%);
    }
    
    .bg-program-image-tecnolochicas {
      background-image: url('../../../assets/images/programas/footer/bg-tecnolochicas.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 200px;
    }
    
    .bg-programa-becalos {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(0, 185, 241, 0.32) 100%);
    }
    
    .bg-program-image-becalos {
      background-image: url('../../../assets/images/programas/footer/bg-becalos.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 200px;
    }
    
    .bg-programa-empieza-temprano {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(212, 86, 155, 0.32) 100%);
    }
    
    .bg-program-image-empieza-temprano {
      background-image: url('../../../assets/images/programas/footer/bg-empieza-temprano.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 200px;
    }
    
    .bg-programa-golmex {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 132, 52, 0.31) 96.5%, rgba(232, 186, 74, 0.32) 100%);
    }
    
    .bg-program-image-golmex {
      background-image: url('../../../assets/images/programas/footer/bg-golmex.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 200px;
    }
    
    .bg-programa-fotograficamx {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(173, 109, 201, 0.32) 100%);
    }
    
    .bg-program-image-fotograficamx {
      background-image: url('../../../assets/images/programas/footer/bg-fotograficamx.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 200px;
    }
    
    .bg-programa-posible {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(232, 186, 74, 0.32) 100%);
    }
    
    .bg-program-image-posible {
      background-image: url('../../../assets/images/programas/footer/bg-posible.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 200px;
    }

    .next-arrows-width {
      width: 35px;
    }
  }

  @media (min-width: 1280px) {
    .next-text {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 26px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 32px */
      text-transform: uppercase;
    }

    .next-title {
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 86.4px */
      text-transform: uppercase;
    }

    .bg-programa-cuantrix {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(50, 114, 204, 0.32) 100%);
    }
    
    .bg-program-image-cuantrix {
      background-image: url('../../../assets/images/programas/footer/bg-cuantrix.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 220px;
    }
    
    .bg-programa-valores {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(33, 108, 149, 0.32) 100%);
    }
    
    .bg-program-image-valores {
      background-image: url('../../../assets/images/programas/footer/bg-valores.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 220px;
    }
    
    .bg-programa-tecnolochicas {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(135, 128, 189, 0.32) 100%);
    }
    
    .bg-program-image-tecnolochicas {
      background-image: url('../../../assets/images/programas/footer/bg-tecnolochicas.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 220px;
    }
    
    .bg-programa-becalos {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(0, 185, 241, 0.32) 100%);
    }
    
    .bg-program-image-becalos {
      background-image: url('../../../assets/images/programas/footer/bg-becalos.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 220px;
    }
    
    .bg-programa-empieza-temprano {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(212, 86, 155, 0.32) 100%);
    }
    
    .bg-program-image-empieza-temprano {
      background-image: url('../../../assets/images/programas/footer/bg-empieza-temprano.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 220px;
    }
    
    .bg-programa-golmex {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 132, 52, 0.31) 96.5%, rgba(232, 186, 74, 0.32) 100%);
    }
    
    .bg-program-image-golmex {
      background-image: url('../../../assets/images/programas/footer/bg-golmex.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 220px;
    }
    
    .bg-programa-fotograficamx {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(173, 109, 201, 0.32) 100%);
    }
    
    .bg-program-image-fotograficamx {
      background-image: url('../../../assets/images/programas/footer/bg-fotograficamx.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 220px;
    }
    
    .bg-programa-posible {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(232, 186, 74, 0.32) 100%);
    }
    
    .bg-program-image-posible {
      background-image: url('../../../assets/images/programas/footer/bg-posible.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 220px;
    }

    .next-arrows-width {
      width: 30px;
    }
  }

  @media (min-width: 1366px) {
    .next-text {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 25px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 32px */
      text-transform: uppercase;
    }

    .next-title {
      font-family: "Rubik One";
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 86.4px */
      text-transform: uppercase;
    }

    .bg-programa-cuantrix {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(50, 114, 204, 0.32) 100%);
    }
    
    .bg-program-image-cuantrix {
      background-image: url('../../../assets/images/programas/footer/bg-cuantrix.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 230px;
    }
    
    .bg-programa-valores {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(33, 108, 149, 0.32) 100%);
    }
    
    .bg-program-image-valores {
      background-image: url('../../../assets/images/programas/footer/bg-valores.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 230px;
    }
    
    .bg-programa-tecnolochicas {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(135, 128, 189, 0.32) 100%);
    }
    
    .bg-program-image-tecnolochicas {
      background-image: url('../../../assets/images/programas/footer/bg-tecnolochicas.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 230px;
    }
    
    .bg-programa-becalos {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(0, 185, 241, 0.32) 100%);
    }
    
    .bg-program-image-becalos {
      background-image: url('../../../assets/images/programas/footer/bg-becalos.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 230px;
    }
    
    .bg-programa-empieza-temprano {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(212, 86, 155, 0.32) 100%);
    }
    
    .bg-program-image-empieza-temprano {
      background-image: url('../../../assets/images/programas/footer/bg-empieza-temprano.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 230px;
    }
    
    .bg-programa-golmex {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 132, 52, 0.31) 96.5%, rgba(232, 186, 74, 0.32) 100%);
    }
    
    .bg-program-image-golmex {
      background-image: url('../../../assets/images/programas/footer/bg-golmex.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 230px;
    }
    
    .bg-programa-fotograficamx {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(173, 109, 201, 0.32) 100%);
    }
    
    .bg-program-image-fotograficamx {
      background-image: url('../../../assets/images/programas/footer/bg-fotograficamx.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 230px;
    }
    
    .bg-programa-posible {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(232, 186, 74, 0.32) 100%);
    }
    
    .bg-program-image-posible {
      background-image: url('../../../assets/images/programas/footer/bg-posible.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 230px;
    }

    .next-arrows-width {
      width: 30px;
    }
  }

  @media (min-width: 1440px) {
    width: 100%;
    height: 220px;
    .next-text {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 32px */
      text-transform: uppercase;
    }

    .next-title {
      font-family: "Rubik One";
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 86.4px */
      text-transform: uppercase;
    }

    .bg-programa-cuantrix {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(50, 114, 204, 0.32) 100%);
    }
    
    .bg-program-image-cuantrix {
      background-image: url('../../../assets/images/programas/footer/bg-cuantrix.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 220px;
    }
    
    .bg-programa-valores {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(33, 108, 149, 0.32) 100%);
    }
    
    .bg-program-image-valores {
      background-image: url('../../../assets/images/programas/footer/bg-valores.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 220px;
    }
    
    .bg-programa-tecnolochicas {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(135, 128, 189, 0.32) 100%);
    }
    
    .bg-program-image-tecnolochicas {
      background-image: url('../../../assets/images/programas/footer/bg-tecnolochicas.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 220px;
    }
    
    .bg-programa-becalos {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(0, 185, 241, 0.32) 100%);
    }
    
    .bg-program-image-becalos {
      background-image: url('../../../assets/images/programas/footer/bg-becalos.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 220px;
    }
    
    .bg-programa-empieza-temprano {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(212, 86, 155, 0.32) 100%);
    }
    
    .bg-program-image-empieza-temprano {
      background-image: url('../../../assets/images/programas/footer/bg-empieza-temprano.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 220px;
    }
    
    .bg-programa-golmex {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 132, 52, 0.31) 96.5%, rgba(232, 186, 74, 0.32) 100%);
    }
    
    .bg-program-image-golmex {
      background-image: url('../../../assets/images/programas/footer/bg-golmex.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 220px;
    }
    
    .bg-programa-fotograficamx {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(173, 109, 201, 0.32) 100%);
    }
    
    .bg-program-image-fotograficamx {
      background-image: url('../../../assets/images/programas/footer/bg-fotograficamx.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 220px;
    }
    
    .bg-programa-posible {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(232, 186, 74, 0.32) 100%);
    }
    
    .bg-program-image-posible {
      background-image: url('../../../assets/images/programas/footer/bg-posible.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 220px;
    }

    .next-arrows-width {
      width: 30px;
    }
  }

  @media (min-width: 1920px) {
    width: 100%;
    height: 336px;
    .next-text {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 32px */
      text-transform: uppercase;
    }

    .next-title {
      font-family: "Rubik One";
      font-size: 72px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 86.4px */
      text-transform: uppercase;
    }

    .bg-programa-cuantrix {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(50, 114, 204, 0.32) 100%);
    }
    
    .bg-program-image-cuantrix {
      background-image: url('../../../assets/images/programas/footer/bg-cuantrix.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 336px;
    }
    
    .bg-programa-valores {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(33, 108, 149, 0.32) 100%);
    }
    
    .bg-program-image-valores {
      background-image: url('../../../assets/images/programas/footer/bg-valores.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 336px;
    }
    
    .bg-programa-tecnolochicas {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(135, 128, 189, 0.32) 100%);
    }
    
    .bg-program-image-tecnolochicas {
      background-image: url('../../../assets/images/programas/footer/bg-tecnolochicas.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 336px;
    }
    
    .bg-programa-becalos {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(0, 185, 241, 0.32) 100%);
    }
    
    .bg-program-image-becalos {
      background-image: url('../../../assets/images/programas/footer/bg-becalos.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 336px;
    }
    
    .bg-programa-empieza-temprano {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(212, 86, 155, 0.32) 100%);
    }
    
    .bg-program-image-empieza-temprano {
      background-image: url('../../../assets/images/programas/footer/bg-empieza-temprano.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 336px;
    }
    
    .bg-programa-golmex {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 132, 52, 0.31) 96.5%, rgba(232, 186, 74, 0.32) 100%);
    }
    
    .bg-program-image-golmex {
      background-image: url('../../../assets/images/programas/footer/bg-golmex.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 336px;
    }
    
    .bg-programa-fotograficamx {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(173, 109, 201, 0.32) 100%);
    }
    
    .bg-program-image-fotograficamx {
      background-image: url('../../../assets/images/programas/footer/bg-fotograficamx.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 336px;
    }
    
    .bg-programa-posible {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(232, 186, 74, 0.32) 100%);
    }
    
    .bg-program-image-posible {
      background-image: url('../../../assets/images/programas/footer/bg-posible.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 336px;
    }

    .next-arrows-width {
      width: 40px;
    }
  }

  @media (min-width: 2560px) {
    width: 100%;
    height: 445px;
    .next-text {
      color: #000;
      text-align: center;
      font-family: 'Lato Bold';
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 32px */
      text-transform: uppercase;
    }

    .next-title {
      font-family: "Rubik One";
      font-size: 72px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 86.4px */
      text-transform: uppercase;
    }

    .bg-programa-cuantrix {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(50, 114, 204, 0.32) 100%);
    }
    
    .bg-program-image-cuantrix {
      background-image: url('../../../assets/images/programas/footer/bg-cuantrix.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 445px;
    }
    
    .bg-programa-valores {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(33, 108, 149, 0.32) 100%);
    }
    
    .bg-program-image-valores {
      background-image: url('../../../assets/images/programas/footer/bg-valores.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 445px;
    }
    
    .bg-programa-tecnolochicas {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(135, 128, 189, 0.32) 100%);
    }
    
    .bg-program-image-tecnolochicas {
      background-image: url('../../../assets/images/programas/footer/bg-tecnolochicas.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 445px;
    }
    
    .bg-programa-becalos {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(0, 185, 241, 0.32) 100%);
    }
    
    .bg-program-image-becalos {
      background-image: url('../../../assets/images/programas/footer/bg-becalos.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 445px;
    }
    
    .bg-programa-empieza-temprano {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(212, 86, 155, 0.32) 100%);
    }
    
    .bg-program-image-empieza-temprano {
      background-image: url('../../../assets/images/programas/footer/bg-empieza-temprano.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 445px;
    }
    
    .bg-programa-golmex {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 132, 52, 0.31) 96.5%, rgba(232, 186, 74, 0.32) 100%);
    }
    
    .bg-program-image-golmex {
      background-image: url('../../../assets/images/programas/footer/bg-golmex.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 445px;
    }
    
    .bg-programa-fotograficamx {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(173, 109, 201, 0.32) 100%);
    }
    
    .bg-program-image-fotograficamx {
      background-image: url('../../../assets/images/programas/footer/bg-fotograficamx.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 445px;
    }
    
    .bg-programa-posible {
      opacity: 0.8;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(232, 186, 74, 0.32) 100%);
    }
    
    .bg-program-image-posible {
      background-image: url('../../../assets/images/programas/footer/bg-posible.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 445px;
    }

    .next-arrows-width {
      width: 45px;
    }
  }
}