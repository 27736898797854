.Comunicacion {
  width: 100%;
  overflow-x: hidden;

  @media (min-width: 768px) {

  }

  @media (min-width: 1024px) {
    
  }
}