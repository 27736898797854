.ContentNuestrasAlas {
  width: 100%;
  background-color: #F8F8F8;
  /* background-image: url('../../../assets/images/nuestrasAlas/content/gradient.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: auto; */
}

.ContentNuestrasAlas .mobile-device {
  display: block;
}

.ContentNuestrasAlas .web-device {
  display: none;
}

.ContentNuestrasAlas .title {
  color: #72CDBC;
  text-align: center;
  font-family: "Rubik One";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
}

.ContentNuestrasAlas .subtitle {
  color: #000;
  font-family: 'Ruda Bold';
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 110%;
  padding-left: 5%;
  padding-right: 5%;

  b {
    color: #51BCA8;
    text-align: center;
    font-family: "Rubik One";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 52.8px */
  }
}

.ContentNuestrasAlas .bg-beneficiada {
  background-image: url('../../../assets/images/nuestrasAlas/content/beneficiada.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 300px;
}

.ContentNuestrasAlas .bg-custom {
  background-color: #51BCA8;
  /* background-image: url('../../../assets/images/nuestrasAlas/content/gradient-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  background-position: bottom; */
}

.ContentNuestrasAlas .second-title {
  color: #F5F5F5;
  text-align: center;
  font-family: "Rubik One";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.ContentNuestrasAlas .paragraph {
  color: #FFF;
  font-family: 'Ruda Bold';
  font-size: 19px;
  font-style: normal;
  font-weight: 800;
  line-height: 114%; /* 59.28px */
  padding-left: 9%;
  padding-right: 9%;
  text-align: center;

  b {
    color: #FFF;
    font-family: "Rubik One";
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 114%;
  }
}

.ContentNuestrasAlas .paragraph b {
  color: #FFF;
  font-family: 'Lato Bold';
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 142%; /* 28.4px */
}

  @media (min-width: 768px) {
    .ContentNuestrasAlas .title {
        color: #72CDBC;
        text-align: center;
        font-family: "Rubik One";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 57.6px */
        text-transform: uppercase;
        padding-left: 15%;
        padding-right: 15%;
        padding-top: 5%;
    }

    .ContentNuestrasAlas .subtitle {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: 110%;
        padding-left: 5%;
        padding-right: 5%;
    }

    .ContentNuestrasAlas .subtitle b {
        color: #51BCA8;
        text-align: center;
        font-family: "Rubik One";
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 52.8px */
    }

    .ContentNuestrasAlas .bg-beneficiada {
        background-image: url('../../../assets/images/nuestrasAlas/content/beneficiada.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 488px;
    }

    .ContentNuestrasAlas .bg-custom {
        background-color: #51BCA8;
    }

    .ContentNuestrasAlas .second-title {
        color: #FFF;
        text-align: center;
        font-family: "Rubik One";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }

    .ContentNuestrasAlas .paragraph {
        color: #FFF;
        font-family: 'Ruda Bold';
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 114%; /* 59.28px */
        padding-left: 9%;
        padding-right: 9%;
        text-align: center;
    }

    .ContentNuestrasAlas .paragraph b {
        color: #FFF;
        font-family: "Rubik One";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 114%;
    }
}

@media (min-width: 1024px) {
    .ContentNuestrasAlas .mobile-device {
        display: none;
    }

    .ContentNuestrasAlas .web-device {
        display: block;
    }

    .ContentNuestrasAlas .bg-contenido {
        background-image: url('../../../assets/images/nuestrasAlas/content/gradient.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: auto;
    }

    .ContentNuestrasAlas .title {
        color: #72CDBC;
        text-align: center;
        font-family: "Rubik One";
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 5%;
    }

    .ContentNuestrasAlas .subtitle {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: 110%;
        padding-left: 5%;
        padding-right: 5%;
    }

    .ContentNuestrasAlas .subtitle b {
        color: #51BCA8;
        text-align: center;
        font-family: "Rubik One";
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 52.8px */
    }

    .ContentNuestrasAlas .bg-beneficiada {
        background-image: url('../../../assets/images/nuestrasAlas/content/beneficiada-web.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 415px;
    }

    .ContentNuestrasAlas .bg-custom {
        background-color: #51BCA8;
    }

    .ContentNuestrasAlas .second-title {
        color: #F5F5F5;
        font-family: "Rubik One";
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: left;
    }

    .ContentNuestrasAlas .paragraph {
        color: #FFF;
        font-family: 'Ruda Bold';
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 114%; /* 59.28px */
        padding-left: 9%;
        padding-right: 9%;
        text-align: left;
    }

    .ContentNuestrasAlas .paragraph b {
        color: #FFF;
        font-family: "Rubik One";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 114%;
    }
}

@media (min-width: 1280px) {
  .ContentNuestrasAlas .mobile-device {
      display: none;
  }

  .ContentNuestrasAlas .web-device {
      display: block;
  }

  .ContentNuestrasAlas .bg-contenido {
      background-image: url('../../../assets/images/nuestrasAlas/content/gradient.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: auto;
  }

  .ContentNuestrasAlas .title {
      color: #72CDBC;
      text-align: center;
      font-family: "Rubik One";
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 5%;
  }

  .ContentNuestrasAlas .subtitle {
      color: #000;
      font-family: 'Ruda Bold';
      font-size: 35px;
      font-style: normal;
      font-weight: 800;
      line-height: 110%;
      padding-left: 5%;
      padding-right: 5%;
  }

  .ContentNuestrasAlas .subtitle b {
      color: #51BCA8;
      text-align: center;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 52.8px */
  }

  .ContentNuestrasAlas .bg-beneficiada {
      background-image: url('../../../assets/images/nuestrasAlas/content/beneficiada-web.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 500px;
  }

  .ContentNuestrasAlas .bg-custom {
      background-color: #51BCA8;
  }

  .ContentNuestrasAlas .second-title {
      color: #F5F5F5;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal; /* 63.636% */
      text-transform: uppercase;
      text-align: left;
  }

  .ContentNuestrasAlas .paragraph {
      color: #FFF;
      font-family: 'Ruda Bold';
      font-size: 35px;
      font-style: normal;
      font-weight: 800;
      line-height: 114%; /* 59.28px */
      padding-left: 9%;
      padding-right: 9%;
  }

  .ContentNuestrasAlas .paragraph b {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 114%;
  }
}

@media (min-width: 1366px) {
  .ContentNuestrasAlas .mobile-device {
      display: none;
  }

  .ContentNuestrasAlas .web-device {
      display: block;
  }

  .ContentNuestrasAlas .bg-contenido {
      background-image: url('../../../assets/images/nuestrasAlas/content/gradient.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: auto;
  }

  .ContentNuestrasAlas .title {
      color: #72CDBC;
      text-align: center;
      font-family: "Rubik One";
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 5%;
  }

  .ContentNuestrasAlas .subtitle {
      color: #000;
      font-family: 'Ruda Bold';
      font-size: 35px;
      font-style: normal;
      font-weight: 800;
      line-height: 110%;
      padding-left: 5%;
      padding-right: 5%;
  }

  .ContentNuestrasAlas .subtitle b {
      color: #51BCA8;
      text-align: center;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 52.8px */
  }

  .ContentNuestrasAlas .bg-beneficiada {
      background-image: url('../../../assets/images/nuestrasAlas/content/beneficiada-web.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 500px;
  }

  .ContentNuestrasAlas .bg-custom {
      background-color: #51BCA8;
  }

  .ContentNuestrasAlas .second-title {
      color: #F5F5F5;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal; /* 63.636% */
      text-transform: uppercase;
      text-align: left;
  }

  .ContentNuestrasAlas .paragraph {
      color: #FFF;
      font-family: 'Ruda Bold';
      font-size: 38px;
      font-style: normal;
      font-weight: 800;
      line-height: 114%; /* 59.28px */
      padding-left: 9%;
      padding-right: 9%;
  }

  .ContentNuestrasAlas .paragraph b {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 38px;
      font-style: normal;
      font-weight: 400;
      line-height: 114%;
  }
}


@media (min-width: 1440px) {
  .ContentNuestrasAlas .title {
      color: #72CDBC;
      text-align: center;
      font-family: "Rubik One";
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 5%;
  }

  .ContentNuestrasAlas .subtitle {
      color: #000;
      font-family: 'Ruda Bold';
      font-size: 35px;
      font-style: normal;
      font-weight: 800;
      line-height: 110%;
      padding-left: 5%;
      padding-right: 5%;
  }

  .ContentNuestrasAlas .subtitle b {
      color: #51BCA8;
      text-align: center;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
  }

  .ContentNuestrasAlas .bg-beneficiada {
      background-image: url('../../../assets/images/nuestrasAlas/content/beneficiada-web.png');
      background-repeat: no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      width: 100%;
      height: 550px;
  }

  .ContentNuestrasAlas .bg-custom {
      background-color: #51BCA8;
  }

  .ContentNuestrasAlas .second-title {
      color: #F5F5F5;
      font-family: "Rubik One";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 142%;
      text-transform: uppercase;
  }

  .ContentNuestrasAlas .paragraph {
      color: #FFF;
      font-family: 'Ruda Bold';
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 114%;
      padding-left: 9%;
      padding-right: 9%;
  }

  .ContentNuestrasAlas .paragraph b {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 114%;
  }

  .ContentNuestrasAlas .custom-margin {
      margin-top: 0rem;
  }
}

@media (min-width: 1600px) {
  .ContentNuestrasAlas .title {
      color: #72CDBC;
      text-align: center;
      font-family: "Rubik One";
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 5%;
  }

  .ContentNuestrasAlas .subtitle {
      color: #000;
      font-family: 'Ruda Bold';
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 110%;
      padding-left: 8%;
      padding-right: 8%;
  }

  .ContentNuestrasAlas .subtitle b {
      color: #51BCA8;
      text-align: center;
      font-family: "Rubik One";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
  }

  .ContentNuestrasAlas .bg-beneficiada {
      background-image: url('../../../assets/images/nuestrasAlas/content/beneficiada-web.png');
      background-repeat: no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      width: 100%;
      height: 600px;
  }

  .ContentNuestrasAlas .bg-custom {
      background-color: #51BCA8;
  }

  .ContentNuestrasAlas .second-title {
      color: #F5F5F5;
      font-family: "Rubik One";
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      line-height: 142%;
      text-transform: uppercase;
  }

  .ContentNuestrasAlas .paragraph {
      color: #FFF;
      font-family: 'Ruda Bold';
      font-size: 45px;
      font-style: normal;
      font-weight: 800;
      line-height: 114%;
      padding-left: 9%;
      padding-right: 9%;
  }

  .ContentNuestrasAlas .paragraph b {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      line-height: 114%;
  }

  .ContentNuestrasAlas .custom-margin {
      margin-top: 0rem;
  }
}

@media (min-width: 1920px) {
  .ContentNuestrasAlas .title {
      color: #72CDBC;
      text-align: center;
      font-family: "Rubik One";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 5%;
  }

  .ContentNuestrasAlas .subtitle {
      color: #000;
      font-family: 'Ruda Bold';
      font-size: 48px;
      font-style: normal;
      font-weight: 800;
      line-height: 110%;
      padding-left: 5%;
      padding-right: 5%;
  }

  .ContentNuestrasAlas .subtitle b {
      color: #51BCA8;
      text-align: center;
      font-family: "Rubik One";
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
  }

  .ContentNuestrasAlas .bg-beneficiada {
      background-image: url('../../../assets/images/nuestrasAlas/content/beneficiada-web.png');
      background-repeat: no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      width: 100%;
      height: 626px;
  }

  .ContentNuestrasAlas .bg-custom {
      background-color: #51BCA8;
  }

  .ContentNuestrasAlas .second-title {
      color: #F5F5F5;
      font-family: "Rubik One";
      font-size: 55px;
      font-style: normal;
      font-weight: 400;
      line-height: 142%;
      text-transform: uppercase;
  }

  .ContentNuestrasAlas .paragraph {
      color: #FFF;
      font-family: 'Ruda Bold';
      font-size: 52px;
      font-style: normal;
      font-weight: 800;
      line-height: 114%;
      padding-left: 9%;
      padding-right: 9%;
  }

  .ContentNuestrasAlas .paragraph b {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 52px;
      font-style: normal;
      font-weight: 400;
      line-height: 114%;
  }

  .ContentNuestrasAlas .custom-margin {
      margin-top: 10rem;
  }
}
