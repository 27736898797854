.Programs {
  width: 100%;

  .Programs .mobile-device {
    display: block;
  }

  .Programs .web-device {
    display: none;
  }

  .Programs .program-container {
    margin-top: 30px;
  }

  .Programs .bg-rectangle-programs {
    background-image: url('../../../assets/images/igualdad/rectangle-programs.png');
    background-position: top right;
    background-repeat: no-repeat;
    width: 100%;
    height: 49px;
    margin-top: -1rem;
  }

  @media (min-width: 768px) {
    .program-container {
      margin-top: 80px;
    }
  }

  @media (min-width: 1024px) {
    .mobile-device {
      display: none;
    }
  
    .web-device {
      display: block;
    }
  
    .program-container {
      margin-top: 70px;
    }
  }
}