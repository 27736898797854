.TestimoniosPosible {
  width: 100%;
}
  .TestimoniosPosible .title-posible {
    color: #CE9200;
    text-align: center;
    font-family: "Rubik One";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;

  }

  .TestimoniosPosible .testimonios-icon {
    width: 50px;
  }

  .TestimoniosPosible .Carousel .arrows-text {
    color: #000;
    text-align: center;
    font-family: 'Lato Bold';
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%;
    text-transform: uppercase;
  }
    .TestimoniosPosible .Carousel .arrows-text b {
      color: #C99822;
      font-family: 'Lato Bold';
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%;
      text-transform: uppercase;
    }


  @media (min-width: 768px) {

  }

  @media (min-width: 1024px) {
    
  }

  @media (min-width: 1440px) {
    .TestimoniosPosible .title-posible {
        color: #CE9200;
        text-align: center;
        font-family: "Rubik One";
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        width: 100%;
    }

    .TestimoniosPosible .testimonios-icon {
        width: 98px;
    }

    .TestimoniosPosible .Carousel .arrows-text {
        color: #000;
        text-align: center;
        font-family: 'Lato Bold';
        font-size: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: 100%;
        text-transform: uppercase;
    }

    .TestimoniosPosible .Carousel .arrows-text b {
        color: #C99822;
        font-family: 'Lato Bold';
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 100%;
        text-transform: uppercase;
    }
}