.NuestrasAcciones {
  width: 100%;
  background: #8780BD;
}

.NuestrasAcciones .mobile-device {
  display: block;
}

.NuestrasAcciones .web-device {
  display: none;
}

.NuestrasAcciones .bg-purple {
  background: #8780BD;
  width: 100%;
  height: auto;
  flex-shrink: 0;
  padding-left: 30px;
  padding-right: 30px;
}

.NuestrasAcciones .title {
  color: #FFF;
  text-align: center;
  font-family: "Rubik One";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  padding-top: 48px;
}

.NuestrasAcciones .paragraph1 {
  color: #FFF;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding-top: 12px;
}

.NuestrasAcciones .paragraph2 {
  color: #FFF;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding-top: 12px;
}

.NuestrasAcciones .chatbot-img {
  width: 300px;
  height: 204px;
  border: 0;
  cursor: pointer;
}

.NuestrasAcciones .chatbot-img iframe {
  max-height: 500px;
}

.NuestrasAcciones .bg-rectangle {
  background-image: url('../../../assets/images/igualdad/rectangle-chatbot.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  background-position: bottom right;
}

.NuestrasAcciones{
  @media (min-width: 768px) {
    .bg-purple {
      background: #8780BD;
      width: 100%;
      height: auto;
      flex-shrink: 0;
      padding-left: 30px;
      padding-right: 30px;
    }

    .title {
      color: #FFF;
      text-align: center;
      font-family: "Rubik One";
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      padding-top: 79px;
    }
  
    .paragraph1 {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 142%; /* 31.24px */
      padding-top: 32px;
      padding-left: 20%;
      padding-right: 20%;
    }
  
    .paragraph2 {
      color: #FFF;
      font-family: Lato;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 142%;
      padding-top: 12px;
      padding-left: 10%;
      padding-right: 10%;
    }

    .chatbot-img {
      width: 709px;
      height: 474px;
      border: 0;
    }
  }

  @media (min-width: 1024px) {
    .mobile-device {
      display: none;
    }
  
    .web-device {
      display: block;
    }

    .bg-rectangle {
      background-image: url('../../../assets/images/igualdad/rectangle-chatbot.png');
       background-position: bottom right;
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      height: auto;
    }

    .bg-purple {
      background: #8780BD;
      width: 100%;
      height: auto;
      flex-shrink: 0;
    }

    .title {
      color: #FFF;
      font-family: "Rubik One";
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 109.756% */
      text-transform: uppercase;
      text-align: left;
      padding-top: 2rem;
    }
  
    .paragraph1 {
      color: #FFF;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      text-align: left;
      padding: 0rem 7rem;

      b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 160%; /* 40px */
      }
    }
  
    .paragraph2 {
      color: #FFF;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
      padding-top: 12px;
      padding-left: 0px;
      padding-right: 0px;
    }

    .chatbot-img {
      width: 709px;
      height: 474px;
      border: 20px solid #69747C;
    }
  }

  @media (min-width: 1440px) {
    .title {
      color: #FFF;
      text-align: center;
      font-family: "Rubik One";
      font-size: 41px;
      font-style: normal;
      font-weight: 400;
      line-height: 45px; /* 109.756% */
      text-transform: uppercase;
    }
  
    .paragraph1 {
      color: #FFF;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      text-align: left;
      padding: 0rem 7rem;

      b, strong {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: 160%; /* 40px */
      }
    }
  
    .paragraph2 {
      color: #FFF;
      font-family: Lato;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 45px;
      text-align: left;
      padding-top: 12px;
      padding-left: 0px;
      padding-right: 0px;
    }

    .chatbot-img {
      width: 709px;
      height: 474px;
      border: 20px solid #69747C;
    }
  }
}