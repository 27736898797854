.golxmex .bg-suave {
    background: linear-gradient(180deg, rgba(255, 132, 52, 0.80) -50.88%, rgba(255, 255, 255, 0.00) 55.55%);
    padding: 1.5rem 3rem;
}

.golxmex .gxm-title {
    color: var(--gxm-color);
    font-family: "Rubik One";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    text-align: start;
}

.golxmex .gxm-par {
    color: #1F282C;
    font-family: Lato;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 38.64px */
    text-align: start;
}

.golxmex .gxm-img {
    width: 100%;
}

.golxmex .gxm-quote {
    color: #FFF;
    font-family: Lato;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 43.75px */
    text-align: center;
}

.golxmex .mh-250 {
    min-height: 300px;
    padding: 3rem;
}

.golxmex .gxm-par .bold, .gxm-quote .bold {
    font-family: 'Lato Bold';
    font-weight: bolder;
}

.golxmex .bg-gxm {
    background-color: var(--gxm-color);
}

.golxmex .bg-gxm-gris {
    background-color: var(--bc-gris);
}

.golxmex .main-title-programas {
    color: var(--gxm-color);
}

@media screen and (max-width:1366px){
    .golxmex .bg-suave{
        padding: 1.5rem;
    }
    .golxmex .gxm-title{
        text-align: left;
        font-size: 2rem;
    }
    .golxmex .gxm-par{
        text-align: left;
        font-size: 1.2rem;
        line-height: 150%;
    }
    .golxmex .gxm-par .bold, .golxmex .gxm-quote .bold{
        font-weight: 400;
        line-height: 150%;
    }
    .golxmex .gxm-quote{
        font-size:25px;
        text-align: center;

    }
    .golxmex .mh-250{
        min-height: 175px;
        padding: 1rem 3rem;
    }
}

@media screen and (max-width:1024px){
    .golxmex .bg-suave{
        padding: 1.5rem;
    }
    .golxmex .gxm-title{
        text-align: left;
        font-size: 1.75rem;
    }
    .golxmex .gxm-par{
        text-align: left;
        font-size: 0.85rem;
        line-height: 150%;
    }
    .golxmex .gxm-par .bold, .golxmex .gxm-quote .bold{
        font-weight: 400;
        line-height: 150%;
    }
    .golxmex .gxm-quote{
        font-size:1.25rem;
        text-align: center;

    }
    .golxmex .mh-250{
        min-height: 175px;
        padding: 1rem 3rem;
    }
}

@media screen and (max-width:900px){
    .golxmex .bg-suave{
        padding: 1.5rem;
    }
    .golxmex .gxm-title{
        text-align: center;
        font-size: 2.125rem;
    }
    .golxmex .gxm-par{
        text-align: center;
        font-size:1.5rem;
        line-height: 150%;
    }
    .golxmex .gxm-par .bold, .golxmex .gxm-quote .bold{
        font-weight: 400;
        line-height: 150%;
    }
    .golxmex .gxm-quote{
        font-size:1.75rem;
        text-align: center;

    }
    .golxmex .gxm-title{
        text-align: center;
    }
    .golxmex .mh-250{
        min-height: 175px;
        padding: 1rem 3rem;
    }
}


@media screen and (max-width:600px){
    .golxmex .bg-suave{
        padding: 1.5rem;
    }
    .golxmex .gxm-title{
        text-align: center;
        font-size: 2.125rem;
    }
    .golxmex .gxm-par{
        text-align: center;
        font-size:1.1rem;
        line-height: 150%;
    }
    .golxmex .gxm-par .bold, .golxmex .gxm-quote .bold{
        font-weight: 400;
        line-height: 150%;
    }
    .golxmex .gxm-quote{
        font-size:1.1875rem;
        text-align: center;

    }
    .golxmex .gxm-title{
        text-align: center;
    }
    .golxmex .mh-250{
        min-height: 175px;
        padding: 1rem;
    }
}