.Button .mobile-device {
    display: block;
}

.Button .web-device {
    display: none;
}

.Button .conoce-mas{
    font-family: 'Lato Bold';
    font-size: 20px;
    font-weight: 800;
    line-height: 16px;
    /* letter-spacing: 0em;
    border: solid 1px #000;
    min-height: 35px; */
    position: relative;
    z-index: 4;
    /* background-color:#E9EDF0;
    padding: 1rem 2rem;
    width: 335px;
    height: 54.74px; */

    border: 0px;
    display: inline-flex;
    padding: 30px 27px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 80px;
    /* background: #29C7F6; */
    width: auto;
}

.Button .conoce-mas-blue {
    background: #29C7F6;
    color: #00556F;
    -webkit-text-stroke-width: 1.399999976158142;
    -webkit-text-stroke-color: #00556F;
    font-family: 'Lato Bold';
    font-size: 14px;
    font-style: normal;
    font-weight: bolder;
    line-height: 120%; /* 28.8px */
    letter-spacing: 2.4px;
}

.Button .conoce-mas-purple {
    background: #A49BE4;
    color: #463E80;
    -webkit-text-stroke-width: 1.399999976158142;
    -webkit-text-stroke-color: #463E80;
    font-family: 'Lato Bold';
    font-size: 14px;
    font-style: normal;
    font-weight: bolder;
    line-height: 120%; /* 28.8px */
    letter-spacing: 2.4px;
}

.Button .conoce-mas-brown {
    background: #F2BC34;
    color: #4D3805;
    -webkit-text-stroke-width: 1.399999976158142;
    -webkit-text-stroke-color: #4D3805;
    font-family: 'Lato Bold';
    font-size: 14px;
    font-style: normal;
    font-weight: bolder;
    line-height: 120%; /* 28.8px */
    letter-spacing: 2.4px;
}

.Button .conoce-mas-orange {
    background: #FF8B40;
    color: #572708;
    -webkit-text-stroke-width: 1.399999976158142;
    -webkit-text-stroke-color: #572708;
    font-family: 'Lato Bold';
    font-size: 14px;
    font-style: normal;
    font-weight: bolder;
    line-height: 120%; /* 28.8px */
    letter-spacing: 2.4px;
}

.Button .conoce-mas-purple-programa {
    background: #B35DD6;
    color: #4D1664;
    -webkit-text-stroke-width: 1.399999976158142;
    -webkit-text-stroke-color: #4D1664;
    font-family: 'Lato Bold';
    font-size: 14px;
    font-style: normal;
    font-weight: bolder;
    line-height: 120%; /* 28.8px */
    letter-spacing: 2.4px;
}

.Button .conoce-mas:hover {
    border: solid 1px #ffffff;
    color: #fff;
    /* background-color: #00b9f175; */
}

.Button {

    @media (min-width:768px) {
        .conoce-mas{
            font-family: 'Lato Bold';
            font-size: 24px;
            font-weight: 800;
            line-height: normal;
            /* letter-spacing: 0em;
            border: solid 1px #000;
            min-height: 35px; */
            position: relative;
            z-index: 4;
            /* background-color:#E9EDF0;
            padding: 1rem 2rem;
            width: 335px;
            height: 54.74px; */

            border: 0px;
            display: inline-flex;
            padding: 30px 27px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 80px;
            /* background: #29C7F6; */
            width: auto;
        }

        .Button .conoce-mas-blue {
            background: #29C7F6;
            color: #00556F;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #00556F;
            font-family: 'Lato Bold';
            font-size: 24px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }
        
        .Button .conoce-mas-purple {
            background: #A49BE4;
            color: #463E80;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #463E80;
            font-family: 'Lato Bold';
            font-size: 24px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }
        
        .Button .conoce-mas-brown {
            background: #F2BC34;
            color: #4D3805;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #4D3805;
            font-family: 'Lato Bold';
            font-size: 24px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }
        
        .Button .conoce-mas-orange {
            background: #FF8B40;
            color: #572708;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #572708;
            font-family: 'Lato Bold';
            font-size: 24px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }
        
        .Button .conoce-mas-purple-programa {
            background: #B35DD6;
            color: #4D1664;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #4D1664;
            font-family: 'Lato Bold';
            font-size: 24px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }
        
        .Button .conoce-mas:hover {
            border: solid 1px #ffffff;
            color: #fff;
            /* background-color: #00b9f175; */
        }
    }

    @media (min-width:1024px) {
        .mobile-device {
            display: none;
        }

        .web-device {
            display: block;
        }

        .conoce-mas{
            font-family: 'Lato Bold';
            font-size: 20px;
            font-weight: 800;
            line-height: 16px;
            /* letter-spacing: 0em;
            border: solid 1px #000;
            min-height: 35px; */
            position: relative;
            z-index: 4;
            /* background-color:#E9EDF0;
            padding: 1rem 2rem;
            width: 335px;
            height: 54.74px; */

            border: 0px;
            display: inline-flex;
            padding: 20px 25px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 80px;
            /* background: #29C7F6; */
            width: auto;
        }

        .conoce-mas-blue {
            background: #29C7F6;
            color: #00556F;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #00556F;
            font-family: 'Lato Bold';
            font-size: 10px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }

        .conoce-mas-purple {
            background: #A49BE4;
            color: #463E80;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #463E80;
            font-family: 'Lato Bold';
            font-size: 10px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }

        .conoce-mas-brown {
            background: #F2BC34;
            color: #4D3805;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #4D3805;
            font-family: 'Lato Bold';
            font-size: 10px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }

        .conoce-mas-orange {
            background: #FF8B40;
            color: #572708;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #572708;
            font-family: 'Lato Bold';
            font-size: 10px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }

        .conoce-mas-purple-programa {
            background: #B35DD6;
            color: #4D1664;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #4D1664;
            font-family: 'Lato Bold';
            font-size: 10px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }

        .conoce-mas:hover {
            border: solid 1px #ffffff;
        }
    }

    @media (min-width:1280px) {
        .mobile-device {
            display: none;
        }

        .web-device {
            display: block;
        }

        .conoce-mas{
            font-family: 'Lato Bold';
            font-size: 20px;
            font-weight: 800;
            line-height: 16px;
            /* letter-spacing: 0em;
            border: solid 1px #000;
            min-height: 35px; */
            position: relative;
            z-index: 4;
            /* background-color:#E9EDF0;
            padding: 1rem 2rem;
            width: 335px;
            height: 54.74px; */

            border: 0px;
            display: inline-flex;
            padding: 39px 36px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 80px;
            /* background: #29C7F6; */
            width: auto;
        }

        .conoce-mas-blue {
            background: #29C7F6;
            color: #00556F;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #00556F;
            font-family: 'Lato Bold';
            font-size: 19px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.3px;
        }

        .conoce-mas-purple {
            background: #A49BE4;
            color: #463E80;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #463E80;
            font-family: 'Lato Bold';
            font-size: 21px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }

        .conoce-mas-brown {
            background: #F2BC34;
            color: #4D3805;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #4D3805;
            font-family: 'Lato Bold';
            font-size: 21px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }

        .conoce-mas-orange {
            background: #FF8B40;
            color: #572708;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #572708;
            font-family: 'Lato Bold';
            font-size: 21px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }

        .conoce-mas-purple-programa {
            background: #B35DD6;
            color: #4D1664;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #4D1664;
            font-family: 'Lato Bold';
            font-size: 21px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }

        .conoce-mas:hover {
            border: solid 1px #ffffff;
        }
    }

    @media (min-width:1440px) {
        .mobile-device {
            display: none;
        }

        .web-device {
            display: block;
        }

        .conoce-mas{
            font-family: 'Lato Bold';
            font-size: 20px;
            font-weight: bolder;
            line-height: 16px;
            /* letter-spacing: 0em;
            border: solid 1px #000;
            min-height: 35px; */
            position: relative;
            z-index: 4;
            /* background-color:#E9EDF0;
            padding: 1rem 2rem;
            width: 335px;
            height: 54.74px; */

            border: 0px;
            display: inline-flex;
            padding: 39px 36px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 80px;
            /* background: #29C7F6; */
            width: auto;
        }

        .conoce-mas-blue {
            background: #29C7F6;
            color: #00556F;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #00556F;
            font-family: 'Lato Bold';
            font-size: 22px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }

        .conoce-mas-purple {
            background: #A49BE4;
            color: #463E80;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #463E80;
            font-family: 'Lato Bold';
            font-size: 22px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }

        .conoce-mas-brown {
            background: #F2BC34;
            color: #4D3805;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #4D3805;
            font-family: 'Lato Bold';
            font-size: 22px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }

        .conoce-mas-orange {
            background: #FF8B40;
            color: #572708;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #572708;
            font-family: 'Lato Bold';
            font-size: 22px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }

        .conoce-mas-purple-programa {
            background: #B35DD6;
            color: #4D1664;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #4D1664;
            font-family: 'Lato Bold';
            font-size: 22px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }

        .conoce-mas:hover {
            border: solid 1px #ffffff;
        }
    }

    @media (min-width:1920px) {
        .mobile-device {
            display: none;
        }

        .web-device {
            display: block;
        }

        .conoce-mas{
            font-family: 'Lato Bold';
            font-size: 20px;
            font-weight: 800;
            line-height: 16px;
            /* letter-spacing: 0em;
            border: solid 1px #000;
            min-height: 35px; */
            position: relative;
            z-index: 4;
            /* background-color:#E9EDF0;
            padding: 1rem 2rem;
            width: 335px;
            height: 54.74px; */

            border: 0px;
            display: inline-flex;
            padding: 39px 36px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 80px;
            /* background: #29C7F6; */
            width: auto;
        }

        .conoce-mas-blue {
            background: #29C7F6;
            color: #00556F;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #00556F;
            font-family: 'Lato Bold';
            font-size: 28px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }

        .conoce-mas-purple {
            background: #A49BE4;
            color: #463E80;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #463E80;
            font-family: 'Lato Bold';
            font-size: 28px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }

        .conoce-mas-brown {
            background: #F2BC34;
            color: #4D3805;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #4D3805;
            font-family: 'Lato Bold';
            font-size: 28px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }

        .conoce-mas-orange {
            background: #FF8B40;
            color: #572708;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #572708;
            font-family: 'Lato Bold';
            font-size: 28px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }

        .conoce-mas-purple-programa {
            background: #B35DD6;
            color: #4D1664;
            -webkit-text-stroke-width: 1.399999976158142;
            -webkit-text-stroke-color: #4D1664;
            font-family: 'Lato Bold';
            font-size: 28px;
            font-style: normal;
            font-weight: bolder;
            line-height: 120%; /* 28.8px */
            letter-spacing: 2.4px;
        }

        .conoce-mas:hover {
            border: solid 1px #ffffff;
        }
    }
}

