.cuantrix .testimonios-title {
    color: var(--ctx-color);
    text-align: center;
    font-family: "Rubik One";
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.cuantrix .img-logo {
    width: 98px;
    margin: auto;
    padding: 3rem 0;
}

.cuantrix .cdig-tit {
    color: #1F282C;
    text-align: center;
    font-family: Ruda;
    font-size: 2.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 2.96875rem */
}

.cuantrix .test-bg {
    background: #E9F2FF;
}

.cuantrix .Carousel .arrows-text {
    color: #000;
    text-align: center;
    font-family: 'Lato Bold';
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%;
    text-transform: uppercase;
  
    b {
      color: #3F8EFF;
      font-family: 'Lato Bold';
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%;
      text-transform: uppercase;
    }
}

.cuantrix .testimonios-titulo {
    color: var(--ctx-color);
}

@media screen and (max-width:600px){
    .cuantrix .testimonios-title {
        color: var(--ctx-color);
        text-align: center;
        font-family: "Rubik One";
        font-size: 2.1875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }

    .cuantrix .testimonios-titulo {
        color: var(--ctx-color);
    }
    
    .cuantrix .img-logo {
        width: 98px;
        margin: auto;
        padding: 1.5rem;
    }
    
}