.Cifras {
  width: 100%;
  background: #E9EDF0;
}
.Cifras .text-content {
    padding: 3rem;
  }

  .Cifras .paragraph {
    color: #59BFAC;
    font-family: Ruda;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 21px;
  }
    .Cifras .paragraph b {
      color: #59BFAC;
      font-family: 'Rubik One';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
    }
.Cifras{

  @media (min-width: 768px) {
    .text-content {
      padding: 4rem;
    }

    .paragraph {
      color: #59BFAC;
      font-family: Ruda;
      font-size: 26px;
      font-style: normal;
      font-weight: 800;
      line-height: 40px;
  
      b {
        color: #59BFAC;
        font-family: 'Ruda Bold';
        font-size: 34px;
        font-style: normal;
        font-weight: 800;
        line-height: 40px;
      }
    }
  }

  @media (min-width: 1024px) {
    .text-content {
      padding: 3rem 7rem;
    }

    .paragraph {
      color: #59BFAC;
      font-family: Ruda;
      font-size: 35px;
      font-style: normal;
      font-weight: 800;
      line-height: 52px;
  
      b {
        color: #59BFAC;
        text-align: center;
        font-family: "Rubik One";
        font-size: 55px;
        font-style: normal;
        font-weight: 400;
        line-height: 52px; /* 94.545% */
      }
    }
  }

  @media (min-width: 1440px) {
    .text-content {
      padding: 5rem 10rem;
    }

    .paragraph {
      color: #59BFAC;
      font-family: Ruda;
      font-size: 35px;
      font-style: normal;
      font-weight: 800;
      line-height: 52px;
  
      b {
        color: #59BFAC;
        text-align: center;
        font-family: "Rubik One";
        font-size: 55px;
        font-style: normal;
        font-weight: 400;
        line-height: 52px; /* 94.545% */
      }
    }
  }
}