.ProgramasComunidadesValores {
  width: 100%;
  background: #FAFFFF;
}

.ProgramasComunidadesValores .title-comunidad-valores {
  color: #216C95;
  text-align: center;
  font-family: "Rubik One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 38.4px */
  text-transform: uppercase;
}

.ProgramasComunidadesValores .bg-bluelight {
  background: rgba(127, 199, 217, 0.22);
}

.ProgramasComunidadesValores .paragraph-comunidad-valores {
  color: #1F282C;
  font-family: 'Ruda Bold';
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 27px;
}

.ProgramasComunidadesValores .paragraph-comunidad-valores b {
  color: #216C95;
  text-align: center;
  font-family: "Rubik One";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 96.429% */
}

.ProgramasComunidadesValores .custom-width-valores {
  width: 100%;
}

.ProgramasComunidadesValores .bg-bluedark {
  background: #216C95;
}




.ProgramasComunidadesValores .paragraph-onu b {
  color: #FFF;
}

.ProgramasComunidadesValores .width-icon-objetivos {
  width: 120px;
}
.ProgramasComunidadesValores li{
  list-style: square;
  color: #216C95;
  font-size: 25px;
}

.ProgramasComunidadesValores .comunidades-digitales-titulo {
  color: #216C95;
}

.ProgramasComunidadesValores .comunidades-digitales-items .highlight {
  color: #216C95;
}

.ProgramasComunidadesValores {
  @media (min-width: 768px) {
    .ProgramasComunidadesValores .title-comunidad-valores {
      color: #216C95;
      text-align: center;
      font-family: "Rubik One";
      font-size: 58px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 69.6px */
      text-transform: uppercase;
    }
  
    .ProgramasComunidadesValores .bg-bluelight {
      background: rgba(127, 199, 217, 0.22);
    }
  
    .ProgramasComunidadesValores .paragraph-comunidad-valores {
      color: #1F282C;
      font-family: 'Ruda Bold';
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%;
    }
  
    .ProgramasComunidadesValores .paragraph-comunidad-valores b {
      color: #216C95;
      text-align: center;
      font-family: "Rubik One";
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 40px */
    }
  
    .ProgramasComunidadesValores .bg-bluedark {
      background: #216C95;
    }
  
    .ProgramasComunidadesValores .width-icon-objetivos {
      width: 280px;
    }
  }
  
  @media (min-width: 1024px) {
    .ProgramasComunidadesValores .title-comunidad-valores {
      color: #216C95;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 86.4px */
      text-transform: uppercase;
    }
  
    .ProgramasComunidadesValores .bg-bluelight {
      background: rgba(127, 199, 217, 0.22);
    }
  
    .ProgramasComunidadesValores .paragraph-comunidad-valores {
      color: #1F282C;
      font-family: 'Ruda Bold';
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 35px;
      text-align: left;
    }
  
    .ProgramasComunidadesValores .paragraph-comunidad-valores b {
      color: #216C95;
      font-family: "Rubik One";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 35px; /* 118.421% */
    }
  
    .ProgramasComunidadesValores .custom-width-valores {
      width: 70%;
    }
  
    .ProgramasComunidadesValores .bg-bluedark {
      background: #216C95;
    }
  
    .ProgramasComunidadesValores .width-icon-objetivos {
      width: 122px;
    }
  }
  
  @media screen and (min-width: 1280px) {
    .ProgramasComunidadesValores .title-comunidad-valores {
      color: #216C95;
      font-family: "Rubik One";
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 86.4px */
      text-transform: uppercase;
      text-align: left;
    }
  
    .ProgramasComunidadesValores .bg-bluelight {
      background: rgba(127, 199, 217, 0.22);
    }
  
    .ProgramasComunidadesValores .paragraph-comunidad-valores {
      color: #1F282C;
      font-family: 'Ruda Bold';
      font-size: 26px;
      font-style: normal;
      font-weight: 800;
      line-height: 35px;
      text-align: left;
    }
  
    .ProgramasComunidadesValores .paragraph-comunidad-valores b {
      color: #216C95;
      font-family: "Rubik One";
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 35px; /* 118.421% */
    }
  
    .ProgramasComunidadesValores .custom-width-valores {
      width: 90%;
    }
  
    .ProgramasComunidadesValores .bg-bluedark {
      background: #216C95;
    }
  
    .ProgramasComunidadesValores .width-icon-objetivos {
      width: 122px;
    }
  }
  
  @media screen and (min-width: 1440px) {
    .ProgramasComunidadesValores .title-comunidad-valores {
      color: #216C95;
      font-family: "Rubik One";
      font-size: 72px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 86.4px */
      text-transform: uppercase;
      text-align: left;
    }
  
    .ProgramasComunidadesValores .custom-width-valores {
      width: 90%;
    }
  
    .ProgramasComunidadesValores .paragraph-comunidad-valores {
      color: #1F282C;
      font-family: 'Ruda Bold';
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 35px;
      text-align: left;
    }
  
    .ProgramasComunidadesValores .paragraph-comunidad-valores b {
      color: #216C95;
      font-family: "Rubik One";
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 35px; /* 118.421% */
    }
  }
  
  @media screen and (min-width: 1920px) {
    .ProgramasComunidadesValores .title-comunidad-valores {
      color: #216C95;
      font-family: "Rubik One";
      font-size: 72px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 86.4px */
      text-transform: uppercase;
      text-align: start;
    }
  
    .ProgramasComunidadesValores .bg-bluelight {
      background: rgba(127, 199, 217, 0.22);
    }
  
    .ProgramasComunidadesValores .paragraph-comunidad-valores {
      color: #1F282C;
      font-family: 'Ruda Bold';
      font-size: 34px;
      font-style: normal;
      font-weight: 800;
      line-height: 45px;
      text-align: left;
    }
  
    .ProgramasComunidadesValores .paragraph-comunidad-valores b {
      color: #216C95;
      font-family: "Rubik One";
      font-size: 34px;
      font-style: normal;
      font-weight: 400;
      line-height: 45px; /* 118.421% */
    }
  
    .ProgramasComunidadesValores .custom-width-valores {
      width: 90%;
    }
  
    .ProgramasComunidadesValores .bg-bluedark {
      background: #216C95;
    }
  
    .ProgramasComunidadesValores .width-icon-objetivos {
      width: 122px;
    }
  }

}