.Ninios {
  overflow-x: hidden;
  background: #E9EDF0;

  background-image: url('../../../assets/images/home/ninios-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 229px;
}
  .mobile-device {
    display: block;
  }

  .web-device {
    display: none;
  }

  .Ninios .title {
    color: #FFFFFF;
    text-align: center;
    font-family: "Rubik One";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .Ninios .paragraph {
    color: #FFFFFF;
    text-align: center;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .Ninios .bottom-border {
    border-top: 3px solid #00B9F1;
    width: 10%;
    /* stroke-width: 2.72px;
    stroke: #00B9F1;     */
  }
  
  @media (min-width: 768px) {
    .Ninios {
      background-image: url('../../../assets/images/home/ninios-mobile.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 450px;
    }
  
    .Ninios .title {
      color: #FFFFFF;
      text-align: center;
      font-family: "Rubik One";
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  
    .Ninios .paragraph {
      color: #FFFFFF;
      text-align: center;
      font-family: Lato;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  
    .Ninios .bottom-border {
      border-top: 3px solid #00B9F1;
      width: 10%;
    }
  }
  
  @media (min-width: 1024px) {
    .Ninios {
      background-image: url('../../../assets/images/home/ninios.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 500px;
      background-position: center;
    }
  
    .Ninios .mobile-device {
      display: none;
    }
  
    .Ninios .web-device {
      display: block;
    }
  
    .Ninios .title {
      color: #FFFFFF;
      text-align: center;
      font-family: "Rubik One";
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  
    .Ninios .paragraph {
      color: #FFFFFF;
      text-align: center;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  
    .Ninios .bottom-border {
      border-top: 3px solid #00B9F1;
      width: 10%;
    }
  }
  
  @media (min-width: 1440px) {
    .Ninios {
      background-image: url('../../../assets/images/home/ninios.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 600px;
      background-position: center;
    }
  
    .Ninios .title {
      color: #FFFFFF;
      text-align: center;
      font-family: "Rubik One";
      font-size: 53px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  
    .Ninios .paragraph {
      color: #FFFFFF;
      font-family: Lato;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  
    .Ninios .bottom-border {
      border-top: 3px solid #00B9F1;
      width: 5%;
    }
  }
  
  @media (min-width: 1920px) {
    .Ninios {
      background-image: url('../../../assets/images/home/ninios.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 786px;
    }
  
    .Ninios .title {
      color: #FFFFFF;
      text-align: center;
      font-family: "Rubik One";
      font-size: 73px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  
    .Ninios .paragraph {
      color: #FFFFFF;
      font-family: Lato;
      font-size: 45px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  
    .Ninios .bottom-border {
      border-top: 3px solid #00B9F1;
      width: 5%;
    }
  }
  
  @media (min-width: 2880px) {
    .Ninios {
      background-image: url('../../../assets/images/home/ninios.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 1200px;
    }
  
    .Ninios .title {
      color: #FFFFFF;
      text-align: center;
      font-family: "Rubik One";
      font-size: 73px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  
    .Ninios .paragraph {
      color: #FFFFFF;
      font-family: Lato;
      font-size: 45px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  
    .Ninios .bottom-border {
      border-top: 3px solid #00B9F1;
      width: 5%;
    }
  }