

.bg-blue{
    background-color: #00B9F1;
}
.contain-text{
    padding: 4rem 8rem;
}
.cult-head{
    color: #F5F5F5;
    font-family: "Rubik One";
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: 127%; /* 4.36563rem */
    text-transform: uppercase;
}
.cult-para{
    color: #FFF;
    font-family: Lato;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.8125rem;
}
.cult-para .bold{
    font-family: "Lato Bold";
    font-weight: 900;
}
.bold{
    font-weight: bolder;
}

.font-italic {
    font-style: italic;
}

@media screen and (max-width:1440px){
    .contain-text{
        padding: 4rem;
    }
    .cult-para{
        color: #FFF;
        font-family: Lato;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

@media screen and (max-width:1366px){
    .bg-gray-cult{
        background-image: unset;
    }
    .cult-head{
        color: #F5F5F5;
        font-family: "Rubik One";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 127%; /* 4.36563rem */
        text-transform: uppercase;
    }
    .cult-para{
        color: #FFF;
        font-family: Lato;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

@media screen and (max-width:1280px){
    .bg-gray-cult{
        height: auto;
    }

    .cult-head{
        color: #F5F5F5;
        font-family: "Rubik One";
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 127%; /* 4.36563rem */
        text-transform: uppercase;
    }
    .cult-para{
        color: #FFF;
        font-family: Lato;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

}
@media screen and (max-width:1024px){
    .bg-gray-cult{
        background-image: unset;
    }
    .cult-head{
        color: #F5F5F5;
        font-family: "Rubik One";
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 127%; /* 4.36563rem */
        text-transform: uppercase;
    }
    .cult-para{
        color: #FFF;
        font-family: Lato;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

@media screen and (min-width:768px) and (orientation:portrait){

    .cult-head{
        text-align: center;
    }
    .cult-para{
        text-align: center;
        color: #FFF;
        font-family: Lato;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .cult-title{
        font-size: 3rem;
    }
    .tf-inic .col-lg-6{
        width: 100%;
    }
    .cult-head{
        color: #F5F5F5;
        font-family: "Rubik One";
        font-size: 3rem;
    }
    .b-boy{
        width: 100%;
    }
}



@media screen and (max-width:600px){
    .contain-text{
        padding:2rem;
    }
    .cult-head{
        text-align: center;
        font-size: 1.65rem;
        line-height: 127%; /* 4.36563rem */
    }
    .cult-para{
        text-align: center;
        font-size: 19px;
        line-height: 2.125rem;
    }
    .cult-para .bold{
        font-weight: 400;
    } 
}
