.NuestrosContenidos {
  width: 100%;
  background: #E9EDF0;
}

.NuestrosContenidos .main-container {
  padding: 3rem 2rem;
}

.NuestrosContenidos .nct-title {
  color: #72CDBC;
  text-align: center;
  font-family: "Rubik One";
  font-size: 2.56rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  text-align: start;
  padding-right: 5rem;
}

.NuestrosContenidos .nct-subtitle {
  color: #1D1D1B;
  font-family: 'Lato Bold';
  font-size: 2.56rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-align: start;
  padding-right: 5rem;
}

.NuestrosContenidos .nct-paragraph {
  color: #1F282C;
  font-family: Lato;
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 134%; 
  margin-top: 12px;
  text-align: start;
}

.NuestrosContenidos .nct-bg-gray {
  background-color: #69747C;
  background-image: url('../../../assets/images/contenidos/gradient1.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.NuestrosContenidos .nct-title2 {
  color: #FFF;
  font-family: Lato;
  font-size: 3.75rem;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  padding: 44px;
}

.NuestrosContenidos .nct-title2 .bold {
  font-family: 'Lato Bold';
  font-weight: 800;
}

.NuestrosContenidos .nct-paragraph .bold {
  font-family: 'Lato Bold';
}

@media screen and (max-width:1080px){
  .NuestrosContenidos {
  .nct-title {
    font-size: 2.56rem;
    font-weight: 400;
    text-align: center;
    padding-right: 2rem;
    padding-left: 2rem;
  }
  
  .nct-subtitle {
    font-size: 2.25rem;
    text-align: center;
    padding-right: 2rem;
    padding-left: 2rem;
  }
  
  .nct-paragraph {
    font-size: 1.5rem;
    line-height: 134%; 
    margin-top: 12px;
    text-align: center;
  }
  
  .nct-bg-gray {
    background-image: unset;
  }
  
  .nct-title2 {
    font-size: 3rem;
    text-align: center;
    padding: 44px;
  }
  }
}
@media screen and (max-width:600px){

    .NuestrosContenidos .main-container{
      padding: 2rem 1rem;
    }
    .NuestrosContenidos .nct-title {
      font-size: 1.5rem;
      font-weight: 400;
      text-align: center;
      padding-right: .5rem;
      padding-left: .5rem;
    }
    
    .NuestrosContenidos .nct-subtitle {
      font-size: 1.15rem;
      text-align: center;
      padding-right: 2rem;
      padding-left: 2rem;
      font-weight: 400;
    }
    
    .NuestrosContenidos .nct-paragraph {
      font-size: 1rem;
      line-height: 134%; 
      margin-top: 12px;
      text-align: center;
    }
    .NuestrosContenidos .nct-paragraph .bold{
      font-weight: 400;
    }
    .NuestrosContenidos .nct-title2 {
      font-size: 2rem;
      text-align: center;
      padding: 5% 3rem;
    }
    .NuestrosContenidos .nct-title2 .bold{
      font-family: Lato;
    }
  }