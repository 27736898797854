.ct-bg{
  background-image: url('../../../assets/images/contenidos/cont-header.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.ct-title {
  color: #FFF;
  text-shadow: 0px 4px 4px rgba(80, 80, 80, 0.82);
  font-family: "Rubik One";
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.ct-header{
  min-height: 55vh;
}
.cntnds .row{
  background-color: rgba(85, 80, 80, 0.13);
}
@media screen and (max-width:1440px){
  .ct-title{
    font-size: 4.5rem;
  }
  .ct-bg{
  min-height: 100vh;
  }
}
@media screen and (max-width:1280px){
  .ct-title{
    font-size: 4.5rem;
  }
  .ct-bg{
    min-height: 100vh;
    }
}
@media screen and (max-width:1080px){
  .ct-title{
    font-size: 4rem;
  }
  .ct-bg{
    background-image: url('../../../assets/images/contenidos/cont-header-r.png');
  }
  .ct-header{
    min-height: 70vh;
  }
}
@media screen and (max-width:600px){
  .ct-title{
    font-size: 2.5rem;
  }
  .ct-header{
    min-height: 70vh;
  }
}