.tecnochicas .testimonios-title{
    color: var(--bc-color);
    text-align: center;
    font-family: "Rubik One";
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}
.tecnochicas .img-logo{
    width: 98px;
    margin: auto;
    padding: 3rem 0;
}
.tecnochicas .cdig-tit{
    color: #1F282C;
    text-align: center;
    font-family: Ruda;
    font-size: 2.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 2.96875rem */
}
.tecnochicas .testim-bg{
    background: #F0EEFF;
}

.tecnochicas .Carousel .arrows-text {
    color: #000;
    text-align: center;
    font-family: 'Lato Bold';
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%;
    text-transform: uppercase;
  
    b {
      color: #8780BD;
      font-family: 'Lato Bold';
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%;
      text-transform: uppercase;
    }
}

@media screen and (max-width:900px){
    .tecnochicas .img-logo{
        width: 58px;
        margin: auto;
        padding: 1rem 0;
    }
}
@media screen and (max-width:600px) {
    .tecnochicas .testimonios-title{
        color: var(--bc-color);
        text-align: center;
        font-family: "Rubik One";
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
}
