.CardGral .title-card{
    color: #00B9F1 !important;
}

    .Iniciativas  .ini-title{
        color: #53616A;
        text-align: center;
        font-family: "Rubik One";
        font-size: 3.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 3.75rem */
        text-transform: uppercase;
    }
@media screen and (max-width:600px){

        .Iniciativas .ini-title{
            font-size: 1.375rem;
        }
        .Iniciativas  .title-card{
            font-size: 1.125rem;
            line-height: 150%;
            margin-top: .9rem;
        }
        .Iniciativas  .description{
            font-size: 1rem;
        }
}