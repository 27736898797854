.bg-union{
    z-index: 10;
    position: relative;
    background-color: #F8F8F8;
    padding-top: 60px;
    padding-bottom: 60px;
}
.u-title.bg{
background-color: #F8F8F8;
}
.u-title{
    color: #000;
    text-align: center;
    font-family: "Ruda Bold";
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%; /* 52.8px */ 
}
.u-title span{
    color: #00B9F1;
    font-family: "Rubik One";
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
}

@media screen and (max-width:1440px){
    .bg-union{
        z-index: 10;
        position: relative;
        background-color: #F8F8F8;
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .u-title.bg{
    background-color: #F8F8F8;
    }
    .u-title{
        color: #000;
        text-align: center;
        font-family: "Ruda Bold";
        font-size: 38px;
        font-style: normal;
        font-weight: 800;
        line-height: 110%; /* 52.8px */ 
    }
    .u-title span{
        color: #00B9F1;
        font-family: "Rubik One";
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
    }
}
@media (max-width: 1366px) {
    .bg-union{
        z-index: 10;
        position: relative;
        background-color: #F8F8F8;
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .u-title.bg{
    background-color: #F8F8F8;
    }
    .u-title{
        color: #000;
        text-align: center;
        font-family: "Ruda Bold";
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 110%; /* 52.8px */ 
    }
    .u-title span{
        color: #00B9F1;
        font-family: "Rubik One";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
    }
}

@media screen and (max-width:1024px){

}

@media screen and (max-width:768px) and (orientation:portrait){

}
@media screen and (max-width:600px) and (orientation:portrait) {
    .bg-union{
        z-index: 10;
        position: relative;
        background-color: #F8F8F8;
        padding-top: 40px;
        padding-bottom: 30px;
    }
    .u-title.bg{
    background-color: #F8F8F8;
    }
    .u-title{
        color: #000;
        text-align: center;
        font-family: "Ruda Bold";
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 110%; /* 52.8px */ 
    }
    .u-title span{
        color: #00B9F1;
        font-family: "Rubik One";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
    }
}