.Awards {
  width: 100%;
  background: url('../../../assets/images/reconocimientos-fundacion/bg-gradiente.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  width: 100%;
  height: auto;

}

.Awards .mobile-device {
  display: block;
}

.Awards .web-device {
  display: none;
}

.Awards .award-description {
  padding: 1rem 2rem;
}

.Awards .award-description .paragraph {
  color: #000;
  text-align: center;
  font-family: 'Ruda Bold';
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 114%; /* 22px */

  b {
    color: #00B9F1;
    font-family: "Rubik One";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 114%;
  }
}

.Awards .award-card-container {
  background-image: url('../../../assets/images/reconocimientos-fundacion/award-gradient.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
}

.Awards .award-card-icon {
  width: 100px;
}

.Awards .award-card-title {
  color: #000;
  text-align: center;
  font-family: "Rubik One";
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.Awards .award-card-text {
  color: #000;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */

  b {
    color: #000;
    font-family: 'Lato Bold';
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
  }
}

@media (min-width: 768px) {
  /* Enhanced padding for award description within Awards */
  .Awards .award-description {
    padding: 3rem 2rem;
  }

  /* Enhanced styling for paragraph within award description */
  .Awards .award-description .paragraph {
    color: #000;
    text-align: center;
    font-family: 'Ruda Bold';
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%; /* 22px */
  }

  .Awards .award-description .paragraph b {
    color: #00B9F1;
    font-family: "Rubik One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }

  /* Enhanced background for award card container */
  .Awards .award-card-container {
    background-image: url('../../../assets/images/reconocimientos-fundacion/award-gradient.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
  }

  /* Fixed width for icons in award cards */
  .Awards .award-card-icon {
    width: 100px;
  }

  /* Styling for titles within award cards */
  .Awards .award-card-title {
    color: #000;
    text-align: center;
    font-family: "Rubik One";
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  /* Styling for text within award cards */
  .Awards .award-card-text {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 142.857% */
  }

  .Awards .award-card-text b {
    color: #000;
    font-family: 'Lato Bold';
    font-size: 25px;
    font-style: normal;
    font-weight: 800;
    line-height: 30px;
  }
}

@media (min-width: 1024px) {
  /* Hide mobile-device elements and display web-device elements within Awards */
  .Awards .mobile-device {
    display: none;
  }

  .Awards .web-device {
    display: block;
  }

  /* Grid settings within Awards */
  .Awards .grid {
    display: grid;
  }

  .Awards .grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .Awards .gap-4 {
    gap: 1rem !important;
  }

  /* Padding adjustment for award description within Awards */
  .Awards .award-description {
    padding: 4rem 5rem;
  }

  /* Styling for paragraph within award description */
  .Awards .award-description .paragraph {
    color: #000;
    text-align: center;
    font-family: 'Ruda Bold';
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 52.8px */
  }

  .Awards .award-description .paragraph b {
    color: #00B9F1;
    font-family: "Rubik One";
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }

  /* Background settings for award card container */
  .Awards .award-card-container {
    background-image: url('../../../assets/images/reconocimientos-fundacion/award-gradient.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
  }

  /* Icon size within award cards */
  .Awards .award-card-icon {
    width: 100px;
  }

  /* Title styling within award cards */
  .Awards .award-card-title {
    color: #000;
    text-align: center;
    font-family: "Rubik One";
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  /* Text styling within award cards */
  .Awards .award-card-text {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 150.228% */
  }

  .Awards .award-card-text b {
    color: #000;
    font-family: 'Lato Bold';
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 22px;
  }
}

@media (min-width: 1280px) {
  .award-description {
    padding: 4rem 5rem;
  }
  
  .award-description .paragraph {
    color: #000;
    text-align: center;
    font-family: Ruda;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 52.8px */
  
    b {
      color: #00B9F1;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
    }
  }
  
  .award-card-container {
    background-image: url('../../../assets/images/reconocimientos-fundacion/award-gradient.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
  }
  
  .award-card-icon {
    width: 100px;
  }
  
  .award-card-title {
    color: #000;
    text-align: center;
    font-family: "Rubik One";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  
  .award-card-text {
    color: #000;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150.228% */
  
    b {
      color: #000;
      font-family: 'Lato Bold';
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 30px;
    }
  }
}

@media (min-width: 1280px) {
  /* Adjust padding for the award description within Awards */
  .Awards .award-description {
    padding: 4rem 5rem;
  }

  /* Styling for the paragraph within the award description */
  .Awards .award-description .paragraph {
    color: #000;
    text-align: center;
    font-family: Ruda;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 52.8px */
  }

  .Awards .award-description .paragraph b {
    color: #00B9F1;
    font-family: "Rubik One";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }

  /* Background settings for the award card container */
  .Awards .award-card-container {
    background-image: url('../../../assets/images/reconocimientos-fundacion/award-gradient.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
  }

  /* Set width for icons within award cards */
  .Awards .award-card-icon {
    width: 100px;
  }

  /* Title styling within the award cards */
  .Awards .award-card-title {
    color: #000;
    text-align: center;
    font-family: "Rubik One";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  /* Text styling within award cards */
  .Awards .award-card-text {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150.228% */
  }

  .Awards .award-card-text b {
    color: #000;
    font-family: 'Lato Bold';
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 30px;
  }
}
@media (min-width: 1440px) {
  /* Padding adjustments for the award description within the Awards section */
  .Awards .award-description {
    padding: 4rem 5rem;
  }

  /* Styling for paragraphs within the award description */
  .Awards .award-description .paragraph {
    color: #000;
    text-align: center;
    font-family: 'Ruda Bold';
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%; /* 22px */
  }

  .Awards .award-description .paragraph b {
    color: #00B9F1;
    font-family: "Rubik One";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }

  /* Background settings for the award card container */
  .Awards .award-card-container {
    background-image: url('../../../assets/images/reconocimientos-fundacion/award-gradient.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
  }

  /* Sizing for icons within award cards */
  .Awards .award-card-icon {
    width: 100px;
  }

  /* Title styling for award cards */
  .Awards .award-card-title {
    color: #000;
    text-align: center;
    font-family: "Rubik One";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  /* Text styling within award cards */
  .Awards .award-card-text {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px; /* 150.228% */
  }

  .Awards .award-card-text b {
    color: #000;
    font-family: 'Lato Bold';
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: 35px;
  }
}
@media (min-width: 1600px) {
  /* Adjusting padding for the award description within the Awards section */
  .Awards .award-description {
    padding: 4rem 5rem;
  }

  /* Styling for the paragraph within the award description */
  .Awards .award-description .paragraph {
    color: #000;
    text-align: center;
    font-family: 'Ruda Bold';
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%; /* 22px */
  }

  .Awards .award-description .paragraph b {
    color: #00B9F1;
    font-family: "Rubik One";
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }

  /* Background settings for the award card container */
  .Awards .award-card-container {
    background-image: url('../../../assets/images/reconocimientos-fundacion/award-gradient.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
  }

  /* Sizing for icons within award cards */
  .Awards .award-card-icon {
    width: 100px;
  }

  /* Title styling for award cards */
  .Awards .award-card-title {
    color: #000;
    text-align: center;
    font-family: "Rubik One";
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  /* Text styling within award cards */
  .Awards .award-card-text {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 31.286px;  
    font-style: normal;
    font-weight: 400;
    line-height: 47px; /* 150.228% */
  }

  .Awards .award-card-text b {
    color: #000;
    font-family: Lato;
    font-size: 31.286px;
    font-style: normal;
    font-weight: 800;
    line-height: 47px;
  }
}

@media (min-width: 1920px) {
  /* Padding adjustments for the award description within the Awards section */
  .Awards .award-description {
    padding: 4rem 5rem;
  }

  /* Styling for the paragraph within the award description */
  .Awards .award-description .paragraph {
    color: #000;
    text-align: center;
    font-family: 'Ruda Bold';
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%; /* 22px */
  }

  .Awards .award-description .paragraph b {
    color: #00B9F1;
    font-family: "Rubik One";
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }

  /* Background settings for the award card container */
  .Awards .award-card-container {
    background-image: url('../../../assets/images/reconocimientos-fundacion/award-gradient.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
  }

  /* Sizing for icons within award cards */
  .Awards .award-card-icon {
    width: 100px;
  }

  /* Title styling for award cards */
  .Awards .award-card-title {
    color: #000;
    text-align: center;
    font-family: "Rubik One";
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  /* Text styling within award cards */
  .Awards .award-card-text {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 31.286px;  
    font-style: normal;
    font-weight: 400;
    line-height: 47px; /* 150.228% */
  }

  .Awards .award-card-text b {
    color: #000;
    font-family: 'Lato Bold';
    font-size: 31.286px;
    font-style: normal;
    font-weight: 800;
    line-height: 47px;
  }
}