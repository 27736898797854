.Comunicacion {
    overflow-x: hidden;

    .bg-purple {
        background: rgba(140, 134, 192, 0.95);
    }

    .purple-title {
        color: #FFF;
        text-align: center;
        font-family: Lato;
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 118%; /* 22.42px */

        b {
            color: #FFF;
            font-family: "Rubik One";
            font-size: 19px;
            font-style: normal;
            font-weight: 400;
            line-height: 118%;
        }
    }
    .purple-title b {
        color: #FFF;
        font-family: "Rubik One";
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 118%;
    }
  }
  
  .Comunicacion .mobile-device {
    display: block;
  }
  
  .Comunicacion .web-device {
    display: none;
  }
  
  .Comunicacion .com {
    background-color: #FFFFFF;
  }
  
  .Comunicacion .com-futel {
    color: #69747C;
    font-family: Ruda;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 200%;
    text-align: center;
  }
  
  .Comunicacion .com-title {
    color: #928CC3;
    text-align: center;
    font-family: "Rubik One";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;
  }
  
  .Comunicacion .com-par {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 142%; /* 22.72px */
  }
  
  .Comunicacion .com-par b {
    color: #000;
    font-family: 'Lato Bold';
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 142%;
  }
  
  .Comunicacion .com-title2 {
    color: #000;
    text-align: center;
    font-family: "Rubik One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 17.6px */
    text-transform: uppercase;
  }
  
  .Comunicacion .com-title-num {
    color: #1F282C;
    text-align: center;
    font-family: "Rubik One";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
  }
  
  .Comunicacion .com-tele {
    color: #000;
    text-align: center;
    font-family: 'Ruda Bold';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: lowercase;
  }
  
  .Comunicacion .com-w-txt {
    width: 90%;
  }
  
  .Comunicacion b,
  .Comunicacion strong {
    font-weight: bolder;
  }
  
  .Comunicacion .megafono {
    width: 4rem;
  }
    @media (min-width: 768px) {
        .Comunicacion .com {
            background-color: #FFFFFF;
        }
    
        .Comunicacion .com-futel {
            color: #69747C;
            font-family: Ruda;
            font-size: 25px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: center;
        }
    
        .Comunicacion .com-title {
            color: rgba(146, 140, 195, 0.95);
            text-align: center;
            font-family: "Rubik One";
            font-size: 48px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 48px */
        }
    
        .Comunicacion .com-par {
            color: #000;
            font-family: Lato;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 45px; /* 140.625% */
        }
    
        .Comunicacion .com-par b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 25px;
            font-style: normal;
            font-weight: 800;
            line-height: 45px;
        }
    
        .Comunicacion .com-title2 {
            color: #1F282C;
            text-align: center;
            font-family: "Rubik One";
            font-size: 36px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 58.8px */
            text-transform: uppercase;
        }
    
        .Comunicacion .com-title-num {
            font-family: Rubik One;
            font-size: 70px;
            font-weight: 400;
            line-height: 70px;
            letter-spacing: 0em;
            text-align: left;
        }
    
        .Comunicacion .com-tele {
            color: #1F282C;
            font-family: Ruda;
            font-size: 28px;
            font-style: normal;
            font-weight: 800;
            line-height: 145%;
        }
    
        .Comunicacion .com-w-txt {
            width: 90%;
        }
    
        .Comunicacion b,
        .Comunicacion strong {
            font-weight: bolder;
        }
    
        .Comunicacion .megafono {
            width: 5rem;
        }
    
        .Comunicacion .purple-title {
            color: #FFF;
            font-family: Lato;
            font-size: 42px;
            font-style: normal;
            font-weight: 400;
            line-height: 125%;
        }
    
        .Comunicacion .purple-title b {
            color: #FFF;
            text-align: center;
            font-family: 'Lato Bold';
            font-size: 42px;
            font-style: normal;
            font-weight: 800;
            line-height: 125%; /* 22.5px */
        }
    }
    
    
    @media (min-width: 1024px) {
        .Comunicacion .mobile-device {
            display: none;
        }

        .Comunicacion .web-device {
            display: block;
        }

        .Comunicacion .com {
            background-color: #FFFFFF;
        }

        .Comunicacion .com-futel {
            font-family: Ruda;
            font-size: 25px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: left;
            color: #69747C;
        }

        .Comunicacion .com-title {
            font-family: Rubik One;
            font-size: 30px;
            font-weight: 400;
            line-height: 40px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(146, 140, 195, 0.95);
        }

        .Comunicacion .com-par {
            color: #000;
            font-family: Lato;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px; /* 140.625% */
            text-align: left;
        }

        .Comunicacion .com-par b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 16px;
            font-style: normal;
            font-weight: bolder;
            line-height: 26px;
        }

        .Comunicacion .com-title2 {
            color: #000;
            font-family: "Rubik One";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%; /* 48.4px */
            text-transform: uppercase;
            text-align: left;
        }

        .Comunicacion .com-tele {
            color: #000;
            font-family: 'Ruda Bold';
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: lowercase;
            text-align: left;
        }

        .Comunicacion .com-title-num {
            font-family: Rubik One;
            font-size: 30px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: left;
        }

        .Comunicacion .com-w-txt {
            width: 95%;
        }

        .Comunicacion .purple-title {
            color: #FFF;
            text-align: center;
            font-family: Lato;
            font-size: 38px;
            font-style: normal;
            font-weight: 400;
            line-height: 118%; /* 59px */
        }

        .Comunicacion .purple-title b {
            color: #FFF;
            font-family: "Rubik One";
            font-size: 38px;
            font-style: normal;
            font-weight: 400;
            line-height: 118%;
        }
    }

@media (min-width: 1280px) {
        .Comunicacion .com {
            background-color: #FFFFFF;
        }

        .Comunicacion .com-futel {
            font-family: Ruda;
            font-size: 25px;
            font-weight: 400;
            line-height: 55px;
            letter-spacing: 0em;
            text-align: left;
            color: #69747C;
        }

        .Comunicacion .com-title {
            font-family: Rubik One;
            font-size: 35px;
            font-weight: 400;
            line-height: 45px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(146, 140, 195, 0.95);
        }

        .Comunicacion .com-par {
            color: #000;
            font-family: Lato;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px; /* 140.625% */
        }

        .Comunicacion .com-par b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: 28px;
        }

        .Comunicacion .com-title2 {
            color: #000;
            font-family: "Rubik One";
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%; /* 48.4px */
            text-transform: uppercase;
        }

        .Comunicacion .com-tele {
            color: #000;
            font-family: Ruda;
            font-size: 25px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: lowercase;
        }

        .Comunicacion .com-title-num {
            font-family: Rubik One;
            font-size: 40px;
            font-weight: 400;
            line-height: 50px;
            letter-spacing: 0em;
            text-align: left;
        }

        .Comunicacion .com-w-txt {
            width: 95%;
        }

        .Comunicacion .purple-title {
            color: #FFF;
            text-align: center;
            font-family: Lato;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 118%; /* 59px */
        }

        .Comunicacion .purple-title b {
            color: #FFF;
            font-family: "Rubik One";
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 118%;
        }
    }
    @media (min-width: 1366px) {
        .Comunicacion .com {
            background-color: #FFFFFF;
        }

        .Comunicacion .com-futel {
            font-family: Ruda;
            font-size: 40px;
            font-weight: 400;
            line-height: 55px;
            letter-spacing: 0em;
            text-align: left;
            color: #69747C;
        }

        .Comunicacion .com-title {
            font-family: Rubik One;
            font-size: 45px;
            font-weight: 400;
            line-height: 55px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(146, 140, 195, 0.95);
        }

        .Comunicacion .com-par {
            color: #000;
            font-family: Lato;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px; /* 140.625% */
        }
    
        .Comunicacion .com-par b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 22px;
            font-style: normal;
            font-weight: 800;
            line-height: 32px;
        }

        .Comunicacion .com-title2 {
            color: #000;
            font-family: "Rubik One";
            font-size: 30px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%; /* 48.4px */
            text-transform: uppercase;
        }

        .Comunicacion .com-tele {
            color: #000;
            font-family: Ruda;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: lowercase;
        }

        .Comunicacion .com-title-num {
            font-family: 'Rubik One';
            font-size: 45px;
            font-weight: 400;
            line-height: 45px;
            letter-spacing: 0em;
            text-align: left;
        }

        .Comunicacion .com-w-txt {
            width: 95%;
        }

        .Comunicacion .purple-title {
            color: #FFF;
            text-align: center;
            font-family: Lato;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 118%; /* 59px */
        }

        .Comunicacion .purple-title b {
            color: #FFF;
            font-family: "Rubik One";
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 118%;
        }
    }  
    
    @media (min-width: 1440px) {
        .Comunicacion .com {
            background-color: #FFFFFF;
        }
        .Comunicacion .com-futel {
            font-family: Ruda;
            font-size: 40px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0em;
            text-align: left;
            color: #69747C;
        }

        .Comunicacion .com-title {
            font-family: Rubik One;
            font-size: 50px;
            font-weight: 400;
            line-height: 60px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(146, 140, 195, 0.95);
        }

        .Comunicacion .com-par {
            color: #000;
            font-family: Lato;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 35px; /* 140.625% */
        }

        .Comunicacion .com-par b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 25px;
            font-style: normal;
            font-weight: 800;
            line-height: 35px;
        }

        .Comunicacion .com-title2 {
            color: #000;
            font-family: "Rubik One";
            font-size: 35px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%; /* 48.4px */
            text-transform: uppercase;
        }

        .Comunicacion .com-tele {
            color: #000;
            font-family: Ruda;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: lowercase;
        }

        .Comunicacion .com-title-num {
            font-family: 'Rubik One';
            font-size: 50px;
            font-weight: 400;
            line-height: 60px;
            letter-spacing: 0em;
            text-align: left;
        }

        .Comunicacion .com-w-txt {
            width: 95%;
        }

        .Comunicacion .purple-title {
            color: #FFF;
            text-align: center;
            font-family: Lato;
            font-size: 45px;
            font-style: normal;
            font-weight: 400;
            line-height: 118%; /* 59px */
        }

        .Comunicacion .purple-title b {
            color: #FFF;
            font-family: "Rubik One";
            font-size: 45px;
            font-style: normal;
            font-weight: 400;
            line-height: 118%;
        }
    }    

    @media screen and (min-width: 1600px) {
    .Comunicacion .com-title {
            font-size: 50px;
            padding-right: 20px;
            color: rgba(146, 140, 195, 0.95);
            line-height: 60px;
        }
        .Comunicacion .com-par {
            color: #000;
            font-family: Lato;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px; /* 140.625% */
        }

        .Comunicacion .com-par b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 28px;
            font-style: normal;
            font-weight: 800;
            line-height: 40px;
        }

        .Comunicacion .com-title2 {
            color: #000;
            font-family: "Rubik One";
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%; /* 48.4px */
            text-transform: uppercase;
        }

        .Comunicacion .com-tele {
            color: #000;
            font-family: Ruda;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: lowercase;
        }

        .Comunicacion .purple-title {
            color: #FFF;
            text-align: center;
            font-family: Lato;
            font-size: 48px;
            font-style: normal;
            font-weight: 400;
            line-height: 118%; /* 59px */
        }
        .Comunicacion .purple-title b {
            color: #FFF;
            font-family: "Rubik One";
            font-size: 48px;
            font-style: normal;
            font-weight: 400;
            line-height: 118%;
        }
    }    

    @media (min-width: 1920px) {
    .Comunicacion .com {
            background-color: #FFFFFF;
        }

        .Comunicacion .com-futel {
            font-family: Ruda;
            font-size: 47px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0em;
            text-align: left;
            color: #69747C;
        }

        .Comunicacion .com-title {
            color: rgba(146, 140, 195, 0.95);
            font-family: "Rubik One";
            font-size: 70px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            text-align: left;
        }

        .Comunicacion .com-par {
            color: #000;
            font-family: Lato;
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 45px; /* 140.625% */
        }

        .Comunicacion .com-par b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 32px;
            font-style: normal;
            font-weight: 800;
            line-height: 45px;
        }

        .Comunicacion .com-title2 {
            color: #000;
            font-family: "Rubik One";
            font-size: 44px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%; /* 48.4px */
            text-transform: uppercase;
        }

        .Comunicacion .com-title-num {
            font-family: Rubik One;
            font-size: 70px;
            font-weight: 400;
            line-height: 70px;
            letter-spacing: 0em;
            text-align: left;
        }

        .Comunicacion .com-tele {
            color: #000;
            font-family: Ruda;
            font-size: 44px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: lowercase;
        }

        .Comunicacion .com-w-txt {
            width: 85%;
        }

        .Comunicacion .purple-title {
            color: #FFF;
            text-align: center;
            font-family: Lato;
            font-size: 50px;
            font-style: normal;
            font-weight: 400;
            line-height: 118%; /* 59px */
        }

        .Comunicacion .purple-title b {
            color: #FFF;
            font-family: "Rubik One";
            font-size: 50px;
            font-style: normal;
            font-weight: 400;
            line-height: 118%;
        }
    }    