.mobile-device {
    display: block;
}

.web-device {
    display: none;
}

.futel-title{
    color:#00B9F1;
    font-family: Rubik One;
    font-size: 73px;
    font-weight: 400;
    line-height: 80px;
    letter-spacing: 0em;
    text-align: left;
}

.futel-oportunidades{
    color: #00B9F1;
    text-align: center;
    font-family: "Rubik One";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 112%; /* 26.88px */
    text-transform: uppercase;

    strong {
        color: #00B9F1;
        text-align: center;
        font-family: 'Ruda Bold';
        font-size: 18px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
    }
}
.futel-oportunidades strong {
    color: #00B9F1;
    text-align: center;
    font-family: 'Ruda Bold';
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}
.futel-enfoque{
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 142%; /* 22.72px */
}
.futel-enfoque b {
    color: #000;
    font-family: 'Lato Bold';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 142%;

}
.futel-enfoque-historias{
    color: #1F282C;
    font-family: Ruda;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 200%;
    text-align: left;
}
.futel-bg-gradient{
    background-image: url('../../../assets/images/home/blue-sparkle.png'); 
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    width: 100%;
    height: 100%;
}
.futel-img-ninas{
    position: relative;
    z-index: 50;
    margin-top: 0vh;
    margin-left: 0%;
    width: 100%;
    height: auto;
}

.bg-blue .title {
    color: #FFF;
    text-align: center;
    font-family: 'Lato Bold';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 62.5px */
}




@media (min-width:768px) {
    .futel-title{
        color:#00B9F1;
        font-family: Rubik One;
        font-size: 73px;
        font-weight: 400;
        line-height: 80px;
        letter-spacing: 0em;
        text-align: left;
    }
    
    .futel-oportunidades{
        color: #00B9F1;
        text-align: center;
        font-family: "Rubik One";
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: 112%; /* 53.76px */
        text-transform: uppercase;
    
    }
    .futel-oportunidades strong {
        color: #1F282C;
        text-align: center;
        font-family: 'Ruda Bold';
        font-size: 28px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
    }
    .futel-enfoque{
        color: #000;
        text-align: center;
        font-family: Lato;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 142%; /* 22.72px */
    }
    .futel-enfoque b {
        color: #000;
        font-family: 'Lato Bold';
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 142%;
    
    }
    .futel-enfoque-historias{
        color: #1F282C;
        font-family: Ruda;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 100%; /* 24px */
        text-align: left;
    }
    .futel-bg-gradient{
        background-image: url('../../../assets/images/home/blue-sparkle-web.png'); 
        background-repeat: no-repeat;
        background-position: bottom;
    }
    .futel-img-ninas{
        position: relative;
        z-index: 50;
        margin-top: 0vh;
        width: 100%;
        margin-left: 0%;
        height :auto;
    
    }

    .bg-blue {
        background: #00B9F1;
        .title {
            color: #FFF;
            text-align: center;
            font-family: 'Lato Bold';
            font-size: 42px;
            font-style: normal;
            font-weight: 700;
            line-height: 125%; /* 52.5px */
        }
    }
}

@media screen and (min-width:1024px) {

    .mobile-device {
        display: none;
    }
    
    .web-device {
        display: block;
    }

    .futel-title{
        font-size: 48px;
        text-align: center;
    }
    .futel-oportunidades{
        font-size: 1rem;
        line-height: normal;
        text-align: left;
        color: #00B9F1;
        padding-top: 0px;

    }
    .futel-oportunidades strong {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 1.5rem;
        font-style: normal;
        font-weight: bolder;
        line-height: normal;
    }
    .futel-enfoque{
        font-family: Lato;
        font-size: 0.8rem;
        line-height: normal;
        max-width: 800px;

    }
    .futel-enfoque strong {
        color: #000;
        font-family: 'Lato Bold';
        font-size: 0.8rem;
        font-style: normal;
        font-weight: bolder;
        line-height: normal;
    }
    .futel-enfoque-historias{
        font-size: 1.5rem;
        line-height: normal;
    }
    .futel-enfoque-historias strong {
        color: #000;
        font-family: 'Lato Bold';
        font-size: 1.5rem;
        font-style: normal;
        font-weight: bolder;
        line-height: normal;
    }
    .futel-bg-gradient{
        background-image: url('../../../assets/images/home/blue-sparkle-web.png'); 
        background-repeat: no-repeat;
        background-position: left;
    }
    .futel-img-ninas{
        position: relative;
        z-index: 50;
        margin-top: 0vh;
        max-width: 100%;
        margin-left:unset;
        height :unset;
    }

    .z-index-img {
        position: relative;
        z-index: 10;
    }

    .bg-blue {
        background: #00B9F1;
    }
    .bg-blue .title {
            color: #FFF;
            text-align: center;
            font-family: 'Lato Bold';
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 125%; /* 62.5px */
    }
}

@media screen and (min-width:1024px) {

    .mobile-device {
        display: none;
    }
    
    .web-device {
        display: block;
    }

    .futel-title{
        font-size: 48px;
        text-align: center;
    }
    .futel-oportunidades{
        color: #00B9F1;
        font-family: "Rubik One";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;

    }
    .futel-oportunidades strong {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 1.5rem;
        font-style: normal;
        font-weight: bolder;
        line-height: normal;
    }
    .futel-enfoque{
        color: #000;
        font-family: 'Lato';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 140.625% */
        text-align: left;
        padding-right: 5%;
    }
        .futel-enfoque b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 14px;
            font-style: normal;
            font-weight: bolder;
            line-height: 24px;
        }
    
    .futel-enfoque-historias{
        font-size: 1.5rem;
        line-height: normal;
    }
        .futel-enfoque-historias strong {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 1.5rem;
            font-style: normal;
            font-weight: bolder;
            line-height: normal;
        }
    
    .futel-bg-gradient{
        background-image: url('../../../assets/images/home/blue-sparkle-web.png'); 
        background-repeat: no-repeat;
        background-position: left;
    }
    .futel-img-ninas{
        position: relative;
        z-index: 50;
        margin-top: 0;/*-6vh;*/
        max-width: 100%;
        margin-left:unset;
        height :unset;
    }

    .z-index-img {
        position: relative;
        z-index: 10;
    }

    .bg-blue {
        background: #00B9F1;
    }    
        .bg-blue .title {
            color: #FFF;
            text-align: center;
            font-family: 'Lato Bold';
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 125%; /* 62.5px */
        }
    
}

@media screen and (min-width:1280px) {

    .futel-title{
        font-size: 48px;
        text-align: center;
    }
    .futel-oportunidades{
        color: #00B9F1;
        font-family: "Rubik One";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
        .futel-oportunidades strong {
            color: #000;
            font-family: 'Ruda Bold';
            font-size: 35px;
            font-style: normal;
            font-weight: bolder;
            line-height: 2.5rem;
        }
    
    .futel-enfoque{
        color: #000;
        font-family: 'Lato';
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 140.625% */
        text-align: left;

        padding-right: 2%;
    }
        .futel-enfoque b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 20px;
            font-style: normal;
            font-weight: 800;
            line-height: 30px;
        }
    
    .futel-enfoque-historias{
        font-size: 2.5rem;
        line-height: 2.75rem;
        padding-top: 2rem;
    }
    .futel-bg-gradient{
        background-image: url('../../../assets/images/home/blue-sparkle-web.png'); 
        background-repeat: no-repeat;
        background-position: left;
    }
    .futel-img-ninas{
        position: relative;
        z-index: 50;
        margin-top: 0/*-9vh*/;
        max-width: 100%;
        margin-left:unset;
        height :unset;
    }
}

@media screen and (min-width:1366px) {

    .futel-title{
        font-size: 48px;
        text-align: center;
    }
    .futel-oportunidades{
        color: #00B9F1;
        font-family: "Rubik One";
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
        .futel-oportunidades strong {
            color: #000;
            font-family: 'Ruda Bold';
            font-size: 35px;
            font-style: normal;
            font-weight: bolder;
            line-height: 2.5rem;
        }
    
    .futel-enfoque{
        color: #000;
        font-family: 'Lato';
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 140.625% */
        text-align: left;

        padding-right: 5%;
    }
        .futel-enfoque b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 22px;
            font-style: normal;
            font-weight: 800;
            line-height: 32px;
        }

    .futel-enfoque-historias{
        font-size: 2.5rem;
        line-height: 2.75rem;
        padding-top: 2rem;
    }
    .futel-bg-gradient{
        background-image: url('../../../assets/images/home/blue-sparkle-web.png'); 
        background-repeat: no-repeat;
        background-position: left;
    }
    .futel-img-ninas{
        position: relative;
        z-index: 50;
        margin-top: 0;/*-5vh;*/
        max-width: 100%;
        margin-left:unset;
        height :unset;
    }
}

@media screen and (min-width:1440px) {

    .futel-title{
        font-size: 48px;
        text-align: center;
    }
    .futel-oportunidades{
        color: #00B9F1;
        font-family: "Rubik One";
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;

        }
        .futel-oportunidades strong {
            color: #000;
            font-family: 'Ruda Bold';
            font-size: 35px;
            font-style: normal;
            font-weight: bolder;
            line-height: 2.5rem;
        }
    
    .futel-enfoque{
        color: #000;
        font-family: 'Lato';
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px; /* 140.625% */
        text-align: left;

        padding-right: 2%;
    }
        .futel-enfoque b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 25px;
            font-style: normal;
            font-weight: 800;
            line-height: 35px;
        }
    
    .futel-enfoque-historias{
        font-size: 2.5rem;
        line-height: 2.75rem;
        padding-top: 2rem;
    }
    .futel-bg-gradient{
        background-image: url('../../../assets/images/home/blue-sparkle-web.png'); 
        background-repeat: no-repeat;
        background-position: left;
    }
    .futel-img-ninas{
        position: relative;
        z-index: 50;
        margin-top: 0;/*-9vh;*/
        max-width: 100%;
        margin-left:unset;
        height :unset;
    }
}

@media screen and (min-width:1600px) {

    .futel-title{
        font-size: 48px;
        text-align: center;
    }
    .futel-oportunidades{
        color: #00B9F1;
        font-family: "Rubik One";
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;

    }
        .futel-oportunidades strong {
            color: #000;
            font-family: 'Ruda Bold';
            font-size: 3rem;
            font-style: normal;
            font-weight: bolder;
            line-height: 3.5rem;
        }
    
    .futel-enfoque{
        color: #000;
        font-family: 'Lato';
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 38px; /* 140.625% */
        text-align: left;

        padding-right: 2%;
    }
        .futel-enfoque b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 28px;
            font-style: normal;
            font-weight: 800;
            line-height: 38px;
        }
    
    .futel-enfoque-historias{
        font-size: 2.75rem;
        line-height: 2.9375rem;
        padding-top: 2rem;
    }
    .futel-bg-gradient{
        background-image: url('../../../assets/images/home/blue-sparkle-web.png'); 
        background-repeat: no-repeat;
        background-position: left;
    }
    .futel-img-ninas{
        position: relative;
        z-index: 50;
        margin-top: -12vh;
        max-width: 100%;
        margin-left:unset;
        height :unset;
    }
}

@media screen and (min-width: 1920px) {
    .futel-title {
        font-size: 48px;
        text-align: center;
    }
    
    .futel-oportunidades {
        color: #00B9F1;
        font-family: "Rubik One";
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: 112%; /* 42.56px */
        text-transform: uppercase;
    }
    
    .futel-oportunidades strong {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 3rem;
        font-style: normal;
        font-weight: bolder;
        line-height: 3.5rem;
    }
    
    .futel-enfoque {
        color: #000;
        font-family: 'Lato';
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px; /* 140.625% */
        text-align: left;
        padding-right: 5%;
    }
    
    .futel-enfoque b {
        color: #000;
        font-family: 'Lato Bold';
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: 45px;
    }
    
    .futel-enfoque-historias {
        font-size: 2.75rem;
        line-height: 2.9375rem;
        padding-top: 2rem;
    }
    
    .futel-bg-gradient {
        background-image: url('../../../assets/images/home/blue-sparkle-web.png'); 
        background-repeat: no-repeat;
        background-position: left;
    }
    
    .futel-img-ninas {
        position: relative;
        z-index: 50;
        margin-top: -10vh;
        max-width: 100%;
        margin-left: unset;
        height: unset;
    }
}

@media screen and (min-width: 2560px) {
    .futel-title {
        font-size: 48px;
        text-align: center;
    }
    
    .futel-oportunidades {
        color: #00B9F1;
        font-family: "Rubik One";
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
    
    .futel-oportunidades strong {
        color: #000;
        font-family: 'Ruda Bold';
        font-size: 3rem;
        font-style: normal;
        font-weight: bolder;
        line-height: 3.5rem;
    }
    
    .futel-enfoque {
        color: #000;
        font-family: 'Lato';
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 45px; /* 140.625% */
        text-align: left;
        padding-right: 5%;
    }
    
    .futel-enfoque b {
        color: #000;
        font-family: 'Lato Bold';
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: 45px;
    }
    
    .futel-enfoque-historias {
        font-size: 2.75rem;
        line-height: 2.9375rem;
        padding-top: 2rem;
    }
    
    .futel-bg-gradient {
        background-image: url('../../../assets/images/home/blue-sparkle-web.png'); 
        background-repeat: no-repeat;
        background-position: left;
    }
    
    .futel-img-ninas {
        position: relative;
        z-index: 50;
        margin-top: -7vh;
        max-width: 100%;
        margin-left: unset;
        height: unset;
    }
}