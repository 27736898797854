.Dnatural {
    overflow-x: hidden;
    background: linear-gradient(163deg, rgba(255, 255, 255, 0.00) 73.49%, rgba(0, 185, 241, 0.50) 97.31%);
}
    .Dnatural .mobile-device {
        display: block;
    }
    
    .Dnatural .web-device {
        display: none;
    }
    
    .Dnatural .dn-bg-mobile{
        background-color: #E9EDF0;
        /* background: linear-gradient(163deg, rgba(255, 255, 255, 0.00) 73.49%, rgba(0, 185, 241, 0.50) 97.31%); */
    }
    .Dnatural .dn-title{
        color: #00B9F1;
        text-align: center;
        font-family: "Rubik One";
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-shadow: unset;
    }
    .Dnatural .dn-par{
        color: #000;
        text-align: center;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 142%; /* 22.72px */
    }
    .dn-par strong, b {
        color: #000;
        font-family: 'Lato Bold';
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 142%;
    }
    
    .Dnatural strong, b {
        font-weight: bolder;
    }
    
    .Dnatural .icon-width {
        width: 4rem;
    }
    @media (min-width: 768px) and (max-width: 1080px) and (orientation: portrait) {
        .Dnatural .mobile-device {
            display: block;
            background-color: #E9EDF0;
        }
    
        .Dnatural .web-device {
            display: none;
        }
    
        .Dnatural .dn-bg {
            background-color: #E9EDF0;
            background-image: url('../../../assets/images/home/dnatural/bgdn-mobile.png');
            background-repeat: no-repeat;
            background-position: bottom left;
        }

        .Dnatural .dn-title {
            color: #00B9F1;
            text-align: center;
            font-family: "Rubik One";
            font-size: 48px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 48px */
            text-shadow: none;
            padding: 0 1.5rem;
        }

        .Dnatural .dn-par {
            color: #1F282C;
            text-align: center;
            font-family: Lato;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 142%;
        }

        .Dnatural .dn-par strong,
        .Dnatural .dn-par b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 25px;
            font-style: normal;
            font-weight: 800;
            line-height: 142%;
        }

        .Dnatural strong,
        .Dnatural b {
            font-weight: bolder;
        }

        .Dnatural .icon-width {
            width: 100px;
            margin: 1.5rem;
        }
    }
    
    @media (min-width: 1024px) {
        .Dnatural {
            background-image: url('../../../assets/images/home/dnatural/bgdn-mobile.png');
            background-size: cover;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
        }
    
        .Dnatural .mobile-device {
            display: none;
        }
    
        .Dnatural .web-device {
            display: block;
        }
    
        .Dnatural .dn-bg {
            background-color: #E9EDF0;
            background-image: url('../../../assets/images/home/dnatural/bgdn-mobile.png');
            background-position: bottom center;
            width: 100%;
        }
    
        .Dnatural .dn-title {
            color: #00B9F1;
            font-family: "Rubik One";
            font-size: 30px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            text-align: left;
        }
    
        .Dnatural .dn-par {
            color: #000;
            font-family: Lato;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px; /* 140.625% */
            text-align: left;
        }
    
        .Dnatural .dn-par b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 16px;
            font-style: normal;
            font-weight: bolder;
            line-height: 26px;
        }
    }
    
    @media (min-width: 1280px) {
        .Dnatural .dn-title {
            color: #00B9F1;
            font-family: "Rubik One";
            font-size: 55px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
        }
    
        .Dnatural .dn-par {
            color: #000;
            font-family: Lato;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px; /* 140.625% */
        }
    
        .Dnatural .dn-par b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 22px;
            font-style: normal;
            font-weight: 800;
            line-height: 32px;
        }
    }    
    
    @media (min-width: 1366px) {
        .Dnatural .dn-title {
            color: #00B9F1;
            font-family: "Rubik One";
            font-size: 55px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
        }
    
        .Dnatural .dn-par {
            color: #000;
            font-family: Lato;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px; /* 140.625% */
        }
        .Dnatural .dn-par b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 22px;
            font-style: normal;
            font-weight: 800;
            line-height: 32px;
        }
    }    
    
    @media (min-width: 1440px) {
        .Dnatural .dn-title {
            color: #00B9F1;
            font-family: "Rubik One";
            font-size: 60px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
        }
    
        .Dnatural .dn-par {
            color: #000;
            font-family: Lato;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 35px; /* 140.625% */
        }
        .Dnatural .dn-par b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 25px;
            font-style: normal;
            font-weight: 800;
            line-height: 35px;
        }
    }    
    
    @media screen and (min-width: 1600px) {
        .Dnatural .dn-title {
            color: #00B9F1;
            font-family: "Rubik One";
            font-size: 65px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
        }
    
        .Dnatural .dn-par {
            color: #000;
            font-family: Lato;
            font-size: 30px;
            font-style: normal;
            font-weight: 400;
            line-height: 42px; /* 140.625% */
        }
        .Dnatural .dn-par b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 30px;
            font-style: normal;
            font-weight: 800;
            line-height: 42px;
        }
    }    

    @media screen and (min-width: 1920px) {
        .Dnatural .dn-title {
            color: #00B9F1;
            font-family: "Rubik One";
            font-size: 70px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
        }
    
        .Dnatural .dn-par {
            color: #000;
            font-family: Lato;
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 45px; /* 140.625% */
        }
    
        .Dnatural .dn-par b {
            color: #000;
            font-family: 'Lato Bold';
            font-size: 32px;
            font-style: normal;
            font-weight: 800;
            line-height: 45px;
        }
    }
    
