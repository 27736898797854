.ComunidadesDigitales {
  width: 100%;
}

.ComunidadesDigitales .grid {
  display: grid;
}

.ComunidadesDigitales .grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.ComunidadesDigitales .grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.ComunidadesDigitales .gap-4 {
  gap: 1rem; /* 16px */
}

.ComunidadesDigitales .mobile-device {
  display: block;
}

.ComunidadesDigitales .web-device {
  display: none;
}

.ComunidadesDigitales .bg-light {
  background: #FAFFFF;
}

.ComunidadesDigitales .bg-gray {
  background: #EDEDF6;
}

.ComunidadesDigitales .title {
  color: #AD6DC9;
  text-align: center;
  font-family: "Rubik One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 38.4px */
  text-transform: uppercase;
}

.ComunidadesDigitales .subtitle {
  color: #1F282C;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 125%;

  .ComunidadesDigitales .subtitle b {
    color: #A55CC2;
    text-align: center;
    font-family: "Rubik One";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 35px */
  }
}

.ComunidadesDigitales .bg-purple {
  background: rgba(165, 92, 194, 0.92);
}

.ComunidadesDigitales .second-title {
  color: #FFF;
  font-family: Lato;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 28.6px */
  text-align: center;
}

.ComunidadesDigitales .second-paragraph {
  color: #FFF;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  text-align: left;

  .ComunidadesDigitales .second-paragraph b {
    color: #FFF;
    font-family: 'Lato Bold';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 20px */
  }
}

.ComunidadesDigitales .w-card-custom {
  width: 100%;
}
.ComunidadesDigitales .par-comunidad-digital b{
  color: #A55CC2;
}
.ComunidadesDigitales .comunidades li{
  color: #AFAADB;
}

  @media (min-width: 768px) {
    .ComunidadesDigitales .title {
      color: #AD6DC9;
      text-align: center;
      font-family: "Rubik One";
      font-size: 58px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 69.6px */
      text-transform: uppercase;
    }
    
    .ComunidadesDigitales .subtitle {
      color: #1F282C;
      font-family: Lato;
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: 125%;
    }
    
    .ComunidadesDigitales .subtitle b {
      color: #A55CC2;
      text-align: center;
      font-family: "Rubik One";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 50px */
    }
    
    .ComunidadesDigitales .bg-purple {
      background: rgba(165, 92, 194, 0.92);
    }
    
    .ComunidadesDigitales .second-title {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 55px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 71.5px */
    }
    
    .ComunidadesDigitales .second-paragraph {
      color: #FFF;
      font-family: Lato;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      text-align: left;
    }
    
    .ComunidadesDigitales .second-paragraph b {
      color: #FFF;
      font-family: 'Lato Bold';
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%; /* 35px */
    }
    .w-card-custom {
      width: 100%;
    }
  }

    @media (min-width: 1024px) {
      .ComunidadesDigitales .mobile-device {
          display: none;
      }
    
      .ComunidadesDigitales .web-device {
          display: block;
      }
    
      .ComunidadesDigitales .title {
          color: #AD6DC9;
          text-align: start;
          font-family: "Rubik One";
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 69.6px */
          text-transform: uppercase;
      }
    
      .ComunidadesDigitales .subtitle {
          color: #1F282C;
          font-family: Lato;
          font-size: 20px;
          font-style: normal;
          font-weight: 800;
          line-height: 125%;
          text-align: left;
      }
  
      .ComunidadesDigitales .subtitle b {
          color: #A55CC2;
          font-family: "Rubik One";
          font-size: 30px;
          font-style: normal;
          font-weight: 400;
          line-height: 125%; /* 50px */
      }
  
      .ComunidadesDigitales .bg-purple {
          background: rgba(165, 92, 194, 0.92);
      }
    
      .ComunidadesDigitales .second-title {
          color: #FFF;
          text-align: left;
          font-family: Lato;
          font-size: 25px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; /* 71.5px */
      }
    
      .ComunidadesDigitales .second-paragraph {
          color: #FFF;
          font-family: Lato;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 125%;
          text-align: left;
      }
  
      .ComunidadesDigitales .second-paragraph b {
          color: #FFF;
          font-family: 'Lato Bold';
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 125%; /* 35px */
      }
  
      .ComunidadesDigitales .w-card-custom {
          width: 100px;
      }
  }
  
  @media (min-width: 1366px) {
      .ComunidadesDigitales .mobile-device {
          display: none;
      }
    
      .ComunidadesDigitales .web-device {
          display: block;
      }
    
      .ComunidadesDigitales .title {
          color: #AD6DC9;
          text-align: start;
          font-family: "Rubik One";
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 69.6px */
          text-transform: uppercase;
      }
    
      .ComunidadesDigitales .subtitle {
          color: #1F282C;
          font-family: Lato;
          font-size: 20px;
          font-style: normal;
          font-weight: 800;
          line-height: 125%;
          text-align: left;
      }
  
      .ComunidadesDigitales .subtitle b {
          color: #A55CC2;
          font-family: "Rubik One";
          font-size: 30px;
          font-style: normal;
          font-weight: 400;
          line-height: 125%; /* 50px */
      }
  
      .ComunidadesDigitales .bg-purple {
          background: rgba(165, 92, 194, 0.92);
      }
    
      .ComunidadesDigitales .second-title {
          color: #FFF;
          text-align: left;
          font-family: Lato;
          font-size: 25px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; /* 71.5px */
      }
    
      .ComunidadesDigitales .second-paragraph {
          color: #FFF;
          font-family: Lato;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 125%;
          text-align: left;
      }
  
      .ComunidadesDigitales .second-paragraph b {
          color: #FFF;
          font-family: 'Lato Bold';
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 125%; /* 35px */
      }
  
      .ComunidadesDigitales .w-card-custom {
          width: 100px;
      }
  }
  
  @media (min-width: 1440px) {
      .ComunidadesDigitales .title {
          color: #AD6DC9;
          font-family: "Rubik One";
          font-size: 72px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 86.4px */
          text-transform: uppercase;
          text-align: start;
      }
    
      .ComunidadesDigitales .subtitle {
          color: #1F282C;
          font-family: 'Lato Bold';
          font-size: 28px;
          font-style: normal;
          font-weight: 800;
          line-height: 45px;
          text-align: left;
      }
  
      .ComunidadesDigitales .subtitle b {
          color: #A55CC2;
          font-family: "Rubik One";
          font-size: 38px;
          font-style: normal;
          font-weight: 400;
          line-height: 45px; /* 118.421% */
      }
  
      .ComunidadesDigitales .bg-purple {
          background: rgba(165, 92, 194, 0.92);
      }
    
      .ComunidadesDigitales .second-title {
          color: #FFF;
          font-family: Lato;
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; /* 52px */
      }
    
      .ComunidadesDigitales .second-paragraph {
          color: #FFF;
          font-family: Lato;
          font-size: 25px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: left;
      }
  
      .ComunidadesDigitales .second-paragraph b {
          color: #FFF;
          font-family: 'Lato Bold';
          font-size: 25px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
      }
  
      .ComunidadesDigitales .w-card-custom {
          width: 122px;
      }
  }
