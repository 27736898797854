.com-tit{
    color: #00B9F1;
    font-family: "Rubik One";
    font-size: 4.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 5.4rem */
    text-transform: uppercase;
    text-align: start;
    margin-left: 7%;
}
.com-par{
    color: #000;
    font-family: 'Lato Bold';
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 45px; /* 160.714% */
    text-align: start;
}
.par-comunidad-digital .highlight{
    color: #00B9F1;
}
.bg-com-l{
    background-color: #E2F5F2;
    padding: 80px;
}
.bg-objetivos{
    background-color: #00B9F1;
}
.img-objetivos{
    width:122px ;
    margin: .5rem;
}
.obj-tit{
    color: #FFF;
    font-family: Lato;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 52px */
}
.obj-par{
    color: #FFF;
    font-family: Lato;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.obj-par .bold{
    font-family: 'Lato Bold';
    font-weight: bolder;
}

@media (max-width: 1366px) {
    .com-tit{
        color: #00B9F1;
        font-family: "Rubik One";
        font-size: 2.825rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 5.4rem */
        text-transform: uppercase;
        text-align: start;
        margin-left: 10%;
    }
    .com-par{
        color: #1F282C;
        font-family: 'Lato Bold';
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.8125rem;
    }
    .highlight{
        color: #00B9F1;
        font-family: "Rubik One";
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.8125rem;
    }
    .bg-com-l{
        background-color: #E2F5F2;
        padding: 100px;
    }
    .bg-objetivos{
        background-color: #00B9F1;
    }
    .img-objetivos{
        width: 122px ;
        margin: .25rem;
    }
    .obj-tit{
        color: #FFF;
        font-family: Lato;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 52px */
    }
    .obj-par{
        color: #FFF;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .obj-par .bold{
        font-family: 'Lato Bold';
        font-weight: bolder;
    }
}

@media (max-width: 1024px) {
    .com-tit{
        color: #00B9F1;
        font-family: "Rubik One";
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 5.4rem */
        text-transform: uppercase;
        text-align: start;
        margin-left: 10%;
    }
    .com-par{
        color: #1F282C;
        font-family: Lato;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.8125rem;
    }
    .highlight{
        color: #00B9F1;
        font-family: "Rubik One";
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.8125rem;
    }
    .bg-com-l{
        background-color: #E2F5F2;
        padding: 100px;
    }
    .bg-objetivos{
        background-color: #00B9F1;
    }
    .img-objetivos{
        width: 122px ;
        margin: .25rem;
    }
    .obj-tit{
        color: #FFF;
        font-family: Lato;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 52px */
    }
    .obj-par{
        color: #FFF;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .obj-par .bold{
        font-family: 'Lato Bold';
        font-weight: bolder;
    }
}

@media screen and (min-width:768px) and (orientation:portrait){
    .obj-tit{
        text-align: center;
        font-family: Lato;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }
    .obj-par{
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: start;
    }
    .img-objetivos{
        width: 280px;
        padding: 0.25rem;
    }
    .com-tit{
        color: #00B9F1;
        font-family: "Rubik One";
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 5.4rem */
        text-transform: uppercase;
        text-align: center;
        margin-left: 10%;
    }
    .com-par{
        color: #1F282C;
        font-family: Lato;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.8125rem;
    }
    .highlight{
        color: #00B9F1;
        font-family: "Rubik One";
        font-size: 1.85rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.8125rem;
    }
}
@media screen and (max-width:600px){
    .com-tit{
        font-size: 2rem;
        text-align: center;
        margin-left: unset;
    }
    .com-par{
        font-weight: 800;
        font-size: 1.25rem;
        line-height: 1.6875rem;
    }
    .highlight{
        font-size: 1.25rem;
        line-height: 1.875rem;
    }
    .bg-com-l{
        padding: 10%;
    }
    .obj-tit{
        font-size: 1.565rem;
        text-align: center;
        margin: 1.5rem 0;
        padding:0;
    }
    .obj-par{
        font-size: 1rem;
        text-align: left;

    }
    .img-objetivos{
        width: 140px;
        padding: 0.25rem;
    }
    .obj-par .bold{
        font-weight: 400;
    }
}