.golxmex .testimonios-title{
    color: #D95D0D;
    text-align: center;
    font-family: "Rubik One";
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}
.golxmex .img-logo{
    width: 98px;
    margin: auto;
    padding: 3rem 0;
}
.golxmex .cdig-tit{
    color: #1F282C;
    text-align: center;
    font-family: Ruda;
    font-size: 2.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 2.96875rem */
}
.golxmex .test-bg{
    background: #FFF3EB;
}

@media screen and (max-width:600px){
    .golxmex .testimonios-title{
        text-align: center;
        font-size: 1.875rem;
    }
    .golxmex .img-logo{
        width: 68px;
        margin: auto;
        padding: 1rem 0;
    }
    .golxmex .cdig-tit{
        text-align: center;
        font-size: 1rem;
    }
}
