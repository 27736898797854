.Resultados {
  width: 100%;
  background-color: #F4FFFD;
}

.Resultados .grid {
  display: grid;
}

.Resultados .grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.Resultados .grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.Resultados .gap-4 {
  gap: 1rem; /* 16px */
}

.Resultados .gap-5 {
  gap: 2rem; /* 16px */
}

.Resultados .mobile-device {
  display: block;
}

.Resultados .web-device {
  display: none;
}

.Resultados .title-content {
  padding-top: 5%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 5%;
}

.Resultados .title {
  color: #2E9B87;
  text-align: center;
  font-family: 'Ruda Bold';
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.Resultados .title-big {
  color: #72CDBC;
  text-align: center;
  font-family: "Rubik One";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  padding-top: 1rem;
}

.Resultados .subtitle {
  color: #00B9F1;
  text-align: center;
  font-family: "Rubik One";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 1rem;
}

.Resultados .subrayado {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}

.Resultados .subrayado img {
  width: 80%;
}

.Resultados .cards-content {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
}

.Resultados .bg-rectangle-green {
  background-image: url('../../../assets/images/nuestrasAlas/gradient-mobile.png');
  width: 100%;
  height: 150px;
  background-color: #F2F3F4;
  background-size: cover;
  padding: 1rem;
}

.Resultados .paragraph {
  color: #000;
  text-align: center;
  font-family: Lato;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 39px */
}

.Resultados .paragraph b {
  color: #000;
  font-family: 'Lato Bold';
  font-size: 22px;
  font-style: normal;
  font-weight: bold;
  line-height: 130%;
}

.Resultados .bg-rectangle-1 {
  background-image: url('../../../assets/images/nuestrasAlas/resultados/gradient-1.png');
  background-repeat: no-repeat;
  background-position: bottom right;
  width: 100%;
  height: auto;
}

.Resultados .bg-rectangle-2 {
  background-image: url('../../../assets/images/nuestrasAlas/resultados/gradient-2-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

  @media (min-width: 768px) {
    .Resultados .title-content {
        padding-top: 5%;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 2%;
    }
  
    .Resultados .title {
        color: #2E9B87;
        text-align: center;
        font-family: 'Ruda Bold';
        font-size: 36px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }
  
    .Resultados .title-big {
        color: #72CDBC;
        text-align: center;
        font-family: "Rubik One";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        padding-top: 1rem;
    }
    
    .Resultados .subtitle{
        color: #00B9F1;
        text-align: center;
        font-family: "Rubik One";
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 1rem;
    }
    
    .Resultados .cards-content {
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 5%;
    }
    
    .Resultados .bg-rectangle-green {
        background-image: url('../../../assets/images/nuestrasAlas/gradient-mobile.png');
        width: 100%;
        height: 150px;
        background-color: #F2F3F4;
        background-size: cover;
        padding: 1rem;
    }
    
    .Resultados .paragraph {
        color: #000;
        text-align: center;
        font-family: Lato;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 30px */
    }

    .Resultados .paragraph b {
        color: #000;
        font-family: 'Lato Bold';
        font-size: 26px;
        font-style: normal;
        font-weight: bold;
        line-height: 125%;
    }
    
    .Resultados .bg-rectangle-1 {
        background-image: url('../../../assets/images/nuestrasAlas/resultados/gradient-1.png');
        background-repeat: no-repeat;
        background-position: bottom right;
        width: 100%;
        height: auto;
    }
    
    .Resultados .bg-rectangle-2 {
        background-image: url('../../../assets/images/nuestrasAlas/resultados/gradient-2.png');
        background-repeat: no-repeat;
        background-position: bottom right;
        width: 100%;
        height: auto;
    }
}

  @media (min-width: 1024px) {
      .Resultados .mobile-device {
          display: none;
      }
    
      .Resultados .web-device {
          display: block;
      }

      .Resultados .title-content {
          padding-top: 5%;
          padding-left: 17%;
          padding-right: 17%;
          padding-bottom: 2%;
      }

      .Resultados .title {
          color: #72CDBC;
          text-align: center;
          font-family: Ruda;
          font-size: 35px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
      }

      .Resultados .title-big {
          color: #72CDBC;
          text-align: center;
          font-family: "Rubik One";
          font-size: 35px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: uppercase;
          padding-top: 1rem;
      }

      .Resultados .subtitle {
          color: #00B9F1;
          text-align: center;
          font-family: "Rubik One";
          font-size: 27px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 1rem;
      }
    
      .Resultados .subrayado {
          display: flex;
          justify-content: center;
          margin-top: 0.5rem;
      }

      .Resultados .subrayado img {
          width: 100px;
      }

      .Resultados .cards-content {
          padding-left: 5%;
          padding-right: 5%;
          padding-top: 5%;
      }
    
      .Resultados .bg-rectangle-green {
          background-image: url('../../../assets/images/nuestrasAlas/gradient-mobile.png');
          width: 100%;
          height: 150px;
          background-color: #F2F3F4;
          background-size: cover;
          padding: 1rem;
      }
    
      .Resultados .paragraph {
          color: #000;
          text-align: center;
          font-family: Lato;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; /* 39px */
      }

      .Resultados .paragraph b {
          color: #000;
          font-family: 'Lato Bold';
          font-size: 20px;
          font-style: normal;
          font-weight: bold;
          line-height: 130%;
      }
    
      .Resultados .bg-rectangle-1 {
          background-image: url('../../../assets/images/nuestrasAlas/resultados/gradient-1-web.png');
          background-repeat: no-repeat;
          background-position: bottom right;
          width: 100%;
          height: auto;
      }
    
      .Resultados .bg-rectangle-2 {
          background-image: url('../../../assets/images/nuestrasAlas/resultados/gradient-2.png');
          background-repeat: no-repeat;
          background-position: bottom right;
          width: 100%;
          height: auto;
      }
  }

  @media (min-width: 1366px) {
    .web-device .Resultados .mobile-device {
        display: none;
    }

    .web-device .Resultados .web-device {
        display: block;
    }

    .Resultados .title-content {
        padding-top: 5%;
        padding-left: 17%;
        padding-right: 17%;
        padding-bottom: 2%;
    }

    .Resultados .title {
        color: #2E9B87;
        text-align: center;
        font-family: 'Ruda Bold';
        font-size: 35px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }

    .Resultados .title-big {
        color: #72CDBC;
        text-align: center;
        font-family: "Rubik One";
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        padding-top: 1rem;
    }

    .Resultados .subtitle {
        color: #00B9F1;
        text-align: center;
        font-family: "Rubik One";
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 1rem;
    }

    .Resultados .subrayado {
        display: flex;
        justify-content: center;
        margin-top: 0.5rem;
    }

    .Resultados .subrayado img {
        width: 80%;
    }

    .Resultados .cards-content {
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 5%;
    }

    .Resultados .bg-rectangle-green {
        background-image: url('../../../assets/images/nuestrasAlas/gradient-mobile.png');
        width: 100%;
        height: 150px;
        background-color: #F2F3F4;
        background-size: cover;
        padding: 1rem;
    }

    .Resultados .paragraph {
        color: #000;
        text-align: center;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 39px */
    }

    .Resultados .paragraph b {
        color: #000;
        font-family: 'Lato Bold';
        font-size: 20px;
        font-style: normal;
        font-weight: bold;
        line-height: 130%;
    }

    .Resultados .bg-rectangle-1 {
        background-image: url('../../../assets/images/nuestrasAlas/resultados/gradient-1-web.png');
        background-repeat: no-repeat;
        background-position: bottom right;
        width: 100%;
        height: auto;
    }

    .Resultados .bg-rectangle-2 {
        background-image: url('../../../assets/images/nuestrasAlas/resultados/gradient-2.png');
        background-repeat: no-repeat;
        background-position: bottom right;
        width: 100%;
        height: auto;
    }
  }

  @media (min-width: 1440px) {
    .Resultados .title-content {
        padding-top: 5%;
        padding-left: 25%;
        padding-right: 25%;
        padding-bottom: 1%;
    }

    .Resultados .title {
        color: #2E9B87;
        font-family: 'Ruda Bold';
        font-size: 35px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }

    .Resultados .title-big {
        color: #51BCA8;
        text-align: center;
        font-family: "Rubik One";
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }

    .Resultados .subtitle {
        color: #00B9F1;
        text-align: center;
        font-family: "Rubik One";
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 1rem;
    }

    .Resultados .subrayado {
        display: flex;
        justify-content: center;
        margin-top: 0.5rem;
    }

    .Resultados .subrayado img {
        width: 80%;
    }

    .Resultados .cards-content {
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 5%;
    }

    .Resultados .bg-rectangle-green {
        background-image: url('../../../assets/images/nuestrasAlas/gradient-mobile.png');
        width: 100%;
        height: 150px;
        background-color: #F2F3F4;
        background-size: cover;
        padding: 1rem;
    }

    .Resultados .paragraph {
        color: #000;
        text-align: center;
        font-family: Lato;
        font-size: 23px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 39px */
    }

    .Resultados .paragraph b {
        color: #000;
        font-family: 'Lato Bold';
        font-size: 23px;
        font-style: normal;
        font-weight: 800;
        line-height: 130%;
    }

    .Resultados .bg-rectangle-1 {
        background-image: url('../../../assets/images/nuestrasAlas/resultados/gradient-1-web.png');
        background-repeat: no-repeat;
        background-position: bottom right;
        width: 100%;
        height: auto;
    }

    .Resultados .bg-rectangle-2 {
        background-image: url('../../../assets/images/nuestrasAlas/resultados/gradient-2.png');
        background-repeat: no-repeat;
        background-position: bottom right;
        width: 100%;
        height: auto;
    }
  }

  @media (min-width: 1920px) {
    .Resultados .title-content {
        padding-top: 5%;
        padding-left: 25%;
        padding-right: 25%;
        padding-bottom: 1%;
    }

    .Resultados .title {
        color: #2E9B87;
        font-family: 'Ruda Bold';
        font-size: 35px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }

    .Resultados .title-big {
        color: #51BCA8;
        text-align: center;
        font-family: "Rubik One";
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }

    .Resultados .subtitle {
        color: #00B9F1;
        text-align: center;
        font-family: "Rubik One";
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 1rem;
    }
  
    .Resultados .subrayado {
        display: flex;
        justify-content: center;
        margin-top: 0.5rem;
    }

    .Resultados .subrayado img {
        width: 80%;
    }

    .Resultados .cards-content {
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 5%;
    }
  
    .Resultados .bg-rectangle-green {
        background-image: url('../../../assets/images/nuestrasAlas/gradient-mobile.png');
        width: 100%;
        height: 150px;
        background-color: #F2F3F4;
        background-size: cover;
        padding: 1rem;
    }
  
    .Resultados .paragraph {
        color: #000;
        text-align: center;
        font-family: Lato;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 39px */
    }

    .Resultados .paragraph b {
        color: #000;
        font-family: 'Lato Bold';
        font-size: 26px;
        font-style: normal;
        font-weight: 800;
        line-height: 130%;
    }
  
    .Resultados .bg-rectangle-1 {
        background-image: url('../../../assets/images/nuestrasAlas/resultados/gradient-1-web.png');
        background-repeat: no-repeat;
        background-position: bottom right;
        width: 100%;
        height: auto;
    }
  
    .Resultados .bg-rectangle-2 {
        background-image: url('../../../assets/images/nuestrasAlas/resultados/gradient-2.png');
        background-repeat: no-repeat;
        background-position: bottom right;
        width: 100%;
        height: auto;
    }
}