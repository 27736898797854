.Programas {
  width: 100%;
  overflow-x: hidden;
  .next-arrow-custom, .prev-arrow-custom{
    background-color: #fff !important;
    border: 0px !important;
    width: 3rem !important;
    height: 3rem !important;
    font-size: 1.75rem !important;
  }
}
.title-comunidades-dig{
  font-size: 32px;
}
.par-comunidad-digital {
  text-align: left;
  color: #000;
  font-family: 'Ruda Bold';
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 132%;
}

.par-comunidad-digital b , .par-comunidad-digital span{
  font-family: "Rubik One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 132%;

}
.comunidades li{
  list-style: square;
  color: #92CDED;
  font-size: 18px;
}
  .Carousel .thumbnails-section{
    max-width: 85%;
    margin: auto;
  }

  .title-onu , .obj-tit{
    color: #FFF;
    text-align: center;
    font-family: 'Lato Bold';
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 28.6px */
  }
  
  .paragraph-onu , .obj-par{
    color: #FFF;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 20px */
    text-align: left;
  }
  .paragraph-onu b {
    font-family: 'Lato Bold';
    font-size: inherit;
    font-style: normal;
    font-weight: 800;
    line-height: inherit;
  }
  .img-objetivos{
    width: 127px;
  }


.Programas .testimonios-titulo {
  text-align: center;
  font-family: "Rubik One";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.Programas .comunidades-digitales-titulo {
  font-family: "Rubik One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
  text-align: center;
}

.Programas .comunidades-digitales-items {
  color: #1F282C;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 132%;
  text-align: left;
}
  .Programas .comunidades-digitales-items .highlight {
    font-family: "Rubik One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 132%;
  }


  @media (min-width: 768px) {
    .par-comunidad-digital {
      text-align: left;
      font-size: 26px;
    }

    .par-comunidad-digital b, .par-comunidad-digital span {
      text-align: left;
      font-size: 26px;
    }
    .title-onu {
      text-align: center;
      font-size: 48px;
    }
    .paragraph-onu {
      font-size: 25px;
      text-align: left;
    }
    .comunidades li{
      list-style: square;
      color: #92CDED;
      font-size: 25px;
    }

    .Programas .testimonios-titulo {
      text-align: center;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
    
    .Programas .comunidades-digitales-titulo {
      font-family: "Rubik One";
      font-size: 44px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      text-transform: uppercase;
      text-align: center;
    }
    
    .Programas .comunidades-digitales-items {
      color: #1F282C;
      font-family: Lato;
      font-size: 26px;
      font-style: normal;
      font-weight: 800;
      line-height: 132%;
      text-align: left;
    }
      .Programas .comunidades-digitales-items .highlight {
        font-family: "Rubik One";
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 132%;
      }
    }
  
  @media (min-width: 1024px) {
    .par-comunidad-digital {
      text-align: left;
      font-size: 20px;
    }
    .par-comunidad-digital b, .par-comunidad-digital span  {
      text-align: left;
      font-size: 20px;
    }
    .title-onu {
      text-align: left;
      font-size: 30px;
    }
    .paragraph-onu {
      font-size: 20px;
      text-align: left;
    }
  }

  @media (min-width: 1366px) {
    .par-comunidad-digital {
      text-align: left;
      font-size: 20px;
    }
    .par-comunidad-digital b, .par-comunidad-digital span  {
      text-align: left;
      font-size: 20px;
    }
    .title-onu {
      text-align: left;
      font-size: 48px;
    }
    .paragraph-onu {
      font-size: 25px;
      text-align: left;
    }

    .Programas .testimonios-titulo {
      text-align: center;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
    
    .Programas .comunidades-digitales-titulo {
      font-family: "Rubik One";
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      text-transform: uppercase;
      text-align: left;
    }
    
    .Programas .comunidades-digitales-items {
      color: #1F282C;
      font-family: Lato;
      font-size: 26px;
      font-style: normal;
      font-weight: 800;
      line-height: 132%;
      text-align: left;
  }    
      .Programas .comunidades-digitales-items .highlight {
        font-family: "Rubik One";
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 132%;
      }
    }


  @media (min-width: 1280px) {
    .par-comunidad-digital {
      text-align: left;
      font-size: 26px;
    }

    .par-comunidad-digital b, .par-comunidad-digital span  {
      text-align: left;
      font-size: 26px;
    }
    .title-onu {
      text-align: left;
      font-size: 25px;
    }
    .paragraph-onu {
      font-size: 18px;
      text-align: left;
    }
  }

  @media (min-width: 1330px) {
    .par-comunidad-digital {
      text-align: left;
      font-size: 26px;
    }

    .par-comunidad-digital b, .par-comunidad-digital span  {
      text-align: left;
      font-size: 26px;
    }
    .title-onu {
      text-align: left;
      font-size: 28px;
    }
    .paragraph-onu {
      font-size: 20px;
      text-align: left;
    }
  }

  @media (min-width: 1366px) {
    .par-comunidad-digital {
      text-align: left;
      font-size: 26px;
    }

    .par-comunidad-digital b , .par-comunidad-digital span {
      text-align: center;
      font-size: 26px;
    }
    .title-onu {
      text-align: left;
      font-size: 28px;
    }
    .paragraph-onu {
      font-size: 20px;
      text-align: left;
    }

    .Programas .testimonios-titulo {
      text-align: center;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
    
    .Programas .comunidades-digitales-titulo {
      font-family: "Rubik One";
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      text-transform: uppercase;
      text-align: left;
    }
    
    .Programas .comunidades-digitales-items {
      color: #1F282C;
      font-family: Lato;
      font-size: 26px;
      font-style: normal;
      font-weight: 800;
      line-height: 132%;
      text-align: left;
    }
      .Programas .comunidades-digitales-items .highlight {
        font-family: "Rubik One";
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 132%;
      }
    }
  
  @media (min-width: 1440px) {
    .par-comunidad-digital {
      text-align: left;
      font-size: 30px;
    }
    .par-comunidad-digital b , .par-comunidad-digital span {
      text-align: center;
      font-size: 30px;
    }
    .title-onu {
      text-align: left;
      font-size: 28px;
    }
    .paragraph-onu {
      font-size: 20px;
      text-align: left;
    }

    .Programas .testimonios-titulo {
      text-align: center;
      font-family: "Rubik One";
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
    
    .Programas .comunidades-digitales-titulo {
      font-family: "Rubik One";
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      text-transform: uppercase;
      text-align: left;
    }
    
    .Programas .comunidades-digitales-items {
      color: #1F282C;
      font-family: Lato;
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 132%;
      text-align: left;
    }
      .Programas .comunidades-digitales-items .highlight {
        font-family: "Rubik One";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 132%;
      }
    }
  
  @media (min-width: 1920px) {
    .par-comunidad-digital {
    text-align: left;
    font-size: 34px;
    }
    .par-comunidad-digital b , .par-comunidad-digital span {
      text-align: center;
      font-size:34px;
    }
    .title-onu {
      text-align: left;
      font-size: 40px;
    }
    .paragraph-onu {
      font-size: 25px;
      text-align: left;
    }

    .Programas .testimonios-titulo {
      text-align: center;
      font-family: "Rubik One";
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
    
    .Programas .comunidades-digitales-titulo {
      font-family: "Rubik One";
      font-size: 72px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      text-transform: uppercase;
      text-align: left;
    }
    
    .Programas .comunidades-digitales-items {
      color: #1F282C;
      font-family: Lato;
      font-size: 34px;
      font-style: normal;
      font-weight: 800;
      line-height: 132%;
      text-align: left;
    }
      .Programas .comunidades-digitales-items .highlight {
        font-family: "Rubik One";
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 132%;
      }
    }


