.EnAccion {
  width: 100%;
  background-color: #E9EDF0;
}

.EnAccion .mobile-device {
  display: block;
}

.EnAccion .web-device {
  display: none;
}

.EnAccion .title {
  color: #8780BD;
  text-align: center;
  font-family: "Rubik One";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  padding-top: 5px;
}

.EnAccion .paragraph1 {
  color: #1F282C;
  font-family: Lato;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 12px;
}

.EnAccion .paragraph2 {
  color: #1F282C;
  font-family: Lato;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 8px;
}

.EnAccion .paragraph1 b,
.EnAccion .paragraph2 b {
  font-family: 'Lato Bold';
  font-weight: 400;
}

.EnAccion .bg-purple {
  background: #8780BD;
  width: 100%;
  height: 168px;
}

.EnAccion .title2 {
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  padding-left: 30px;
  padding-right: 30px;
}

.EnAccion .bg-gray {
  background: #69747C;
  width: 100%;
  height: 185px;
}

.EnAccion .title3 {
  color: #FFF;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  padding-left: 30px;
  padding-right: 30px;
}

.EnAccion .bg-blue {
  background: #00B9F1;
  width: 100%;
  height: 168px;
}

.EnAccion .title4 {
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  padding-left: 6%;
  padding-right: 6%;
}

.EnAccion .bg-rectangle {
  background-image: url('../../../assets/images/igualdad/rectangle-en-accion.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.EnAccion {
  /* Tablet  */
  @media (min-width: 768px) {
    .title {
      color: #8780BD;
      text-align: center;
      font-family: "Rubik One";
      font-size: 62px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      padding-top: 70px;
    }
  
    .paragraph1 {
      color: #1F282C;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 142%;
      padding-left: 95px;
      padding-right: 95px;
      margin-top: 32px;
    }
  
    .paragraph2 {
      color: #1F282C;
      text-align: center;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 142%; /* 34.08px */
      padding-left: 95px;
      padding-right: 95px;
      margin-top: 12px;
    }

    .bg-purple {
      background: #8780BD;
      width: 100%;
      height: 308px;
    }

    .title2 {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%; /* 60px */
      padding-left: 30px;
      padding-right: 30px;
    }

    .bg-gray {
      background: #69747C;
      width: 100%;
      height: 434px;
    }
  
    .title3 {
      color: #FFF;
      font-family: Lato;
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      padding-left: 40px;
      padding-right: 40px;
    }
    
    .bg-blue {
      background: #00B9F1;
      width: 100%;
      height: 308px;
    }
  
    .title4 {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 42px;
      font-style: normal;
      font-weight: 500;
      line-height: 125%; /* 52.5px */
      padding-left: 90px;
      padding-right: 90px;
    }
  }

  @media (min-width: 1024px) {
    .mobile-device {
      display: none;
    }
  
    .web-device {
      display: block;
    }

    .bg-rectangle {
      background-image: url('../../../assets/images/igualdad/rectangle-en-accion.png') ;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .title {
      color: #8780BD;
      font-family: "Rubik One";
      font-size: 41px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      text-align: left;
      margin-left: 115px;
      margin-top: 88px;
      padding: 1rem;
      text-align: left;
    }

    .paragraph1 {
      color: #1F282C;
      font-family: Lato;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 45px;
      margin-top: 88px;
      text-align: left;
      padding-left: 50px;
    }

    .paragraph2 {
      color: #1F282C;
      font-family: Lato;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 45px;
      margin-top: 88px;
      text-align: left;
      padding-left: 50px;
    }

    .bg-purple {
      background: #8780BD;
      width: 100%;
      height: 277px;
    }

    .title2 {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 35px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%; /* 62.5px */
      padding-left: 20%;
      padding-right: 20%;
    }

    .bg-gray {
      background: #69747C;
      width: 100%;
      height: 300px;
    }

    .bg-blue {
      background: #00B9F1;
      width: 100%;
      height: 300px;
    }

    .title3 {
      color: #FFF;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      text-align: left;
    }

    .title4 {
      color: #FFF;
      font-family: Lato;
      font-size: 25px;
      font-style: normal;
      font-weight: 500;
      line-height: 125%; /* 43.75px */
      text-align: left;
    }
  }

  @media (min-width: 1440px) {
    .title {
      color: #8780BD;
      font-family: "Rubik One";
      font-size: 41px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      margin-left: 115px;
      margin-top: 88px;
      padding: 1rem;
      text-align: left;
    }

    .title2 {
      color: #FFF;
      text-align: center;
      font-family: Lato;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%; /* 62.5px */
      padding-left: 26.5%;
      padding-right: 26.5%;
      
    }

    .paragraph1 {
      color: #1F282C;
      font-family: Lato;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 134%;
      margin-top: 0px;
      text-align: left;
      padding-left: 50px;

      b {
        color: #1F282C;
        font-family: 'Lato Bold';
        font-size: 30px;
        font-style: normal;
        font-weight: 900;
        line-height: 134%; /* 40.2px */
      }
    }

    .paragraph2 {
      color: #1F282C;
      font-family: Lato;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 45px;
      margin-top: 88px;
      text-align: left;
      padding-left: 50px;
    }

    .bg-gray {
      background: #69747C;
      width: 100%;
      height: 350px;
    }

    .bg-blue {
      background: #00B9F1;
      width: 100%;
      height: 350px;
    }

    .title3 {
      color: #FFF;
      font-family: Lato;
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      text-align: left;

      b {
        color: #FFF;
        font-family: Lato;
        font-size: 35px;
        font-style: normal;
        font-weight: 900;
        line-height: 125%; /* 43.75px */
      }
    }

    .title4 {
      color: #FFF;
      font-family: Lato;
      font-size: 35px;
      font-style: normal;
      font-weight: 500;
      line-height: 125%; /* 43.75px */
      text-align: left;
    }
  }
}