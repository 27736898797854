
    .DesastresNaturalesProgramas .prog-bg{
        background-color: #E9EDF0;
        background-image: url('../../../assets/images/desastres/pog-grad.png');
        background-position: bottom right;
        background-repeat: no-repeat;

    }
    .DesastresNaturalesProgramas .prog-img{
        border: 20px solid #69747C;
    }
    .DesastresNaturalesProgramas .prog-tit{
        color: #3DB5CF;
        font-family: "Rubik One";
        font-size: 2.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: start;
        margin: 1.5rem 2.5rem 2.5rem 1rem;
    }
    .DesastresNaturalesProgramas .prog-descr{
        color: #1F282C;
        font-family: Lato;
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 152%; /* 2.85rem */
        text-align: start;
        margin: 1.5rem 2.5rem 2.5rem 1rem;
    }
    .DesastresNaturalesProgramas .prog-descr .bold{
        font-family: 'Lato Bold';
        font-weight: 900;
    }
    
    @media screen and (max-width:1440px){}
    @media screen and (max-width:1366px){
        .DesastresNaturalesProgramas .prog-descr{
            color: #1F282C;
            font-family: Lato;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 152%; /* 2.85rem */
            text-align: start;
        }

        .DesastresNaturalesProgramas .prog-tit{
            color: #3DB5CF;
            font-family: "Rubik One";
            font-size: 2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            text-align: start;
            margin: 1rem 1.5rem 1.5rem 1rem;
        }
    }
    @media screen and (min-width:768px) and (orientation:portrait){
    .DesastresNaturalesProgramas .prog-tit{
        color: #00B9F1;
        font-size: 2.875rem;
        text-align: start;
        margin: 1.5rem 0;
    }
    .DesastresNaturalesProgramas .prog-descr{
        font-size: 1.5rem;
        line-height: 142%; /* 2.85rem */
        text-align: start;
        margin: 1.5rem 0;
    }
    .DesastresNaturalesProgramas .col-lg-5 {
        width: 78%;
    }
    }
    @media screen and (max-width:600px){
        .DesastresNaturalesProgramas .prog-tit{
            color: #00B9F1;
            font-size: 1.25rem;
            text-align: start;
            margin: 1.5rem 0 0;
        }
        .DesastresNaturalesProgramas .prog-descr{
            font-size: 0.9rem;
            line-height: 134%; /* 2.85rem */
            text-align: start;
            margin: 0 0 1.5rem;
        }
        .DesastresNaturalesProgramas .prog-descr .bold{
            font-weight: 400;
        }
        .DesastresNaturalesProgramas .prog-img{
            border: 10px solid #69747C;
        }
        .DesastresNaturalesProgramas .prog-bg{
            padding-top: 0rem;
            padding-bottom: 0rem;
        }
    }