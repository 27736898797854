.Home {
  width: 100%;
  overflow-x: hidden;
}
  .Home .mobile-device {
    display: block;
    text-align: center;
  }

  .Home .web-device {
    display: none;
  }

  @media (min-width: 1024px) {
    .Home .mobile-device {
      display: none;
    }
  
    .Home .web-device {
      display: block;
    }
  }