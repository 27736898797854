.temprano .bg-suave {
    background-image: url('../../../../assets/images/programas/temprano/rect-emptemp.png');
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
}

.temprano .et-title {
    color: var(--et-color);
    font-family: "Rubik One";
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    text-align: start;
}
.temprano .et-subt{
        color: #1F282C;
        text-align: center;
        font-family: Ruda;
        font-size: 25px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
}
.temprano .et-par {
    color: #1F282C;
    font-family: Lato;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-align: start;
}

.temprano .et-par .highlight {
    color: var(--et-color);
    font-family: Lato;
    font-size: 38px;
    font-style: normal;
    font-weight: 800;
    line-height: 138%;
}

.temprano .et-img {
    width: 100%;
}

.temprano .et-quote {
    color: #FFF;
    text-align: center;
    font-family: Lato;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 43.75px */
}
    .temprano .et-quote b {
        color: #FFF;
        font-family: 'Lato Bold';
        font-size: 35px;
        font-style: normal;
        font-weight: 800;
        line-height: 125%;
    }


.temprano .mh-250 {
    min-height: 200px;
    padding: 3rem 0;
}

.temprano .et-par .bold,
.temprano .et-quote .bold {
    font-family: 'Lato Bold';
    font-weight: bolder;
}

.temprano .bg-et {
    background-color: var(--et-color);
}

.temprano .bg-et-gris {
    background-color: var(--et-gris);
}

.temprano .main-title-programas {
    color: var(--et-color);
}

@media screen and (max-width:1366px) {
    .temprano .et-title {
        color: var(--et-color);
        font-family: "Rubik One";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: start;
    }
    .temprano .et-subt{
        color: #1F282C;
        text-align: center;
        font-family: Ruda;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
}
    .temprano .et-par {
        color: #1F282C;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-align: start;
    }
    
    .temprano .et-par .highlight {
        color: var(--et-color);
        font-family: Lato;
        font-size: 38px;
        font-style: normal;
        font-weight: 800;
        line-height: 138%;
    }
    
    .temprano .et-img {
        width: 100%;
    }
    
    .temprano .et-quote {
        color: #FFF;
        font-family: Lato;
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%;
        text-align: center;
    }
        .temprano .et-quote b {
            color: #FFF;
            font-family: 'Lato Bold';
            font-size: 25px;
            font-style: normal;
            font-weight: 800;
            line-height: 125%;
        }

    
    .temprano .mh-250 {
        min-height: 200px;
        padding: 3rem 0;
    }
    
    .temprano .et-par .bold,
    .temprano .et-quote .bold {
        font-family: 'Lato Bold';
        font-weight: bolder;
    }
    
    .temprano .bg-et {
        background-color: var(--et-color);
    }
    
    .temprano .bg-et-gris {
        background-color: var(--et-gris);
    } 

}

@media screen and (max-width:1024px) {
    .temprano .et-title {
        color: var(--et-color);
        font-family: "Rubik One";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: start;
    }
    .temprano .et-subt{
        color: #1F282C;
        text-align: center;
        font-family: Ruda;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
}
    .temprano .et-par {
        color: #1F282C;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-align: start;
    }
    
    .temprano .et-par .highlight {
        color: var(--et-color);
        font-family: Lato;
        font-size: 38px;
        font-style: normal;
        font-weight: 800;
        line-height: 138%;
    }
    
    .temprano .et-img {
        width: 100%;
    }
    
    .temprano .et-quote {
        color: #FFF;
        font-family: Lato;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%;
        text-align: center;
    }
        .temprano .et-quote b {
            color: #FFF;
            font-family: 'Lato Bold';
            font-size: 22px;
            font-style: normal;
            font-weight: 800;
            line-height: 125%;
        }

    
    .temprano .mh-250 {
        min-height: 200px;
        padding: 3rem 0;
    }
    
    .temprano .et-par .bold,
    .temprano .et-quote .bold {
        font-family: 'Lato Bold';
        font-weight: bolder;
    }
    
    .temprano .bg-et {
        background-color: var(--et-color);
    }
    
    .temprano .bg-et-gris {
        background-color: var(--et-gris);
    } 

}

@media screen and (max-width:900px){
    .temprano .et-title {
        color: var(--et-color);
        font-family: "Rubik One";
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: center;
        margin-top: 1.5rem;
    }
    .temprano .et-subt{
        color: #1F282C;
        text-align: center;
        font-family: Ruda;
        font-size: 26px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
}
    .temprano .et-par {
        color: #1F282C;
        font-family: Lato;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-align: center;
    }
    
    .temprano .et-par .highlight {
        color: var(--et-color);
        font-family: Lato;
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        line-height: 138%;
    }
    
    .temprano .et-img {
        width: 100%;
    }
    
    .temprano .et-quote {
        color: #FFF;
        font-family: Lato;
        font-size: 38px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%;
        text-align: center;
    }
        .temprano .et-quote b {
            color: #FFF;
            font-family: 'Lato Bold';
            font-size: 38px;
            font-style: normal;
            font-weight: 800;
            line-height: 125%;
        }

    
    .temprano .mh-250 {
        min-height: 200px;
        padding: 1.5rem;
    }    

}

@media screen and (max-width:600px){
    .temprano .et-title {
        color: var(--et-color);
        font-family: "Rubik One";
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-align: center;
        margin-top: 1.5rem;
    }
    .temprano .et-subt{
        color: #1F282C;
        text-align: center;
        font-family: Ruda;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
}
    .temprano .et-par {
        color: #1F282C;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-align: center;
    }
    
    .temprano .et-par .highlight {
        color: var(--et-color);
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 138%;
    }
    
    .temprano .et-img {
        width: 100%;
    }
    
    .temprano .et-quote {
        color: #FFF;
        text-align: center;
        font-family: Lato;
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 23.75px */
    }
        .temprano .et-quote b {
            color: #FFF;
            font-family: 'Lato Bold';
            font-size: 19px;
            font-style: normal;
            font-weight: 800;
            line-height: 125%;
        }

    .et-quote .w-50{
        width: 75% !important;
    }
    
    .temprano .mh-250 {
        min-height: 200px;
        padding: 1.5rem;
    }    

}